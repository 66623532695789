import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import styles from './styles.module.scss';
import { useReaction } from '@utils/hooks';
import { TemplateModel, TemplateSwapModel } from '@models/Templates';
import { templatesControllerStore } from '@store/templatesControllerStore';
import { schemaCreateTemplate } from '@pages/templatesController/createTemplateControllerModal/schema';
import { Button, Input, Select } from 'antd';
import { FieldContainer } from '@components/Form/FieldContainer/FieldContainer';
import { ValidationMessage } from '@components/Form/ValidationMessage/ValidationMessage';
import { TargetTypeEnum, TargetTypesList } from 'types/targetTypes';
import { TargetTemplateTypeEnum, TargetTemplateTypeList } from 'types/targetTemplateTypes';
import cn from 'classnames';
import { observer } from 'mobx-react';

export const EditForm: React.FC<{
    localSwaps: TemplateSwapModel[];
    setIdsOfEditedRow: Dispatch<SetStateAction<number[]>>;
    actions: () => JSX.Element[];
    setLocalData: (val: TemplateSwapModel[]) => void;
}> = observer(({ localSwaps, setIdsOfEditedRow, actions, setLocalData }) => {
    const [template] = useReaction<TemplateModel | null>(() => templatesControllerStore.template);
    const [isSaving] = useReaction<boolean>(() => templatesControllerStore.isSaving);
    const [isLoading] = useReaction<boolean>(() => templatesControllerStore.isLoading);
    const isTouchedCustom = !(JSON.stringify(template?.swaps) === JSON.stringify(localSwaps));

    const {
        handleSubmit,
        reset,
        watch,
        formState: { errors, isValid, isDirty, dirtyFields },
        control,
    } = useForm<TemplateModel>({
        defaultValues: {},
        mode: 'all',
        resolver: yupResolver(schemaCreateTemplate),
    });

    useEffect(() => {
        if (template) {
            reset(template);
        }
    }, [template]);

    const onSave: SubmitHandler<TemplateModel> = (data) => {
        templatesControllerStore
            .saveTemplate({
                ...data,
                swaps: localSwaps,
            })
            .finally(() => {
                templatesControllerStore.getTemplateById(data.id).then((teplate) => {
                    templatesControllerStore.template = teplate;
                    setLocalData(teplate.swaps);
                });
            });
        setTimeout(() => {
            setIdsOfEditedRow([]);
        }, 500); // TODO
    };

    const takerType = watch('type');

    return (
        <div className={styles.container}>
            <form id="editPanelTop" className={styles.form}>
                <div className={styles.panelTopFields}>
                    <div className={styles.field}>
                        <div className={styles.fieldLabel}>Name:</div>
                        <FieldContainer className={styles.fieldContainer}>
                            <Controller
                                control={control}
                                name="name"
                                render={({ field, field: { onChange } }) => (
                                    <Input
                                        {...field}
                                        disabled={isLoading}
                                        onChange={(value) => {
                                            onChange(value);
                                        }}
                                        className={cn({
                                            changed: dirtyFields.name,
                                            invalid: !!errors.name?.message,
                                        })}
                                        value={field.value ?? undefined}
                                        size="small"
                                    />
                                )}
                            />
                            <ValidationMessage message={errors.name?.message} />
                        </FieldContainer>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.fieldLabel}>Server Type:</div>
                        <FieldContainer className={styles.fieldContainer}>
                            <Controller
                                control={control}
                                name="type"
                                render={({ field }) => {
                                    return (
                                        <div className={styles.textValue}>
                                            {TargetTypesList.find((item) => item.key === field.value)?.title ?? ''}
                                        </div>
                                    );
                                }}
                            />
                        </FieldContainer>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.fieldLabel}>Template Type:</div>
                        <FieldContainer className={styles.fieldContainer}>
                            <Controller
                                control={control}
                                name="templateType"
                                render={({ field: { onChange, value } }) => {
                                    return (
                                        <Select
                                            placeholder="- Choose -"
                                            allowClear={false}
                                            size="small"
                                            className={cn({
                                                changed: dirtyFields.templateType,
                                                invalid: !!errors.templateType?.message,
                                            })}
                                            value={value}
                                            onChange={(val) => {
                                                onChange(val);
                                                if (templatesControllerStore.template) {
                                                    templatesControllerStore.template.templateType = val;
                                                }
                                            }}
                                            options={TargetTemplateTypeList.filter(
                                                (item) =>
                                                    !(
                                                        takerType === TargetTypeEnum.takerFix &&
                                                        takerType !== null &&
                                                        item.key !== TargetTemplateTypeEnum.swapsBySymbol &&
                                                        item.key !== TargetTemplateTypeEnum.dividends
                                                    ),
                                            ).map((item) => ({
                                                value: item.key,
                                                label: item.title,
                                            }))}
                                        />
                                    );
                                }}
                            />
                            <ValidationMessage message={errors.templateType?.message} />
                        </FieldContainer>
                    </div>
                </div>
                <div className={styles.btnBlock}>
                    {actions()}
                    <Button
                        key="submit"
                        type="primary"
                        onClick={handleSubmit(onSave)}
                        disabled={!isValid || (!isDirty && !isTouchedCustom)}
                        className={styles.footerButton}
                        loading={isSaving}
                    >
                        Save
                    </Button>
                </div>
            </form>
        </div>
    );
});
