/* eslint-disable @typescript-eslint/no-explicit-any */
import { EVERY_SYMBOL, S, SYMBOL } from '@constants';
import { DaysTypesAll } from '@constants/days';
import { SwapsResponseType, SwapsResponseTypeUpperCase, SwapsType, TargetTemplateType } from 'types/commonTypes';
import React from 'react';

export const LS_FORM_WIDTH_KEY = 'RightPanel.FormWidth';

export const typedMemo: <T>(component: T) => T = React.memo;

export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const templateTypeFormatter = (string: string) => {
    if (string === TargetTemplateType.swapsBySymbol) return 'Swaps by Symbols';
    if (string === TargetTemplateType.swapsByGroups) return 'Swaps by Groups';
    if (string === TargetTemplateType.dividends) return 'Dividends';
    return string;
};

export const swapsTypesFormatter = (string: string) => {
    if (string === SwapsResponseType.byMoney) return SwapsType.byMoney;
    if (string === SwapsResponseType.byPoints) return SwapsType.byPoints;
    if (string === SwapsResponseType.byInterest) return SwapsType.byInterest;
    if (string === SwapsResponseType.byMarginCurrency) return SwapsType.byMarginCurrency;
    if (string === SwapsResponseType.bygroupcurrency) return SwapsType.bygroupcurrency;
    if (string === SwapsResponseType.byinterestcurrent) return SwapsType.byinterestcurrent;
    if (string === SwapsResponseType.byinterestopen) return SwapsType.byinterestopen;
    if (string === SwapsResponseType.bycloseprice) return SwapsType.bycloseprice;
    if (string === SwapsResponseType.bybid) return SwapsType.bybid;
    if (string === SwapsResponseType.byprofitcurrency) return SwapsType.byprofitcurrency;
    if (string === SwapsResponseTypeUpperCase.byMoney) return SwapsType.byMoney;
    if (string === SwapsResponseTypeUpperCase.byPoints) return SwapsType.byPoints;
    if (string === SwapsResponseTypeUpperCase.byInterest) return SwapsType.byInterest;
    if (string === SwapsResponseTypeUpperCase.byMarginCurrency) return SwapsType.byMarginCurrency;
    if (string === SwapsResponseTypeUpperCase.bygroupcurrency) return SwapsType.bygroupcurrency;
    if (string === SwapsResponseTypeUpperCase.byinterestcurrent) return SwapsType.byinterestcurrent;
    if (string === SwapsResponseTypeUpperCase.byinterestopen) return SwapsType.byinterestopen;
    if (string === SwapsResponseTypeUpperCase.bycloseprice) return SwapsType.bycloseprice;
    if (string === SwapsResponseTypeUpperCase.bybid) return SwapsType.bybid;
    if (string === SwapsResponseTypeUpperCase.byprofitcurrency) return SwapsType.byprofitcurrency;
    return string;
};
// TODO ENUMs
export const swapsTypeFormatterRemoveSpaces = (string: string) => {
    if (string === SwapsType.byMoney) return SwapsResponseType.byMoney;
    if (string === 'ByMoney') return SwapsResponseType.byMoney;
    if (string === 'By Money') return SwapsResponseType.byMoney;
    if (string === SwapsType.byPoints) return SwapsResponseType.byPoints;
    if (string === SwapsResponseType.byPoints) return SwapsResponseType.byPoints;
    if (string === 'ByPoints') return SwapsResponseType.byPoints;
    if (string === 'By Points') return SwapsResponseType.byPoints;
    if (string === SwapsType.byInterest) return SwapsResponseType.byInterest;
    if (string === SwapsResponseTypeUpperCase.byInterest) return SwapsResponseType.byInterest;
    if (string === SwapsType.byMarginCurrency) return SwapsResponseType.byMarginCurrency;
    if (string === SwapsResponseTypeUpperCase.byMarginCurrency) return SwapsResponseType.byMarginCurrency;
    if (string === SwapsType.bygroupcurrency) return SwapsResponseType.bygroupcurrency;
    if (string === SwapsResponseTypeUpperCase.bygroupcurrency) return SwapsResponseType.bygroupcurrency;
    if (string === SwapsType.byinterestcurrent) return SwapsResponseType.byinterestcurrent;
    if (string === SwapsResponseTypeUpperCase.byinterestcurrent) return SwapsResponseType.byinterestcurrent;
    if (string === SwapsType.byinterestopen) return SwapsResponseType.byinterestopen;
    if (string === SwapsResponseTypeUpperCase.byinterestopen) return SwapsResponseType.byinterestopen;
    if (string === SwapsType.bycloseprice) return SwapsResponseType.bycloseprice;
    if (string === SwapsResponseTypeUpperCase.bycloseprice) return SwapsResponseType.bycloseprice;
    if (string === SwapsType.bybid) return SwapsResponseType.bybid;
    if (string === SwapsResponseTypeUpperCase.bybid) return SwapsResponseType.bybid;
    if (string === SwapsType.byprofitcurrency) return SwapsResponseType.byprofitcurrency;
    if (string === SwapsResponseTypeUpperCase.byprofitcurrency) return SwapsResponseType.byprofitcurrency;
    return string;
};

export const tsdFormatter = (string: DaysTypesAll): DaysTypesAll => {
    if (string === DaysTypesAll.allWeek) return 'allweek' as DaysTypesAll;
    return string;
};

export const tsdConverted = (string: string | DaysTypesAll): DaysTypesAll => {
    if (string === 'Allweek') return DaysTypesAll.allWeek;
    if (string === 'AllWeek') return DaysTypesAll.allWeek;
    if (string === 'allweek') return DaysTypesAll.allWeek;
    if (string === 'monday') return DaysTypesAll.monday;
    if (string === 'thursday') return DaysTypesAll.thursday;
    if (string === 'wednesday') return DaysTypesAll.wednesday;
    if (string === 'friday') return DaysTypesAll.friday;
    if (string === 'saturday') return DaysTypesAll.saturday;
    if (string === 'sunday') return DaysTypesAll.sunday;
    return string as DaysTypesAll;
};

// export const tsdFormatterTable = (string: string) => {
//     if (string === 'allweek') return DaysTypesAll.allWeek;
//     if (string === 'Allweek') return DaysTypesAll.allWeek;
//     return string;
// };
// TODO add same for GROUPMASKS
export const modifiedSymbolsMasks = (record: string[] | null): string[] => {
    let modifiedSymbolsMasksRecord: string[] = [];
    if (record !== null && record !== undefined) {
        if (record.length === 0) return [];
        modifiedSymbolsMasksRecord = record.map((e) => {
            if (e === '*') {
                return (e = EVERY_SYMBOL);
            }
            if (e.includes(S)) {
                return e.replace(S, SYMBOL);
            }
            return e;
        });
    } else {
        modifiedSymbolsMasksRecord = [];
    }
    return modifiedSymbolsMasksRecord;
};

export const LS_DISABLED_COLS_PREFIX = '.DisabledCols';

export const isFilledString = (value: any): boolean => typeof value === 'string' && !!value;

export const isValidNumber = (value: any): boolean =>
    typeof value === 'number' && Number.isFinite(value) && !Number.isNaN(value);

export const isInEnum = (value: any, enumObj: any): boolean => Object.values(enumObj).includes(value);

export const getParsedJSON = (value: string): {} | null => {
    try {
        return JSON.parse(value);
    } catch (error) {
        return null;
    }
};
