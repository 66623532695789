/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState } from 'react';
import { useCSVReader } from 'react-papaparse';
import { Message, MessageType } from '@components/Message/Message';
import ArrowDownSwap from '@assets/icons/arrowDownSwap.svg';
import styles from '../importFinalto/styles.module.scss';
import { parseCSV, TemplatesControllerSwapsCSVRecord } from './csvUtils';
import { executeImport } from './executeImport';
import { ValidateErrorType } from '@models/ImportTemplates';
import { ParseError } from 'papaparse';
import { isFilledString, isValidNumber } from '@utils';
import { BaseTemplateModel } from '@models/Templates';
import { showErrorsModal } from './ErrorsModal';

type Props = {
    onSubmitImportCSV: (records: TemplatesControllerSwapsCSVRecord[]) => void;
    template?: BaseTemplateModel | null;
    okText?: string;
};

const validateFile = (file: { type: string; name: string }): any[] | null => {
    if (!file.name.endsWith('.csv')) {
        return [{ code: '*.csv', message: 'Invalid file extension! Allowed only CSV format' }];
    }
    if (file.type !== 'text/csv') {
        return [{ code: 'text/csv', message: 'Invalid file type! Allowed only "text/csv" type' }];
    }
    return null;
};

const validatorErrorsToValidateErrorType = (errors: ParseError[]): ValidateErrorType[] => {
    return errors && Array.isArray(errors)
        ? errors.map((res, index) => ({
              id: `${res?.row?.toString()}_${res?.code?.toString()}_${index}`,
              value: isValidNumber(res?.row) ? `Row ${res.row + 1}` : res?.code ?? '',
              description: isFilledString(res?.message) ? res.message : '',
          }))
        : [];
};

export const ImportCSV: FC<Props> = ({ onSubmitImportCSV, template, okText }: Props) => {
    const { CSVReader } = useCSVReader();
    const [validateMessages, setValidateMessages] = useState<ValidateErrorType[]>([]);

    return (
        <CSVReader
            onUploadAccepted={(results: any) => {
                try {
                    const { errors, data }: { errors: ParseError[]; data: any[] } = results;
                    const allErrors = Array.isArray(errors) ? errors.flat() : [];
                    let errorMessages = validateMessages.concat(validatorErrorsToValidateErrorType(allErrors));
                    if (!data || !data.length) {
                        errorMessages.unshift({ id: 'noData', value: 'No Data', description: 'No rows to import' });
                    }
                    const parsedResult = parseCSV(data, template?.type);
                    errorMessages = errorMessages.concat(parsedResult.errors);
                    setValidateMessages(errorMessages);
                    executeImport({
                        data: parsedResult.parsedData,
                        errors: errorMessages,
                        onOk: (res) => {
                            onSubmitImportCSV(res);
                            setValidateMessages([]);
                        },
                        template: template,
                        okText: okText,
                        onCancel: () => {
                            setValidateMessages([]);
                        },
                    });
                } catch (error) {
                    Message(MessageType.error, 'File parsing uncaught error');
                }
            }}
            onUploadRejected={(results: any) => {
                const rawErrors = Array.isArray(results) ? results.map((item) => item?.errors).flat() : [];
                const errors = validatorErrorsToValidateErrorType(rawErrors);
                if (errors.length !== 0) {
                    showErrorsModal({ errors });
                }
            }}
            validator={validateFile}
            accept=".csv"
            config={{
                skipEmptyLines: true,
                maxFiles: 1,
                header: true,
                newline: '\n',
                delimiter: '',
            }}
        >
            {({ getRootProps }: any) => {
                const rootProps = getRootProps();
                return (
                    <div {...rootProps} className={styles.importCSVBtn}>
                        <img alt="logo" data-tooltip-id="tooltip-csvImport" src={ArrowDownSwap} />
                    </div>
                );
            }}
        </CSVReader>
    );
};
