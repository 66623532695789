import React, { FC } from 'react';
import { FieldContainer } from '@components/Form/FieldContainer/FieldContainer';
import { FieldLabel } from '@components/Form/FieldLabel/FieldLabel';
import { PresetServerModifier, PresetServerModifierMarkup } from '@models/Preset';
import { InputNumber } from 'antd';
import { inputNumberKeyDownHandler } from '@utils/number';
import Big from 'big.js';
import { v4 } from 'uuid';
import { FieldError } from 'react-hook-form';
import { getErrorMessage } from '@pages/presets/utils';
import { ValidationMessage } from '@components/Form/ValidationMessage/ValidationMessage';
import { ServerModifierEnum } from 'types/modifiers';

type Props = {
    value: PresetServerModifierMarkup;
    onChange: (val: PresetServerModifierMarkup) => void;
    error?: FieldError;
};

export const getDefaultMarkupModifier = (): PresetServerModifier => ({
    id: v4(),
    orderId: 1,
    type: ServerModifierEnum.markup,
    name: ServerModifierEnum.markup,
    shortName: '',
    data: {
        positive: new Big(0),
        negative: new Big(0),
    } as PresetServerModifierMarkup,
});

export const Markup: FC<Props> = ({ value, onChange, error }) => (
    <div>
        <FieldContainer>
            <FieldLabel title="Coefficient for negative values" required />
            <InputNumber<string>
                onChange={(val) => {
                    if (!val) onChange({ ...value, negative: null });
                    if (val && val.length > 0) {
                        const numValue = new Big(val);
                        onChange({ ...value, negative: numValue });
                    }
                }}
                decimalSeparator="."
                value={value.negative ? value.negative.toString() : ''}
                onKeyDown={inputNumberKeyDownHandler}
                stringMode
            />
            <ValidationMessage message={getErrorMessage('data.negative', error)} />
        </FieldContainer>
        <FieldContainer>
            <FieldLabel title="Coefficient for positive values" required />
            <InputNumber<string>
                onChange={(val) => {
                    if (!val) onChange({ ...value, positive: null });
                    if (val && val.length > 0) {
                        const numValue = new Big(val);
                        onChange({ ...value, positive: numValue });
                    }
                }}
                decimalSeparator="."
                value={value.positive ? value.positive.toString() : ''}
                onKeyDown={inputNumberKeyDownHandler}
                stringMode
            />
            <ValidationMessage message={getErrorMessage('data.positive', error)} />
        </FieldContainer>
    </div>
);
