/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useContext, useState } from 'react';
import { Button, DatePicker } from 'antd';
import { FilterPropsRangeDate } from './types';
import moment from 'moment';
import { typedMemo } from '../../../utils';
import { IDataPicker } from '../types';
import { FilterValuesContext } from '../TableServerSide';
import { FORMAT_D } from '@utils/datetime';
import styles from './RowFilters.module.scss';
import ButtonGroup from 'antd/lib/button/button-group';
import { CloseCircleFilled } from '@ant-design/icons';

export const DataPickerFilter = typedMemo((props: FilterPropsRangeDate) => {
    const filterValues = useContext(FilterValuesContext);
    const [value, setValue] = useState<IDataPicker>(
        filterValues.values[props.dataIndex] ? (filterValues.values[props.dataIndex] as IDataPicker) : { from: null },
    );
    const onFilter = (from: any) => {
        filterValues.setValues({
            ...filterValues.values,
            [props.dataIndex]: !from ? null : from,
        });
        props.confirmCallback();
    };

    const onTimeChange = (rangeValue: moment.Moment | null) => {
        setValue({ from: rangeValue !== null ? rangeValue.utc().startOf('day') : null });
        // onFilter({ from: rangeValue !== null ? rangeValue.startOf('day') : null });
    };

    const onReset = () => {
        filterValues.setValues({ ...filterValues.values, [props.dataIndex]: null });
        setValue({ from: null });
        props.confirmCallback();
    };
    const today = moment().utc();

    const okHandler = () => {
        onFilter({ from: value.from });
    };

    const disabledDate = useCallback(
        (d: any) => {
            if (d == null) {
                return null;
            }
            return (
                (props.firstAvailiableDateForCalendar != null &&
                    d.isBefore(props.firstAvailiableDateForCalendar, 'day') &&
                    !d.isSame(props.firstAvailiableDateForCalendar, 'day')) ||
                (today != null && d.isAfter(today, 'day') && !d.isSame(today, 'day'))
            );
        },
        [props.firstAvailiableDateForCalendar, today],
    );
    const getDateRangeFooter = () => (
        <div>
            <ButtonGroup className={styles.timePicker}>
                <Button
                    type="link"
                    onClick={() => {
                        const newVal = moment.utc().startOf('day');
                        onTimeChange(newVal);
                        onFilter({ from: newVal });
                    }}
                >
                    Today
                </Button>
                <Button
                    type="link"
                    onClick={() => {
                        const newVal = moment.utc().add(-1, 'days').startOf('day');
                        onTimeChange(newVal);
                        onFilter({ from: newVal });
                    }}
                >
                    Yesterday
                </Button>
            </ButtonGroup>
        </div>
    );
    return (
        <div className={styles.wrapper}>
            <DatePicker
                showTime={props.firstAvailiableDateForCalendar !== null}
                className={styles.search}
                format={FORMAT_D}
                onChange={onTimeChange}
                value={value.from ? moment.utc(value.from) : null}
                allowClear={false}
                clearIcon={<CloseCircleFilled onClick={onReset} />}
                // onOk={onFilter}
                renderExtraFooter={getDateRangeFooter}
                showMinute={props.firstAvailiableDateForCalendar !== null}
                showNow={false}
                showToday={false}
                onSelect={onTimeChange}
                disabledDate={disabledDate}
                // disabledTime={isDisabledFutureTime && disabledTime}
            />
            <div className={styles.btnBlock}>
                <Button type="link" className={styles.reset} onClick={onReset}>
                    Reset
                </Button>
                <Button type="primary" onClick={okHandler} size="small" className={styles.button}>
                    Filter
                </Button>
            </div>
        </div>
    );
});
