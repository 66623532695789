/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-escape */
import * as yup from 'yup';

const shouldSkipTesting = (val: any | null | undefined) => val === 'undefined' || val === null;

const FORBIDDEN_NAMES = [
    'con',
    'prn',
    'aux',
    'nul',
    'com1',
    'com2',
    'com3',
    'com4',
    'com5',
    'com6',
    'com7',
    'com8',
    'com9',
    'lpt1',
    'lpt2',
    'lpt3',
    'lpt4',
    'lpt5',
    'lpt6',
    'lpt7',
    'lpt8',
    'lpt9',
];

export const checkStringByRegExp = (regExp: RegExp, message: string) =>
    yup.string().test('checkStringByRegExp', message, (value) => (value ? !!value.match(regExp) : true));

export const spacesStartNotAllowed = () =>
    yup.string().test({
        name: 'spacesStartNotAllowed',
        message: 'Leading spaces are not allowed',
        test: (value: string | undefined | null) => {
            if (shouldSkipTesting(value)) return true;
            return typeof value === 'string' ? value[0] !== ' ' && value[0] !== '	' : true;
        },
    });

export const hasWhiteSpace = () =>
    yup.string().test({
        name: 'hasWhiteSpace',
        message: 'Spaces are not allowed',
        test: (value: string | undefined | null) => {
            const checkHasWhiteSpace = (s: string) => {
                if (typeof s === 'string' && !!s.length ? s[s.length - 1] !== ' ' && s[s.length - 1] !== '	' : true) {
                    return s.indexOf(' ') >= 0;
                }
                return null;
            };
            if (shouldSkipTesting(value)) return true;
            return typeof value === 'string' ? !checkHasWhiteSpace(value) : true;
        },
    });

export const spacesEndNotAllowed = () =>
    yup.string().test({
        name: 'spacesEndNotAllowed',
        message: 'Trailing spaces are not allowed',
        test: (value: string | undefined | null) => {
            if (shouldSkipTesting(value)) return true;
            return typeof value === 'string' && !!value.length
                ? value[value.length - 1] !== ' ' && value[value.length - 1] !== '	'
                : true;
        },
    });

export const notAllowedWords = () =>
    yup
        .string()
        .test('allowedWords', 'Value contains forbidden word', (value) =>
            typeof value === 'string' && !!value ? !FORBIDDEN_NAMES.includes(value.toLowerCase()) : true,
        );
