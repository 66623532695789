import { isFilledString, isInEnum } from '@utils';

export enum DaysEnum {
    monday = 'monday',
    tuesday = 'tuesday',
    wednesday = 'wednesday',
    thursday = 'thursday',
    friday = 'friday',
    saturday = 'saturday',
    sunday = 'sunday',
    allWeek = 'allweek',
}

export const WeekDaysList: Array<{ index: number; key: DaysEnum; title: string; csvKey: string }> = [
    { index: 0, key: DaysEnum.monday, title: 'Monday', csvKey: 'Monday' },
    { index: 1, key: DaysEnum.tuesday, title: 'Tuesday', csvKey: 'Tuesday' },
    { index: 2, key: DaysEnum.wednesday, title: 'Wednesday', csvKey: 'Wednesday' },
    { index: 3, key: DaysEnum.thursday, title: 'Thursday', csvKey: 'Thursday' },
    { index: 4, key: DaysEnum.friday, title: 'Friday', csvKey: 'Friday' },
];

export const EndOfWeekDaysList: Array<{ index: number; key: DaysEnum; title: string; csvKey: string }> = [
    { index: 5, key: DaysEnum.saturday, title: 'Saturday', csvKey: 'Saturday' },
    { index: 6, key: DaysEnum.sunday, title: 'Sunday', csvKey: 'Sunday' },
    { index: 7, key: DaysEnum.allWeek, title: 'All Week', csvKey: 'AllWeek' },
];

export const AllDaysList: Array<{ index: number; key: DaysEnum; title: string; csvKey: string }> = [
    ...WeekDaysList,
    ...EndOfWeekDaysList,
];

export const ConvertStringToAllDays = (value?: string | null): DaysEnum | null => {
    if (!value || !isFilledString(value)) return null;
    const valueLower = value.toLowerCase().trim();
    if (!isInEnum(valueLower, DaysEnum)) {
        return null;
    }
    if (valueLower === DaysEnum.monday) return DaysEnum.monday;
    if (valueLower === DaysEnum.tuesday) return DaysEnum.tuesday;
    if (valueLower === DaysEnum.wednesday) return DaysEnum.wednesday;
    if (valueLower === DaysEnum.thursday) return DaysEnum.thursday;
    if (valueLower === DaysEnum.friday) return DaysEnum.friday;
    if (valueLower === DaysEnum.saturday) return DaysEnum.saturday;
    if (valueLower === DaysEnum.sunday) return DaysEnum.sunday;
    if (valueLower === DaysEnum.allWeek) return DaysEnum.allWeek;
    return null;
};

export const renderDay = (value: DaysEnum | string | null | undefined): string => {
    const targetRecord = AllDaysList.find(
        (item) => item.key === (value ? value.trim().replace(' ', '').toLowerCase() : null),
    );
    return targetRecord?.title ?? '';
};

// export const getBEKeyDaysEnum = (value: DaysEnum): string => {
//     switch (value) {
//         case DaysEnum.monday:
//             return 'Monday';
//         case DaysEnum.tuesday:
//             return 'Tuesday';
//         case DaysEnum.wednesday:
//             return 'Wednesday';
//         case DaysEnum.thursday:
//             return 'Thursday';
//         case DaysEnum.friday:
//             return 'Friday';
//         case DaysEnum.saturday:
//             return 'Saturday';
//         case DaysEnum.sunday:
//             return 'Sunday';
//         case DaysEnum.allWeek:
//             return 'Allweek';
//         default:
//             return '';
//     }
// };
