import React, { FC } from 'react';
import styles from './ModifiersModal.module.scss';
import { Modal } from 'antd';
import { useForm } from 'react-hook-form';
import { PresetServer } from '@models/Preset';
import { yupResolver } from '@hookform/resolvers/yup';
import { PresetServerSchema as schema } from './schema';
import Title from 'antd/es/typography/Title';
import { FormFooter } from '@components/Form/FormFooter/FormFooter';
import { OperationType } from 'types/commonTypes';
import { ModifiersList } from './EditByType/ModifiersList';

type Props = {
    onCancel: () => void;
    onApply: (values: PresetServer) => void;
    server: PresetServer;
    mode: OperationType;
    serverName: string;
};

const FORM_ID = 'ModifiersModalForm';

export const ModifiersModal: FC<Props> = ({ onCancel, onApply, server, serverName }) => {
    const {
        handleSubmit,
        reset,
        watch,
        setValue,
        formState: { isDirty, isValid, errors, dirtyFields },
        control,
    } = useForm<PresetServer>({
        defaultValues: server,
        mode: 'all',
        resolver: yupResolver(schema),
    });

    const handleReset = () => {
        reset(server);
    };

    const cancelHandler = () => {
        handleReset();
        onCancel();
    };

    return (
        <Modal className={styles.modifiersModal} open onCancel={onCancel} footer={null}>
            <Title className={styles.modalTitle} level={3}>{`Modifiers of "${serverName}"`}</Title>
            <form id={FORM_ID}>
                <ModifiersList
                    control={control}
                    errors={errors}
                    dirtyFields={dirtyFields}
                    watch={watch}
                    setValue={setValue}
                />
                <FormFooter
                    isValid={isValid}
                    isDirty={isDirty}
                    type={OperationType.update}
                    submitBtnTitle="Apply"
                    onBack={cancelHandler}
                    onSubmit={handleSubmit(onApply)}
                />
            </form>
        </Modal>
    );
};
