import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import Title from 'antd/es/typography/Title';
import { FieldContainer } from '@components/Form/FieldContainer/FieldContainer';
import { FieldLabel } from '@components/Form/FieldLabel/FieldLabel';
import { FormFooter } from '@components/Form/FormFooter/FormFooter';
import { Controller, useForm } from 'react-hook-form';
import { Modal, Input, Radio } from 'antd';
import { RowContainer } from '@components/Form/RowContainer/RowContainer';
import { ValidationMessage } from '@components/Form/ValidationMessage/ValidationMessage';
import styles from './styles.module.scss';
import { TemplateCreateModel } from '@models/Templates';
import { OperationType } from 'types/commonTypes';
import { TargetTemplateTypeEnum, TargetTemplateTypeList } from 'types/targetTemplateTypes';
import { schemaCreateTemplate } from './schema';
import { TargetTypeEnum, TargetTypesList } from 'types/targetTypes';

const FORM_ID = 'CreateTemplateControllerModal';

const defaultTemplate = schemaCreateTemplate.cast({});

type Props = {
    onSubmit: (value: TemplateCreateModel) => void;
    onCancel: () => void;
};

export const CreateTemplateControllerModal = ({ onSubmit, onCancel }: Props) => {
    const {
        handleSubmit,
        reset,
        watch,
        resetField,
        formState: { isDirty, isValid, isSubmitSuccessful, errors },
        control,
    } = useForm<TemplateCreateModel>({
        defaultValues: defaultTemplate,
        mode: 'all',
        resolver: yupResolver(schemaCreateTemplate),
    });

    const handleReset = () => {
        reset(defaultTemplate);
    };

    const cancelHandler = () => {
        handleReset();
        onCancel();
    };

    useEffect(() => {
        handleReset();
    }, [isSubmitSuccessful]);

    const templateType = watch('templateType');
    const takerType = watch('type');

    return (
        <Modal open onCancel={cancelHandler} footer={null}>
            <Title level={3}>Create Template</Title>
            <form id={FORM_ID}>
                <FieldContainer>
                    <FieldLabel title="Name" required />
                    <Controller
                        control={control}
                        name="name"
                        render={({ field }) => {
                            return <Input {...field} value={field.value ?? undefined} />;
                        }}
                    />
                    <ValidationMessage message={errors.name?.message} />
                </FieldContainer>
                <RowContainer>
                    <FieldContainer className={styles.typesTemplates}>
                        <FieldLabel title="Server Type" required />
                        <Controller
                            control={control}
                            name="type"
                            render={({ field: { value, onChange }, field }) => (
                                <Radio.Group
                                    {...field}
                                    onChange={(val) => {
                                        if (
                                            val.target.value === 'fix' &&
                                            templateType !== TargetTemplateTypeEnum.swapsByGroups
                                        ) {
                                            if (
                                                templateType === TargetTemplateTypeEnum.dividends ||
                                                templateType === TargetTemplateTypeEnum.swapsBySymbol
                                            ) {
                                                onChange(val);
                                                return;
                                            }
                                            resetField('templateType');
                                            onChange(val);
                                        } else {
                                            onChange(val);
                                        }
                                    }}
                                    value={value}
                                    className={styles.radioGroup}
                                >
                                    {TargetTypesList.map((type) => (
                                        <Radio
                                            key={type.id}
                                            disabled={
                                                type.key === TargetTypeEnum.takerFix &&
                                                templateType !== null &&
                                                templateType === TargetTemplateTypeEnum.swapsByGroups
                                            }
                                            value={type.key}
                                        >
                                            {type.title}
                                        </Radio>
                                    ))}
                                </Radio.Group>
                            )}
                        />
                    </FieldContainer>
                    <FieldContainer className={styles.typesTemplates}>
                        <FieldLabel title="Template Type " required />
                        <Controller
                            control={control}
                            name="templateType"
                            render={({ field: { value, onChange }, field }) => (
                                <Radio.Group
                                    {...field}
                                    onChange={(val) => {
                                        if (
                                            takerType === TargetTypeEnum.takerFix &&
                                            takerType !== null &&
                                            val.target.value !== TargetTemplateTypeEnum.swapsBySymbol &&
                                            val.target.value !== TargetTemplateTypeEnum.dividends
                                        ) {
                                            resetField('templateType');
                                        } else {
                                            onChange(val);
                                        }
                                    }}
                                    value={value}
                                    className={styles.radioGroup}
                                >
                                    {TargetTemplateTypeList.map((type) => (
                                        <Radio
                                            key={type.id}
                                            disabled={
                                                takerType === TargetTypeEnum.takerFix &&
                                                takerType !== null &&
                                                type.key !== TargetTemplateTypeEnum.swapsBySymbol &&
                                                type.key !== TargetTemplateTypeEnum.dividends
                                            }
                                            value={type.key}
                                        >
                                            {type.updaterLabel}
                                        </Radio>
                                    ))}
                                </Radio.Group>
                            )}
                        />
                    </FieldContainer>
                </RowContainer>
                <FormFooter
                    isValid={isValid}
                    isDirty={isDirty}
                    type={OperationType.create}
                    onBack={cancelHandler}
                    onSubmit={handleSubmit(onSubmit)}
                    submitBtnTitle="Create"
                />
            </form>
        </Modal>
    );
};
