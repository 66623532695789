import { Modal, Tabs } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import React from 'react';
import { BaseTemplateModel } from '@models/Templates';
import styles from '../importFinalto/styles.module.scss';
import { ValidateErrorType } from '@models/ImportTemplates';
import { ErrorsList } from '../validating/errorsList';
import { TabLabel } from '@components/TabLabel/TabLabel';

export const getTemplateNameById = (tempates: BaseTemplateModel[], id: string): string =>
    tempates.find((e) => e.id === id)?.name ?? '';

type Props = {
    errors: ValidateErrorType[];
    title?: string;
    skipValueCol?: boolean;
};

export const showErrorsModal = ({ errors, title = 'Invalid CSV file', skipValueCol }: Props): void => {
    Modal.error({
        width: 1000,
        title: title,
        icon: <ExclamationCircleOutlined className={styles.circleIcon} />,
        content: (
            <div>
                <Tabs
                    defaultActiveKey="swaps"
                    className={styles.tabs}
                    items={[
                        {
                            label: <TabLabel text="Errors" count={errors.length} variant="danger" />,
                            key: 'errors',
                            children: <ErrorsList errors={errors} skipValueCol={skipValueCol} />,
                            disabled: !errors.length,
                        },
                    ]}
                />
            </div>
        ),
    });
};
