/* eslint-disable @typescript-eslint/no-explicit-any */
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import React from 'react';
import { BaseTemplateModel } from '@models/Templates';
import styles from './styles.module.scss';
import { IMPORT_CSV, PresetParsedRecord } from './csvUtils';
import { PresetModel } from '@models/Preset';
import { Message, MessageType } from '@components/Message/Message';
import { presetsStore } from '@store/presetsStore';
import get from 'lodash/get';
import { convertDataKeyToLowerCase } from '@pages/templatesController/import/importCSV/csvUtils';
import { TagsInput } from 'react-tag-input-component';
import { FieldLabel } from '@components/Form/FieldLabel/FieldLabel';
import { TargetTypeEnum } from 'types/targetTypes';
import { TargetTemplateTypeEnum } from 'types/targetTemplateTypes';
import { findDuplicates } from '@pages/utils/commonUtils';
import { showErrorsModal } from '@pages/templatesController/import/importCSV/ErrorsModal';
import { getReplacesSToSymbol, groupMaskValidations, symbolMaskValidations } from '@pages/utils/maskValidations';
import { MaskKeys } from 'types/commonTypes';

export const getTemplateNameById = (tempates: BaseTemplateModel[], id: string): string =>
    tempates.find((e) => e.id === id)?.name ?? '';

export const validateJSON = (content: string, targetPreset?: PresetModel): string[] => {
    try {
        try {
            JSON.parse(content);
        } catch (e) {
            return ['Invalid JSON'];
        }
        const converted = convertDataKeyToLowerCase(JSON.parse(content));

        const symbolMasks: string[] = get(converted, MaskKeys.symbolMasks.toLowerCase(), []);
        const groupMasks: string[] = get(converted, MaskKeys.groupMasks.toLowerCase(), []);
        if (!targetPreset) {
            return ['Execution Template not found'];
        }

        const isShowGroupMask = // meta & (dividends || swapsByGroups)
            targetPreset.type !== TargetTypeEnum.takerFix &&
            targetPreset.templateType !== TargetTemplateTypeEnum.swapsBySymbol;

        const errors = [] as string[];

        if (!converted.hasOwnProperty(MaskKeys.symbolMasks.toLowerCase())) {
            return [`Missing "${MaskKeys.symbolMasks}" property`];
        }

        if (!converted.hasOwnProperty(MaskKeys.groupMasks.toLowerCase()) && isShowGroupMask) {
            errors.push(`Missing "${MaskKeys.groupMasks}" property`);
        }

        if (symbolMasks.length === 0 && isShowGroupMask) {
            errors.push('Required field');
        }
        if (groupMasks.length === 0 && isShowGroupMask) {
            errors.push('Required field');
        }
        if (groupMasks.length !== 0 && !isShowGroupMask) {
            errors.push(
                `JSON have "${MaskKeys.groupMasks}" property. Group Mask is not allowed for this Execution Template`,
            );
        }

        if (symbolMasks && !Array.isArray(symbolMasks)) {
            errors.push('Invalid value Symbol Mask. It must be an array');
        }
        if (groupMasks && !Array.isArray(groupMasks)) {
            errors.push('Invalid value Group Mask. It must be an array');
        }

        if (symbolMasks.length !== 0) {
            const duplicates = findDuplicates(symbolMasks);
            if (duplicates.length > 0) {
                errors.push(`Invalid value Symbol Mask. Duplicates are not allowed`);
            }
            if (symbolMasks.some((el) => typeof el !== 'string')) {
                return ['Invalid value Symbol Mask. Every mask item must be a string'];
            }
            symbolMasks.forEach((el) => {
                if (el === '') {
                    errors.push('Invalid value Symbol Mask. Empty string is not allowed');
                }
                if (el.includes(' ')) {
                    errors.push('Invalid value Symbol Mask. Spaces are not allowed');
                }
            });
            if (symbolMaskValidations.mandatory(symbolMasks) === false) {
                errors.push(
                    'Invalid value Symbol Mask. Symbol Mask must contain the required key {symbol} or {s} or *',
                );
            }
            if (symbolMaskValidations.maxLength(symbolMasks) === false) {
                errors.push('Invalid value Symbol Mask. Maximum length is 100 characters');
            }
            if (symbolMaskValidations.isTwice(symbolMasks) === false) {
                errors.push("Don't use {symbol} tag twice");
            }
            if (symbolMaskValidations.checkCase(symbolMasks) === false) {
                errors.push('Use tag {symbol} in lower case');
            }
            if (symbolMaskValidations.isDuplicates(symbolMasks) === false) {
                errors.push('Duplicates are not allowed');
            }
        }
        if (groupMasks.length !== 0) {
            const duplicates = findDuplicates(groupMasks);
            if (duplicates.length > 0) {
                errors.push('Invalid value Group Mask. Duplicates are not allowed');
            }
            if (groupMasks.some((el) => typeof el !== 'string')) {
                return ['Invalid value Group Mask. Every mask item must be a string'];
            }
            groupMasks.forEach((el) => {
                if (el === '') {
                    errors.push('Invalid value Group Mask. Empty string is not allowed');
                }
                if (el.includes(' ')) {
                    errors.push('Invalid value Group Mask. Spaces are not allowed');
                }
            });
            if (
                groupMaskValidations.maxLength(
                    groupMasks,
                    targetPreset.type?.toString() ?? '',
                    targetPreset?.templateType?.toString() ?? '',
                ) === false
            ) {
                errors.push('Invalid value Group Mask. Maximum length is 100 characters');
            }
            if (
                groupMaskValidations.mandatory(
                    groupMasks,
                    targetPreset.type?.toString() ?? '',
                    targetPreset?.templateType?.toString() ?? '',
                ) === false
            ) {
                errors.push('Invalid value Group Mask');
            }
            if (groupMaskValidations.isDuplicates(symbolMasks) === false) {
                errors.push('Duplicates are not allowed');
            }
        }
        return errors;
    } catch (error) {
        Message(MessageType.error, `File validation failed`);
        return ['Invalid JSON'];
    }
};

const executeImport = ({
    data,
    preset,
    onOk,
    okText = IMPORT_CSV,
}: {
    data: PresetParsedRecord;
    preset: PresetModel;
    onOk: (prev: PresetParsedRecord | null) => void;
    okText: string;
}): void => {
    const isShowGroupMask = // meta & (dividends || swapsByGroups)
        preset.type !== TargetTypeEnum.takerFix && preset.templateType !== TargetTemplateTypeEnum.swapsBySymbol;
    Modal.confirm({
        className: styles.modalImport,
        title: 'Import Execution Template',
        icon: <ExclamationCircleOutlined className={styles.circleIcon} />,
        content: (
            <div>
                <p>The following fields will be overwritten:</p>
                <div>
                    <FieldLabel title={`New Symbol Mask ${data.symbolMasks.length === 0 ? '(will be empty)' : ''}`} />
                    <TagsInput value={data.symbolMasks} disabled />
                </div>
                {isShowGroupMask && data.groupMasks.length > 0 && (
                    <div>
                        <FieldLabel title="New Group Mask" />
                        <TagsInput value={data.groupMasks} disabled />
                    </div>
                )}
                <h4>Do you want to overwrite the current Execution Template?</h4>
            </div>
        ),
        okText,
        okType: 'primary',
        cancelText: 'Cancel',
        onOk: () => {
            onOk(data);
        },
    });
};

export const handleImportJSONPreset = (id: string, presets: PresetModel[]) => {
    const targetPreset = presets.find((item) => item.id === id);

    const parseContent = (content: string): PresetParsedRecord | null => {
        if (!content) {
            Message(MessageType.error, 'No data.');
            return null;
        }
        if (!targetPreset) {
            Message(MessageType.error, 'Execution Template not found.');
            return null;
        }
        const isShowGroupMask = // meta & (dividends || swapsByGroups)
            targetPreset.type !== TargetTypeEnum.takerFix &&
            targetPreset.templateType !== TargetTemplateTypeEnum.swapsBySymbol;
        try {
            const errors = validateJSON(content, targetPreset);
            if (errors.length === 0) {
                const converted = convertDataKeyToLowerCase(JSON.parse(content));
                const symbolMasks: string[] = get(converted, MaskKeys.symbolMasks.toLowerCase(), []);
                const groupMasks: string[] = isShowGroupMask
                    ? get(converted, MaskKeys.groupMasks.toLowerCase(), [])
                    : [];
                return {
                    symbolMasks: symbolMasks.map(getReplacesSToSymbol),
                    groupMasks,
                };
            } else {
                // Modal.error({
                //     title: 'Invalid JSON',
                //     width: 800,
                //     content: (
                //         <div className={styles.errorContainer}>
                //             {errors.map((err) => (
                //                 <div className={styles.errorRecord}>{err}</div>
                //             ))}
                //         </div>
                //     ),
                // });
                showErrorsModal({
                    title: 'Invalid JSON',
                    skipValueCol: true,
                    errors: errors.map((err, index) => ({
                        id: `Row ${index + 1}`,
                        value: `№ ${index + 1}`,
                        description: err,
                    })),
                });
                return null;
            }
        } catch (error) {
            Message(MessageType.error, 'Invalid file data.');
            return null;
        }
    };

    const saveParsedRecord = (data: PresetParsedRecord) => {
        if (targetPreset) {
            executeImport({
                data,
                preset: targetPreset,
                onOk: (prev) => {
                    presetsStore.updatePresetFromCSV(targetPreset, prev);
                },
                okText: 'Apply',
            });
        } else {
            Message(MessageType.error, `Can\'t import Execution Template. ${id} is not in the list of controllers`);
        }
    };

    try {
        var input = document.createElement('input');
        input.id = `import_json_${id}`;
        input.type = 'file';
        input.accept = '.JSON';
        input.onchange = (e) => {
            // @ts-ignore
            var file = e.target.files[0];
            console.log(file);
            if (file.type !== 'application/json' || !file.name.endsWith('.json')) {
                showErrorsModal({
                    title: 'Invalid JSON file',
                    errors: [
                        {
                            id: 'invalidFileType',
                            value: '*.json',
                            description: 'Invalid file type! Allowed only JSON format',
                        },
                    ],
                });
                return;
            }

            // setting up the reader
            var reader = new FileReader();
            reader.readAsText(file, 'UTF-8');

            // here we tell the reader what to do when it's done reading...
            reader.onload = (readerEvent) => {
                if (readerEvent && readerEvent.target) {
                    var content = readerEvent.target.result; // this is the content!
                    const parsedContent = parseContent(content?.toString() ?? '');
                    if (parsedContent !== null) {
                        saveParsedRecord(parsedContent);
                    }
                }
            };
        };
        input.click();
    } catch (error) {
        Message(MessageType.error, 'Parsing file failed. Invalid JSON format.');
    }
};
