export interface IAppConfig {
    clientId: string,
    authorityUrl?: string,
    redirectUri: string,
    scope: string
};

let config: IAppConfig | undefined;

export const initialize = async () => {
    if(config){
        throw new Error("config is already intitialized");
    }
    const authObj = {
        clientId: process.env.REACT_APP_KEY ? process.env.REACT_APP_KEY : '',
        redirectUri: '/',
        scope: 'api://7d519c04-8671-4154-87d0-8c6a4dc8b0e0/access_as_user',
        authorityUrl: process.env.REACT_APP_AUTHORITY_URL ? process.env.REACT_APP_AUTHORITY_URL : '',
    }
    config = await authObj;
}
    
export const getConfig = () => {
    if(!config){
        throw new Error("App configuration isn't intitialized.");
    }
    
    return config; 
}