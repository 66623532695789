export const textAreaAutoSize = {
    minRows: 1,
    maxRows: 10,
};

export const IMPORT_CSV_TAGS_COUNT = 20;

export const NUM_INPUT_PRECISION = 8;

export const MAX_APP_NUMBER_INT = 900000000;
export const MIN_APP_NUMBER_INT = -900000000;
