import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import cn from 'classnames';
import { ValidationMessageType } from '@models/Swaps';
import { Button } from 'antd';
import { Statistic } from './Statistic';
import { getValidationClassName } from '../utils';

const countToExpand = 4;

const List = ({ validations }: { validations: ValidationMessageType[] }) => {
    return (
        <>
            {validations.map((item) => (
                <div className={cn(styles.problem, styles[getValidationClassName(item.status)])}>
                    {item.description}
                </div>
            ))}
        </>
    );
};

export const ProblemsList = ({ values }: { values: ValidationMessageType[] }) => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false);

    const errors = values.filter((item) => item.status === 0);
    const warnings = values.filter((item) => item.status === 1);
    const infos = values.filter((item) => item.status === 2);
    const other = values.filter((item) => item.status > 2);
    const count = values.length;

    const valuesToShow = [...errors, ...warnings, ...infos, ...other];

    const [isExpandable, setIsExpandable] = useState<boolean>(count > countToExpand);

    useEffect(() => {
        setIsExpandable(values.length > countToExpand);
    }, [values]);

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div>
            <div className={cn(styles.problemsListContainer, isExpandable && !isExpanded && styles.collapsed)}>
                {valuesToShow.length > 0 && (
                    <div className={cn(styles.listContainer)}>
                        <List
                            validations={
                                isExpandable && isExpanded ? valuesToShow : valuesToShow.slice(0, countToExpand)
                            }
                        />
                    </div>
                )}
            </div>
            <div className={styles.footer}>
                <Statistic errors={errors} warnings={warnings} infos={infos} other={other} />
                {isExpandable && (
                    <Button type="link" onClick={toggleExpanded} className={styles.expandButton}>
                        {isExpanded ? 'Show less' : 'Show more'}
                    </Button>
                )}
            </div>
        </div>
    );
};
