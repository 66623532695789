/* eslint-disable no-multi-assign */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState, useRef } from 'react';
import { reaction } from 'mobx';
import { useDebounce } from 'usehooks-ts';

export const lsGetItem = (key: string) => {
    if (typeof window === 'undefined') {
        return null;
    }
    try {
        const item = window.localStorage.getItem(key);
        return item || null;
    } catch (error) {
        return null;
    }
};

export const lsSetItem = (key: string, value: string): string | null => {
    if (typeof window === 'undefined') {
        return null;
    }
    try {
        window.localStorage.setItem(key, value);
        return value;
    } catch (error) {
        return null;
    }
};

// Hook
export const useLocalStorage = (key?: string, defaultValue?: any) => {
    const [value, setValue] = useState(() => {
        const lsValue = key ? localStorage.getItem(key) : undefined;
        return lsValue ? JSON.parse(lsValue) : defaultValue;
    });

    useEffect(() => {
        if (key) localStorage.setItem(key, JSON.stringify(value));
    }, [value, key]);

    return [value, setValue];
};

export function useWindowSize() {
    const [size, setSize] = useState<[number, number]>([0, 0]);
    const debouncedValue = useDebounce<[number, number]>(size, 100);
    useEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return debouncedValue;
}

export const useReaction = <T, U = T>(
    what: () => T,
    opts: {
        transform?: (from: T) => U;
    } = {},
): [U, () => void] => {
    const [value, setValue] = useState<T>(() => what());
    const disposeRef = useRef(() => {});

    useEffect(() => {
        const dispose = (disposeRef.current = reaction(what, (newValue) => {
            setValue(opts.transform ? opts.transform(newValue) : (newValue as any));
        }));

        return dispose;
    }, []);

    return [value as any, disposeRef.current];
};
