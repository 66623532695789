import { isFilledString, isInEnum } from '@utils';

export enum TargetTypeEnum {
    mt4 = 'mt4',
    mt5 = 'mt5',
    takerFix = 'fix',
}

export const TargetTypesMetaList = [
    { id: 1, key: TargetTypeEnum.mt4, title: 'MT4' },
    { id: 2, key: TargetTypeEnum.mt5, title: 'MT5' },
];
export const TargetTypesList = [...TargetTypesMetaList, { id: 3, key: TargetTypeEnum.takerFix, title: 'Taker FIX' }];

export const ConvertStringToTargetType = (value?: string | null): TargetTypeEnum | null => {
    if (!value || !isFilledString(value)) return null;
    const valueLower = value.toLowerCase().trim();
    if (!isInEnum(valueLower, TargetTypeEnum)) {
        return null;
    }
    if (valueLower === TargetTypeEnum.mt4) return TargetTypeEnum.mt4;
    if (valueLower === TargetTypeEnum.mt5) return TargetTypeEnum.mt5;
    if (valueLower === TargetTypeEnum.takerFix || valueLower === 'takerfix') return TargetTypeEnum.takerFix;
    return null;
};

export const renderTargetType = (value: TargetTypeEnum | string | null | undefined): string => {
    const targetRecord = TargetTypesList.find((item) => item.key === value?.trim().toLowerCase());
    return targetRecord?.title ?? '';
};

// export const getBEKeyTargetType = (value: TargetTypeEnum): string => {
//     switch (value) {
//         case TargetTypeEnum.mt4:
//             return 'mt4';
//         case TargetTypeEnum.mt5:
//             return 'mt5';
//         case TargetTypeEnum.takerFix:
//             return 'fix';
//         default:
//             return '';
//     }
// };
