import React, { FC } from 'react';
import { Switch, Tooltip } from 'antd';
import { PresetServer } from '@models/Preset';
import { ServerModel } from '@models/Server';
import { ModifierTag } from './ModifierTag';
import { ModifierEditButton } from './ModifierEditButton';
import styles from './Modifiers.module.scss';
import { ServerModifierEnum } from 'types/modifiers';
import Available from '@assets/icons/availiable.svg';
import unAvailable from '@assets/icons/unavailiable.svg';

type Props = {
    presetServer: PresetServer;
    onDeleteModifier: (idToDelete: string) => void;
    onChangeState: (id: string, newState: boolean) => void;
    onOpenEdit: ({ id, name }: { id: string; name: string }) => void;
    servers: ServerModel[];
};

const MAX_MODIFIERS_LENGTH = Object.keys(ServerModifierEnum).length;

export const ModifierItemPreview: FC<Props> = ({
    presetServer,
    onChangeState,
    onDeleteModifier,
    onOpenEdit,
    servers,
}) => {
    const targetServer = servers.find((e) => e.id === presetServer.serverId);

    const deleteTagHandler = (id: string | null) => {
        if (id) {
            onDeleteModifier(id);
        }
    };

    const openEditHandler = ({ id, name }: { id: string; name: string }) => {
        onOpenEdit({ id, name });
    };

    const changeStateHandler = (newState: boolean) => {
        if (presetServer.id) {
            onChangeState(presetServer.id, newState);
        }
    };

    return (
        <>
            <div className={styles.modifier}>
                <Tooltip
                    title={`${!!targetServer?.available ? 'Server connected' : 'No server connection'}`}
                    className={styles.status}
                >
                    <img alt="logo" src={!!targetServer?.available ? Available : unAvailable} />
                </Tooltip>
                <div className={styles.controls}>
                    <div className={styles.switchBlock}>
                        <div className={styles.switchElement}>
                            <Switch
                                className={styles.switch}
                                checked={presetServer.state}
                                size="small"
                                onChange={changeStateHandler}
                            />
                            <span className={styles.switchLabel} title={targetServer?.name ?? ''}>
                                {targetServer?.name ?? ''}
                            </span>
                        </div>
                    </div>
                    <ModifierEditButton
                        icon={presetServer.presetServerModifiers.length < MAX_MODIFIERS_LENGTH ? 'add' : 'edit'}
                        onClick={() => {
                            if (presetServer && presetServer.id) {
                                openEditHandler({ id: presetServer.id, name: targetServer?.name ?? '' });
                            }
                        }}
                    />
                    <div className={styles.modifierTags}>
                        {presetServer.presetServerModifiers
                            .sort((a, b) => (a.orderId ?? 0) - (b.orderId ?? 0))
                            .map((item) => (
                                <ModifierTag label={item.shortName} onDelete={() => deleteTagHandler(item.id)} />
                            ))}
                    </div>
                </div>
            </div>
        </>
    );
};
