/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { HistoryType, TableCondition, historyUpdatedSwapsDetailsType } from '@models/History';
import { historyStore } from '@store/history/historyStore';
import { columnsHistoryDetailes, columnsHistoryDetailesDividends, historyColumns } from './utils';
import { useReaction } from '@utils/hooks';
import { TableServerSide } from '@components/TableServerSide/TableServerSide';
import { TableExt } from '@components/TableExt/TableExt';
import { Button } from 'antd';
import CollapseUp from '@assets/icons/sort_up.svg';
import CollapseDown from '@assets/icons/sort_down.svg';
import { AppRoutes } from '@constants';
import styles from '@pages/history/styles.module.scss';
import { HistoryTemplateType } from 'types/commonTypes';
import { DEFAULT_PAGE_SIZE } from '@components/TableServerSide/contants';
import { Link } from 'react-router-dom';

const ROW_KEY_EXPANDED = 'serverName';
const ROW_KEY = 'id';

export const HistoryTable = () => {
    const [histories] = useReaction<HistoryType[]>(() => historyStore.data);
    const [isLoading] = useReaction<boolean>(() => historyStore.isLoading);
    const [pageSize] = useReaction<number>(() => historyStore.pageSize);
    const [currentPage] = useReaction<number>(() => historyStore.currentPage);
    const [recordsTotal] = useReaction<number>(() => historyStore.recordsTotal);
    const [localHistorySelectedId, setLocalHistorySelectedId] = useState('');
    const [localHistoryTemplateSelectedType, setLocalHistoryTemplateSelectedType] = useState<HistoryTemplateType>(
        HistoryTemplateType.MT4swapsByGroups,
    );

    const footerBtnGroup = () => {
        return (
            <div className={styles.footerBtnGroup}>
                <Link to={`${AppRoutes.history}/${localHistorySelectedId}/${localHistoryTemplateSelectedType}`}>
                    See Full Information
                </Link>
            </div>
        );
    };

    const rowClassNameHandler = (record: TableCondition) => {
        if (record.historyServers.length > 0) return 'validationTable';
        return 'validationTableExpandable';
    };

    const expandedRowRenderSymbols = (data: historyUpdatedSwapsDetailsType[], isTypeDividends: boolean = false) => {
        return (
            <TableExt
                style={{ margin: 0 }}
                className="validationTable"
                header={false}
                rowKey={ROW_KEY_EXPANDED}
                size="small"
                columns={isTypeDividends ? columnsHistoryDetailesDividends() : columnsHistoryDetailes()}
                dataSource={data}
                pagination={false}
                footerBtnGroup={footerBtnGroup}
                rowClassNameHandler={() => {
                    return 'expandedHistoryTable';
                }}
            />
        );
    };

    return (
        <TableServerSide<TableCondition>
            rowKey={ROW_KEY}
            columns={historyColumns}
            title="History"
            dataSource={histories}
            loading={isLoading}
            rowClassNameHandler={rowClassNameHandler}
            focusedRowEntityId={null}
            onTableFiltersChange={(filtration) => {
                if (Object.keys(filtration).length > 0) {
                    historyStore.onPageChange(1, DEFAULT_PAGE_SIZE); // GOTO FIRST PAGE
                    historyStore.setFiltration(filtration);
                    historyStore.getHistoryWithParameters();
                }
            }}
            onTableChange={(pagination, sorter) => {
                if (pagination.current !== undefined && pagination.pageSize !== undefined) {
                    historyStore.onPageChange(pagination.current, pagination.pageSize);
                }
                historyStore.setSorting(sorter);
                historyStore.getHistoryWithParameters();
            }}
            pagination={{
                current: currentPage,
                total: recordsTotal,
                hideOnSinglePage: false,
                defaultPageSize: 1,
                showLessItems: true,
                showQuickJumper: false,
                showSizeChanger: true,
                showTitle: true,
                size: 'default',
                pageSize,
            }}
            expandable={{
                expandedRowRender: (record: TableCondition) => {
                    if (record.historyServers.length > 0) {
                        const { historyServers, type } = record;
                        const typeString: HistoryTemplateType = type.toString() as HistoryTemplateType;
                        const isTypeDividendsByMT4orMT5 =
                            typeString === HistoryTemplateType.Mt4AdjustDividends ||
                            typeString === HistoryTemplateType.Mt5AdjustDividends;
                        setLocalHistorySelectedId(record.id);
                        setLocalHistoryTemplateSelectedType(typeString);
                        if (historyServers.length > 3) {
                            return expandedRowRenderSymbols(historyServers.slice(0, 4), isTypeDividendsByMT4orMT5);
                        }
                        return expandedRowRenderSymbols(historyServers, isTypeDividendsByMT4orMT5);
                    }
                    return null;
                },
                rowExpandable: (record: TableCondition) => {
                    if (record.historyServers.length > 0) {
                        return true;
                    }
                    return false;
                },
                expandRowByClick: true,
                expandIconColumnIndex: 3,
                expandIcon: ({ expanded, onExpand, record }: any) => {
                    // if (!record.historyServers.length > 0) {
                    //     return null;
                    // }
                    if (record.historyServers.length > 0) {
                        return (
                            <Button type="link" onClick={(e) => onExpand(record, e)}>
                                <img alt="logo" src={expanded ? CollapseUp : CollapseDown} />
                            </Button>
                        );
                    }
                    return null;
                },
            }}
        />
    );
};
