import { TemplatesControllerSwapsCSVRecord } from '@pages/templatesController/import/importCSV/csvUtils';
import { Modal, Tabs } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import React from 'react';
import { BaseTemplateModel, TemplateSwapModel } from '@models/Templates';
import styles from '../importFinalto/styles.module.scss';
import { ValidateErrorType } from '@models/ImportTemplates';
import { ErrorsList } from '../validating/errorsList';
import { SwapsList } from '../validating/swapsList';
import { TabLabel } from '@components/TabLabel/TabLabel';

export const getTemplateNameById = (tempates: BaseTemplateModel[], id: string): string =>
    tempates.find((e) => e.id === id)?.name ?? '';

type Props = {
    data: TemplatesControllerSwapsCSVRecord[];
    errors: ValidateErrorType[];
    onOk: (prev: TemplatesControllerSwapsCSVRecord[]) => void;
    onCancel: () => void;
    template?: BaseTemplateModel | null;
    okText?: string;
};

export const executeImport = ({
    data,
    onOk,
    errors,
    template,
    okText = 'Import Symbols and Save',
    onCancel,
}: Props): void => {
    Modal.confirm({
        width: 1000,
        title: 'Please confirm import',
        icon: <ExclamationCircleOutlined className={styles.circleIcon} />,
        content: (
            <div>
                <Tabs
                    defaultActiveKey="swaps"
                    className={styles.tabs}
                    items={[
                        {
                            label: <TabLabel text="Imported Data" count={data.length} variant="default" />,
                            key: 'swaps',
                            children: template ? (
                                <SwapsList
                                    swaps={
                                        data.map((item) => ({
                                            symbol: item.symbol,
                                            shortSwap: item.shortswap,
                                            longSwap: item.longswap,
                                            type: item.type,
                                            tripleSwapDay: item.tripleswapday,
                                        })) as TemplateSwapModel[]
                                    }
                                    type={template.type}
                                    templateType={template.templateType}
                                />
                            ) : null,
                            // disabled: !data.length,
                        },
                        {
                            label: <TabLabel text="Errors" count={errors.length} variant="danger" />,
                            key: 'errors',
                            children: <ErrorsList errors={errors} />,
                            disabled: !errors.length,
                        },
                    ]}
                />
            </div>
        ),
        okText: okText,
        okType: 'primary',
        cancelText: 'Cancel',
        onOk: () => {
            onOk(data);
        },
        onCancel: () => {
            onCancel();
        },
    });
};
