import React, { useEffect, useContext, Context } from 'react';
import { RightPanel } from '@components/RightPanelForm';
import { useForm, SubmitHandler, UseFormSetError, FieldErrors } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IFormContext, OperationType } from 'types/commonTypes';
import { schema } from './schema';
import { MaskType, PresetModel } from '@models/Preset';
import { FormContext } from '../Presets';
import { presetsStore } from '@store/presetsStore';
import { General } from './General';
import { RightPanelCard } from '@components/RightPanelForm/Groups';
import { observer } from 'mobx-react';
import { useReaction } from '@utils/hooks';
import { Modifiers } from './Modifiers/Modifiers';
import { Mask } from './Mask';
import { ServerModel } from '@models/Server';

export const PresetsForm: React.FC = observer(() => {
    const [isLoading] = useReaction<boolean>(() => presetsStore.isLoading);
    const [data] = useReaction<PresetModel[]>(() => presetsStore.data);
    const [preset] = useReaction<PresetModel | null>(() => presetsStore.preset);
    const [servers] = useReaction<ServerModel[]>(() => presetsStore.servers);

    const { formData, setFormData } = useContext<IFormContext<PresetModel>>(
        FormContext as Context<IFormContext<PresetModel>>,
    );

    const {
        handleSubmit,
        reset,
        clearErrors,
        setError,
        trigger,
        formState: { isDirty, isValid, isSubmitting, errors, dirtyFields },
        control,
    } = useForm<PresetModel>({
        defaultValues: schema(data).cast({}),
        mode: 'all',
        resolver: yupResolver(schema(data)),
    });

    useEffect(() => {
        reset(preset ?? undefined);
        trigger();
    }, [preset]);

    if (!preset) return <></>;

    const onSave: SubmitHandler<PresetModel> = (val) => {
        presetsStore.update(val).then(() => {
            presetsStore.getAllPresets().then((result) => {
                presetsStore.setData(result);
            });
            presetsStore.resetPreset();
            setFormData(undefined);
        });
    };

    const handleDelete = () => {
        presetsStore.delete(preset.id).finally(() => {
            setFormData(undefined);
        });
    };

    const handleClose = () => {
        setFormData(undefined);
    };

    return (
        <RightPanel<PresetModel>
            entityTitle={preset.name}
            deleteConfirmationMessage="Template will be deleted. This action cannot be undone."
            name="Execution Options"
            type={OperationType.update}
            isValid={!!isValid}
            isOpen={!!formData}
            isTouched={isDirty}
            onClose={handleClose}
            onDelete={handleDelete}
            onSubmit={handleSubmit(onSave)}
            isSubmitting={isSubmitting || isLoading}
        >
            <RightPanelCard isLoading={isLoading}>
                <General isLoading={isLoading} control={control} errors={errors} dirtyFields={dirtyFields} />
                <br />
                <Mask
                    // @ts-ignore
                    trigger={trigger as UseFormTrigger<MaskType>}
                    symbolMasks={preset.symbolMasks}
                    groupMasks={preset.groupMasks}
                    type={preset.type}
                    templateType={preset.templateType}
                    // @ts-ignore
                    control={control as Control<MaskType>}
                    // @ts-ignore
                    clearErrors={clearErrors as UseFormClearErrors<MaskType>}
                    setError={setError as UseFormSetError<MaskType>}
                    errors={errors as FieldErrors<MaskType>}
                />
            </RightPanelCard>
            <Modifiers
                control={control}
                errors={errors}
                dirtyFields={dirtyFields}
                preset={preset}
                isLoading={isLoading}
                servers={servers}
            />
        </RightPanel>
    );
});
