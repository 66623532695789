import { SettingsModel } from '@models/Settings';
import { yupNumber } from '@utils/yupValidations';
import * as yup from 'yup';

export const settingsSchema = yup.object().shape({
    id: yup.string(),
    maxDifferenceFactor: yupNumber()
        .min(2, 'Must be greater than 1')
        .max(2147483647, 'Must be less than 2147483647')
        .integer(`Decimal values are not allowed`)
        .required('Required field'),
    deleteLogInterval: yupNumber()
        .min(1, 'Must be greater than 0')
        .max(2147483647, 'Must be less than 2147483647')
        .integer(`Decimal values are not allowed`)
        .required('Required field'),
});

export const defaultSettings: SettingsModel = {
    id: '',
    maxDifferenceFactor: 0,
    deleteLogInterval: 365,
};
