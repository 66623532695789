export const LS_SERVER_URL_KEY = 'serverUrl';
export const UI_VERSION: string = process.env.REACT_APP_BUILD_ID || process.env.REACT_APP_VERSION || '0';

export enum AppRoutes {
    main = '/symbols-templates',
    templatesController = '/symbols-templates',
    selectServer = '/select-server',
    updaterTool = '/updater-tool',
    dividendsUpdaterTool = '/dividends-updater-tool',
    serversSettings = '/servers-settings',
    settings = '/settings',
    history = '/history',
    alertsMessages = '/alerts-messages',
    users = '/users',
    presets = '/execution-templates',
}

export const DEL_SERVER_CONFIRM_TEXT = 'This server will be deleted. This action cannot be undone.';
export const allTakerTemplateFilterValues = ['mt4', 'mt5', 'fix'];
export const allTemplateFilterValues = ['Swaps by Groups', 'Swaps by Symbols', 'Dividends'];
export const SwapsTemplateFilterValues = ['Swaps by Groups', 'Swaps by Symbols'];
export const allServersFilterValues = [true, false];
export const S = '{s}';
export const SYMBOL = '{symbol}';
export const SYMBOL_ARR = ['{symbol}'];
export const EVERY_SYMBOL = '*{symbol}*';
export const symbolAll = '/*/';

export const MAX_URL_LENGTH = 255;
