import React, { forwardRef } from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import Reorder from '@assets/icons/reorder.svg';
import Cross from '@assets/icons/cross.svg';
import { Collapse, Divider, Input, Select, Tooltip } from 'antd';
import { FieldLabel } from '@components/Form/FieldLabel/FieldLabel';
import styles from './styles.module.scss';
import { PresetServerModifier, PresetServerModifierMarkup, PresetServerModifierOpposite } from '@models/Preset';
import { Markup } from './variants/Markup';
import { Opposite } from './variants/Opposite';
import { FieldContainer } from '@components/Form/FieldContainer/FieldContainer';
import { FieldError } from 'react-hook-form';
import { ValidationMessage } from '@components/Form/ValidationMessage/ValidationMessage';
import { getErrorMessage } from '@pages/presets/utils';
import { ServerModifiersList, ServerModifierEnum } from 'types/modifiers';

const { Panel } = Collapse;

type Props = {
    isDragging: boolean;
    isDraggable: boolean;
    onChange: (mod: PresetServerModifier) => void;
    onChangeType: (modId: string, newType: ServerModifierEnum) => void;
    onDelete: (modId: string) => void;
    modifier: PresetServerModifier;
    dragHandleProps: DraggableProvidedDragHandleProps | null;
    usedTypes: ServerModifierEnum[];
    activeKey?: string;
    onCollapse: ({ id, state }: { id: string; state: boolean }) => void;
    error?: FieldError;
};

const SelectModifierType = ({
    order,
    type,
    onChange,
    usedTypes,
}: {
    order: number;
    type: ServerModifierEnum | null;
    usedTypes: ServerModifierEnum[];
    onChange: (type: ServerModifierEnum | undefined) => void;
}) => (
    <div className={styles.selectModifierType}>
        {/* <FieldLabel title={`Modifier ${order}`} required /> */}
        <div className={styles.orderId}>{order}</div>
        <Select
            placeholder="- Choose -"
            allowClear={false}
            value={type}
            onChange={onChange}
            options={ServerModifiersList.filter((item) => item.key === type || !usedTypes.includes(item.key)).map(
                (item) => ({
                    value: item.key,
                    label: item.name,
                }),
            )}
        />
        <Tooltip title="Delete Modifier">
            <img
                onClick={() => {
                    onChange(undefined);
                }}
                alt="Delete"
                src={Cross}
                className={styles.deleteIcon}
            />
        </Tooltip>
    </div>
);

export const ModifierComponent = forwardRef<HTMLDivElement | null, Props>(
    (
        {
            modifier,
            onChangeType,
            onChange,
            dragHandleProps,
            onDelete,
            usedTypes,
            isDragging,
            isDraggable,
            activeKey,
            onCollapse,
            error,
            ...restProps
        },
        ref,
    ) => {
        const changeTypeHandler = (type: ServerModifierEnum | undefined) => {
            if (!type) {
                onDelete(modifier.id ?? '');
            } else {
                onChangeType(modifier.id ?? '', type);
            }
        };

        const changeMarkupHandler = (val: PresetServerModifierMarkup) => {
            modifier.data = val;
            onChange(modifier);
        };

        const changeOppositeHandler = (val: PresetServerModifierOpposite) => {
            modifier.data = val;
            onChange(modifier);
        };

        return (
            <div {...restProps} ref={ref} className={styles.modifierComponent}>
                <Collapse
                    collapsible="header"
                    activeKey={activeKey}
                    ghost
                    expandIconPosition="right"
                    bordered={false}
                    onChange={(key) => {
                        if (Array.isArray(key)) {
                            onCollapse(
                                key[0]
                                    ? {
                                          id: key[0],
                                          state: true,
                                      }
                                    : {
                                          id: modifier.id ?? '',
                                          state: false,
                                      },
                            );
                        }
                    }}
                >
                    <Panel
                        className={styles.collapsePanel}
                        header=""
                        key={modifier.id ?? ''}
                        forceRender
                        extra={
                            <SelectModifierType
                                order={modifier.orderId ?? 0}
                                type={modifier.type}
                                onChange={changeTypeHandler}
                                usedTypes={usedTypes}
                            />
                        }
                    >
                        {modifier.data && modifier.type === ServerModifierEnum.markup && (
                            <Markup
                                value={modifier.data as PresetServerModifierMarkup}
                                onChange={changeMarkupHandler}
                                error={error}
                            />
                        )}
                        {modifier.data && modifier.type === ServerModifierEnum.opposite && (
                            <Opposite
                                value={modifier.data as PresetServerModifierOpposite}
                                onChange={changeOppositeHandler}
                            />
                        )}
                        <FieldContainer>
                            <FieldLabel title="Short Name" required />
                            <Input
                                value={modifier.shortName}
                                onChange={(e) => {
                                    const shortName = e.target.value;
                                    onChange({ ...modifier, shortName });
                                }}
                                size="middle"
                            />
                            <ValidationMessage message={getErrorMessage('shortName', error)} />
                        </FieldContainer>
                        <Divider />
                    </Panel>
                </Collapse>
                <div {...dragHandleProps}>
                    {isDraggable ? (
                        <Tooltip title="Drag to reorder">
                            <img alt="Drag" src={Reorder} className={styles.dragIcon} />
                        </Tooltip>
                    ) : null}
                </div>
            </div>
        );
    },
);
