/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useContext, useState } from 'react';
import { Button, Input } from 'antd';
import { FilterPropsRangeNum } from './types';
import styles from './RowFilters.module.scss';
import { FilterValuesContext } from '../TableExt';
import { IRangeInput, IRangeInputN } from '../types';
import { typedMemo } from '../../../utils';

export interface IComparePare {
    from: number | string;
    to: number | string;
}

export const RowFilterRangeNum = typedMemo((props: FilterPropsRangeNum) => {
    const filterValues = useContext(FilterValuesContext);
    const [value, setValue] = useState<IRangeInput | IRangeInputN>(
        filterValues.values[props.dataIndex]
            ? (filterValues.values[props.dataIndex] as IRangeInputN)
            : { from: '', to: '' },
    );

    const onFilter = useCallback(() => {
        if (value.from === '' && value.to === '') {
            filterValues.setValues({ ...filterValues.values, [props.dataIndex]: null });
        } else {
            filterValues.setValues({
                ...filterValues.values,
                [props.dataIndex]: {
                    from: parseFloat((value.from as string).replace(',', '.')),
                    to: parseFloat((value.to as string).replace(',', '.')),
                },
            });
        }
        props.confirmCallback();
    }, [filterValues, props.dataIndex, value.from, value.to]);

    const onChangeHandler = (val: string, type: 'from' | 'to') => {
        const regExp = /\d+[.,]?\d*/g;
        const match: RegExpExecArray | null = regExp.exec(val);
        if (match || val === '') setValue({ ...value, [type]: match ? match[0] : '' });
    };

    const onFromInput = (e: any) => {
        onChangeHandler(e.target.value, 'from');
    };

    const onToInput = (e: any) => {
        onChangeHandler(e.target.value, 'to');
    };

    const onReset = () => {
        filterValues.setValues({ ...filterValues.values, [props.dataIndex]: null });
        setValue({ from: '', to: '' });
        props.confirmCallback();
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles['site-input-group-wrapper']}>
                <Input.Group compact>
                    <Input
                        onChange={onFromInput}
                        className={styles['site-input-left']}
                        placeholder="From"
                        value={value.from}
                        onPressEnter={onFilter}
                    />
                    <Input className={styles['site-input-split']} placeholder="→" disabled />
                    <Input
                        className={styles['site-input-right']}
                        value={value.to}
                        onChange={onToInput}
                        placeholder="To"
                        onPressEnter={onFilter}
                    />
                </Input.Group>
            </div>
            <div className={styles.btnBlock}>
                <Button type="link" className={styles.reset} onClick={onReset}>
                    Reset
                </Button>
                <Button type="primary" onClick={onFilter} size="small" className={styles.button}>
                    Filter
                </Button>
            </div>
        </div>
    );
});
