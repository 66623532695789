export const getValidationClassName = (status: number): string => {
    switch (status) {
        case 0:
            return 'error';
        case 1:
            return 'warning';
        case 2:
            return 'info';
        default:
            return 'other';
    }
};
