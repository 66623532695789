import React, { FC, memo } from 'react';
import { Button } from 'antd';
import styles from './rightPanel.module.scss';
import { OperationType } from 'types/commonTypes';

type Props = {
    type: OperationType;
    isTouched?: boolean;
    onReset?(): void;
    onSubmit?(): void;

    submitBtnTitle?: string;
    isSubmitDisabled?: boolean;
    isSubmitting?: boolean;

    onDelete?(): void;
    customButtons?: React.JSX.Element | null;
};

export const RightPanelFooter: FC<Props> = memo(
    ({ onDelete, isSubmitDisabled, isSubmitting, submitBtnTitle = 'Save', customButtons, onSubmit }) => {
        return (
            <div className={styles.rightPanel__footer}>
                <span className={styles.rightPanel__footer__btnGroup}>
                    {customButtons}
                    {onSubmit && (
                        <Button
                            size="large"
                            type="primary"
                            htmlType="submit"
                            className={styles.btn_create}
                            disabled={isSubmitDisabled}
                            loading={isSubmitting}
                        >
                            {submitBtnTitle}
                        </Button>
                    )}
                </span>
                {!!onDelete && (
                    <Button size="large" danger htmlType="button" onClick={onDelete}>
                        Delete
                    </Button>
                )}
            </div>
        );
    },
);
