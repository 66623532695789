/* eslint-disable @typescript-eslint/no-explicit-any */
import { makeAutoObservable } from 'mobx';
import {
    FilterTimeStamp,
    HistoryDetailsDividendsResponseType,
    HistoryDetailsFIXDividendsServersType,
    HistoryDetailsFIXServersType,
    HistoryDetailsMTDividendsServersType,
    HistoryDetailsMTServersType,
    HistoryDetailsResponseType,
    HistoryDetailsType,
    HistoryType,
    OrderColumnType,
    TableCondition,
    getConvertedHistoryDetailsModels,
} from '@models/History';
import { Message, MessageType } from '@components/Message/Message';
import { API } from '@api/client/apiEndpoints';
import { DEFAULT_PAGE_SIZE } from '@components/TableServerSide/contants';
import { FilterValuesT } from '@components/TableServerSide/types';
import { SorterResult } from 'antd/lib/table/interface';
import { HistoryTemplateType } from 'types/commonTypes';

export class HistoryStore {
    constructor() {
        makeAutoObservable(this);
    }

    data: HistoryType[] = [];

    historyMT: HistoryDetailsType<HistoryDetailsResponseType<HistoryDetailsMTServersType>> = {} as HistoryDetailsType<
        HistoryDetailsResponseType<HistoryDetailsMTServersType>
    >;

    historyFIX: HistoryDetailsType<HistoryDetailsResponseType<HistoryDetailsFIXServersType>> = {} as HistoryDetailsType<
        HistoryDetailsResponseType<HistoryDetailsFIXServersType>
    >;

    historyDividendsMT: HistoryDetailsType<HistoryDetailsDividendsResponseType<HistoryDetailsMTDividendsServersType>> =
        {} as HistoryDetailsType<HistoryDetailsDividendsResponseType<HistoryDetailsMTDividendsServersType>>;

    historyDividendsFIX: HistoryDetailsType<
        HistoryDetailsDividendsResponseType<HistoryDetailsFIXDividendsServersType>
    > = {} as HistoryDetailsType<HistoryDetailsDividendsResponseType<HistoryDetailsFIXDividendsServersType>>;

    isLoading: boolean = false;

    isLoadingHistoryDetails: boolean = false;

    pageSize: number = DEFAULT_PAGE_SIZE;

    currentPage: number = 1;

    orderColumn: OrderColumnType | null = null;

    orderByDesc: boolean | null = null;

    filterColumn: OrderColumnType | null = null;

    filterQuery: string | null = '';

    filterEvent: string | null = '';

    filterTimestamp: FilterTimeStamp | null = '' as unknown as FilterTimeStamp;

    filterUser: string | null = '';

    recordsTotal: number = 0;

    setData(value: HistoryType[]): void {
        this.data = value;
    }

    getHistoryWithParameters() {
        this.isLoading = true;
        const urlParams = {} as Record<string, string>;
        if (this.currentPage !== null) {
            urlParams.page = this.currentPage.toString();
        }
        if (this.pageSize !== null) {
            urlParams.pageCount = this.pageSize.toString();
        }
        if (this.orderColumn !== null) {
            urlParams.orderColumn = this.orderColumn.toString();
        }
        if (this.orderByDesc !== null) {
            urlParams.orderByDesc = this.orderByDesc.toString();
        }
        if (this.filterUser) {
            urlParams.user = this.filterUser;
        }
        if (this.filterEvent) {
            urlParams.event = this.filterEvent;
        }
        if (this.filterTimestamp && this.filterTimestamp.from) {
            urlParams.timestamp = this.filterTimestamp.from.format('YYYY-MM-DDTHH:mm:ssZ');
        }
        const dynamicURL = new URLSearchParams(urlParams).toString();
        API.history
            .get(dynamicURL)
            .then(({ data: { histories, count } }) => {
                this.setData(histories);
                this.recordsTotal = Number(count);
                if (Number(count) < this.pageSize) {
                    this.currentPage = 1;
                }
            })
            .catch((e) => {
                Message(MessageType.error, e);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    getHistoryDetails(id: string, type: HistoryTemplateType) {
        this.isLoadingHistoryDetails = true;
        // MT4/MT5 Groups
        if (
            type === HistoryTemplateType.MT4swapsByGroups ||
            type === HistoryTemplateType.MT5swapsByGroups ||
            type === HistoryTemplateType.MT4swapsBySymbol ||
            type === HistoryTemplateType.MT5swapsBySymbol
        ) {
            API.history
                .getDetailsMT(id)
                .then(({ data }) => {
                    this.historyMT =
                        getConvertedHistoryDetailsModels<
                            HistoryDetailsType<HistoryDetailsResponseType<HistoryDetailsMTServersType>>
                        >(data);
                })
                .catch((e) => {
                    Message(MessageType.error, e);
                    Promise.reject(e);
                })
                .finally(() => {
                    this.isLoadingHistoryDetails = false;
                });
        }
        if (type === HistoryTemplateType.FIXSwapsUpdated) {
            API.history
                .getDetailsFIX(id)
                .then(({ data }) => {
                    this.historyFIX = getConvertedHistoryDetailsModels(data);
                })
                .catch((e) => {
                    Message(MessageType.error, e);
                    Promise.reject(e);
                })
                .finally(() => {
                    this.isLoadingHistoryDetails = false;
                });
        }
        if (type === HistoryTemplateType.Mt4AdjustDividends || type === HistoryTemplateType.Mt5AdjustDividends) {
            API.history
                .getDetailsMTDevidends(id)
                .then(({ data }) => {
                    this.historyDividendsMT =
                        getConvertedHistoryDetailsModels<
                            HistoryDetailsType<
                                HistoryDetailsDividendsResponseType<HistoryDetailsMTDividendsServersType>
                            >
                        >(data);
                })
                .catch((e) => {
                    Message(MessageType.error, e);
                    Promise.reject(e);
                })
                .finally(() => {
                    this.isLoadingHistoryDetails = false;
                });
        }
        if (type === HistoryTemplateType.FIXAjustDividends) {
            API.history
                .getDetailsDevidendsFIX(id)
                .then(({ data }) => {
                    this.historyDividendsFIX =
                        getConvertedHistoryDetailsModels<
                            HistoryDetailsType<
                                HistoryDetailsDividendsResponseType<HistoryDetailsFIXDividendsServersType>
                            >
                        >(data);
                })
                .catch((e) => {
                    Message(MessageType.error, e);
                    Promise.reject(e);
                })
                .finally(() => {
                    this.isLoadingHistoryDetails = false;
                });
        }
    }

    onPageChange(page: number, pageSize: number) {
        this.pageSize = pageSize;
        this.currentPage = page;
    }

    setFiltration(filter: FilterValuesT) {
        const allKeys = Object.keys(filter);
        if (allKeys.includes('event')) {
            this.filterEvent = filter.event as string | null;
        } else {
            this.filterEvent = null;
        }
        if (allKeys.includes('user')) {
            this.filterUser = filter.user as string | null;
        } else {
            this.filterUser = null;
        }
        if (allKeys.includes('timestamp')) {
            if (filter.timestamp !== null) {
                this.filterTimestamp = filter.timestamp as FilterTimeStamp;
            } else {
                this.filterTimestamp = null;
            }
        }
    }

    setSorting(value: SorterResult<TableCondition> | SorterResult<TableCondition>[]) {
        const sorterResult = Array.isArray(value) ? value[0] : value;
        this.orderByDesc = sorterResult.order !== undefined ? sorterResult.order === 'ascend' : null;
        if ((sorterResult.order !== undefined ? sorterResult.order === 'ascend' : null) !== null) {
            this.orderColumn = sorterResult.field as OrderColumnType;
        } else {
            this.orderColumn = null;
        }
    }

    reset(): void {
        this.data = [];
        this.isLoading = false;
        this.orderColumn = null;
        this.orderByDesc = null;
        this.filterColumn = null;
        this.filterQuery = null;
        this.filterEvent = null;
        this.filterTimestamp = null;
        this.filterUser = null;
        this.historyMT = {} as HistoryDetailsType<HistoryDetailsResponseType<HistoryDetailsMTServersType>>;
        this.historyFIX = {} as HistoryDetailsType<HistoryDetailsResponseType<HistoryDetailsFIXServersType>>;
        this.historyDividendsMT = {} as HistoryDetailsType<
            HistoryDetailsDividendsResponseType<HistoryDetailsMTDividendsServersType>
        >;
        this.historyDividendsFIX = {} as HistoryDetailsType<
            HistoryDetailsDividendsResponseType<HistoryDetailsFIXDividendsServersType>
        >;
        this.currentPage = 1;
    }
}

const historyStore = new HistoryStore();
export { historyStore };
