/* eslint-disable @typescript-eslint/no-explicit-any */
import { NUM_INPUT_PRECISION } from '@constants/inputs';
import { getParsedJSON, isFilledString } from '@utils';
import Big from 'big.js';
import moment from 'moment';
import { ConvertStringToServerModifier, ServerModifierEnum } from 'types/modifiers';
import { ConvertStringToTargetTemplateType, TargetTemplateTypeEnum } from 'types/targetTemplateTypes';
import { ConvertStringToTargetType, TargetTypeEnum } from 'types/targetTypes';

export type PresetServerModifierMarkup = {
    negative: Big | null;
    positive: Big | null;
};

export type PresetServerModifierOpposite = {
    longEnabled: boolean;
    shortEnabled: boolean;
};

export type PresetServerModifierData = PresetServerModifierMarkup | PresetServerModifierOpposite;

export type PresetServerModifier = {
    id: string | null;
    orderId: number | null;
    type: ServerModifierEnum | null;
    name: string;
    shortName: string;
    data: PresetServerModifierData | null;
};

export type PresetServer = {
    id: string | null;
    serverId: string | null;
    state: boolean;
    presetServerModifiers: PresetServerModifier[];
};

export type MaskType = { symbolMasks: string[]; groupMasks: string[] };

export type PresetModel = MaskType & {
    id: string;
    name: string;
    type: TargetTypeEnum | null;
    templateType: TargetTemplateTypeEnum | null;
    createDate: moment.Moment | null;
    updateDate: moment.Moment | null;
    presetServers: PresetServer[];
};

export type PresetCreateModel = {
    name: string;
    type: TargetTypeEnum | null;
    templateType: TargetTemplateTypeEnum | null;
    symbolMask: string[];
    groupMask: string[];
};

const convertJSONToPresetServerModifierData = (
    data: any | null,
    type: ServerModifierEnum,
): PresetServerModifierMarkup | PresetServerModifierOpposite | null => {
    if (data === null) return null;
    if (type === ServerModifierEnum.markup) {
        if (Number.isFinite(+data.negative) && Number.isFinite(+data.positive)) {
            return {
                negative: new Big(data.negative).round(NUM_INPUT_PRECISION),
                positive: new Big(data.positive).round(NUM_INPUT_PRECISION),
            };
        }
        alert('Invalid ServerModifierMarkup');
        return null;
    }
    if (type === ServerModifierEnum.opposite) {
        if (typeof data.longEnabled === 'boolean' && typeof data.shortEnabled === 'boolean') {
            return {
                longEnabled: data.longEnabled,
                shortEnabled: data.shortEnabled,
            };
        }
        alert('Invalid ServerModifierOpposite');
        return null;
    }
    return null;
};

const convertPresetServerModifier = (record: any): PresetServerModifier => {
    const type = ConvertStringToServerModifier(record.type);
    if (type === null) {
        throw new Error("Invalid record. Can't parse to PresetServerModifier. ServerModifier type is not set");
    }
    return {
        id: isFilledString(record.id) ? record.id : null,
        orderId: Number.isFinite(record.orderId) ? record.orderId : null,
        type,
        name: isFilledString(record.name) ? record.name : '',
        shortName: isFilledString(record.shortName) ? record.shortName : '',
        data: isFilledString(record.data)
            ? convertJSONToPresetServerModifierData(getParsedJSON(record.data), type)
            : null,
    };
};

const convertPresetServer = (record: any): PresetServer => ({
    id: isFilledString(record.id) ? record.id : null,
    serverId: isFilledString(record.serverId) ? record.serverId : null,
    state: typeof record.state === 'boolean' ? record.state : false,
    presetServerModifiers: Array.isArray(record.presetServerModifiers)
        ? record.presetServerModifiers.map((item: any) => convertPresetServerModifier(item))
        : [],
});

export const convertRecordToPresetModel = (record: any): PresetModel => {
    const result = {
        id: isFilledString(record.id) ? record.id : '',
        name: isFilledString(record.name) ? record.name : '',
        type: ConvertStringToTargetType(record.type),
        templateType: ConvertStringToTargetTemplateType(record.templateType),
        createDate: isFilledString(record.createDate) ? moment.utc(record.createDate) : null,
        updateDate: isFilledString(record.updateDate) ? moment.utc(record.updateDate) : null,
        symbolMasks: Array.isArray(record.symbolMasks)
            ? record.symbolMasks.map((item: any) => (isFilledString(item) ? item : '')).filter((item: any) => !!item)
            : [],
        groupMasks: Array.isArray(record.groupMasks)
            ? record.groupMasks.map((item: any) => (isFilledString(item) ? item : '')).filter((item: any) => !!item)
            : [],
        presetServers: Array.isArray(record.presetServers)
            ? record.presetServers.map((item: any) => convertPresetServer(item))
            : [],
    };
    return result;
};

export const presetServerModifiersToApi = (item: PresetServerModifierData | null): string => {
    if (!item) return '';
    return JSON.stringify({
        ...item,
        //   @ts-ignore
        negative: item.negative ? item.negative.toString() : '',
        //   @ts-ignore
        positive: item.positive ? item.positive.toString() : '',
    });
};
export const presetModelToApi = (record: PresetModel | PresetCreateModel) => {
    if ((record as PresetModel).presetServers) {
        const newRecord = { ...record } as PresetModel;
        return {
            ...newRecord,
            presetServers: newRecord.presetServers.map((ps) => ({
                ...ps,
                presetServerModifiers: ps.presetServerModifiers.map((psm) => ({
                    ...psm,
                    data: presetServerModifiersToApi(psm.data),
                })),
            })),
        };
    }
    return {
        ...record,
    };
};
