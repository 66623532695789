/* eslint-disable react/jsx-props-no-spreading */
import { PresetServer, PresetServerModifier } from '@models/Preset';
import React, { FC, useState } from 'react';
import cn from 'classnames';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { ModifierComponent } from './ModifierComponent';
import { AddModifier } from './AddModifier';
import { FieldContainer } from '@components/Form/FieldContainer/FieldContainer';
import {
    Control,
    Controller,
    FieldErrors,
    FieldNamesMarkedBoolean,
    FieldValues,
    UseFormSetValue,
    UseFormWatch,
} from 'react-hook-form';
import { Message, MessageType } from '@components/Message/Message';
import { getDefaultMarkupModifier } from './variants/Markup';
import { getDefaultOppositeModifier } from './variants/Opposite';
import { ServerModifierEnum } from 'types/modifiers';

type Props = {
    control: Control<PresetServer>;
    errors: FieldErrors<FieldValues>;
    dirtyFields: FieldNamesMarkedBoolean<FieldValues>;
    setValue: UseFormSetValue<PresetServer>;
    watch: UseFormWatch<PresetServer>;
};

const getNewDefaultModifier = (modifiers: PresetServerModifier[]): PresetServerModifier | null => {
    let availableTypes = Object.values(ServerModifierEnum);
    let maxOrderId = modifiers.length;
    modifiers.forEach((mod) => {
        availableTypes = availableTypes.filter((type) => type !== mod.type);
        if (mod.orderId !== null && mod.orderId > maxOrderId) {
            maxOrderId = mod.orderId;
        }
    });
    if (availableTypes.length === 0) {
        Message(MessageType.error, 'No available modifier types');
    } else {
        const targetAvailableType = availableTypes[0];
        if (targetAvailableType === ServerModifierEnum.markup) {
            return { ...getDefaultMarkupModifier(), orderId: maxOrderId + 1 };
        }
        if (targetAvailableType === ServerModifierEnum.opposite) {
            return { ...getDefaultOppositeModifier(), orderId: maxOrderId + 1 };
        }
    }
    return null;
};

export const ModifiersList: FC<Props> = ({ control }) => {
    const [activeKeys, setActiveKeys] = useState<Set<string>>(new Set([]));

    return (
        <FieldContainer>
            <Controller
                control={control}
                name="presetServerModifiers"
                render={({ field: { value, onChange }, fieldState }) => {
                    const isDraggable = value.length > 0;

                    const usedModifierTypes = value.map((mod) => mod.type).filter((type) => type !== null);

                    const handleDragEnd = (result: DropResult) => {
                        try {
                            const sourceIndex = result.source.index;
                            const destinationIndex = result.destination?.index ?? sourceIndex;
                            if (sourceIndex >= 0 && destinationIndex >= 0) {
                                const movingItem = value.splice(sourceIndex, 1);
                                value.splice(destinationIndex, 0, movingItem[0]);
                                value.forEach((item, index) => {
                                    item.orderId = index + 1;
                                });
                                onChange([...value.sort((a, b) => (a.orderId ?? 0) - (b.orderId ?? 0))]);
                            }
                        } catch (error) {
                            Message(MessageType.error, "Can't move");
                        }
                    };

                    const deleteModifierHandler = (modId: string) => {
                        let newList = [...value.filter((e) => e.id !== modId)];
                        newList = newList.map((item, index) => {
                            item.orderId = index + 1;
                            return item;
                        });
                        onChange(newList);
                    };

                    const addHandler = () => {
                        const newDefaultValue: PresetServerModifier | null = getNewDefaultModifier(value);
                        if (newDefaultValue) {
                            onChange([...value, newDefaultValue]);
                            if (newDefaultValue.id !== null) {
                                activeKeys.add(newDefaultValue.id);
                                setActiveKeys(new Set(activeKeys));
                            }
                        }
                    };

                    const changeTypeModifierHandler = (modId: string, newType: ServerModifierEnum) => {
                        if (value.filter((item) => item.type === newType).length > 0) {
                            Message(MessageType.error, 'Modifier type is already used');
                            return;
                        }
                        const orderId = value.find((e) => e.id === modId)?.orderId ?? 0;
                        const newValue: PresetServerModifier = (() => {
                            switch (newType) {
                                case ServerModifierEnum.markup:
                                    return {
                                        ...getDefaultMarkupModifier(),
                                        orderId,
                                    };
                                case ServerModifierEnum.opposite:
                                    return {
                                        ...getDefaultOppositeModifier(),
                                        orderId,
                                    };
                            }
                        })();
                        const newVal = [...value, newValue]
                            .filter((e) => e.id !== modId)
                            .sort((a, b) => (a.orderId ?? 0) - (b.orderId ?? 0))
                            .map((item, index) => {
                                item.orderId = index + 1;
                                return item;
                            });
                        onChange(newVal);
                        if (newValue.id !== null) {
                            activeKeys.add(newValue.id);
                            setActiveKeys(new Set(activeKeys));
                        }
                    };

                    const changeModifierHandler = (mod: PresetServerModifier) => {
                        let targetModIndex = value.findIndex((e) => e.id === mod.id);
                        if (targetModIndex !== -1) {
                            const newValue = [...value];
                            newValue[targetModIndex] = mod;
                            onChange(newValue);
                        }
                    };

                    const handleCollapse = ({ id, state }: { id: string; state: boolean }) => {
                        if (state) {
                            activeKeys.add(id);
                            setActiveKeys(new Set(activeKeys));
                        } else {
                            activeKeys.delete(id);
                            setActiveKeys(new Set(activeKeys));
                        }
                    };

                    return (
                        <>
                            <DragDropContext onDragEnd={handleDragEnd}>
                                <Droppable droppableId="container">
                                    {(provided, { isDraggingOver }) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            className={cn({
                                                containerIsDragging: isDraggingOver,
                                            })}
                                        >
                                            {value
                                                .sort((a, b) => (a.orderId ?? 0) - (b.orderId ?? 0))
                                                .map((modifier, index) => {
                                                    const fieldStateError =
                                                        Array.isArray(fieldState.error) && fieldState.error[index]
                                                            ? fieldState.error[index]
                                                            : undefined;
                                                    return (
                                                        <Draggable
                                                            isDragDisabled={!isDraggable}
                                                            draggableId={`comment-${modifier.id}`}
                                                            index={index}
                                                            key={modifier.id}
                                                        >
                                                            {(
                                                                { innerRef, dragHandleProps, draggableProps },
                                                                { isDragging },
                                                            ) => (
                                                                <ModifierComponent
                                                                    {...draggableProps}
                                                                    ref={innerRef}
                                                                    dragHandleProps={dragHandleProps ?? null}
                                                                    isDragging={isDragging}
                                                                    isDraggable={isDraggable}
                                                                    modifier={modifier}
                                                                    onChange={changeModifierHandler}
                                                                    onDelete={deleteModifierHandler}
                                                                    onChangeType={changeTypeModifierHandler}
                                                                    onCollapse={handleCollapse}
                                                                    activeKey={
                                                                        modifier.id !== null &&
                                                                        activeKeys.has(modifier.id)
                                                                            ? modifier.id
                                                                            : undefined
                                                                    }
                                                                    error={fieldStateError}
                                                                    usedTypes={
                                                                        usedModifierTypes as ServerModifierEnum[]
                                                                    }
                                                                />
                                                            )}
                                                        </Draggable>
                                                    );
                                                })}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            <AddModifier modifiersCount={value.length} onAdd={addHandler} />
                        </>
                    );
                }}
            />
        </FieldContainer>
    );
};
