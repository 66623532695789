import React, { useState } from 'react';
import { FilterableTableProps, FilterValuesT, FilterValuesContextT } from './types';
import { TableExtInner } from './TableExtInner/TableExtInner';

export const FilterValuesContext = React.createContext<FilterValuesContextT>(null!);

export function TableServerSide<ContextDataT extends {}>(props: FilterableTableProps<ContextDataT>) {
    const { onFilterChange, filterValues } = props;
    const [filterValuesState, setFilterValuesState] = useState<FilterValuesT>(filterValues || {});
    const values = onFilterChange && filterValues ? filterValues : filterValuesState;

    function handleFilterUpdate(newValues: FilterValuesT) {
        setFilterValuesState(newValues);
        if (onFilterChange) onFilterChange(values);
    }

    return (
        <FilterValuesContext.Provider value={{ values, setValues: handleFilterUpdate }}>
            <TableExtInner {...props} />
        </FilterValuesContext.Provider>
    );
}
