import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import Title from 'antd/es/typography/Title';
import { FieldContainer } from '@components/Form/FieldContainer/FieldContainer';
import { FieldLabel } from '@components/Form/FieldLabel/FieldLabel';
import { FormFooter } from '@components/Form/FormFooter/FormFooter';
import { Controller, useForm } from 'react-hook-form';
import { Modal, Input, Select, InputNumber } from 'antd';
import { OperationType, OperationTypeBtnTitle } from 'types/commonTypes';
import { inputNumberKeyDownHandler } from '@utils/number';
import cn from 'classnames';
import styles from '../editTemplate.module.scss';
import { ValidationMessage } from '@components/Form/ValidationMessage/ValidationMessage';
import { RowContainer } from '@components/Form/RowContainer/RowContainer';
import { TemplateModel, TemplateSwapModel } from '@models/Templates';
import { TargetTypeEnum } from 'types/targetTypes';
import { swapSchema } from './schema';
import { AllDaysList, WeekDaysList } from 'types/days';
import { SwapTypesListShort } from 'types/swapsTypes';
import Big from 'big.js';

const FORM_ID = 'EditSwap';

const getIdNewSwap = (swaps: TemplateSwapModel[]): number => {
    const maxSwapId = swaps.reduce((acc: number, swap: TemplateSwapModel) => Math.max(acc, swap.id), 0);
    return maxSwapId + 1;
};

const getTargetSwap = (swaps: TemplateSwapModel[], swapId: number): TemplateSwapModel | null => {
    return swaps.find((swap) => swap.id === swapId) ?? null;
};

export const SwapModal = (props: {
    onSubmit: ({ value, isNewRecord }: { value: TemplateSwapModel; isNewRecord: boolean }) => void;
    current?: TemplateSwapModel;
    title: string;
    submitBtnTitle?: string;
    isShowTripleSD?: boolean;
    onCancel: () => void;
    isShowTypes: boolean;
    swaps: TemplateSwapModel[];
    idSwapToEdit: number | null;
    template: TemplateModel;
}) => {
    const { onSubmit, onCancel, title, submitBtnTitle, isShowTripleSD, isShowTypes, swaps, idSwapToEdit, template } =
        props;

    const defaultNewSwap = swapSchema(swaps).cast({ id: getIdNewSwap(swaps) });

    const defaultValues = idSwapToEdit === null ? defaultNewSwap : getTargetSwap(swaps, idSwapToEdit) ?? defaultNewSwap;

    const {
        handleSubmit,
        reset,
        formState: { isDirty, isValid, errors, dirtyFields },
        control,
    } = useForm<TemplateSwapModel>({
        defaultValues,
        mode: 'all',
        resolver: yupResolver(swapSchema(swaps)),
    });

    const handleReset = () => {
        reset(defaultValues);
    };
    const cancelHandler = () => {
        handleReset();
        onCancel();
    };

    if (!template) return null;

    const daysOptions = template.type === TargetTypeEnum.mt5 ? AllDaysList : WeekDaysList;
    const isTargetTypeFix = template.type === TargetTypeEnum.takerFix;

    const submitHandler = (value: TemplateSwapModel) => {
        onSubmit({ value, isNewRecord: idSwapToEdit === null });
    };

    const ShortInput = (
        <FieldContainer>
            <FieldLabel title="Short" required />
            <Controller
                control={control}
                name="shortSwap"
                render={({ field: { onChange, value } }) => {
                    return (
                        <InputNumber<string>
                            onChange={(val) => {
                                if (!val) onChange(null);
                                if (val && val.length > 0) {
                                    const numValue = new Big(val);
                                    onChange(numValue);
                                }
                            }}
                            decimalSeparator="."
                            value={value ? value.toString() : ''}
                            onKeyDown={inputNumberKeyDownHandler}
                            stringMode
                            size="middle"
                            className={
                                submitBtnTitle === OperationTypeBtnTitle.update
                                    ? cn({
                                          changed: dirtyFields.shortSwap,
                                          invalid: !!errors.shortSwap?.message,
                                      })
                                    : ''
                            }
                        />
                    );
                }}
            />
            <ValidationMessage message={errors.shortSwap?.message} />
        </FieldContainer>
    );

    const LongInput = (
        <FieldContainer>
            <FieldLabel title="Long" required />
            <Controller
                control={control}
                name="longSwap"
                render={({ field: { onChange, value } }) => {
                    return (
                        <InputNumber<string>
                            onChange={(val) => {
                                if (!val) onChange(null);
                                if (val && val.length > 0) {
                                    const numValue = new Big(val);
                                    onChange(numValue);
                                }
                            }}
                            decimalSeparator="."
                            value={value ? value.toString() : ''}
                            onKeyDown={inputNumberKeyDownHandler}
                            stringMode
                            size="middle"
                            className={
                                submitBtnTitle === OperationTypeBtnTitle.update
                                    ? cn({
                                          changed: dirtyFields.longSwap,
                                          invalid: !!errors.longSwap?.message,
                                      })
                                    : ''
                            }
                        />
                    );
                }}
            />
            <ValidationMessage message={errors.longSwap?.message} />
        </FieldContainer>
    );

    return (
        <Modal className={styles.modal} open onCancel={cancelHandler} footer={null}>
            <Title level={3}>{title}</Title>
            <form id={FORM_ID}>
                <FieldContainer>
                    <FieldLabel title="Name" required />
                    <Controller
                        control={control}
                        name="symbol"
                        render={({ field }) => {
                            return (
                                <Input
                                    {...field}
                                    className={
                                        submitBtnTitle === OperationTypeBtnTitle.update
                                            ? cn({
                                                  changed: dirtyFields.symbol,
                                                  invalid: !!errors.symbol?.message,
                                              })
                                            : ''
                                    }
                                    value={field.value ?? undefined}
                                />
                            );
                        }}
                    />
                    <ValidationMessage message={errors.symbol?.message} />
                </FieldContainer>
                {isShowTypes && (
                    <FieldContainer>
                        <FieldLabel title="Type" />
                        <Controller
                            control={control}
                            name="type"
                            render={({ field }) => {
                                return (
                                    <Select
                                        {...field}
                                        placeholder="Select..."
                                        allowClear
                                        removeIcon
                                        className={
                                            submitBtnTitle === OperationTypeBtnTitle.update
                                                ? cn({
                                                      changed: dirtyFields.type,
                                                      invalid: !!errors.type?.message,
                                                  })
                                                : ''
                                        }
                                        value={field.value}
                                        options={SwapTypesListShort.map((item) => ({
                                            value: item.key,
                                            label: item.title,
                                        }))}
                                        onChange={(value) => {
                                            field.onChange(value ?? null);
                                        }}
                                    />
                                );
                            }}
                        />
                        <ValidationMessage message={errors.type?.message} />
                    </FieldContainer>
                )}
                {isTargetTypeFix ? (
                    <RowContainer>
                        {ShortInput}
                        {LongInput}
                    </RowContainer>
                ) : (
                    <RowContainer>
                        {LongInput}
                        {ShortInput}
                    </RowContainer>
                )}
                {isShowTripleSD && (
                    <FieldContainer>
                        <FieldLabel title="Triple Swap Day" />
                        <Controller
                            control={control}
                            name="tripleSwapDay"
                            render={({ field }) => {
                                return (
                                    <Select
                                        {...field}
                                        value={field.value}
                                        className={
                                            submitBtnTitle === OperationTypeBtnTitle.update
                                                ? cn({
                                                      changed: dirtyFields.tripleSwapDay,
                                                      invalid: !!errors.tripleSwapDay?.message,
                                                  })
                                                : ''
                                        }
                                        allowClear
                                        removeIcon
                                        placeholder="Select..."
                                        options={daysOptions.map((item) => ({
                                            label: item.title,
                                            value: item.key,
                                        }))}
                                        onChange={(value) => {
                                            field.onChange(value ?? null);
                                        }}
                                    />
                                );
                            }}
                        />
                        <ValidationMessage message={errors.tripleSwapDay?.message} />
                    </FieldContainer>
                )}
                <FormFooter
                    isValid={isValid}
                    isDirty={isDirty}
                    type={OperationType.create}
                    onBack={cancelHandler}
                    onSubmit={handleSubmit(submitHandler)}
                    submitBtnTitle={submitBtnTitle}
                />
            </form>
        </Modal>
    );
};
