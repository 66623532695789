/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch } from 'react';
import { Button, Space, Tooltip } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import './style.scss';
import { typedMemo } from '@utils';
import { CustomColumnType, TableColsSettings } from '../types';
import cn from 'classnames';
// import { AppLayoutContext } from '@App/layouts/AppLayout';

type Props<ContextDataT> = {
    updateData?: () => void;
    toolbar?: JSX.Element[];
    activeColumnsSetter: Dispatch<string[]>;
    reorderColumns: Dispatch<CustomColumnType<ContextDataT>[]>;
    columns: CustomColumnType<ContextDataT>[];
    exportData?: any[];
    title: string;
    titleJSX?: () => JSX.Element;
    colsSettings?: TableColsSettings;
};

export const TableHeader = typedMemo(<ContextDataT,>(props: Props<ContextDataT>) => {
    const { title, updateData, toolbar, titleJSX } = props;

    return (
        <div className="table-header">
            {title !== '' && (
                <Space align="baseline" className="header-space">
                    <h1 className="table-header-h1">{title}</h1>
                </Space>
            )}
            {titleJSX && titleJSX()}

            <Space align="baseline" className="toolBar-wrapper">
                {toolbar || null}
                <Space>
                    {updateData ? (
                        <Tooltip title="refresh">
                            <Button
                                onClick={updateData}
                                icon={<ReloadOutlined />}
                                className={cn(
                                    'btn-no-outline',
                                    'table-header-btn',
                                    // isSidebarCollapsed ? 'table-header-btn_collapsed' : 'table-header-btn_open',
                                )}
                            />
                        </Tooltip>
                    ) : null}
                </Space>
            </Space>
        </div>
    );
});
