import React, { FC } from 'react';
import Add from '@assets/icons/add.svg';
import styles from './styles.module.scss';
import { ServerModifierEnum } from 'types/modifiers';

type Props = {
    modifiersCount: number;
    onAdd: () => void;
};

const MAX_MODIFIERS_LENGTH = Object.keys(ServerModifierEnum).length;

export const AddModifier: FC<Props> = ({ onAdd, modifiersCount }) => {
    const isMaximumReached = modifiersCount >= MAX_MODIFIERS_LENGTH;

    return isMaximumReached ? (
        <div>Maximum amount of multipliers applied </div>
    ) : (
        <div onClick={onAdd} className={styles.addButton}>
            <img alt="Add" src={Add} />
            Add a New Modifier{' '}
        </div>
    );
};
