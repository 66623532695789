import React from 'react';
import { useReaction } from '@utils/hooks';
import { TableExt } from '@components/TableExt/TableExt';
import { useNavigate } from 'react-router';
import { AppRoutes } from '@constants';
import Details from '@assets/icons/details.svg';
import { templatesSwapStore } from '@store/templatesSwapStore';
import { BaseTemplateModel } from '@models/Templates';
import { observer } from 'mobx-react';
import { columns } from './colums';

export const SwapTemplatesTable: React.FC = observer(() => {
    const [isLoading] = useReaction<boolean>(() => templatesSwapStore.isLoading);
    const [data] = useReaction<BaseTemplateModel[]>(() => templatesSwapStore.data);
    const navigate = useNavigate();

    const handleEditClick = (id: string) => {
        const targetTemplate = data.find((item) => item.id === id);
        if (targetTemplate) {
            navigate(`${AppRoutes.updaterTool}/${id}`);
        }
    };

    return (
        <TableExt<BaseTemplateModel>
            rowKey="id"
            title="Swaps Updater"
            columns={columns}
            dataSource={data}
            loading={isLoading}
            actionColumn={{
                title: '',
                dataIndex: 'id',
                fixed: false,
                custom: [
                    {
                        icon: () => <img alt="logo" src={Details} />,
                        onClick: handleEditClick,
                        text: 'Use template',
                    },
                ],
            }}
        />
    );
});
