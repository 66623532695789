/* eslint-disable @typescript-eslint/no-explicit-any */
import { isFilledString, isValidNumber } from '@utils';
import { MaskType, PresetServer, presetServerModifiersToApi } from './Preset';
import { SwapModel } from './Templates';
import { ConvertStringToAllDays, DaysEnum } from 'types/days';
import { ConvertStringToSwapTypes, SwapTypeEnum } from 'types/swapsTypes';
import Big from 'big.js';
import { NUM_INPUT_PRECISION } from '@constants/inputs';

export type UpdateSwapReqestModel = ValidationReqestModel & {
    templateId: string;
    templateName: string;
};

export type ValidationReqestModel = MaskType & {
    swaps: SwapModel[];
    servers: PresetServer[];
};

export type ValidationResponseModel = {
    serverName: string;
    serverId: string;
    status: number;
    description: string;
    data: ValidationResponseDataType[];
};

export type ValidationResponseDataType = {
    id: string;
    symbolInfo: ValidationSymbolInfoType | null;
    validationMessages: ValidationMessageType[];
};

export type ValidationMessageType = {
    status: number;
    description: string;
};

type ValidationSymbolInfoType = {
    id: string;
    name: string;
    oldShort: Big | null;
    oldLong: Big | null;
    short: Big | null;
    long: Big | null;
    oldTripleSwapDay: DaysEnum | null;
    tripleSwapDay: DaysEnum | null;
    type: SwapTypeEnum | null;
    oldType: SwapTypeEnum | null;
    swapsEnabled: boolean | null;
    oldSwapsEnabled: boolean | null;
    group: string;
};

const convertToValidationSymbolInfoType = (record: any): ValidationSymbolInfoType => {
    const id = isFilledString(record.id) ? (record.id as string) : null;
    const name = isFilledString(record.name) ? (record.name as string) : '';
    const oldShort = isValidNumber(record.oldShort) ? Big(record.oldShort as number).round(NUM_INPUT_PRECISION) : null;
    const oldLong = isValidNumber(record.oldLong) ? Big(record.oldLong as number).round(NUM_INPUT_PRECISION) : null;
    const short = isValidNumber(record.short) ? Big(record.short as number).round(NUM_INPUT_PRECISION) : null;
    const long = isValidNumber(record.long) ? Big(record.long as number).round(NUM_INPUT_PRECISION) : null;
    const oldTripleSwapDay = isFilledString(record.oldTripleSwapDay)
        ? ConvertStringToAllDays(record.oldTripleSwapDay)
        : null;
    const tripleSwapDay = isFilledString(record.tripleSwapDay) ? ConvertStringToAllDays(record.tripleSwapDay) : null;
    const type = isFilledString(record.type) ? ConvertStringToSwapTypes(record.type) : null;
    const oldType = isFilledString(record.oldType) ? ConvertStringToSwapTypes(record.oldType) : null;
    const swapsEnabled = typeof record.swapsEnabled === 'boolean' ? (record.swapsEnabled as boolean) : null;
    const oldSwapsEnabled = typeof record.oldSwapsEnabled === 'boolean' ? (record.oldSwapsEnabled as boolean) : null;
    const group = isFilledString(record.group) ? (record.group as string) : '';

    if (id === null) {
        throw new Error("Invalid record. Can't parse to ValidationResponseDataType");
    }

    return {
        id,
        name,
        oldShort,
        oldLong,
        short,
        long,
        oldTripleSwapDay,
        tripleSwapDay,
        type,
        oldType,
        swapsEnabled,
        oldSwapsEnabled,
        group,
    };
};

const convertToValidationMessageType = (record: any): ValidationMessageType => {
    const status = isValidNumber(record.status) ? (record.status as number) : null;
    const description = isFilledString(record.description) ? (record.description as string) : '';

    if (status === null || description === null) {
        throw new Error("Invalid record. Can't parse to ValidationMessageType");
    }

    return {
        status,
        description,
    };
};

const convertToValidationResponseDataType = (record: any): ValidationResponseDataType => {
    const id = isFilledString(record.id) ? (record.id as string) : null;
    const symbolInfo = record.symbolInfo ? convertToValidationSymbolInfoType(record.symbolInfo) : null;
    const validationMessages = Array.isArray(record.validationMessages)
        ? record.validationMessages.map(convertToValidationMessageType)
        : [];

    if (id === null || symbolInfo === null) {
        throw new Error("Invalid record. Can't parse to ValidationResponseDataType");
    }

    return {
        id,
        symbolInfo,
        validationMessages,
    };
};

export const convertToValidationResponseModel = (record: any): ValidationResponseModel => {
    const serverId = isFilledString(record.serverId) ? (record.serverId as string) : null;
    const serverName = isFilledString(record.serverName) ? (record.serverName as string) : '';
    const description = isFilledString(record.description) ? (record.description as string) : '';
    const status = isValidNumber(record.status) ? (record.status as number) : null;
    const groupedData = Array.isArray(record.groupedData)
        ? record.groupedData.map(convertToValidationResponseDataType)
        : [];

    if (serverId === null || status === null) {
        throw new Error("Invalid record. Can't parse to ValidationResponseMode");
    }

    return {
        status,
        serverId,
        serverName,
        description,
        data: groupedData,
    };
};

export const convertValidationReqestModelToApi = (record: ValidationReqestModel): any => ({
    ...record,
    servers: record.servers.map((server) => ({
        ...server,
        presetServerModifiers: server.presetServerModifiers.map((mod) => ({
            ...mod,
            data: presetServerModifiersToApi(mod.data),
        })),
    })),
});

export const convertUpdateSwapReqestModeltoApi = (record: UpdateSwapReqestModel): any => ({
    ...record,
    servers: record.servers.map((server) => ({
        ...server,
        presetServerModifiers: server.presetServerModifiers.map((mod) => ({
            ...mod,
            data: presetServerModifiersToApi(mod.data),
        })),
    })),
});
