import React, { useState } from 'react';
import { Spin } from 'antd';
import { MsalProvider } from '@azure/msal-react';
import useAsyncEffect from 'use-async-effect';
import { observer } from 'mobx-react';
import { selectServerStore } from '@store/selectServer';
import { BrowserRouter } from 'react-router-dom';
import * as config from '@api/services/app-config-service';
import * as auth from '@api/services/auth-service';
import { Content } from 'Content';
import styles from './style.module.scss';
import { Message, MessageType } from '@components/Message/Message';

export const App: React.FunctionComponent = observer(() => {
    const [loading, setLoading] = useState<boolean>(true);

    useAsyncEffect(async () => {
        await config.initialize();
        await auth.intitalize();
        await selectServerStore
            .getVersion()
            .catch(() => {
                Message(MessageType.error, `Can't connect to server`);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    return (
        <BrowserRouter>
            <div className="App">
                {loading ? (
                    <Spin className={styles.loader} />
                ) : (
                    <MsalProvider instance={auth.getMsalInstance()}>
                        <Content />
                    </MsalProvider>
                )}
            </div>
        </BrowserRouter>
    );
});
