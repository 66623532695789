import React, { Context, useCallback, useContext, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Modal } from 'antd';
import { TableExt } from '@components/TableExt/TableExt';
import { useReaction } from '@utils/hooks';
import { EntityIdT } from '@components/TableExt/types';
import { FormContext } from '../Presets';
import { columns } from './colums';
import { IFormContext } from 'types/commonTypes';
import { PresetCreateModel, PresetModel } from '@models/Preset';
import { presetsStore } from '@store/presetsStore';
import styles from './PresetsTable.module.scss';
import { CreatePresetModal } from '../presetsModal/CreatePresetModal';
import Copy from '@assets/icons/copy.svg';
import { Message, MessageType } from '@components/Message/Message';
import { openEditForm } from '@pages/utils/commonUtils';
import ArrowUpSwap from '@assets/icons/exportCSV.svg';
import ArrowDownSwap from '@assets/icons/arrowDownSwap.svg';
import { EXPORT_CSV, IMPORT_CSV } from '../import/csvUtils';
import { handleExportJSONPreset } from '../export/export';
import { handleImportJSONPreset } from '../import/executeImport';

const ROW_KEY = 'id';

export const PresetsTable: React.FC = observer(() => {
    const [presets] = useReaction<PresetModel[]>(() => presetsStore.data);
    const [isLoading] = useReaction<boolean>(() => presetsStore.isLoading);
    const [currentRow, setCurrentRow] = useState<EntityIdT | null>(null);
    const [isOpenCreateModal, setIsOpenCreateModal] = useState<boolean>(false);
    const currentFormContext = useContext<IFormContext<PresetModel>>(FormContext as Context<IFormContext<PresetModel>>);

    const handleOpenCreatePresetForm = () => {
        setIsOpenCreateModal(true);
    };

    const handleCancelCreateClick = () => {
        setIsOpenCreateModal(false);
    };

    const handleCopyClick = (id: string) => {
        const presetname = presets.find((templ) => templ.id === id)?.name;
        if (presetname && presetname.length < 45) {
            presetsStore.copy(id);
        } else {
            Message(
                MessageType.error,
                `The file name would be too long for the copy. You can shorten the preset name and try again.`,
            );
        }
    };

    const renderToolbar = useCallback(
        () => [
            <div className={styles.buttonsGroup}>
                <Button
                    style={{ background: '#FCB70B', border: 'none' }}
                    key="update"
                    type="primary"
                    onClick={handleOpenCreatePresetForm}
                >
                    Create Template
                </Button>
            </div>,
        ],
        [currentFormContext.formData?.currentRow],
    );

    const onSubmitModal = (value: PresetCreateModel) => {
        presetsStore.create(value);
        setIsOpenCreateModal(false);
    };

    const handleEdit = (id: string) => {
        const targetPreset = presets.find((item) => item.id === id);
        if (targetPreset) {
            openEditForm(targetPreset, currentFormContext, setCurrentRow, ROW_KEY);
        }
    };

    const handleDelete = (id: string) => {
        Modal.confirm({
            title: 'Template will be deleted. This action cannot be undone.',
            okText: 'Delete',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk: async () => {
                presetsStore.delete(id);
            },
        });
    };

    return (
        <>
            <TableExt<PresetModel>
                rowKey={ROW_KEY}
                title="Execution Templates"
                columns={columns()}
                dataSource={presets}
                focusedRowEntityId={currentRow}
                headerToolbar={renderToolbar}
                loading={isLoading}
                actionColumn={{
                    title: 'Actions',
                    dataIndex: 'id',
                    fixed: false,
                    onEdit: handleEdit,
                    custom: [
                        {
                            icon: () => <img key="1" alt="logo" src={Copy} />,
                            onClick: handleCopyClick,
                            text: 'Copy',
                        },
                        {
                            icon: () => <img key="2" alt="logo" className={styles.exportCSVIcon} src={ArrowDownSwap} />,
                            onClick: (id) => handleImportJSONPreset(id, presets),
                            text: IMPORT_CSV,
                        },
                        {
                            icon: () => <img key="3" className={styles.exportCSVIcon} alt="logo" src={ArrowUpSwap} />,
                            onClick: (id) =>
                                handleExportJSONPreset({
                                    id,
                                    successText: 'Execution Template was exported',
                                }),
                            text: EXPORT_CSV,
                        },
                    ],
                    onDelete: handleDelete,
                }}
            />
            {isOpenCreateModal && <CreatePresetModal onSubmit={onSubmitModal} onCancel={handleCancelCreateClick} />}
        </>
    );
});
