/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState } from 'react';
import { Tooltip } from 'antd';
import { FieldContainer } from '@components/Form/FieldContainer/FieldContainer';
import { FieldLabel } from '@components/Form/FieldLabel/FieldLabel';
import cn from 'classnames';
import styles from '@pages/page-styles.module.scss';
import { Control, Controller, FieldErrors, UseFormClearErrors, UseFormSetError, UseFormTrigger } from 'react-hook-form';
import { ValidationMessage } from '@components/Form/ValidationMessage/ValidationMessage';
import { TagsInput } from 'react-tag-input-component';
import { InfoCircleFilled } from '@ant-design/icons';
import { TargetTypeEnum } from 'types/targetTypes';
import { TargetTemplateTypeEnum } from 'types/targetTemplateTypes';
import { MaskType } from '@models/Preset';
import { MaskKeys } from 'types/commonTypes';
import { rawTagValueToMasks, validateNewRawTag } from '@pages/utils/maskValidations';

type Props = {
    symbolMasks: string[];
    groupMasks: string[];
    type: TargetTypeEnum | null;
    templateType: TargetTemplateTypeEnum | null;
    trigger: UseFormTrigger<MaskType>;
    control: Control<MaskType>;
    errors: FieldErrors<MaskType>;
    setError: UseFormSetError<MaskType>;
    clearErrors: UseFormClearErrors<MaskType>;
};

export const Mask: FC<Props> = ({
    symbolMasks,
    groupMasks,
    type,
    templateType,
    control,
    errors,
    trigger,
    setError,
    clearErrors,
}) => {
    const [masks, setMasks] = useState<MaskType>({
        [MaskKeys.symbolMasks]: symbolMasks,
        [MaskKeys.groupMasks]: groupMasks,
    });

    const onBlurHandler = (event: React.FocusEvent<any>, maskName: MaskKeys, onChange: (value: string[]) => void) => {
        const incomingValue: string = event.target.value;
        if (incomingValue) {
            const errorMassages = validateNewRawTag({ value: incomingValue, masks, maskName });
            if (errorMassages.length > 0) {
                setError(maskName, {
                    type: 'custom',
                    message: errorMassages[0],
                });
            } else {
                const newTags = rawTagValueToMasks(incomingValue);
                const newMasks = {
                    ...masks,
                    [maskName]: masks[maskName].concat(newTags),
                };
                setMasks(newMasks);
                onChange(newMasks[maskName]);
                event.target.value = null;
            }
        } else {
            trigger(maskName);
        }
    };
    const validationHandler = (tag: string, existingTags: string[], maskName: MaskKeys) => {
        const errorMassages = validateNewRawTag({
            value: tag,
            masks: {
                [maskName]: existingTags,
            } as MaskType,
            maskName,
        });
        if (errorMassages.length > 0) {
            setError(maskName, {
                type: 'custom',
                message: errorMassages[0],
            });
            return false;
        }
        clearErrors(maskName);
        return true;
    };

    const isShowGroupMask = type !== TargetTypeEnum.takerFix && templateType !== TargetTemplateTypeEnum.swapsBySymbol; // meta & (dividends || swapsByGroups)

    return (
        <>
            <FieldContainer>
                <FieldLabel title="Symbol Mask" required>
                    <Tooltip title="Masks are supported: {symbol}pro,!{symbol}c,*">
                        <InfoCircleFilled style={{ color: '#079b51' }} />
                    </Tooltip>
                </FieldLabel>
                <Controller
                    control={control}
                    name={MaskKeys.symbolMasks}
                    render={({ field, field: { onChange } }) => {
                        return (
                            <div
                                className={cn({
                                    invalid: !!errors[MaskKeys.symbolMasks]?.message,
                                })}
                            >
                                <TagsInput
                                    {...field}
                                    value={field.value}
                                    classNames={{ input: styles.maskInput }}
                                    onBlur={(event: React.FocusEvent<any>) => {
                                        onBlurHandler(event, MaskKeys.symbolMasks, onChange);
                                    }}
                                    beforeAddValidate={(a, b) => validationHandler(a, b, MaskKeys.symbolMasks)}
                                    onExisting={() => {
                                        setError(MaskKeys.symbolMasks, {
                                            type: 'custom',
                                            message: 'Duplicates are not allowed',
                                        });
                                    }}
                                    onChange={(value) => {
                                        const newMasks = {
                                            ...masks,
                                            [MaskKeys.symbolMasks]:
                                                value.length !== 0
                                                    ? value.map((tagValue) => rawTagValueToMasks(tagValue)).flat()
                                                    : value,
                                        };
                                        setMasks(newMasks);
                                        onChange(newMasks[MaskKeys.symbolMasks]);
                                    }}
                                    placeHolder="{symbol}pro,!{symbol}c,*"
                                />
                            </div>
                        );
                    }}
                />
                <ValidationMessage message={errors[MaskKeys.symbolMasks]?.message} />
            </FieldContainer>
            {isShowGroupMask && (
                <>
                    <FieldContainer>
                        <div>
                            <FieldLabel title="Group Mask" required>
                                <Tooltip title="Masks are supported: !group1,group2*">
                                    <InfoCircleFilled style={{ color: '#079b51' }} />
                                </Tooltip>
                            </FieldLabel>
                        </div>
                        <Controller
                            control={control}
                            name={MaskKeys.groupMasks}
                            render={({ field, field: { onChange } }) => {
                                return (
                                    <div
                                        className={cn({
                                            invalid: !!errors[MaskKeys.groupMasks]?.message,
                                        })}
                                    >
                                        <TagsInput
                                            {...field}
                                            classNames={{ input: styles.maskInput }}
                                            onBlur={(event: React.FocusEvent<any>) => {
                                                onBlurHandler(event, MaskKeys.groupMasks, onChange);
                                            }}
                                            onExisting={() => {
                                                setError(MaskKeys.groupMasks, {
                                                    type: 'custom',
                                                    message: 'Duplicates are not allowed',
                                                });
                                            }}
                                            beforeAddValidate={(a, b) => validationHandler(a, b, MaskKeys.groupMasks)}
                                            value={field.value}
                                            onChange={(value) => {
                                                const newMasks = {
                                                    ...masks,
                                                    [MaskKeys.groupMasks]:
                                                        value.length !== 0
                                                            ? value
                                                                  .map((tagValue) => rawTagValueToMasks(tagValue))
                                                                  .flat()
                                                            : value,
                                                };
                                                setMasks(newMasks);
                                                onChange(newMasks[MaskKeys.groupMasks]);
                                            }}
                                            placeHolder="!group1,group2*"
                                        />
                                    </div>
                                );
                            }}
                        />
                        <ValidationMessage message={errors[MaskKeys.groupMasks]?.message} />
                    </FieldContainer>
                </>
            )}
        </>
    );
};
