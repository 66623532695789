import React, { FC, memo, ReactElement, useState } from 'react';
import cn from 'classnames';
import { Modal, Spin, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { RightPanelWrapper } from '@components/RightPanelForm/PanelWrapper';
import { RightPanelFooter } from '@components/RightPanelForm/Footer';
import { RightPanelProps } from './index';
import { userCatchError } from '@utils/error-handler';
import { OperationType } from 'types/commonTypes';
import styles from './rightPanel.module.scss';

const { Title } = Typography;

export const RealRightPanel = memo((props: RightPanelProps<unknown>) => {
    const {
        onDelete,
        onClose,
        isTouched,
        isValid,
        isSubmitting,
        isOpen,
        name,
        onSubmit,
        onReset,
        type = OperationType.read,
        deleteConfirmationMessage,
        children,
        hideFooter,
        resizable,
        isOverlayEnabled,
        submitBtnTitle = 'Save',
        isShowSpinner = false,
        customButtons,
    } = props;

    const [showSpinner, setShowSpinner] = useState(false);

    const handleDeleteClick = () => {
        if (!onDelete) return;
        Modal.confirm({
            title: deleteConfirmationMessage,
            icon: <ExclamationCircleOutlined className={styles.circleIcon} />,
            okText: 'Delete',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk: async () => {
                const promise = onDelete();
                if (promise instanceof Promise) {
                    setShowSpinner(true);
                    await userCatchError(
                        promise,
                        () => {
                            setShowSpinner(false);
                        },
                        {
                            about: 'Failed',
                        },
                    );
                }
                if (onClose) onClose();
            },
        });
    };
    return (
        <RightPanelWrapper
            isOpen={isOpen}
            onClose={onClose}
            resizable={resizable}
            isTouched={isTouched}
            isOverlayEnabled={isOverlayEnabled}
        >
            {(isShowSpinner || showSpinner) && (
                <div className={styles.spinnerBackdrop}>
                    <Spin className={styles.spinner} tip={4} />
                </div>
            )}
            <Title level={4} className={styles.rightPanel__title}>
                {name}
            </Title>
            <div className={cn(styles.rightPanel__scrollable, hideFooter && styles.rightPanel__noFooter)}>
                <form onSubmit={onSubmit} className={cn(styles.rightPanel__form)} autoComplete="off">
                    <>
                        {children}
                        {!hideFooter && (
                            <RightPanelFooter
                                type={type}
                                onDelete={onDelete ? handleDeleteClick : undefined}
                                onReset={onReset}
                                isTouched={isTouched}
                                isSubmitDisabled={!isValid || (!isOverlayEnabled ? !isTouched : !isOverlayEnabled)}
                                isSubmitting={isSubmitting}
                                submitBtnTitle={submitBtnTitle}
                                customButtons={customButtons}
                                onSubmit={onSubmit}
                            />
                        )}
                    </>
                </form>
            </div>
        </RightPanelWrapper>
    );
}) as <T>(props: RightPanelProps<T>) => ReactElement;

(RealRightPanel as FC<unknown>).displayName = 'RealRightPanel';
