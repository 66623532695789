import React, { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import BackIcon from '@assets/icons/back.svg';
import styles from './editTemplate.module.scss';
import { useParams } from 'react-router-dom';
import { SwapsTable } from './SwapsTable/SwapsTable';
import { IFormContext, IFormData } from 'types/commonTypes';
import { Button, Tooltip } from 'antd';
import { AppRoutes } from '@constants';
import { TemplateModel, TemplateSwapModel } from '@models/Templates';
import { observer } from 'mobx-react';
import { templatesControllerStore } from '@store/templatesControllerStore';
import { runInAction } from 'mobx';

export const FormContext = createContext<IFormContext<TemplateModel>>(null!);

export const TemplateControllerDetails = observer(() => {
    const [formData, setFormData] = useState<IFormData<TemplateModel> | undefined>(undefined);
    const [localData, setLocalData] = useState<TemplateSwapModel[]>([]);
    const [idsOfEditedRow, setIdsOfEditedRow] = useState<number[]>([]);
    const { templateId } = useParams();

    useEffect(() => {
        if (templateId) {
            runInAction(() => {
                templatesControllerStore.getTemplatesAll();
                templatesControllerStore.getTemplateById(templateId!).then((teplate) => {
                    templatesControllerStore.template = teplate;
                    setLocalData(teplate.swaps);
                });
            });
        }
    }, [templateId]);

    useEffect(() => {
        return () => {
            templatesControllerStore.reset();
        };
    }, []);

    const navigate = useNavigate();

    const clickBackHandler = () => {
        navigate(AppRoutes.templatesController);
        templatesControllerStore.resetTemplate();
    };

    return (
        <FormContext.Provider value={{ reset: () => setFormData(undefined), formData, setFormData }}>
            <div className={styles.pageWrapper}>
                <div className={styles.contentWrapper}>
                    <Tooltip title="Back to Symbols Templates">
                        <Button type="text" className={styles.headerButtonBack} onClick={clickBackHandler}>
                            <img alt="logo" className="logo_image" src={BackIcon} />
                        </Button>
                    </Tooltip>
                    <SwapsTable
                        swaps={localData}
                        setLocalData={setLocalData}
                        idsOfEditedRow={idsOfEditedRow}
                        setIdsOfEditedRow={setIdsOfEditedRow}
                    />
                </div>
            </div>
        </FormContext.Provider>
    );
});
