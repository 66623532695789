import { historyStore } from '@store/history/historyStore';
import React, { useEffect } from 'react';
import styles from '@pages/page-styles.module.scss';
import { HistoryTable } from '@pages/history/historyTable/HistoryTable';

export const History = () => {
    useEffect(() => {
        historyStore.getHistoryWithParameters();
        return () => {
            historyStore.reset();
        };
    }, []);

    return (
        <div className={styles.pageWrapper}>
            <div className={styles.contentWrapper}>
                <HistoryTable />
            </div>
        </div>
    );
};
