/* eslint-disable prettier/prettier */
import moment, { Moment } from 'moment';
import * as yup from 'yup';

export const FORMAT_T = 'HH:mm:ss';
export const MS_IN_DAY = 86400000;
export const FORMAT_DT = 'HH:mm:ss DD.MM.YYYY';
const NANOSEC_IN_SEC = 1000000000;
export const FORMAT_D = 'DD MMM YYYY';

export const rangePickerRanges: Record<string, () => [Moment, Moment]> = {
    today: () => [moment().startOf('day'), moment()],
    twoDays: () => [moment(new Date().getTime() - MS_IN_DAY).startOf('day'), moment()],
    week: () => [moment().startOf('week'), moment()],
    month: () => [moment().startOf('month'), moment()],
};

// @ts-ignore
export const getOffset = (): number => globalThis.managerTimezoneOffset ?? 0;

export const getDateSecondsUTC = (date: Date | null | undefined): number => {
    if (date) {
        const val = new Date(date);
        val.setMinutes(val.getMinutes() - val.getTimezoneOffset());
        return Math.floor(val.valueOf() / 1000);
    }
    return 0;
};

export const timeToNanoseconds = (value: moment.Moment | Date | null): number | null => {
    if (value === null) return null;
    const momentValToParse = moment(value);
    const secondsAfterMidnight =
        momentValToParse.hours() * 3600 + momentValToParse.minutes() * 60 + momentValToParse.seconds();
    return secondsAfterMidnight * NANOSEC_IN_SEC;
};

export const momentValueFromDate = (date?: Moment | Date | null): Moment | null => (date ? moment(date) : null);

export const getDateSeconds = (val: Moment | Date | null | undefined): number =>
    val ? Math.floor(val.valueOf() / 1000) : 0;

export const momentValueFromDateWithOffset = (date?: Date | null): Moment | null =>
    date ? moment(date).utcOffset(getOffset()) : null;

export const getFormattedDate = (value: Date | null | undefined): string =>
    !value || value.getTime() === 0 ? '' : moment(value).format(FORMAT_DT);

export const momentWithOffset = (date?: Moment | null): Moment | null => (date ? date.utcOffset(getOffset()) : null);

export const getOffsettedMomentDate = (value: Moment | null | undefined, withOffset?: boolean): Moment | null => {
    if (withOffset) {
        return momentWithOffset(value);
    }
    return value ?? null;
};

export const dateSchema = yup.mixed().test('date validation', 'Invalid date or format.', (val) => {
    if (!val) {
        return true;
    }
    return moment(val).isValid();
});
