/* eslint-disable @typescript-eslint/no-explicit-any */
import { TableExt } from '@components/TableExt/TableExt';
import { historyStore } from '@store/history/historyStore';
import { useReaction } from '@utils/hooks';
import BackIcon from '@assets/icons/back.svg';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { columnsHistoryDetailesAll, columnsHistoryDetailesAllErrors } from '../historyTable/utils';
import { Button, Tooltip } from 'antd';
import pageStyles from '@pages/page-styles.module.scss';
import styles from '@pages/history/styles.module.scss';
import { AppRoutes } from '@constants';
import { HistoryTemplateType } from 'types/commonTypes';
import moment from 'moment';
import { HistoryModal } from './historyModal/HistoryModal';
import {
    HistoryDetailsDividendsResponseType,
    HistoryDetailsFIXDividendsServersType,
    HistoryDetailsFIXServersType,
    HistoryDetailsMTDividendsServersType,
    HistoryDetailsMTServersType,
    HistoryDetailsResponseType,
    HistoryDetailsType,
} from '@models/History';
import { HistoryModalDividends } from './historyModal/HistoryModalDividends';
import { AvaliableTypes } from '@models/Server';

const ROW_KEY = 'id';
export const HistoryDetailes = () => {
    const { historyId, templateType } = useParams();
    const navigate = useNavigate();
    const [historyMT] = useReaction<HistoryDetailsType<HistoryDetailsResponseType<HistoryDetailsMTServersType>>>(
        () => historyStore.historyMT,
    );
    const [historyFIX] = useReaction<HistoryDetailsType<HistoryDetailsResponseType<HistoryDetailsFIXServersType>>>(
        () => historyStore.historyFIX,
    );
    const [historyDividendsMT] = useReaction<
        HistoryDetailsType<HistoryDetailsDividendsResponseType<HistoryDetailsMTDividendsServersType>>
    >(() => historyStore.historyDividendsMT);

    const [historyDividendsFIX] = useReaction<
        HistoryDetailsType<HistoryDetailsDividendsResponseType<HistoryDetailsFIXDividendsServersType>>
    >(() => historyStore.historyDividendsFIX);
    const [selectedServerGroups, setSelectedServerGroups] = useState<HistoryDetailsMTServersType>(
        {} as HistoryDetailsMTServersType,
    );
    const [selectedServerSymbols, setSelectedServerSymbols] = useState<HistoryDetailsFIXServersType>(
        {} as HistoryDetailsMTServersType,
    );
    const [selectedServerGroupsDividends, setSelectedServerGroupsDividends] =
        useState<HistoryDetailsMTDividendsServersType>({} as HistoryDetailsMTDividendsServersType);
    const [selectedServerAccountsDividends, setSelectedServerAccountsDividends] =
        useState<HistoryDetailsFIXDividendsServersType>({} as HistoryDetailsFIXDividendsServersType);
    const [isAllServersWithErrors, setIsAllServersWithErrors] = useState(false);
    const [isLoading] = useReaction<boolean>(() => historyStore.isLoadingHistoryDetails);
    useEffect(() => {
        historyStore.getHistoryDetails(historyId!, templateType as HistoryTemplateType);
        return () => {
            historyStore.reset();
        };
    }, [historyId, templateType]);
    useEffect(() => {
        if (historyMT.data) {
            setIsAllServersWithErrors(historyMT.data.servers.every((server) => !server.isValid));
        }
        if (historyFIX.data) {
            setIsAllServersWithErrors(historyFIX.data.servers.every((server) => !server.isValid));
        }
        if (historyDividendsMT.data) {
            setIsAllServersWithErrors(historyDividendsMT.data.servers.every((server) => !server.isValid));
        }
        if (historyDividendsFIX.data) {
            setIsAllServersWithErrors(historyDividendsFIX.data.servers.every((server) => !server.isValid));
        }
    }, [historyMT, historyFIX, historyDividendsMT, historyDividendsFIX]);

    const backHandler = () => {
        navigate(AppRoutes.history);
        historyStore.reset();
    };
    const isTypeFIXDividends = templateType === HistoryTemplateType.FIXAjustDividends;
    const isTypeDividendsByMT4orMT5 =
        templateType === HistoryTemplateType.Mt4AdjustDividends ||
        templateType === HistoryTemplateType.Mt5AdjustDividends;
    const isTypeSwapsBySymbols =
        templateType === HistoryTemplateType.MT4swapsBySymbol || templateType === HistoryTemplateType.MT5swapsBySymbol;
    const isTypeSwapsByGroups =
        templateType === HistoryTemplateType.MT4swapsByGroups || templateType === HistoryTemplateType.MT5swapsByGroups;
    const isTypeFIX = templateType === HistoryTemplateType.FIXSwapsUpdated;

    const avaliableServers = (
        servers:
            | (
                  | HistoryDetailsMTServersType[]
                  | HistoryDetailsFIXServersType[]
                  | HistoryDetailsMTDividendsServersType[]
                  | HistoryDetailsFIXDividendsServersType[]
              )
            | null,
    ) => {
        if (servers) {
            return servers
                .map((server) => {
                    return {
                        text: server.serverName!,
                        value: server.serverName!,
                    };
                })
                .filter((value, index, self) => index === self.findIndex((t) => t.value === value.value));
        }
        return [];
    };
    const handleEditClick = (id: string) => {
        if (isTypeSwapsByGroups) {
            const targetServer = historyMT.data.servers.find((item) => item.id === id);
            if (targetServer) {
                setSelectedServerGroups(targetServer);
            }
        }
        if (isTypeSwapsBySymbols) {
            const targetServer = historyMT.data.servers.find((item) => item.id === id);
            if (targetServer) {
                setSelectedServerSymbols(targetServer);
            }
        }
        if (isTypeFIX) {
            const targetServer = historyFIX.data.servers.find((item) => item.id === id);
            if (targetServer) {
                setSelectedServerSymbols(targetServer);
            }
        }

        if (isTypeDividendsByMT4orMT5) {
            const targetServer = historyDividendsMT.data.servers.find((item) => item.id === id);
            if (targetServer) {
                setSelectedServerGroupsDividends(targetServer);
            }
        }
        if (isTypeFIXDividends) {
            const targetServer = historyDividendsFIX.data.servers.find((item) => item.id === id);
            if (targetServer) {
                setSelectedServerAccountsDividends(targetServer);
            }
        }
    };
    const renderColumns = useCallback(
        (avaliableServersNames: AvaliableTypes[], isDividends = false) =>
            isAllServersWithErrors
                ? columnsHistoryDetailesAllErrors(avaliableServersNames, isDividends)
                : columnsHistoryDetailesAll(avaliableServersNames, handleEditClick, isDividends),
        [historyMT, historyFIX, isAllServersWithErrors, historyDividendsMT, historyDividendsFIX],
    );
    const renderMainInfo = (
        info: HistoryDetailsType<
            | HistoryDetailsResponseType<HistoryDetailsMTServersType | HistoryDetailsFIXServersType>
            | HistoryDetailsDividendsResponseType<
                  HistoryDetailsMTDividendsServersType | HistoryDetailsFIXDividendsServersType
              >
        >,
    ) => {
        return (
            <div className={styles.mainInfo}>
                <span>
                    <span style={{ fontWeight: 'bold' }}>User:</span> {`${info.userName}`}
                </span>
                <span>
                    <span style={{ fontWeight: 'bold' }}>Time: </span>{' '}
                    {`${moment(info.time).format('HH:mm:ss DD.MM.YYYY')}`}
                </span>
                {/* // Task 29926. v1 was: {isTypeFIXDividends && ( */}
                {info.data.templateName && (
                    <span>
                        <span style={{ fontWeight: 'bold' }}>Used Template: </span> {`${info.data.templateName}`}
                    </span>
                )}
            </div>
        );
    };
    const isShowTripleSwapDay =
        isTypeFIX ||
        templateType === HistoryTemplateType.MT4swapsBySymbol ||
        templateType === HistoryTemplateType.MT5swapsBySymbol;

    const renderToolbar = useCallback(
        () => [
            <div className={styles.contentWrapperHistoryDetailsHeader}>
                <div className={styles.back}>
                    <Tooltip title="Back to History tab">
                        <Button type="text" className={styles.headerButtonBack} onClick={backHandler}>
                            <img alt="logo" src={BackIcon} />
                        </Button>
                        <span>Back to History</span>
                    </Tooltip>
                </div>
                {historyMT.data || historyFIX.data ? (
                    isTypeFIX ? (
                        renderMainInfo(historyFIX)
                    ) : (
                        renderMainInfo(historyMT)
                    )
                ) : (
                    <></>
                )}
                {historyDividendsMT.data || historyDividendsFIX.data
                    ? isTypeFIXDividends
                        ? renderMainInfo(historyDividendsFIX)
                        : renderMainInfo(historyDividendsMT)
                    : null}
                {}
            </div>,
        ],
        [historyMT, historyFIX, historyDividendsMT, historyDividendsFIX],
    );
    const rowClassNameHandler = (
        record:
            | HistoryDetailsMTServersType
            | HistoryDetailsFIXServersType
            | HistoryDetailsMTDividendsServersType
            | HistoryDetailsFIXDividendsServersType,
    ) => {
        if (record.isValid === false) {
            return 'validationTableNotExpandable';
        }
        if (isTypeSwapsByGroups) {
            if ('groups' in record && record.groups && record.groups.length === 0) {
                return 'validationTableNotExpandable';
            }
        }
        return 'validationTable';
    };

    const modalWindow = (
        server: HistoryDetailsMTServersType | HistoryDetailsFIXServersType,
        setServer: (value: HistoryDetailsMTServersType) => void,
        type: HistoryTemplateType,
    ) => {
        return (
            Object.keys(server).length > 0 && (
                <HistoryModal
                    isShowTripleSwapDay={isShowTripleSwapDay}
                    type={type}
                    isShowModalValidation={true}
                    selectedServer={server}
                    setSelectedServer={setServer}
                />
            )
        );
    };

    const modalWindowDividends = (
        server: HistoryDetailsMTDividendsServersType | HistoryDetailsFIXDividendsServersType,
        setServer: (value: HistoryDetailsMTDividendsServersType | HistoryDetailsFIXDividendsServersType) => void,
        type: HistoryTemplateType,
    ) => {
        return (
            Object.keys(server).length > 0 && (
                <HistoryModalDividends
                    type={type}
                    isShowModalValidation={true}
                    selectedServer={server}
                    setSelectedServer={setServer}
                />
            )
        );
    };
    return (
        <div className={pageStyles.pageWrapper}>
            <div className={pageStyles.contentWrapper}>
                {(isTypeSwapsByGroups || isTypeSwapsBySymbols) && (
                    <TableExt<
                        | HistoryDetailsMTServersType
                        | HistoryDetailsFIXServersType
                        | HistoryDetailsMTDividendsServersType
                        | HistoryDetailsFIXDividendsServersType
                    >
                        rowKey={ROW_KEY}
                        headerToolbar={renderToolbar}
                        scroll={{ x: 800, y: 600 }}
                        style={{ minHeight: 300, backgroundColor: 'white', paddingBottom: '25px' }}
                        rowClassNameHandler={rowClassNameHandler}
                        loading={isLoading}
                        columns={renderColumns(avaliableServers(historyMT.data ? historyMT.data.servers : []))}
                        dataSource={historyMT.data ? historyMT.data.servers : []}
                        pagination={false}
                    />
                )}
                {isTypeFIX && (
                    <TableExt<
                        | HistoryDetailsFIXServersType
                        | HistoryDetailsFIXServersType
                        | HistoryDetailsMTDividendsServersType
                        | HistoryDetailsFIXDividendsServersType
                    >
                        rowKey={ROW_KEY}
                        headerToolbar={historyFIX.data && renderToolbar}
                        scroll={{ x: 800, y: 600 }}
                        style={{ minHeight: 300, backgroundColor: 'white', paddingBottom: '25px' }}
                        rowClassNameHandler={rowClassNameHandler}
                        loading={isLoading}
                        columns={renderColumns(avaliableServers(historyFIX.data ? historyFIX.data.servers : null))}
                        dataSource={historyFIX.data ? historyFIX.data.servers : []}
                        pagination={false}
                    />
                )}
                {isTypeDividendsByMT4orMT5 && (
                    <TableExt<
                        | HistoryDetailsFIXServersType
                        | HistoryDetailsFIXServersType
                        | HistoryDetailsMTDividendsServersType
                        | HistoryDetailsFIXDividendsServersType
                    >
                        rowKey={ROW_KEY}
                        headerToolbar={historyDividendsMT.data && renderToolbar}
                        scroll={{ x: 800, y: 600 }}
                        style={{ minHeight: 300, backgroundColor: 'white', paddingBottom: '25px' }}
                        rowClassNameHandler={rowClassNameHandler}
                        loading={isLoading}
                        columns={renderColumns(
                            avaliableServers(historyDividendsMT.data ? historyDividendsMT.data.servers : null),
                            true,
                        )}
                        dataSource={historyDividendsMT.data ? historyDividendsMT.data.servers : []}
                        pagination={false}
                    />
                )}
                {isTypeFIXDividends && (
                    <TableExt<
                        | HistoryDetailsFIXServersType
                        | HistoryDetailsFIXServersType
                        | HistoryDetailsMTDividendsServersType
                        | HistoryDetailsFIXDividendsServersType
                    >
                        rowKey={ROW_KEY}
                        headerToolbar={historyDividendsFIX.data && renderToolbar}
                        scroll={{ x: 800, y: 600 }}
                        style={{ minHeight: 300, backgroundColor: 'white', paddingBottom: '25px' }}
                        rowClassNameHandler={rowClassNameHandler}
                        loading={isLoading}
                        columns={renderColumns(
                            avaliableServers(historyDividendsFIX.data ? historyDividendsFIX.data.servers : null),
                            true,
                        )}
                        dataSource={historyDividendsFIX.data ? historyDividendsFIX.data.servers : []}
                        pagination={false}
                    />
                )}
            </div>
            {Object.keys(selectedServerGroups).length > 0 &&
                modalWindow(selectedServerGroups, setSelectedServerGroups, templateType as HistoryTemplateType)}
            {Object.keys(selectedServerSymbols).length > 0 &&
                modalWindow(selectedServerSymbols, setSelectedServerSymbols, templateType as HistoryTemplateType)}
            {Object.keys(selectedServerGroupsDividends).length > 0 &&
                modalWindowDividends(
                    selectedServerGroupsDividends,
                    setSelectedServerGroupsDividends as (
                        value: HistoryDetailsMTDividendsServersType | HistoryDetailsFIXDividendsServersType,
                    ) => void,
                    templateType as HistoryTemplateType,
                )}
            {Object.keys(selectedServerAccountsDividends).length > 0 &&
                modalWindowDividends(
                    selectedServerAccountsDividends,
                    setSelectedServerAccountsDividends as (
                        value: HistoryDetailsMTDividendsServersType | HistoryDetailsFIXDividendsServersType,
                    ) => void,
                    templateType as HistoryTemplateType,
                )}
        </div>
    );
};
