/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Modal, Table } from 'antd';
import { TableExt } from '@components/TableExt/TableExt';
import ValidAlert from '@assets/icons/validAlert.svg';
import styles from '@pages/history/styles.module.scss';
import { GroupsValidateType, SymbolsValidateType } from '@models/SwapsUpdater';
import { HistoryTemplateType } from 'types/commonTypes';
import CollapseUp from '@assets/icons/sort_up.svg';
import CollapseDown from '@assets/icons/sort_down.svg';
import UpScroll from '@assets/icons/upScroll.svg';
import {
    columnsExpandGroups,
    columnsExpandSymbolsGroups,
    columnsExpandSymbolsTripleSwapDay,
    columnsExpandSymbolsTypes,
} from '@pages/history/historyTable/utils';
import { renderWarningErrorsAndSymbols } from '@pages/history/historyTable/commonUtils';
import { HistoryDetailsFIXServersType, HistoryDetailsMTServersType } from '@models/History';
import { columnsExpandSymbolsTripleSwapDayFIX } from '../../historyTable/utils';
import { hasValue } from '@pages/utils/commonUtils';

export const HistoryModal: React.FC<{
    isShowTripleSwapDay: boolean;
    type: HistoryTemplateType;
    isShowModalValidation: boolean;
    selectedServer: HistoryDetailsMTServersType | HistoryDetailsFIXServersType;
    setSelectedServer: (value: HistoryDetailsMTServersType) => void;
}> = ({ isShowTripleSwapDay, type, isShowModalValidation, selectedServer, setSelectedServer }) => {
    const cancelHandler = () => {
        setSelectedServer({} as HistoryDetailsMTServersType);
    };
    const [isShowScrollUpBtn, setisShowScrollUpBtn] = useState(false);
    // const tableRef = useRef<HTMLDivElement | null>(null);
    const tableRef: Parameters<typeof Table>[0]['ref'] = useRef(null);
    const [expandedRows, setExpandedRows] = useState<React.Key[]>([]);
    const handleExpandAll = () => {
        if ('groups' in selectedServer && selectedServer.groups) {
            setExpandedRows([]);
        }
    };
    const handleOpenAll = () => {
        if ('groups' in selectedServer) {
            if (selectedServer.groups.length > 0) {
                setExpandedRows(selectedServer.groups.map((e) => e.id));
            }
        }
    };

    const expandedRowRenderSymbols = (data: SymbolsValidateType[]) => {
        return (
            <TableExt
                style={{ margin: 0 }}
                header={false}
                className="expandedTableSymbolsHistoryDetails"
                rowKey="id"
                columns={columnsExpandSymbolsGroups()}
                dataSource={data}
                pagination={false}
            />
        );
    };
    const renderToolbar = useCallback(
        () => [
            <div className={styles.contentWrapperHistoryModalHeader}>
                {expandedRows.length > 0 ? (
                    <Button
                        type="link"
                        onClick={() => {
                            handleExpandAll();
                        }}
                    >
                        Close all tabs
                    </Button>
                ) : (
                    <Button
                        type="link"
                        onClick={() => {
                            handleOpenAll();
                        }}
                    >
                        Open all tabs
                    </Button>
                )}
            </div>,
        ],
        [expandedRows],
    );

    const scrollToTop = () => {
        if (tableRef !== null && tableRef.current !== null) {
            if (tableRef.current.querySelector('.ant-table-body') !== null) {
                // @ts-ignore
                tableRef.current.querySelector('.ant-table-body').scrollTop = 0;
            }
        }
    };

    const isTypeSwapsBySymbols =
        type === HistoryTemplateType.MT4swapsBySymbol || type === HistoryTemplateType.MT5swapsBySymbol;
    const isTypeSwapsByGroups =
        type === HistoryTemplateType.MT4swapsByGroups || type === HistoryTemplateType.MT5swapsByGroups;
    const isTypeFIX = type === HistoryTemplateType.FIXSwapsUpdated;
    const renderColumnsWithCoditions = () => {
        if (isTypeSwapsBySymbols) {
            return columnsExpandSymbolsTypes();
        }
        if (!isTypeSwapsBySymbols && isShowTripleSwapDay) {
            if (isTypeFIX) {
                return columnsExpandSymbolsTripleSwapDayFIX();
            } else {
                return columnsExpandSymbolsTripleSwapDay();
            }
        }
        return columnsExpandSymbolsGroups();
    };
    useEffect(() => {
        if (tableRef !== null && tableRef.current !== null) {
            const modalContent: HTMLCanvasElement = tableRef.current.querySelector(
                '.ant-table-body',
            ) as HTMLCanvasElement;
            const handleScroll = () => {
                if (hasValue(modalContent?.scrollTop)) {
                    if (modalContent.scrollTop > 200) {
                        setisShowScrollUpBtn(true);
                    } else {
                        setisShowScrollUpBtn(false);
                    }
                }
            };
            modalContent?.addEventListener('scroll', handleScroll);
            return () => {
                modalContent?.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);
    return (
        <Modal
            className="historyModal"
            open={isShowModalValidation}
            onCancel={cancelHandler}
            footer={
                <div className={styles.historyBtnClose}>
                    <Button onClick={cancelHandler} size="large" type="default">
                        Close
                    </Button>
                </div>
            }
            width="1050px"
        >
            <div className={styles.containerModal}>
                <div className={styles.headerBlocks}>
                    <div className={styles.headerContainerLeft}>
                        <img alt="logo" className={styles.headerContainerLogo} src={ValidAlert} />
                        <h2>Server name: {selectedServer.serverName!}</h2>
                    </div>
                    <div>{renderWarningErrorsAndSymbols(selectedServer, false)}</div>
                </div>
                {isTypeSwapsByGroups && 'groups' in selectedServer && selectedServer.groups.length > 0 && (
                    <TableExt<GroupsValidateType>
                        rowKey="id"
                        scroll={{ x: 500, y: 360 }}
                        size="small"
                        headerToolbar={renderToolbar}
                        style={{ margin: 0, height: 425 }}
                        columns={columnsExpandGroups()}
                        dataSource={selectedServer.groups}
                        tableRef={tableRef}
                        expandable={{
                            expandedRowRender: (record: GroupsValidateType) => {
                                return expandedRowRenderSymbols(record.symbols);
                            },
                            expandRowByClick: true,
                            expandedRowKeys: expandedRows,
                            rowExpandable: (record: GroupsValidateType) => {
                                if (record.symbols) return true;
                                return false;
                            },
                            onExpand: (expanded, record) => {
                                setExpandedRows(
                                    expanded
                                        ? [...expandedRows, record.id]
                                        : expandedRows.filter((key) => key !== record.id),
                                );
                            },
                            expandIcon: ({ expanded, onExpand, record }: any) => {
                                if (record.symbols.length > 0) {
                                    return (
                                        <Button type="link" onClick={(e) => onExpand(record, e)}>
                                            <img
                                                alt="logo"
                                                className={styles.collapseIcon}
                                                src={expanded ? CollapseUp : CollapseDown}
                                            />
                                        </Button>
                                    );
                                }
                                return null;
                            },
                        }}
                        pagination={false}
                    />
                )}

                {(isTypeSwapsBySymbols || isTypeFIX) && selectedServer.symbols.length > 0 && (
                    <TableExt
                        scroll={{ x: 500, y: 360 }}
                        size="small"
                        tableRef={tableRef}
                        style={{ margin: 0, height: 400 }}
                        rowKey="id"
                        columns={renderColumnsWithCoditions()}
                        dataSource={selectedServer.symbols}
                        pagination={false}
                    />
                )}

                {isShowScrollUpBtn && (
                    <Button type="link" className={styles.scrollUpBtn} onClick={scrollToTop}>
                        <img alt="logo" className={styles.scrollUpBtnIcon} src={UpScroll} />
                    </Button>
                )}
            </div>
        </Modal>
    );
};
