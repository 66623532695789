/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'antd';
import { TableExt } from '@components/TableExt/TableExt';
import ValidAlert from '@assets/icons/validAlert.svg';
import styles from '@pages/history/styles.module.scss';
import { HistoryTemplateType } from 'types/commonTypes';
import CollapseUp from '@assets/icons/sort_up.svg';
import CollapseDown from '@assets/icons/sort_down.svg';
import UpScroll from '@assets/icons/upScroll.svg';
import { hasValue } from '../../../utils/commonUtils';
import {
    columnsExpandAccountsDividends,
    columnsExpandDividends,
    columnsExpandGroupsDividends,
} from '@pages/history/historyTable/utils';
import {
    AccountsDividendsType,
    DividendsType,
    GroupsDividendsValidateType,
    HistoryDetailsFIXDividendsServersType,
    HistoryDetailsMTDividendsServersType,
} from '@models/History';

export const HistoryModalDividends: React.FC<{
    type: HistoryTemplateType;
    isShowModalValidation: boolean;
    selectedServer: HistoryDetailsMTDividendsServersType | HistoryDetailsFIXDividendsServersType;
    setSelectedServer: (value: HistoryDetailsMTDividendsServersType | HistoryDetailsFIXDividendsServersType) => void;
}> = ({ type, isShowModalValidation, selectedServer, setSelectedServer }) => {
    const cancelHandler = () => {
        setSelectedServer({} as HistoryDetailsMTDividendsServersType | HistoryDetailsFIXDividendsServersType);
    };
    const [isShowScrollUpBtn, setisShowScrollUpBtn] = useState(false);
    const tableRef = useRef<HTMLDivElement | null>(null);
    const [expandedRows, setExpandedRows] = useState<React.Key[]>([]);
    const handleExpandAll = () => {
        if ('groups' in selectedServer && selectedServer.groups) {
            setExpandedRows([]);
        }
        if ('accounts' in selectedServer && selectedServer.accounts) {
            setExpandedRows([]);
        }
    };
    const handleOpenAll = () => {
        if ('groups' in selectedServer) {
            if (selectedServer.groups !== undefined && selectedServer.groups.length > 0) {
                const allIds: string[] = [];
                selectedServer.groups.forEach((group) => {
                    allIds.push(group.id);
                    group.accounts.forEach((account) => {
                        allIds.push(account.id);
                    });
                });
                setExpandedRows(allIds);
            }
        }
        if ('accounts' in selectedServer) {
            if (selectedServer.accounts !== undefined && selectedServer.accounts.length > 0) {
                setExpandedRows(selectedServer.accounts.map((e) => e.id));
            }
        }
    };
    const expandedRowRenderDividends = (data: DividendsType[]) => {
        return (
            <TableExt<DividendsType>
                style={{ margin: 0 }}
                header={false}
                className="expandedTableSymbolsHistoryDetails"
                rowKey="id"
                columns={columnsExpandDividends()}
                dataSource={data}
                pagination={false}
            />
        );
    };

    const isTypeFIXDividends = type === HistoryTemplateType.FIXAjustDividends;
    const isTypeDividendsByMT4orMT5 =
        type === HistoryTemplateType.Mt4AdjustDividends || type === HistoryTemplateType.Mt5AdjustDividends;
    const expandedRowRenderAccounts = (data: AccountsDividendsType[]) => {
        return (
            <TableExt<AccountsDividendsType>
                header={false}
                style={{ margin: 0 }}
                className="expandedTableSymbolsHistoryDetails"
                rowKey="id"
                columns={columnsExpandAccountsDividends()}
                dataSource={data}
                pagination={false}
                expandable={{
                    expandedRowRender: (record: AccountsDividendsType) => {
                        return expandedRowRenderDividends(record.dividends);
                    },
                    expandRowByClick: true,
                    expandedRowKeys: expandedRows,
                    rowExpandable: (record: AccountsDividendsType) => {
                        if (record.dividends) return true;
                        return false;
                    },
                    onExpand: (expanded, record) => {
                        setExpandedRows(
                            expanded ? [...expandedRows, record.id] : expandedRows.filter((key) => key !== record.id),
                        );
                    },
                    expandIcon: ({ expanded, onExpand, record }: any) => {
                        if (record.dividends.length > 0) {
                            return (
                                <Button type="link" onClick={(e) => onExpand(record, e)}>
                                    <img
                                        alt="logo"
                                        className={styles.collapseIcon}
                                        src={expanded ? CollapseUp : CollapseDown}
                                    />
                                </Button>
                            );
                        }
                        return null;
                    },
                }}
            />
        );
    };

    const renderToolbar = useCallback(
        () => [
            <div className={styles.contentWrapperHistoryModalHeader}>
                {expandedRows.length > 0 ? (
                    <Button
                        type="link"
                        onClick={() => {
                            handleExpandAll();
                        }}
                    >
                        Close all tabs
                    </Button>
                ) : (
                    <Button
                        type="link"
                        onClick={() => {
                            handleOpenAll();
                        }}
                    >
                        Open all tabs
                    </Button>
                )}
            </div>,
        ],
        [expandedRows],
    );
    const scrollToTop = () => {
        if (tableRef !== null && tableRef.current !== null) {
            if (tableRef.current.querySelector('.ant-table-body') !== null) {
                // @ts-ignore
                tableRef.current.querySelector('.ant-table-body').scrollTop = 0;
            }
        }
    };
    useEffect(() => {
        if (tableRef !== null && tableRef.current !== null) {
            const modalContent: HTMLCanvasElement = tableRef.current.querySelector(
                '.ant-table-body',
            ) as HTMLCanvasElement;
            const handleScroll = () => {
                if (hasValue(modalContent?.scrollTop)) {
                    if (modalContent.scrollTop > 200) {
                        setisShowScrollUpBtn(true);
                    } else {
                        setisShowScrollUpBtn(false);
                    }
                }
            };
            modalContent?.addEventListener('scroll', handleScroll);
            return () => {
                modalContent?.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);

    return (
        <Modal
            className="historyModal"
            open={isShowModalValidation}
            onCancel={cancelHandler}
            footer={
                <div className={styles.historyBtnClose}>
                    <Button onClick={cancelHandler} size="large" type="default">
                        Close
                    </Button>
                </div>
            }
            width="1050px"
        >
            <div className={styles.containerModal}>
                <div className={styles.headerBlocks}>
                    <div className={styles.headerContainerLeft}>
                        <img alt="logo" className={styles.headerContainerLogo} src={ValidAlert} />
                        <h2>Server name: {selectedServer.serverName!}</h2>
                    </div>
                    {/* <div>{renderWarningErrorsAndSymbols(selectedServer, false)}</div> */}
                </div>

                {isTypeDividendsByMT4orMT5 && 'groups' in selectedServer && selectedServer.groups.length > 0 && (
                    <TableExt<GroupsDividendsValidateType>
                        rowKey="id"
                        scroll={{ x: 500, y: 360 }}
                        size="small"
                        headerToolbar={renderToolbar}
                        style={{ margin: 0, height: 425 }}
                        columns={columnsExpandGroupsDividends()}
                        dataSource={selectedServer.groups}
                        tableRef={tableRef}
                        expandable={{
                            expandedRowRender: (record: GroupsDividendsValidateType) => {
                                return expandedRowRenderAccounts(record.accounts);
                            },
                            expandRowByClick: true,
                            expandedRowKeys: expandedRows,
                            rowExpandable: (record: GroupsDividendsValidateType) => {
                                if (record.accounts) return true;
                                return false;
                            },
                            onExpand: (expanded, record) => {
                                setExpandedRows(
                                    expanded
                                        ? [...expandedRows, record.id]
                                        : expandedRows.filter((key) => key !== record.id),
                                );
                            },
                            expandIcon: ({ expanded, onExpand, record }: any) => {
                                if (record.accounts.length > 0) {
                                    return (
                                        <Button type="link" onClick={(e) => onExpand(record, e)}>
                                            <img
                                                alt="logo"
                                                className={styles.collapseIcon}
                                                src={expanded ? CollapseUp : CollapseDown}
                                            />
                                        </Button>
                                    );
                                }
                                return null;
                            },
                        }}
                        pagination={false}
                    />
                )}
                {isTypeFIXDividends && 'accounts' in selectedServer && selectedServer.accounts.length > 0 ? (
                    expandedRowRenderAccounts(selectedServer.accounts)
                ) : (
                    <></>
                )}
                {isShowScrollUpBtn && (
                    <Button type="link" className={styles.scrollUpBtn} onClick={scrollToTop}>
                        <img alt="logo" className={styles.scrollUpBtnIcon} src={UpScroll} />
                    </Button>
                )}
            </div>
        </Modal>
    );
};
