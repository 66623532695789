import React from 'react';
import styles from '../styles.module.scss';
import { TemplateModel } from '@models/Templates';
import { renderTargetType } from 'types/targetTypes';
import { renderTargetTemplateType } from 'types/targetTemplateTypes';

type Props = {
    template?: TemplateModel | null;
};

export const TableInfo: React.FC<Props> = ({ template }) => {
    const name = template ? template.name : '';
    const type = template ? renderTargetType(template.type) : null;
    const templateType = template ? renderTargetTemplateType(template.templateType) : null;

    return (
        <div className={styles.mainInfo}>
            <span>
                <span style={{ fontWeight: 'bold' }}>Template Name: </span>
                {name}
            </span>
            <span>
                <span style={{ fontWeight: 'bold' }}>Server Type: </span> {type}
            </span>
            <span>
                <span style={{ fontWeight: 'bold' }}>Template Type:</span> {templateType}
            </span>
        </div>
    );
};
