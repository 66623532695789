import { makeAutoObservable } from 'mobx';
import { Message, MessageType } from '@components/Message/Message';
import { API } from '@api/client/apiEndpoints';
import { convertToTemplateModel, TemplateModel } from '@models/Templates';
import { convertRecordToPresetModel, PresetModel } from '@models/Preset';
import { baseSorter } from '@components/TableExt/RowFilters/utils';
import { TargetTypeEnum } from 'types/targetTypes';
import { convertServers, ServerModel } from '@models/Server';
import { TargetTemplateTypeEnum } from 'types/targetTemplateTypes';
import {
    convertToValidationResponseModel,
    UpdateSwapReqestModel,
    ValidationReqestModel,
    ValidationResponseModel,
} from '@models/Swaps';
import { hasValue } from '@pages/utils/commonUtils';
import { modifiedSymbolsMasks } from '@utils';
import { DividendsRequestType } from '@models/Dividents';
import FileSaver from 'file-saver';

export class UpdaterToolStore {
    constructor() {
        makeAutoObservable(this);
    }

    template: TemplateModel | null = null;

    presets: PresetModel[] = [];

    servers: ServerModel[] = [];

    validation: ValidationResponseModel[] = [];

    isLoading: boolean = false;

    isValidating: boolean = false;

    isUpdating: boolean = false;

    isSaving: boolean = false;

    isExporting: boolean = false;

    async getTemplateById(id: string): Promise<TemplateModel> {
        this.isLoading = true;
        return new Promise<TemplateModel>((resolve, reject) => {
            API.templates
                .getTemplateById(id)
                .then(({ data }) => {
                    return resolve(convertToTemplateModel(data));
                })
                .catch(() => {
                    return reject();
                })
                .finally(() => {
                    this.isLoading = false;
                });
        });
    }

    async saveTemplate(template: TemplateModel) {
        this.isLoading = true;
        this.isSaving = true;
        await API.templates
            .update(template)
            .then(() => {
                Message(MessageType.success, 'Symbols Template was saved');
            })
            .catch((e) => {
                Message(MessageType.error, `Can't save Symbols Template. ${e}`);
                Promise.reject(e);
            })
            .finally(() => {
                this.isLoading = false;
                this.isSaving = false;
            });
    }

    async getPresetsByType(type: TargetTypeEnum, templateType: TargetTemplateTypeEnum) {
        this.isLoading = true;
        return new Promise<PresetModel[]>((resolve, reject) => {
            API.presets
                .getByType(type, templateType)
                .then(({ data }) => {
                    const result = data
                        .map(convertRecordToPresetModel)
                        .sort((a, b) =>
                            baseSorter(b.updateDate ? b.updateDate.unix() : 0, a.updateDate ? a.updateDate.unix() : 0),
                        );
                    return resolve(result);
                })
                .catch((e) => {
                    Message(MessageType.error, e);
                    return reject();
                })
                .finally(() => {
                    this.isLoading = false;
                });
        });
    }

    async getServersByType(type: TargetTypeEnum) {
        this.isLoading = true;
        return new Promise<ServerModel[]>((resolve, reject) => {
            API.servers
                .getByType(type)
                .then(({ data: items }) => {
                    const result = convertServers(items);
                    return resolve(result);
                })
                .catch((e) => {
                    Message(MessageType.error, e);
                    return reject();
                })
                .finally(() => {
                    this.isLoading = false;
                });
        });
    }

    async validateSwaps({
        validation,
        type,
        templateType,
    }: {
        validation: ValidationReqestModel;
        type: TargetTypeEnum;
        templateType: TargetTemplateTypeEnum;
    }) {
        return new Promise<ValidationResponseModel[]>((resolve, reject) => {
            const isSwapsBySymbol = templateType === TargetTemplateTypeEnum.swapsBySymbol;
            const servers = validation.servers.filter((server) => server.state);
            let method = null;

            if (type === TargetTypeEnum.mt4 && isSwapsBySymbol) {
                method = API.validateSwaps.validateMt4SwapsBySymbol;
            }
            if (type === TargetTypeEnum.mt5 && isSwapsBySymbol) {
                method = API.validateSwaps.validateMt5SwapsBySymbol;
            }
            if (type === TargetTypeEnum.mt4 && !isSwapsBySymbol) {
                method = API.validateSwaps.validateMt4Swaps;
            }
            if (type === TargetTypeEnum.mt5 && !isSwapsBySymbol) {
                method = API.validateSwaps.validateMt5Swaps;
            }
            if (type === TargetTypeEnum.takerFix) {
                method = API.validateSwaps.validateTakerFixSwaps;
            }

            if (servers.length === 0) {
                Message(MessageType.error, 'No enabled servers.');
                this.isValidating = false;
                return resolve([]);
            }

            if (method) {
                method({ ...validation, servers, symbolMasks: modifiedSymbolsMasks(validation.symbolMasks) })
                    .then(({ data }) => {
                        if (Array.isArray(data)) {
                            const result = data.map(convertToValidationResponseModel);
                            return resolve(result);
                        } else {
                            return reject();
                        }
                    })
                    .catch((e) => {
                        Message(MessageType.error, e);
                        return reject(e);
                    });
            }
        });
    }

    export({
        validation,
        templateName,
        templateId,
    }: {
        validation: ValidationReqestModel;
        templateName: string;
        templateId: string;
    }) {
        try {
            const servers = validation.servers.filter((server) => server.state);
            this.isExporting = true;

            API.updateSwaps
                .export({
                    ...validation,
                    servers,
                    symbolMasks: modifiedSymbolsMasks(validation.symbolMasks),
                    templateName,
                    templateId,
                })
                .then((data) => {
                    try {
                        const blob = new Blob([data.data], {
                            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        });
                        FileSaver.saveAs(blob, `${templateName}.xlsx`);
                        Message(MessageType.success, 'Swap rates after applied modifiers were exported');
                    } catch (error) {
                        Message(MessageType.error, "Can't export file");
                    }
                })
                .catch(() => {
                    Message(MessageType.error, "Can't export file");
                })
                .finally(() => {
                    this.isExporting = false;
                });
        } catch {
            Message(MessageType.error, "Can't export file");
        }
    }

    async updateSwaps({
        swaps,
        type,
        templateType,
    }: {
        swaps: UpdateSwapReqestModel;
        type: TargetTypeEnum;
        templateType: TargetTemplateTypeEnum;
    }) {
        return new Promise((resolve, reject) => {
            const isSwapsBySymbol = templateType === TargetTemplateTypeEnum.swapsBySymbol;
            const servers = swaps.servers.filter((server) => server.state);
            let method = null;
            if (type === TargetTypeEnum.mt4 && isSwapsBySymbol) {
                method = API.updateSwaps.updateMt4SwapsBySymbol;
            }
            if (type === TargetTypeEnum.mt5 && isSwapsBySymbol) {
                method = API.updateSwaps.updateMt5SwapsBySymbol;
            }
            if (type === TargetTypeEnum.mt4 && !isSwapsBySymbol) {
                method = API.updateSwaps.updateMt4Swaps;
            }
            if (type === TargetTypeEnum.mt5 && !isSwapsBySymbol) {
                method = API.updateSwaps.updateMt5Swaps;
            }
            if (type === TargetTypeEnum.takerFix) {
                method = API.updateSwaps.updateTakerFixSwaps;
            }

            if (servers.length === 0) {
                Message(MessageType.error, 'No enabled servers.');
                this.isValidating = false;
                return;
            }

            if (method) {
                method({ ...swaps, servers, symbolMasks: modifiedSymbolsMasks(swaps.symbolMasks) })
                    .then(({ data }) => {
                        if (data && Array.isArray(data.servers)) {
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            const isErrors = data.servers.some((serv: any) => hasValue(serv.error));
                            if (isErrors) {
                                Message(MessageType.error, 'Some swaps were NOT updated: see History for details.');
                            } else {
                                Message(MessageType.success, 'Swaps were updated');
                            }
                            return resolve(data);
                        } else {
                            return reject();
                        }
                    })
                    .catch((e) => {
                        Message(MessageType.error, `Can't update Swaps. ${e}`);
                        return reject(e);
                    });
            }
        });
    }

    async adjustDividents({ dividents, type }: { dividents: DividendsRequestType; type: TargetTypeEnum }) {
        return new Promise<void>((resolve, reject) => {
            let method = null;
            if (type === TargetTypeEnum.mt4) {
                method = API.dividents.adjustMt4Dividends;
            }
            if (type === TargetTypeEnum.mt5) {
                method = API.dividents.adjustMt5Dividends;
            }
            if (type === TargetTypeEnum.takerFix) {
                method = API.dividents.adjustTakerFixDividends;
            }

            if (dividents.serverIds.length === 0) {
                Message(MessageType.error, 'No enabled servers.');
                this.isValidating = false;
                return reject();
            }

            if (method) {
                method({ ...dividents, symbolMasks: modifiedSymbolsMasks(dividents.symbolMasks) })
                    .then(({ data }) => {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        if (data.servers.some((serv: any) => hasValue(serv.error))) {
                            Message(MessageType.error, 'Dividends were NOT adjusted: see History for details');
                            return reject();
                        } else {
                            Message(MessageType.success, 'Dividends were adjusted');
                            return resolve();
                        }
                    })
                    .catch((e) => {
                        Message(MessageType.error, `Dividends were NOT adjusted. ${e}`);
                        return reject();
                    });
            }
        });
    }

    reset() {
        this.template = null;
        this.isLoading = false;
        this.isSaving = false;
        this.isValidating = false;
        this.isUpdating = false;
        this.isExporting = false;
        this.presets = [];
        this.servers = [];
        this.validation = [];
    }

    resetTemplate() {
        this.template = null;
    }
}

const updaterToolStore = new UpdaterToolStore();
export { updaterToolStore };
