import React, { FC } from 'react';
import { Control, Controller, UseFormTrigger } from 'react-hook-form';
import { Switch, Tooltip } from 'antd';
import { FieldLabel } from '@components/Form/FieldLabel/FieldLabel';
import styles from './styles.module.scss';
import { DividentType } from '@models/Dividents';
import Available from '@assets/icons/availiable.svg';
import unAvailable from '@assets/icons/unavailiable.svg';

type Props = {
    control: Control<DividentType>;
    trigger: UseFormTrigger<DividentType>;
};

export const Servers: FC<Props> = ({ control, trigger }) => {
    return (
        <Controller
            control={control}
            name="servers"
            render={({ field: { value, onChange } }) => {
                return (
                    <>
                        {value.map((element) => (
                            <div className={styles.server}>
                                <Tooltip
                                    title={`${element.available ? 'Server connected' : 'No server connection'}`}
                                    className={styles.status}
                                >
                                    <img alt="logo" src={element.available ? Available : unAvailable} />
                                </Tooltip>
                                <div className={styles.switchBlock} title={element.serverName}>
                                    <Switch
                                        checked={element.enabled}
                                        disabled={!element.available}
                                        onChange={(e) => {
                                            onChange(
                                                value.map((val) => {
                                                    if (val.serverId === element.serverId) {
                                                        return { ...element, enabled: e };
                                                    }
                                                    return val;
                                                }),
                                            );
                                            trigger();
                                        }}
                                        size="small"
                                        className={styles.switch}
                                    />
                                    <FieldLabel title={element.serverName} />
                                </div>
                            </div>
                        ))}
                    </>
                );
            }}
        />
    );
};
