import { Message, MessageType } from '@components/Message/Message';
import { isFilledString } from '@utils';
import moment from 'moment';
import { ConvertStringToTargetType, TargetTypeEnum } from 'types/targetTypes';

export type AvaliableTypes = {
    text: string;
    value: string;
};

export type ServerCreateBaseModel = {
    type: TargetTypeEnum;
};

export type ServerModel = {
    id: string;
    name: string;
    type: TargetTypeEnum;
    address: string;
    username: string;
    password: string;
    taName: string;
    available: boolean;
    createDate: moment.Moment;
};

export type ServerModelApiCreate = {
    name: string;
    type: string;
    taName: string;
    address: string;
    username: string;
    password: string;
};

export type ServerModelApiUpdate = ServerModelApiCreate & {
    id: string;
    createDate: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertToServerModel = (record: any): ServerModel => {
    const id: string = isFilledString(record.id) ? record.id : '';
    const name: string = isFilledString(record.name) ? record.name : '';
    const type: TargetTypeEnum | null = ConvertStringToTargetType(record.type);
    const address = isFilledString(record.address) ? record.address : '';
    const username = isFilledString(record.username) ? record.username : '';
    const password = isFilledString(record.password) ? record.password : '';
    const taName = isFilledString(record.taName) ? record.taName : null;
    const available = typeof record.available === 'boolean' ? record.available : false;
    const createDate = isFilledString(record.createDate) ? moment.utc(record.createDate) : moment.utc('2000-01-01');
    if (!id) throw new Error("Can't convert to ServerModel. Server id is not set");
    if (type === null) throw new Error("Can't convert to ServerModel. Server type is not set");
    if (taName === null) throw new Error("Can't convert to ServerModel. Server Taket Adapter Name is not set");
    if (type === null) {
        Message(MessageType.error, 'Server type is not set');
    }
    return {
        id,
        name,
        type,
        address,
        username,
        password,
        taName,
        available,
        createDate,
    };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const convertServers = (record: any[]): ServerModel[] => {
    if (Array.isArray(record)) {
        return record.map((e) => convertToServerModel(e));
    }
    return [];
};

export const ServerModelToApiCreate = (record: ServerModel): ServerModelApiCreate => ({
    address: record.address,
    name: record.name,
    password: record.password,
    type: record.type ? record.type.toString() : '',
    username: record.username,
    taName: record.taName ?? '',
});

export const ServerModelToApiUpdate = (record: ServerModel): ServerModelApiUpdate => ({
    ...ServerModelToApiCreate(record),
    id: record.id ?? '',
    createDate: record.createDate ? record.createDate.toISOString() : '',
});
