import React from 'react';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import { SwapOutlined, SettingOutlined, PieChartOutlined } from '@ant-design/icons';
import SwapsUpdater from '@assets/icons/swaps_updater.svg';
import Tools from '@assets/icons/tools.svg';
import Boxes from '@assets/icons/boxes.svg';
import { Link, useLocation } from 'react-router-dom';
import History from '@assets/icons/history.svg';

import { AppRoutes } from '@constants';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[]): MenuItem {
    return {
        key,
        icon,
        children,
        label,
    } as MenuItem;
}

const items: MenuItem[] = [
    getItem(
        'Swaps Updater',
        AppRoutes.updaterTool,
        <Link to={AppRoutes.updaterTool}>
            <SwapOutlined style={{ color: '#00A7EE', fontSize: '18px' }} />
        </Link>,
    ),
    getItem(
        'CFD Dividends Updater',
        AppRoutes.dividendsUpdaterTool,
        <Link to={AppRoutes.dividendsUpdaterTool}>
            <PieChartOutlined style={{ color: '#00A7EE', fontSize: '18px' }} />
        </Link>,
    ),
    getItem(
        'Symbols Templates',
        AppRoutes.templatesController,
        <Link to={AppRoutes.templatesController}>
            <img alt="logo" src={SwapsUpdater} style={{ color: '#00A7EE', fontSize: '18px' }} />
        </Link>,
    ),
    getItem(
        'Execution Templates',
        AppRoutes.presets,
        <Link to={AppRoutes.presets}>
            <img alt="logo" src={Boxes} style={{ color: '#00A7EE', fontSize: '18px' }} />
        </Link>,
    ),
    getItem(
        'Settings',
        AppRoutes.settings,
        <Link to={AppRoutes.settings}>
            <SettingOutlined style={{ color: '#00A7EE', fontSize: '18px' }} />
        </Link>,
    ),
    getItem(
        'Servers Settings',
        AppRoutes.serversSettings,
        <Link to={AppRoutes.serversSettings}>
            <img alt="logo" src={Tools} style={{ color: '#00A7EE', fontSize: '18px' }} />
        </Link>,
    ),
    getItem(
        'History',
        AppRoutes.history,
        <Link to={AppRoutes.history}>
            <img alt="logo" src={History} style={{ color: '#00A7EE', fontSize: '18px' }} />
        </Link>,
    ),
];

type PropsType = {
    collapsed: boolean;
};

export const Sidebar = (props: PropsType) => {
    const location = useLocation();
    return (
        <Menu
            mode="inline"
            theme="light"
            defaultSelectedKeys={[location.pathname]}
            items={items}
            inlineCollapsed={props.collapsed}
        />
    );
};
