/* eslint-disable @typescript-eslint/no-explicit-any */
import { CustomColumnType } from '@components/TableExt/types';
import { renderSwapType, SwapTypesList, SwapTypesListShort } from 'types/swapsTypes';
import { AllDaysList, renderDay, WeekDaysList } from 'types/days';
import { TargetTypeEnum } from 'types/targetTypes';
import { TemplateSwapModel } from '@models/Templates';
import { baseSorter } from '@components/TableExt/RowFilters/utils';
import { TargetTemplateTypeEnum } from 'types/targetTemplateTypes';
import { printNumber } from '@utils/number';

const tableColKeys = {
    symbol: 'symbol',
    short: 'shortSwap',
    long: 'longSwap',
    type: 'type',
    tsd: 'tripleSwapDay',
};

export const colsToExport = ({
    type,
    templateType,
}: {
    type: TargetTypeEnum | null;
    templateType: TargetTemplateTypeEnum | null;
}): {
    key: string;
    title: string;
    formatter?: (val: any) => string | any;
}[] => {
    let cols = [{ key: tableColKeys.symbol, title: 'Symbol' }];
    let colsMT = [
        { key: tableColKeys.long, title: 'LongSwap', formatter: (val: any) => (val ? val.toString() : '') },
        { key: tableColKeys.short, title: 'ShortSwap', formatter: (val: any) => (val ? val.toString() : '') },
    ];
    let colsFIX = [
        { key: tableColKeys.short, title: 'ShortSwap', formatter: (val: any) => (val ? val.toString() : '') },
        { key: tableColKeys.long, title: 'LongSwap', formatter: (val: any) => (val ? val.toString() : '') },
    ];
    if (!type && !templateType) return cols;

    if (type === TargetTypeEnum.takerFix) {
        cols.push(...colsFIX);
    }
    if (type === TargetTypeEnum.mt4 || type === TargetTypeEnum.mt5) {
        cols.push(...colsMT);
    }

    if (templateType === TargetTemplateTypeEnum.swapsBySymbol && type !== TargetTypeEnum.takerFix) {
        cols.push({
            key: tableColKeys.type,
            title: 'Type',
            // @ts-ignore
            formatter: (val: any) => SwapTypesList.find((item) => item.key === val)?.csvKey ?? val,
        });
    }

    if (templateType == TargetTemplateTypeEnum.swapsBySymbol) {
        cols.push({
            key: tableColKeys.tsd,
            title: 'TripleSwapDay',
            // @ts-ignore
            formatter: (val: any) => AllDaysList.find((item) => item.key === val)?.csvKey ?? val,
        });
    }
    return cols;
};

export const columns: ({
    type,
    templateType,
}: {
    type: TargetTypeEnum | null;
    templateType: TargetTemplateTypeEnum | null;
}) => CustomColumnType<TemplateSwapModel>[] = ({ type, templateType }) => {
    const shortCol = {
        title: 'Short',
        dataIndex: tableColKeys.short,
        sorter: (a, b) => baseSorter(a.shortSwap, b.shortSwap),
        render: printNumber,
        align: 'right',
        filterOptions: { type: 'Search', searchDataFormatter: printNumber },
    } as CustomColumnType<TemplateSwapModel>;

    const longCol = {
        title: 'Long',
        dataIndex: tableColKeys.long,
        sorter: (a, b) => baseSorter(a.longSwap, b.longSwap),
        render: printNumber,
        align: 'right',
        filterOptions: { type: 'Search', searchDataFormatter: printNumber },
    } as CustomColumnType<TemplateSwapModel>;

    const shortLongCols = type === TargetTypeEnum.takerFix ? [shortCol, longCol] : [longCol, shortCol];

    let cols: CustomColumnType<TemplateSwapModel>[] = [
        {
            title: 'Symbol',
            dataIndex: tableColKeys.symbol,
            align: 'left',
            sorter: (a, b) => baseSorter(a.symbol, b.symbol),
            filterOptions: { type: 'Search' },
        },
        ...shortLongCols,
    ];

    if (!type && !templateType) return cols;

    if (templateType === TargetTemplateTypeEnum.swapsBySymbol && type !== TargetTypeEnum.takerFix) {
        cols.push({
            title: 'Type',
            dataIndex: tableColKeys.type,
            render: renderSwapType,
            sorter: (a, b) => baseSorter(renderSwapType(a.type), renderSwapType(b.type)),
            align: 'left',
            filterOptions: {
                type: 'Checkbox',
                getFilterPreviewValue: (value) => {
                    if (Array.isArray(value)) {
                        return value.map((item) => renderSwapType(item)).join(', ');
                    }
                    return value?.toString() ?? '';
                },
                checkboxValues: () =>
                    SwapTypesListShort.map((item) => {
                        return {
                            compareValue: item.key,
                            title: item.title,
                        };
                    }),
            },
        });
    }

    if (templateType == TargetTemplateTypeEnum.swapsBySymbol) {
        cols.push({
            title: 'Triple Swap Day',
            dataIndex: tableColKeys.tsd,
            sorter: (a, b) => baseSorter(renderDay(a.tripleSwapDay), renderDay(b.tripleSwapDay)),
            render: renderDay,
            align: 'left',
            filterOptions: {
                type: 'Checkbox',
                getFilterPreviewValue: (value) => {
                    if (Array.isArray(value)) {
                        return value.map((item) => renderDay(item.trim().toLowerCase())).join(', ');
                    }
                    return value?.toString() ?? '';
                },
                checkboxValues: () =>
                    (type === TargetTypeEnum.mt5 ? AllDaysList : WeekDaysList).map((item) => ({
                        compareValue: item.key,
                        title: item.title,
                    })),
            },
        });
    }
    return cols;
};
