/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Input } from 'antd';
import React, { useContext, useState } from 'react';
import styles from './RowFilters.module.scss';
import { IFilterPropsSearch } from './types';
import { FilterValuesContext } from '../TableExt';
import { typedMemo } from '../../../utils';

export const RowFilterSearch = typedMemo((props: IFilterPropsSearch) => {
    const filterValues = useContext(FilterValuesContext);
    const [value, setValue] = useState<string>(
        filterValues.values[props.dataIndex] ? (filterValues.values[props.dataIndex] as string) : '',
    );

    const onChangeCallback = (event: any) => setValue(event.target.value);

    const onFilter = () => {
        if (value === '') {
            filterValues.setValues({ ...filterValues.values, [props.dataIndex]: null });
        } else {
            filterValues.setValues({
                ...filterValues.values,
                [props.dataIndex]: value,
            });
        }
        props.confirmCallback();
    };

    const onReset = () => {
        filterValues.setValues({ ...filterValues.values, [props.dataIndex]: null });
        setValue('');
        props.confirmCallback();
    };

    return (
        <div className={styles.wrapper}>
            <Input
                className={styles.search}
                placeholder="Please enter"
                onPressEnter={onFilter}
                onChange={onChangeCallback}
                value={value}
            />
            <div className={styles.btnBlock}>
                <Button type="link" className={styles.reset} onClick={onReset}>
                    Reset
                </Button>
                <Button type="primary" onClick={onFilter} size="small" className={styles.button}>
                    Filter
                </Button>
            </div>
        </div>
    );
});
