import React from 'react';
import { RightPanel } from '@components/RightPanelForm';
import { OperationType } from 'types/commonTypes';
import { PresetModel } from '@models/Preset';
import { RightPanelCard } from '@components/RightPanelForm/Groups';

type Props = {};

export const PanelRightLoading: React.FC<Props> = () => {
    return (
        <>
            <RightPanel<PresetModel>
                entityTitle="SwapUpdaterPreset"
                name="Execution Options"
                type={OperationType.update}
                isOpen
                isOverlayEnabled={false}
                submitBtnTitle="Update Swaps"
            >
                <RightPanelCard isLoading>
                    <div style={{ height: 100 }}> </div>
                </RightPanelCard>
                <RightPanelCard isLoading>
                    <div style={{ height: 100 }}> </div>
                </RightPanelCard>
                <RightPanelCard isLoading>
                    <div style={{ height: 100 }}> </div>
                </RightPanelCard>
            </RightPanel>
        </>
    );
};
