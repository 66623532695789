import { PresetModel } from '@models/Preset';
import { yupCommonString } from '@utils/yupValidations';
import * as yup from 'yup';

export const newPresetCreateSchema = (presets: PresetModel[]) =>
    yup.object().shape({
        name: yupCommonString
            .max(50, 'Must be less than 50 characters')
            .default('')
            .test('uniqueName', 'This name already exists', (value, { parent: { id } }) => {
                return !presets.find((m) => (m.name ?? '').toLowerCase() === value.toLowerCase() && m.id !== id);
            })
            .required('Required field'),
    });
