import React from 'react';
import styles from './styles.module.scss';
import { TemplateSwapModel } from '@models/Templates';
import { TableExt } from '@components/TableExt/TableExt';
import { TargetTypeEnum } from 'types/targetTypes';
import { TargetTemplateTypeEnum } from 'types/targetTemplateTypes';
import { columns } from '@pages/templateControllerDetails/SwapsTable/columns';

type Props = {
    swaps: TemplateSwapModel[];
    type: TargetTypeEnum | null;
    templateType: TargetTemplateTypeEnum | null;
};

export const SwapsList = ({ swaps, type, templateType }: Props) => {
    return (
        <TableExt<TemplateSwapModel>
            rowKey="id"
            columns={columns({ type, templateType })}
            dataSource={swaps}
            className={styles.swapsList}
            pagination={false}
            focusedRowEntityId={null}
            scroll={{ x: 800, y: 600 }}
            style={{ minHeight: 300, backgroundColor: 'white' }}
        />
    );
};
