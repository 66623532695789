import * as yup from 'yup';
import { spacesEndNotAllowed, spacesStartNotAllowed, hasWhiteSpace, notAllowedWords } from './yupValidationMethods';
import { MAX_APP_NUMBER_INT, MIN_APP_NUMBER_INT, NUM_INPUT_PRECISION } from '@constants/inputs';
import Big from 'big.js';
import { printNumber } from './number';

export const NAME_STRING_MAX_LENGTH = 60;

export const yupCommonString = yup
    .string()
    .concat(spacesStartNotAllowed())
    .concat(spacesEndNotAllowed())
    .max(Number.MAX_SAFE_INTEGER, 'Too large value');

export const yupEntityNameValidationWithSpaces = yup
    .string()
    .concat(spacesStartNotAllowed())
    .concat(spacesEndNotAllowed())
    .concat(notAllowedWords())
    .max(NAME_STRING_MAX_LENGTH, `Must be less than ${NAME_STRING_MAX_LENGTH} characters`);

export const yupEntityNameValidation = yup
    .string()
    .concat(hasWhiteSpace())
    .concat(notAllowedWords())
    .max(NAME_STRING_MAX_LENGTH, `Must be less than ${NAME_STRING_MAX_LENGTH} characters`);

export const yupNumber = (precision?: number) =>
    yup
        .number()
        .transform((value) => (Number.isNaN(value) ? undefined : value))
        .test('matchPrecision', 'matchPrecision', (value) => {
            if (precision !== undefined && typeof value === 'number') {
                const decimalPart = value.toString().split('.')[1];
                return decimalPart ? decimalPart.length <= precision : true;
            }
            return true;
        });

export const yupBigNumber = () =>
    yup
        .mixed()
        .nullable()
        .default(null)
        .test('decinalTooLong', `Max precision is ${NUM_INPUT_PRECISION}`, (value) => {
            const stringVal = printNumber(value);
            const decimalPart = value && stringVal.includes('.') ? stringVal.split('.')[1] : '';
            return decimalPart.length <= NUM_INPUT_PRECISION;
        })
        .test('integerTooLarge', `Must be less than ${MAX_APP_NUMBER_INT}`, (value) => {
            const stringVal = printNumber(value);
            const intPart = value && stringVal.includes('.') ? stringVal.split('.')[0] : stringVal;
            if (intPart === '') return true;
            return Big(intPart).lt(0) // negative
                ? true
                : Big(intPart).lte(MAX_APP_NUMBER_INT);
        })
        .test('integerTooSmall', `Must be greater than ${MIN_APP_NUMBER_INT}`, (value) => {
            const stringVal = printNumber(value);
            const intPart = value && stringVal.includes('.') ? stringVal.split('.')[0] : stringVal;
            if (intPart === '') return true;
            return Big(intPart).lt(0) // negative
                ? Big(intPart).gte(MIN_APP_NUMBER_INT)
                : true;
        });
