import React from 'react';
import { Button, Form, Input } from 'antd';
import styles from './LoginForm.module.scss';
import { FieldLabel } from '@components/Form/FieldLabel/FieldLabel';
import { ValidationMessage } from '@components/Form/ValidationMessage/ValidationMessage';
import { FieldContainer } from '@components/Form/FieldContainer/FieldContainer';
import { Controller, useForm } from 'react-hook-form';
import { selectServerStore } from '@store/selectServer';
import { useNavigate } from 'react-router';
import { Message, MessageType } from '@components/Message/Message';
import { useReaction } from '@utils/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { defaultValue, serverSchema } from './schemas';
import { ServerConfig } from '@models/selectServer';
import { AppRoutes } from '@constants';

export const SelectServerForm = () => {
    const [server] = useReaction<string>(() => selectServerStore.server);
    const [isLoading] = useReaction<boolean>(() => selectServerStore.isLoading);
    const navigate = useNavigate();

    const {
        handleSubmit,
        formState: { isValid, errors },
        control,
    } = useForm<ServerConfig>({
        mode: 'all',
        defaultValues: {
            ...defaultValue,
            server: server || defaultValue.server,
        },
        resolver: yupResolver(serverSchema),
    });

    const onSubmit = (values: ServerConfig) => {
        selectServerStore.setServer(values.server);
        selectServerStore
            .getVersion()
            .then(() => {
                navigate(AppRoutes.main);
            })
            .catch(() => {
                Message(MessageType.error, 'Invalid server');
            });
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <h1 className={styles.header}>Select Server</h1>
                <Form name="ServerUrl">
                    <FieldContainer>
                        <FieldLabel title="Server" required />
                        <Controller
                            control={control}
                            name="server"
                            render={({ field }) => (
                                <Input {...field} placeholder="[Server Address]" addonBefore="https://" />
                            )}
                        />
                        <ValidationMessage message={errors.server?.message} />
                    </FieldContainer>
                    <div className={styles.formFooter}>
                        <Button
                            className={styles.submitBtn}
                            type="primary"
                            htmlType="submit"
                            onClick={handleSubmit(onSubmit)}
                            disabled={!isValid}
                            loading={isLoading}
                        >
                            Select Server
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    );
};
