import React, { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import BackIcon from '@assets/icons/back.svg';
import { useParams } from 'react-router-dom';
import { useReaction } from '@utils/hooks';
import { IFormContext, IFormData } from 'types/commonTypes';
import { Button, Tooltip } from 'antd';
import { AppRoutes } from '@constants';
import styles from '@pages/page-styles.module.scss';
import { TemplateModel, TemplateSwapModel } from '@models/Templates';
import { updaterToolStore } from '@store/updaterToolStore';
import { TargetTemplateTypeEnum } from 'types/targetTemplateTypes';
import { SwapsTable } from './swapsTable/SwapsTable';
import { observer } from 'mobx-react';
import { PresetPanelRight } from './rightPanel/PresetPanelRight';
import { PresetModel } from '@models/Preset';
import { TargetTypeEnum } from 'types/targetTypes';
import { schema } from '@pages/presets/presetsForm/schema';
import { ServerModel } from '@models/Server';
import { v4 } from 'uuid';
import { Message, MessageType } from '@components/Message/Message';
import { PanelRightLoading } from './rightPanel/PanelRightLoading';

export const FormContext = createContext<IFormContext<TemplateModel>>(null!);

export const DEFAULT_NAME_PRESET = 'New Preset';

const getDefaultPreset = (
    presets: PresetModel[],
    servers: ServerModel[],
    type: TargetTypeEnum,
    templateType: TargetTemplateTypeEnum,
) => {
    const newPreset = schema(presets).cast({ type, templateType, name: DEFAULT_NAME_PRESET });
    servers.forEach((server) => {
        const isServerInPreset = !!newPreset.presetServers.find((e) => e.serverId === server.id);
        if (!isServerInPreset) {
            newPreset.presetServers.push({
                id: v4(),
                serverId: server.id,
                state: false,
                presetServerModifiers: [],
            });
        }
    });
    return newPreset;
};

export const UpdaterTool = observer(() => {
    const [formData, setFormData] = useState<IFormData<TemplateModel> | undefined>(undefined);
    const [template] = useReaction<TemplateModel | null>(() => updaterToolStore.template);
    const [localSwaps, setLocalSwaps] = useState<TemplateSwapModel[]>([]);
    const [idsOfEditedRow, setIdsOfEditedRow] = useState<number[]>([]);
    const [localPreset, setLocalPreset] = useState<PresetModel | null>(null);
    const [defaultPreset, setDefaultPreset] = useState<PresetModel | null>(null);
    const navigate = useNavigate();
    const { templateId } = useParams();

    useEffect(() => {
        if (templateId) {
            updaterToolStore
                .getTemplateById(templateId)
                .then((result) => {
                    updaterToolStore.template = result;
                    setLocalSwaps(result.swaps);
                    if (result) {
                        updaterToolStore.getServersByType(result.type).then((servers) => {
                            updaterToolStore.servers = servers;

                            updaterToolStore.getPresetsByType(result.type, result.templateType).then((presets) => {
                                updaterToolStore.presets = presets;

                                const newPreset = getDefaultPreset(presets, servers, result.type, result.templateType);
                                setDefaultPreset(newPreset);
                                setLocalPreset(newPreset);
                            });
                        });
                    }
                })
                .catch(() => {
                    Message(MessageType.error, "Can't get template");
                });
        }
        return () => {
            updaterToolStore.reset();
        };
    }, []);

    const backHandler = () => {
        updaterToolStore.resetTemplate();
        if (template?.templateType === TargetTemplateTypeEnum.dividends) {
            navigate(AppRoutes.dividendsUpdaterTool);
        } else {
            navigate(AppRoutes.updaterTool);
        }
    };

    const backButtonTitle = template
        ? `Back to ${
              template.templateType === TargetTemplateTypeEnum.dividends ? 'CFD Dividends Updater' : 'Swaps Updater'
          }`
        : 'Back';

    const isTouchedCustom = !!localPreset && JSON.stringify(localPreset) !== JSON.stringify(defaultPreset);

    return (
        <FormContext.Provider value={{ reset: () => setFormData(undefined), formData, setFormData }}>
            <div className={styles.pageWrapper}>
                <div className={styles.contentWrapper}>
                    <Tooltip title={backButtonTitle}>
                        <Button type="text" className={styles.headerButtonBack} onClick={backHandler}>
                            <img alt="logo" className="logo_image" src={BackIcon} />
                        </Button>
                    </Tooltip>
                    <SwapsTable
                        swaps={localSwaps}
                        setLocalSwaps={setLocalSwaps}
                        idsOfEditedRow={idsOfEditedRow}
                        setIdsOfEditedRow={setIdsOfEditedRow}
                    />
                </div>
                {defaultPreset && localPreset ? (
                    <PresetPanelRight
                        isTouchedCustom={isTouchedCustom}
                        localSwaps={localSwaps}
                        localPreset={localPreset}
                        setLocalPreset={setLocalPreset}
                        defaultPreset={defaultPreset}
                    />
                ) : (
                    <PanelRightLoading />
                )}
            </div>
        </FormContext.Provider>
    );
});
