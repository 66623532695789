/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import styles from './ExportCSV.module.scss';
import { Message, MessageType } from '@components/Message/Message';
import { Parser } from '@json2csv/plainjs';
import FileSaver from 'file-saver';
import { Tooltip } from 'antd';
import ArrowUpSwap from '@assets/icons/exportCSV.svg';
import cn from 'classnames';
import get from 'lodash/get';

export type Col = { key: string; title: string; formatter?: (val: any) => string | any };

type ExportCSVProps<DataT> = {
    fileName?: string;
    className?: string;
    tooltipText?: string;
    successText?: string;
    isDisabled?: boolean;
    getDataToExport: () => Promise<{ data: DataT[]; cols: Col[] }>;
};

const valuesFormatter = (data: any[], cols: Col[]): Record<string, string>[] =>
    data.map((el) => {
        const result: Record<string, string> = {};
        cols.forEach((col) => {
            const rawVal = get(el, col.key) ?? '';
            const val = `${col.formatter ? col.formatter(rawVal) : rawVal}`;
            result[col.title] = val;
        });
        return result;
    });

const parseAndSaveData = ({ data, fileName }: { data: any[]; fileName: string }) => {
    const json2csvParser = new Parser({
        delimiter: ',',
        header: true,
        eol: '\n',
        withBOM: true,
    });
    const csv = json2csvParser.parse(data);
    const blob = new Blob([csv], { type: 'text/plain;charset=utf-8' });
    FileSaver.saveAs(blob, `${fileName}.csv`);
};

export const SaveCSV = <DataT extends { id: string | number }>({
    isDisabled,
    fileName = 'Export',
    tooltipText,
    getDataToExport,
    successText,
    className,
}: ExportCSVProps<DataT>) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const makeAnExport = () => {
        setIsLoading(true);
        getDataToExport()
            .then(({ data, cols }) => {
                const values = valuesFormatter(data, cols);
                if (values.length === 0) {
                    Message(MessageType.error, 'No Data to Export');
                } else {
                    parseAndSaveData({
                        data: values,
                        fileName,
                    });
                    Message(MessageType.success, successText ?? `Exported ${fileName}.csv`);
                }
            })
            .catch(() => {
                Message(MessageType.error, "Can't Execute Export Data");
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <Tooltip title={tooltipText} className={styles.csvToolTipWindow} placement="left">
            <img
                alt="export icon"
                className={cn(styles.exportIcon, className, { [styles.disabled]: isDisabled || isLoading })}
                src={ArrowUpSwap}
                onClick={isDisabled || isLoading ? () => {} : makeAnExport}
            />
        </Tooltip>
    );
};
