import { makeAutoObservable } from 'mobx';
import { API } from '@api/client/apiEndpoints';
import { lsGetItem, lsSetItem } from '@utils/hooks';
import { LS_SERVER_URL_KEY } from '@constants';

export class SelectServerStore {
    version: string = '';

    isLoading: boolean = false;

    server: string = lsGetItem(LS_SERVER_URL_KEY) ?? '';

    constructor() {
        makeAutoObservable(this);
    }

    setServer(serverUrl: string) {
        this.server = serverUrl;
        lsSetItem(LS_SERVER_URL_KEY, serverUrl);
    }

    async getVersion(): Promise<string> {
        this.isLoading = true;
        return new Promise<string>((resolve, reject) => {
            API.version
                .get()
                .then(({ data: { version } }) => {
                    this.version = version;
                    resolve(version);
                })
                .catch(() => {
                    this.version = '';
                    reject();
                })
                .finally(() => {
                    this.isLoading = false;
                });
        });
    }
}

const selectServerStore = new SelectServerStore();
export { selectServerStore };
