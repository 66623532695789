/* eslint-disable @typescript-eslint/no-explicit-any */
import Tooltip from 'antd/es/tooltip';
import React, { FC } from 'react';
import { FieldErrors, FieldValues } from 'react-hook-form';
import { InfoCircleFilled } from '@ant-design/icons';
import style from './ValidationTooltip.module.scss';
import { get } from 'lodash';

export const ValidationTooltip: FC<{
    errors?: FieldErrors<FieldValues>;
    fieldNames?: string[];
}> = ({ errors, fieldNames }) => {
    if (!fieldNames || !errors) return null;

    const errorMessages: any = fieldNames.reduce((acc: any, item: any) => {
        const targetErrors = get(errors, item);
        if (targetErrors) return [...acc, targetErrors];
        return acc;
    }, []);

    return errorMessages.length ? (
        <Tooltip title={fieldNames} color="red" key={fieldNames.join()}>
            <InfoCircleFilled className={style.icon} />
        </Tooltip>
    ) : null;
};
