import React, { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import BackIcon from '@assets/icons/back.svg';
import { useParams } from 'react-router-dom';
import { useReaction } from '@utils/hooks';
import { IFormContext, IFormData } from 'types/commonTypes';
import { Button, Tooltip } from 'antd';
import { AppRoutes } from '@constants';
import styles from '@pages/page-styles.module.scss';
import { TemplateModel, TemplateSwapModel } from '@models/Templates';
import { updaterToolStore } from '@store/updaterToolStore';
import { TargetTemplateTypeEnum } from 'types/targetTemplateTypes';
import { observer } from 'mobx-react';
import { Message, MessageType } from '@components/Message/Message';
import { SwapsTable } from '@pages/updaterTool/swapsTable/SwapsTable';
import { PanelRightLoading } from '@pages/updaterTool/rightPanel/PanelRightLoading';
import { PanelRight } from './panelRight/PanelRight';

export const FormContext = createContext<IFormContext<TemplateModel>>(null!);

export const DividentUpdater = observer(() => {
    const [formData, setFormData] = useState<IFormData<TemplateModel> | undefined>(undefined);
    const [template] = useReaction<TemplateModel | null>(() => updaterToolStore.template);
    const [localSwaps, setLocalSwaps] = useState<TemplateSwapModel[]>([]);
    const [idsOfEditedRow, setIdsOfEditedRow] = useState<number[]>([]);
    const navigate = useNavigate();
    const { templateId } = useParams();

    useEffect(() => {
        if (templateId) {
            updaterToolStore
                .getTemplateById(templateId)
                .then((result) => {
                    updaterToolStore.template = result;
                    setLocalSwaps(result.swaps);
                    if (result) {
                        updaterToolStore.getServersByType(result.type).then((servers) => {
                            updaterToolStore.servers = servers;
                        });
                    }
                })
                .catch(() => {
                    Message(MessageType.error, "Can't get template");
                });
        }
        return () => {
            updaterToolStore.reset();
        };
    }, []);

    const backHandler = () => {
        updaterToolStore.resetTemplate();
        if (template?.templateType === TargetTemplateTypeEnum.dividends) {
            navigate(AppRoutes.dividendsUpdaterTool);
        } else {
            navigate(AppRoutes.updaterTool);
        }
    };

    const backButtonTitle = template ? `Back to CFD Dividends Updater` : 'Back';

    return (
        <FormContext.Provider value={{ reset: () => setFormData(undefined), formData, setFormData }}>
            <div className={styles.pageWrapper}>
                <div className={styles.contentWrapper}>
                    <Tooltip title={backButtonTitle}>
                        <Button type="text" className={styles.headerButtonBack} onClick={backHandler}>
                            <img alt="logo" className="logo_image" src={BackIcon} />
                        </Button>
                    </Tooltip>
                    <SwapsTable
                        swaps={localSwaps}
                        setLocalSwaps={setLocalSwaps}
                        idsOfEditedRow={idsOfEditedRow}
                        setIdsOfEditedRow={setIdsOfEditedRow}
                    />
                </div>
                {!!template ? <PanelRight localSwaps={localSwaps} /> : <PanelRightLoading />}
            </div>
        </FormContext.Provider>
    );
});
