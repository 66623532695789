/* eslint-disable @typescript-eslint/no-explicit-any */
import { NUM_INPUT_PRECISION } from '@constants/inputs';
import { isFilledString, isValidNumber } from '@utils';
import Big from 'big.js';
import moment from 'moment';
import { ConvertStringToAllDays, DaysEnum } from 'types/days';
import { ConvertStringToSwapTypes, SwapTypeEnum } from 'types/swapsTypes';
import { ConvertStringToTargetTemplateType, TargetTemplateTypeEnum } from 'types/targetTemplateTypes';
import { ConvertStringToTargetType, TargetTypeEnum } from 'types/targetTypes';

export enum SymbolModeEnum {
    FOREX = 'FOREX',
    CFD = 'CFD',
}

export type ImportFinaltoType = {
    name: string;
    type: TargetTypeEnum;
    symbolMode: SymbolModeEnum;
    templateType: TargetTemplateTypeEnum;
    serverId: string;
    file?: File;
};

export type BaseTemplateModel = {
    id: string;
    name: string;
    type: TargetTypeEnum;
    templateType: TargetTemplateTypeEnum;
    createDate: moment.Moment;
    updateDate: moment.Moment;
};

export type TemplateModel = BaseTemplateModel & {
    swaps: TemplateSwapModel[];
};

export type SwapModel = {
    symbol: string;
    shortSwap: Big;
    longSwap: Big;
};

export type TemplateSwapModel = SwapModel & {
    id: number;
    type: SwapTypeEnum | null;
    tripleSwapDay: DaysEnum | null;
    swapsEnabled: boolean;
};

export type TemplateCreateModel = {
    name: string;
    type: TargetTypeEnum;
    templateType: TargetTemplateTypeEnum;
    symbolMode: SymbolModeEnum;
};

export const convertToBaseTemplateModel = (record: any): BaseTemplateModel => {
    const id: string = isFilledString(record.id) ? record.id : '';
    const name: string = isFilledString(record.name) ? record.name : '';
    const type = ConvertStringToTargetType(record.type);
    const templateType = ConvertStringToTargetTemplateType(record.templateType);
    const createDate: moment.Moment | null = isFilledString(record.createDate) ? moment.utc(record.createDate) : null;
    const updateDate: moment.Moment | null = isFilledString(record.updateDate) ? moment.utc(record.updateDate) : null;

    if (
        id === '' ||
        name === '' ||
        type === null ||
        templateType === null ||
        createDate === null ||
        updateDate === null
    ) {
        throw new Error("Invalid record. Can't parse to BaseTemplateModel");
    }

    return {
        id,
        name,
        type,
        templateType,
        createDate,
        updateDate,
    };
};

export const convertToTemplateSwapModel = (record: any): TemplateSwapModel => {
    const id: number | null = isValidNumber(record.id) ? record.id : null;
    const shortSwap: Big | null = isValidNumber(record.shortSwap)
        ? Big(record.shortSwap).round(NUM_INPUT_PRECISION)
        : null;
    const longSwap: Big | null = isValidNumber(record.longSwap)
        ? Big(record.longSwap).round(NUM_INPUT_PRECISION)
        : null;
    const symbol: string = isFilledString(record.symbol) ? record.symbol : '';
    const type = ConvertStringToSwapTypes(record.type);
    const tripleSwapDay = ConvertStringToAllDays(record.tripleSwapDay);
    const swapsEnabled: boolean = typeof record.swapsEnabled === 'boolean' ? record.swapsEnabled : false;

    if (id === null || shortSwap === null || longSwap === null || symbol === '') {
        throw new Error("Invalid record. Can't parse to TemplateSwapModel");
    }

    return {
        id,
        symbol,
        shortSwap,
        longSwap,
        type,
        tripleSwapDay,
        swapsEnabled,
    };
};

export const convertBaseTemplates = (record: any[]): BaseTemplateModel[] => {
    if (Array.isArray(record)) {
        return record.map((e) => convertToBaseTemplateModel(e));
    }
    return [];
};

export const convertToTemplateModel = (record: any): TemplateModel => ({
    ...convertToBaseTemplateModel(record),
    swaps: Array.isArray(record.swaps) ? record.swaps.map((swap: any) => convertToTemplateSwapModel(swap)) : [],
});

export const convertTemplates = (record: any[]): TemplateModel[] => {
    if (Array.isArray(record)) {
        return record.map((e) => convertToTemplateModel(e));
    }
    return [];
};

export const templateModelToApi = (record: TemplateModel) => ({
    ...record,
    createDate: record.createDate ? record.createDate.toISOString() : '',
    updateDate: record.updateDate ? record.updateDate.toISOString() : '',
});
