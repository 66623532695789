import React, { FC, memo, ReactNode } from 'react';
import { Card, Collapse } from 'antd';
import { MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { FieldErrors, FieldValues } from 'react-hook-form';
import Title from 'antd/es/typography/Title';
import { ValidationTooltip } from '@components/Form/ValidationTooltip/ValidationTooltip';

import Text from 'antd/lib/typography/Text';
import cn from 'classnames';
import pageStyles from '@pages/page-styles.module.scss';
import styles from './rightPanel.module.scss';

export const ReadOnlyCollapse: FC<{
    header?: string | ReactNode;
    children: ReactNode;
    noCollapse?: boolean;
    defaultOpened?: boolean;
    className?: string;
    suffix?: string | JSX.Element;
}> = memo(({ header, children, noCollapse, defaultOpened = false, className, suffix }) => (
    <Collapse
        ghost
        expandIconPosition="left"
        expandIcon={(panelProps) =>
            panelProps.isActive ? (
                <MinusSquareOutlined className={styles.rightPanel__header_icon} />
            ) : (
                <PlusSquareOutlined className={styles.rightPanel__header_icon} />
            )
        }
        collapsible={noCollapse ? 'disabled' : undefined}
        defaultActiveKey={defaultOpened ? ['1'] : []}
        className={cn(styles.readOnly__item, className)}
    >
        <Collapse.Panel
            header={
                <div className={styles.rightPanel__header_wrapper}>
                    {header ? (
                        typeof header === 'string' ? (
                            <p className="collapse-header">{header}</p>
                        ) : (
                            header
                        )
                    ) : undefined}
                    {suffix ? typeof suffix === 'string' ? <Text type="secondary">{suffix}</Text> : suffix : undefined}
                </div>
            }
            key="1"
            forceRender
        >
            {children}
        </Collapse.Panel>
    </Collapse>
));

export const RightPanelCollapse: FC<{
    header?: string | ReactNode;
    children: ReactNode;
    noCollapse?: boolean;
    defaultOpened?: boolean;
    className?: string;
}> = memo(({ header, children, noCollapse, defaultOpened = false, className }) => (
    <Collapse
        ghost
        expandIconPosition="right"
        collapsible={noCollapse ? 'disabled' : undefined}
        defaultActiveKey={defaultOpened ? ['1'] : []}
        className={className}
    >
        <Collapse.Panel
            header={
                header ? typeof header === 'string' ? <p className="collapse-header">{header}</p> : header : undefined
            }
            key="1"
            className={styles.collapsePanel}
            forceRender
        >
            {children}
        </Collapse.Panel>
    </Collapse>
));

export const CollapseHeader: FC<{
    title: string;
    errors?: FieldErrors<FieldValues>;
    fieldNames?: string[];
    required?: boolean;
}> = ({ title, errors, fieldNames, required = false }) => (
    <Title className={pageStyles.cardTitle} level={4}>
        {title}
        {required ? <span className={styles.rightPanel__header_required}>*</span> : null}
        <ValidationTooltip fieldNames={fieldNames} errors={errors} />
    </Title>
);

export const RightPanelCard: FC<{
    title?: string | ReactNode;
    children: ReactNode;
    className?: string;
    isLoading?: boolean;
}> = memo(({ title, children, isLoading, className }) => (
    <Card
        loading={isLoading}
        title={title ? typeof title === 'string' ? <p className="collapse-header">{title}</p> : title : undefined}
        bordered={false}
        className={cn(styles.card, className)}
    >
        {children}
    </Card>
));
