import { makeAutoObservable } from 'mobx';
import { Message, MessageType } from '@components/Message/Message';
import { API } from '@api/client/apiEndpoints';
import { BaseTemplateModel, convertBaseTemplates } from '@models/Templates';
import { baseSorter } from '@components/TableExt/RowFilters/utils';

export class TemplatesDividentStore {
    constructor() {
        makeAutoObservable(this);
    }

    data: BaseTemplateModel[] = [];

    isLoading: boolean = false;

    getAllDividendTemplates() {
        this.isLoading = true;
        API.templates
            .getTemplateByTypeDividens()
            .then(({ data }) => {
                this.data = convertBaseTemplates(data).sort((a, b) =>
                    baseSorter(b.updateDate.unix(), a.updateDate.unix()),
                );
            })
            .catch((e) => {
                Message(MessageType.error, e);
                Promise.reject(e);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    reset() {
        this.data = [];
        this.isLoading = false;
    }
}

const templatesDividentStore = new TemplatesDividentStore();
export { templatesDividentStore };
