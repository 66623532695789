import React from 'react';
import { App } from './App';
import { createRoot } from 'react-dom/client';
import './antd.less';
import './styles/global.scss';
import '@assets/fonts/fonts.scss';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);

root.render(<App />);
