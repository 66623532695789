import React from 'react';
import styles from './TabLabel.module.scss';
import cn from 'classnames';

type Props = {
    text: string;
    variant?: 'default' | 'danger';
    count?: number;
};
export const TabLabel = ({ text, count, variant = 'default' }: Props) => {
    return (
        <div className={cn(styles.tabLabel, styles[variant])}>
            <span className={styles.tabLabelText}>
                {text}
                {!!count && <span className={styles.tabLabelCount}>{count}</span>}
            </span>
        </div>
    );
};
