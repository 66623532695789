import React, { FC } from 'react';
import styles from './Modifiers.module.scss';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { CloseOutlined } from '@ant-design/icons';
import { Modal } from 'antd';

type Props = {
    onDelete: () => void;
    label: string;
};

export const ModifierTag: FC<Props> = ({ label, onDelete }) => {
    const deleteHandler = () => {
        Modal.confirm({
            title: 'Delete Modifier?',
            icon: <ExclamationCircleOutlined className={styles.circleIcon} />,
            okText: 'Delete',
            okType: 'primary',
            cancelText: 'Cancel',
            onOk: onDelete,
        });
    };
    return (
        <div className={styles.tag}>
            <span className={styles.tagLabel} title={label}>
                {label}
            </span>
            <div className={styles.tagCross} onClick={deleteHandler} title="Delete Modifier">
                <CloseOutlined />
            </div>
        </div>
    );
};
