import React from 'react';
import styles from './styles.module.scss';
import { ValidationResultType } from '@models/ImportTemplates';
import { Button, Modal, Tabs } from 'antd';
import { ErrorsList } from '../validating/errorsList';
import { SwapsList } from '../validating/swapsList';
import { TargetTypeEnum } from 'types/targetTypes';
import { TargetTemplateTypeEnum } from 'types/targetTemplateTypes';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { TabLabel } from '@components/TabLabel/TabLabel';

type Props = {
    data: ValidationResultType;
    onClose: () => void;
    onOk: () => void;
    isLoading: boolean;

    type: TargetTypeEnum | null;
    templateType: TargetTemplateTypeEnum | null;
};

export const ValidatingModal = ({ data, onClose, onOk, isLoading, type, templateType }: Props) => {
    return (
        <Modal className={styles.modal} open onCancel={onClose} footer={null} width="900px">
            <div className={styles.modalTitle}>
                <ExclamationCircleOutlined className={styles.circleIcon} />
                Please confirm template creation
            </div>
            <Tabs
                defaultActiveKey="swaps"
                className={styles.tabs}
                items={[
                    {
                        label: <TabLabel text="Imported Data" count={data.swapsParsed.length} variant="default" />,
                        key: 'swaps',
                        children: <SwapsList swaps={data.swapsParsed} type={type} templateType={templateType} />,
                        // disabled: !data.swapsParsed.length,
                    },
                    {
                        label: <TabLabel text="Errors" count={data.errorsParsed.length} variant="danger" />,
                        key: 'errors',
                        children: <ErrorsList errors={data.errorsParsed} />,
                        disabled: !data.errorsParsed.length,
                    },
                ]}
            />
            <div className={styles.btnBlock}>
                <Button type="default" onClick={onClose}>
                    Cancel
                </Button>
                <Button
                    type="primary"
                    onClick={onOk}
                    disabled={isLoading || !data.swapsParsed.length}
                    loading={isLoading}
                >
                    Create Template
                </Button>
            </div>
        </Modal>
    );
};
