// import { TargetTemplateType, TargetType } from '@pages/types/commonTypes';
import { Moment } from 'moment';
import { GroupsValidateType, SymbolsValidateType } from './SwapsUpdater';

export type TableCondition = HistoryType & {
    index?: number;
    Value?: number | (number | string)[];
};

export type TemplatesControllerSwapsType = {
    id: string;
    symbol: string;
    shortSwap: number;
    longSwap: number;
};

export type HistoryType = {
    id: string;
    timestamp: string;
    user: string;
    event: string;
    type: number;
    historyServers: historyUpdatedSwapsDetailsType[];
};

export type historyUpdatedSwapsDetailsType = {
    id: string;
    number: number;
    serverId: string;
    serverName: string;
    updatedSwaps: number;
    failedSwaps: number;
    additionalInfo: string | null;
    isEmptyRow?: boolean;
    symbols?: number | null;
    logins?: number | null;
    adjustedDividends?: number;
};

export type FilteredHistoryType = {
    count: string;
    startDate: string;
    histories: HistoryType[];
};

export enum OrderColumnType {
    timestamp = 'timestamp',
    user = 'user',
    event = 'event',
}

export type FilterType = {
    event: string | null;
    user: string | null;
    timestamp: {
        from: Moment | null;
    };
};
export type FilterTimeStamp = {
    from: Moment | null;
};

// HISTORY DETAILS PAGE
export type HistoryDetailsType<T> = {
    id: string;
    data: T;
    templateId?: string;
    templateName?: string;
    historyId?: string;
    time: string;
    userName: string;
};

export type HistoryDetailsResponseType<T> = {
    servers: T[];
    templateId: string;
    templateName: string | null;
};

export type HistoryDetailsDividendsResponseType<T> = {
    servers: T[];
    templateId: string;
    templateName: string | null;
};

export type HistoryDetailsMTServersType = {
    errorsCount: number;
    id: string;
    name?: string;
    serverName?: string;
    error: string | null;
    groups: GroupsValidateType[];
    symbols: SymbolsValidateType[];
    isValid: boolean | null;
};

export type HistoryDetailsFIXServersType = Omit<HistoryDetailsMTServersType, 'groups'>;

export type HistoryDetailsMTDividendsServersType = {
    errorsCount: number;
    id: string;
    name?: string;
    serverName: string;
    error: string | null;
    groups: GroupsDividendsValidateType[];
    symbols?: SymbolsValidateType[]; // TODO del
    isValid: boolean | null;
};

export type HistoryDetailsFIXDividendsServersType = {
    accounts: AccountsDividendsType[];
    errorsCount: number;
    id: string;
    serverName: string;
    error: string | null;
    isValid: boolean | null;
};

// export type HistoryDetailsDividendsServersType = Omit<HistoryDetailsMTDividendsServersType, 'groups'>;

export type GroupsDividendsValidateType = {
    groupName: string;
    accounts: AccountsDividendsType[];
    id: string;
    errorsCount: 0;
    isValid: boolean | null;
};

export type AccountsDividendsType = {
    id: string;
    login: number | null;
    dividends: DividendsType[];
    errorsCount: 0;
    isValid: boolean | null;
};
export type DividendsType = {
    id: string;
    symbol: string;
    balanceOperationId: number | null;
    orderId: number | null;
    value: number | null;
    result: string;
    isValid: boolean | null;
};

const getConvertedSymbolsMTModel = (records: SymbolsValidateType[]): SymbolsValidateType[] => {
    return records.map((record) => {
        const result = {
            id: typeof record.id === 'string' ? record.id : '',
            name: typeof record.name === 'string' ? record.name : '',
            oldShort: typeof record.oldShort === 'number' ? record.oldShort : null,
            oldLong: typeof record.oldLong === 'number' ? record.oldLong : null,
            type: typeof record.type === 'string' ? record.type : null,
            oldType: typeof record.oldType === 'string' ? record.oldType : null,
            group: typeof record.group === 'string' ? record.group : null,
            short: typeof record.short === 'number' ? record.short : null,
            long: typeof record.long === 'number' ? record.long : null,
            isValid: typeof record.isValid === 'boolean' ? record.isValid : null,
            tripleSwapDay: typeof record.tripleSwapDay === 'string' ? record.tripleSwapDay : '',
            oldTripleSwapDay: typeof record.oldTripleSwapDay === 'string' ? record.oldTripleSwapDay : '',
            result: typeof record.result === 'string' ? record.result : '',
        };
        return result;
    });
};

const getConvertedDividendsMTModel = (records: DividendsType[]): DividendsType[] => {
    return records.map((record) => {
        const result = {
            id: typeof record.id === 'string' ? record.id : '',
            symbol: typeof record.symbol === 'string' ? record.symbol : '',
            orderId: typeof record.orderId === 'number' ? record.orderId : null,
            balanceOperationId: typeof record.balanceOperationId === 'number' ? record.balanceOperationId : null,
            value: typeof record.value === 'number' ? record.value : null,
            result: typeof record.result === 'string' ? record.result : '',
            isValid: typeof record.isValid === 'boolean' ? record.isValid : null,
        };
        return result;
    });
};

const getConvertedAccountsMTModel = (records: AccountsDividendsType[]): AccountsDividendsType[] => {
    return records.map((record) => {
        const result = {
            id: typeof record.id === 'string' ? record.id : '',
            login: typeof record.login === 'number' ? record.login : null,
            dividends:
                'dividends' in record && Array.isArray(record.dividends)
                    ? getConvertedDividendsMTModel(record.dividends)
                    : [],
            isValid: typeof record.isValid === 'boolean' ? record.isValid : null,
            errorsCount: 'errorsCount' in record && typeof record.errorsCount === 'number' ? record.errorsCount : 0,
        };
        return result;
    });
};

const getConvertedGroupsMTModel = (
    records: GroupsValidateType[] | GroupsDividendsValidateType[],
): GroupsValidateType[] | GroupsDividendsValidateType => {
    return records.map((record) => {
        const result = {
            name: 'name' in record && typeof record.name === 'string' ? record.name : '',
            isValid: 'isValid' in record && typeof record.isValid === 'boolean' ? record.isValid : null,
            symbols:
                'symbols' in record && Array.isArray(record.symbols) ? getConvertedSymbolsMTModel(record.symbols) : [],
            errorsCount: 'errorsCount' in record && typeof record.errorsCount === 'number' ? record.errorsCount : 0,
            id: typeof record.id === 'string' ? record.id : '',
            groupName: 'groupName' in record && typeof record.groupName === 'string' ? record.groupName : '',
            accounts:
                'accounts' in record && Array.isArray(record.accounts)
                    ? getConvertedAccountsMTModel(record.accounts)
                    : [],
        };
        return result;
    });
};

const getConvertedHistoryDetailsDataModel = <
    T extends
        | HistoryDetailsResponseType<HistoryDetailsMTServersType | HistoryDetailsFIXServersType>
        | HistoryDetailsDividendsResponseType<
              HistoryDetailsMTDividendsServersType | HistoryDetailsFIXDividendsServersType
          >,
>(
    record: T,
) => {
    const result = {
        servers: record.servers.map((server) => ({
            errorsCount: typeof server.errorsCount === 'number' ? server.errorsCount : 0,
            id: typeof server.id === 'string' ? server.id : '',
            name: 'name' in server && typeof server.name === 'string' ? server.name : '',
            serverName: typeof server.serverName === 'string' ? server.serverName : '',
            groups: 'groups' in server && Array.isArray(server.groups) ? getConvertedGroupsMTModel(server.groups) : [],
            symbols:
                'symbols' in server && Array.isArray(server.symbols) ? getConvertedSymbolsMTModel(server.symbols) : [],
            isValid: typeof server.isValid === 'boolean' ? server.isValid : null,
            error: typeof server.error === 'string' ? server.error : null,
            accounts:
                'accounts' in server && Array.isArray(server.accounts)
                    ? getConvertedAccountsMTModel(server.accounts)
                    : [],
        })),
        templateId: typeof record.templateId === 'string' ? record.templateId : '',
        templateName: typeof record.templateName === 'string' ? record.templateName : '',
    };
    return result;
};

export const getConvertedHistoryDetailsModels = <
    T extends HistoryDetailsType<
        | HistoryDetailsResponseType<HistoryDetailsMTServersType | HistoryDetailsFIXServersType>
        | HistoryDetailsDividendsResponseType<
              HistoryDetailsMTDividendsServersType | HistoryDetailsFIXDividendsServersType
          >
    >,
>(
    record: T,
): T => {
    const result = {
        id: typeof record.id === 'string' ? record.id : '',
        data:
            typeof record.data === 'object' && record.data !== null
                ? getConvertedHistoryDetailsDataModel<
                      | HistoryDetailsResponseType<HistoryDetailsMTServersType | HistoryDetailsFIXServersType>
                      | HistoryDetailsDividendsResponseType<
                            HistoryDetailsMTDividendsServersType | HistoryDetailsFIXDividendsServersType
                        >
                  >(record.data)
                : ({} as T),
        templateId: typeof record.templateId === 'string' ? record.templateId : '',
        templateName: typeof record.templateName === 'string' ? record.templateName : '',
        historyId: typeof record.historyId === 'string' ? record.historyId : '',
        time: typeof record.time === 'string' ? record.time : '',
        userName: typeof record.userName === 'string' ? record.userName : '',
    };
    return result as unknown as T;
};
