import React from 'react';
import {
    AccountsDividendsType,
    DividendsType,
    GroupsDividendsValidateType,
    HistoryDetailsFIXDividendsServersType,
    HistoryDetailsFIXServersType,
    HistoryDetailsMTDividendsServersType,
    HistoryDetailsMTServersType,
    TableCondition,
    historyUpdatedSwapsDetailsType,
} from '@models/History';
import { getFormattedDate } from '@utils/datetime';
import ErrorValidation from '@assets/icons/errorValidation.svg';
import Details from '@assets/icons/details.svg';
import { GroupsValidateType, SymbolsValidateType } from '@models/SwapsUpdater';
import { Tooltip } from 'antd';
import {
    expandedtableColKeys,
    renderErrorsAndUpdates,
    renderWarningErrorsAndSymbolsFIX,
    renderWarningErrorsAndSymbols,
    tableColKeys,
} from './commonUtils';
import { swapsTypesFormatter } from '@utils';
import { DaysType, DaysTypesAll, TypesForFilter } from '@constants/days';
import { Pair } from '@components/Pair/Pair';
import { renderDay } from 'types/days';
import { renderSwapType } from 'types/swapsTypes';
import { formatNumber } from '@utils/number';
import { AvaliableTypes } from '@models/Server';
import { CustomColumnType } from '@components/TableExt/types';
import { CustomColumnType as CustomColumnTypeSS } from '@components/TableServerSide/types';

const bracketsToTags = (source: string) =>
    source.replace(new RegExp(/\{(.+?)\}/, 'g'), (_, content) => `<span style="color: #00A7EE">${content}</span>`);

export const historyColumns: CustomColumnTypeSS<TableCondition>[] = [
    {
        title: 'Date',
        dataIndex: tableColKeys.date,
        render: (val) => (val ? getFormattedDate(new Date(val)) : ''),
        align: 'left',
        sorter: true,
        filterOptions: {
            type: 'DataPicker',
        },
    },
    {
        title: 'User',
        dataIndex: tableColKeys.user,
        align: 'left',
        sorter: true,
        filterOptions: { type: 'Search' },
    },
    {
        title: 'Event',
        dataIndex: tableColKeys.event,
        filterOptions: { type: 'Search' },
        width: '60%',
        render: (value) => (
            <div
                dangerouslySetInnerHTML={{
                    __html: bracketsToTags(value),
                }}
            />
        ),
        align: 'left',
    },
];

export const columnsHistoryDetailes: () => CustomColumnType<historyUpdatedSwapsDetailsType>[] = () => [
    {
        title: 'Number',
        dataIndex: expandedtableColKeys.number,
        align: 'left',
    },
    {
        title: 'Server Name',
        dataIndex: expandedtableColKeys.server,
        align: 'left',
    },
    {
        title: 'Updated Symbols',
        dataIndex: expandedtableColKeys.updatedSwaps,
        align: 'left',
    },
];

export const columnsHistoryDetailesDividends: () => CustomColumnType<historyUpdatedSwapsDetailsType>[] = () => [
    {
        title: 'Server Name',
        dataIndex: expandedtableColKeys.server,
        align: 'left',
    },
    {
        title: 'Charged by Symbols',
        dataIndex: expandedtableColKeys.symbols,
        align: 'left',
    },

    {
        title: 'Trading Accounts',
        dataIndex: expandedtableColKeys.logins,
        align: 'left',
    },
    {
        title: 'Balance Operations',
        dataIndex: expandedtableColKeys.adjustedDividends,
        align: 'left',
    },
];

export const columnsHistoryDetailesAll: (
    avaliableServers: AvaliableTypes[],
    handleEditClick: (id: string) => void,
    isDividends: boolean,
) => CustomColumnType<
    | HistoryDetailsMTServersType
    | HistoryDetailsFIXServersType
    | HistoryDetailsMTDividendsServersType
    | HistoryDetailsFIXDividendsServersType
>[] = (avaliableServers, handleEditClick, isDividends) => {
    return [
        {
            title: 'Server',
            dataIndex: expandedtableColKeys.server,
            align: 'left',
            width: 150,
            filterOptions: {
                type: 'Checkbox',
                getFilterPreviewValue: (value) => {
                    if (Array.isArray(value)) {
                        return value.join(', ');
                    }
                    return value?.toString() ?? '';
                },
                checkboxValues: () => {
                    return avaliableServers.map((item) => ({
                        compareValue: item.value,
                        title: item.text,
                    }));
                },
            },
        },
        {
            dataIndex: expandedtableColKeys.description,
            render: (value, record) => renderWarningErrorsAndSymbols(record, true, isDividends),
            align: 'center',
        },
        {
            title: 'More details',
            dataIndex: 'id',
            width: 250,
            render: (_, record) => {
                return record.isValid ? (
                    <Tooltip title="More details">
                        <img onClick={() => handleEditClick(record.id)} alt="logo" src={Details} />
                    </Tooltip>
                ) : (
                    <></>
                );
            },
            align: 'center',
        },
    ];
};
export const columnsHistoryDetailesAllErrors: (
    avaliableServers: AvaliableTypes[],
    isDividends: boolean,
) => CustomColumnType<
    | HistoryDetailsMTServersType
    | HistoryDetailsFIXServersType
    | HistoryDetailsMTDividendsServersType
    | HistoryDetailsFIXDividendsServersType
>[] = (avaliableServers, isDividends) => {
    return [
        {
            title: 'Server',
            dataIndex: expandedtableColKeys.server,
            align: 'left',
            width: 150,
            filterOptions: {
                type: 'Checkbox',
                getFilterPreviewValue: (value) => {
                    if (Array.isArray(value)) {
                        return value.join(', ');
                    }
                    return value?.toString() ?? '';
                },
                checkboxValues: () => {
                    return avaliableServers.map((item) => ({
                        compareValue: item.value,
                        title: item.text,
                    }));
                },
            },
        },
        {
            dataIndex: expandedtableColKeys.description,
            render: (value, record) => renderWarningErrorsAndSymbols(record, true, isDividends),
            align: 'center',
        },
    ];
};

export const columnsHistoryDetailesFIX: (
    avaliableServers: AvaliableTypes[],
) => CustomColumnType<HistoryDetailsFIXServersType>[] = (avaliableServers) => {
    return [
        {
            title: 'Server',
            dataIndex: expandedtableColKeys.server,
            align: 'left',
            width: 150,
            filterOptions: {
                type: 'Checkbox',
                getFilterPreviewValue: (value) => {
                    if (Array.isArray(value)) {
                        return value.join(', ');
                    }
                    return value?.toString() ?? '';
                },
                checkboxValues: () => {
                    return avaliableServers.map((item) => ({
                        compareValue: item.value,
                        title: item.text,
                    }));
                },
            },
        },
        {
            dataIndex: expandedtableColKeys.description,
            render: (value, record) => {
                return renderWarningErrorsAndSymbolsFIX(value, record);
            },
            align: 'center',
        },
    ];
};

export const columnsExpandGroups: () => CustomColumnType<GroupsValidateType>[] = () => {
    return [
        {
            title: 'Group',
            dataIndex: expandedtableColKeys.name,
            filterOptions: { type: 'Search' },
            width: 150,
            align: 'left',
        },
        {
            dataIndex: expandedtableColKeys.errorsCount,
            align: 'center',
            render: (value, record) => renderErrorsAndUpdates(value, record),
        },
    ];
};
export const columnsExpandGroupsDividends: () => CustomColumnType<GroupsDividendsValidateType>[] = () => {
    return [
        {
            title: 'Group',
            dataIndex: expandedtableColKeys.group,
            filterOptions: { type: 'Search' },
            width: 150,
            align: 'left',
        },
        {
            dataIndex: expandedtableColKeys.errorsCount,
            align: 'center',
            render: (value, record) => renderErrorsAndUpdates(value, record),
        },
    ];
};

export const columnsExpandSymbolsTypes: () => CustomColumnType<SymbolsValidateType>[] = () => {
    return [
        {
            title: 'Symbol',
            dataIndex: expandedtableColKeys.name,
            filterOptions: { type: 'Search' },
            render: (value) => <div>{value}</div>,
            align: 'left',
        },
        {
            title: 'Long (Old)',
            dataIndex: expandedtableColKeys.long,
            render: (value, obj) => <div>{Pair(formatNumber(value), formatNumber(obj.oldLong))}</div>,
            align: 'left',
        },
        {
            title: 'Short (Old)',
            dataIndex: expandedtableColKeys.short,
            render: (value, obj) => <div>{Pair(formatNumber(value), formatNumber(obj.oldShort))}</div>,
            align: 'left',
        },
        {
            title: 'Type (Old)',
            dataIndex: expandedtableColKeys.type,
            render: (value, record) => {
                return <div>{Pair(renderSwapType(value), renderSwapType(record.oldType))}</div>;
            },
            align: 'left',
            filterOptions: {
                type: 'Checkbox',
                getFilterPreviewValue: (value) => {
                    if (Array.isArray(value)) {
                        return value.map((item) => renderSwapType(item)).join(', ');
                    }
                    return value?.toString() ?? '';
                },
                checkboxValues: () => {
                    return Object.values(TypesForFilter).map((item) => {
                        return {
                            compareValue: item,
                            title: swapsTypesFormatter(item),
                        };
                    });
                },
            },
        },
        {
            title: 'Triple Swap Day (Old)',
            dataIndex: expandedtableColKeys.tripleSwapDay,
            render: (value, record) => {
                return <div>{Pair(renderDay(value), renderDay(record.oldTripleSwapDay))}</div>;
            },
            align: 'left',
            filterOptions: {
                type: 'Checkbox',
                searchDataFormatter: (value) => value.replace(' ', '').toLowerCase(),
                getFilterPreviewValue: (value) => {
                    if (Array.isArray(value)) {
                        return value.map((item) => renderDay(item)).join(', ');
                    }
                    return value?.toString() ?? '';
                },
                checkboxValues: () =>
                    Object.values(DaysTypesAll).map((item) => ({
                        compareValue: item.replace(' ', '').toLowerCase(),
                        title: item,
                    })),
            },
        },
        {
            title: 'Result',
            dataIndex: expandedtableColKeys.result,
            render: (value, record) => {
                if (!record.isValid) {
                    return (
                        <span>
                            <Tooltip title={value}>
                                <img style={{ height: '25px', width: '25px' }} alt="logo" src={ErrorValidation} />
                            </Tooltip>
                        </span>
                    );
                }
                return <span>{record.result}</span>;
            },
            align: 'left',
        },
    ];
};

export const columnsExpandSymbolsGroups: () => CustomColumnType<SymbolsValidateType>[] = () => {
    return [
        {
            title: 'Symbol',
            dataIndex: expandedtableColKeys.name,
            filterOptions: { type: 'Search' },
            render: (value) => <div>{value}</div>,
            align: 'left',
        },
        {
            title: 'Long (Old)',
            dataIndex: expandedtableColKeys.long,
            render: (value, obj) => <div>{Pair(formatNumber(value), formatNumber(obj.oldLong))}</div>,
            align: 'left',
        },
        {
            title: 'Short (Old)',
            dataIndex: expandedtableColKeys.short,
            render: (value, obj) => <div>{Pair(formatNumber(value), formatNumber(obj.oldShort))}</div>,
            align: 'left',
        },
        {
            title: 'Result',
            dataIndex: expandedtableColKeys.result,
            render: (value, record) => {
                if (!record.isValid) {
                    return (
                        <span>
                            <Tooltip title={value}>
                                <img style={{ height: '25px', width: '25px' }} alt="logo" src={ErrorValidation} />
                            </Tooltip>
                        </span>
                    );
                }
                return <span>{record.result}</span>;
            },
            align: 'left',
        },
    ];
};

export const columnsExpandAccountsDividends: () => CustomColumnType<AccountsDividendsType>[] = () => {
    return [
        {
            title: 'Login',
            dataIndex: expandedtableColKeys.login,
            filterOptions: { type: 'Search' },
            width: 150,
            align: 'left',
        },
        {
            dataIndex: expandedtableColKeys.errorsCount,
            align: 'center',
            render: (value, record) => renderErrorsAndUpdates(value, record),
        },
    ];
};

export const columnsExpandDividends: () => CustomColumnType<DividendsType>[] = () => {
    return [
        {
            title: 'Symbol',
            dataIndex: expandedtableColKeys.symbol,
            filterOptions: { type: 'Search' },
            width: 150,
            align: 'left',
        },
        {
            title: 'Order ID',
            dataIndex: expandedtableColKeys.orderId,
            filterOptions: { type: 'Search' },
            width: 150,
            align: 'left',
        },
        {
            title: 'Balance Operation ID',
            dataIndex: expandedtableColKeys.balanceOperationId,
            filterOptions: { type: 'Search' },
            width: 150,
            align: 'left',
        },
        {
            title: 'Value',
            dataIndex: expandedtableColKeys.val,
            filterOptions: { type: 'Search' },
            width: 150,
            align: 'left',
        },
        {
            title: 'Result',
            dataIndex: expandedtableColKeys.result,
            width: 150,
            align: 'left',
        },
    ];
};

export const columnsExpandSymbolsTripleSwapDay: () => CustomColumnType<SymbolsValidateType>[] = () => {
    return [
        {
            title: 'Symbol',
            filterOptions: { type: 'Search' },
            dataIndex: expandedtableColKeys.name,
            render: (value) => <div style={{ paddingLeft: '20px' }}>{value.toUpperCase()}</div>,
            align: 'left',
        },
        {
            title: 'Long (Old)',
            dataIndex: expandedtableColKeys.long,
            render: (value, obj) => <div>{Pair(formatNumber(value), formatNumber(obj.oldLong))}</div>,
            align: 'left',
        },
        {
            title: 'Short (Old)',
            dataIndex: expandedtableColKeys.short,
            render: (value, obj) => <div>{Pair(formatNumber(value), formatNumber(obj.oldShort))}</div>,
            align: 'left',
        },
        {
            title: 'Triple Swap Day (Old)',
            dataIndex: expandedtableColKeys.tripleSwapDay,
            render: (value, record) => {
                return <div>{Pair(renderDay(value), renderDay(record.oldTripleSwapDay))}</div>;
            },
            align: 'left',
            filterOptions: {
                type: 'Checkbox',
                searchDataFormatter: (value) => value.replace(' ', '').toLowerCase(),
                getFilterPreviewValue: (value) => {
                    if (Array.isArray(value)) {
                        return value.map((item) => renderDay(item)).join(', ');
                    }
                    return value?.toString() ?? '';
                },
                checkboxValues: () =>
                    Object.values(DaysType).map((item) => ({
                        compareValue: item,
                        title: item,
                    })),
            },
        },
        {
            title: 'Result',
            dataIndex: expandedtableColKeys.result,
            render: (value, record) => {
                if (!record.isValid) {
                    return (
                        <span>
                            <Tooltip title={value}>
                                <img style={{ height: '25px', width: '25px' }} alt="logo" src={ErrorValidation} />
                            </Tooltip>
                        </span>
                    );
                }
                return <span>{record.result}</span>;
            },
            align: 'left',
        },
    ];
};

export const columnsExpandSymbolsTripleSwapDayFIX: () => CustomColumnType<SymbolsValidateType>[] = () => {
    return [
        {
            title: 'Symbol',
            filterOptions: { type: 'Search' },
            dataIndex: expandedtableColKeys.name,
            render: (value) => <div style={{ paddingLeft: '20px' }}>{value.toUpperCase()}</div>,
            align: 'left',
        },
        {
            title: 'Short (Old)',
            dataIndex: expandedtableColKeys.short,
            render: (value, obj) => <div>{Pair(formatNumber(value), formatNumber(obj.oldShort))}</div>,
            align: 'left',
        },
        {
            title: 'Long (Old)',
            dataIndex: expandedtableColKeys.long,
            render: (value, obj) => <div>{Pair(formatNumber(value), formatNumber(obj.oldLong))}</div>,
            align: 'left',
        },
        {
            title: 'Triple Swap Day (Old)',
            dataIndex: expandedtableColKeys.tripleSwapDay,
            render: (value, record) => <div>{Pair(renderDay(value), renderDay(record.oldTripleSwapDay))}</div>,
            align: 'left',
            filterOptions: {
                type: 'Checkbox',
                searchDataFormatter: (value) => value.replace(' ', '').toLowerCase(),
                getFilterPreviewValue: (value) => {
                    if (Array.isArray(value)) {
                        return value.map((item) => renderDay(item)).join(', ');
                    }
                    return value?.toString() ?? '';
                },
                checkboxValues: () =>
                    Object.values(DaysType).map((item) => ({
                        compareValue: item,
                        title: item,
                    })),
            },
        },
        {
            title: 'Result',
            dataIndex: expandedtableColKeys.result,
            render: (value, record) => {
                if (!record.isValid) {
                    return (
                        <span>
                            <Tooltip title={value}>
                                <img style={{ height: '25px', width: '25px' }} alt="logo" src={ErrorValidation} />
                            </Tooltip>
                        </span>
                    );
                }
                return <span>{record.result}</span>;
            },
            align: 'left',
        },
    ];
};
