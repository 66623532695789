import React, { useCallback, useContext, useState } from 'react';
import { Button, Input, InputNumber } from 'antd';
import { FilterPropsRangeNum } from './types';
import styles from './RowFilters.module.scss';
import { FilterValuesContext } from '../TableServerSide';
import { IRangeInputN } from '../types';
import { typedMemo } from '../../../utils';
import { inputNumberKeyDownHandler } from '@utils/number';
import Big from 'big.js';

export interface IComparePare {
    from: Big | null;
    to: Big | null;
}

export const RowFilterRangeNum = typedMemo((props: FilterPropsRangeNum) => {
    const filterValues = useContext(FilterValuesContext);
    const [value, setValue] = useState<IRangeInputN>(
        filterValues.values[props.dataIndex]
            ? (filterValues.values[props.dataIndex] as IRangeInputN)
            : { from: null, to: null },
    );

    const onFilter = useCallback(() => {
        if (value.from === null && value.to === null) {
            filterValues.setValues({ ...filterValues.values, [props.dataIndex]: null });
        } else {
            filterValues.setValues({
                ...filterValues.values,
                [props.dataIndex]: {
                    from: value.from,
                    to: value.to,
                },
            });
        }
        props.confirmCallback();
    }, [filterValues, props.dataIndex, value.from, value.to]);

    const onChangeHandler = (val: Big | null, type: 'from' | 'to') => {
        setValue({ ...value, [type]: val });
    };

    const onFromInput = (val: string | null) => {
        if (!val) onChangeHandler(null, 'from');
        if (val && val.length > 0) {
            onChangeHandler(new Big(val), 'from');
        }
    };

    const onToInput = (val: string | null) => {
        if (!val) onChangeHandler(null, 'to');
        if (val && val.length > 0) {
            onChangeHandler(new Big(val), 'to');
        }
    };

    const onReset = () => {
        filterValues.setValues({ ...filterValues.values, [props.dataIndex]: null });
        setValue({ from: null, to: null });
        props.confirmCallback();
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles['site-input-group-wrapper']}>
                <Input.Group compact>
                    <InputNumber<string>
                        decimalSeparator="."
                        onChange={onFromInput}
                        value={value.from !== null ? Big(value.from).toString() : ''}
                        className={styles['site-input-left']}
                        placeholder="From"
                        onPressEnter={onFilter}
                        controls={false}
                        onKeyDown={inputNumberKeyDownHandler}
                        stringMode
                    />
                    <Input className={styles['site-input-split']} placeholder="→" disabled />
                    <InputNumber<string>
                        decimalSeparator="."
                        onChange={onToInput}
                        value={value.to !== null ? Big(value.to).toString() : ''}
                        className={styles['site-input-right']}
                        placeholder="To"
                        onPressEnter={onFilter}
                        controls={false}
                        onKeyDown={inputNumberKeyDownHandler}
                        stringMode
                    />
                </Input.Group>
            </div>
            <div className={styles.btnBlock}>
                <Button type="link" className={styles.reset} onClick={onReset}>
                    Reset
                </Button>
                <Button type="primary" onClick={onFilter} size="small" className={styles.button}>
                    Filter
                </Button>
            </div>
        </div>
    );
});
