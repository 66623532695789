import { Message, MessageType } from '@components/Message/Message';
import { convertRecordToPresetModel } from '@models/Preset';
import { presetsStore } from '@store/presetsStore';
import FileSaver from 'file-saver';
import { MaskKeys } from 'types/commonTypes';
import { TargetTemplateTypeEnum } from 'types/targetTemplateTypes';
import { TargetTypeEnum } from 'types/targetTypes';

function parseAndSaveData(dataToExport: Record<string, string[]>, fileName: string) {
    const json = JSON.stringify(dataToExport, null, 2);
    const blob = new Blob([json], { type: 'application/json' });
    FileSaver.saveAs(blob, `${fileName}.json`);
}

export const handleExportJSONPreset = async ({
    id,
    name,
    successText,
}: {
    id: string;
    name?: string;
    successText?: string;
}) => {
    try {
        presetsStore.getById(id).then((p) => {
            const preset = convertRecordToPresetModel(p);
            const filename = name ?? preset.name;

            let masks: Record<string, string[]> = {};
            const isShowGroupMask = // meta & (dividends || swapsByGroups)
                preset.type !== TargetTypeEnum.takerFix && preset.templateType !== TargetTemplateTypeEnum.swapsBySymbol;
            if (isShowGroupMask) {
                masks = {
                    [MaskKeys.symbolMasks]: preset.symbolMasks,
                    [MaskKeys.groupMasks]: preset.groupMasks,
                };
            } else {
                masks = {
                    [MaskKeys.symbolMasks]: preset.symbolMasks,
                };
            }
            parseAndSaveData(masks, filename ?? preset.name);
            Message(MessageType.success, successText ?? `Exported ${filename}.json`);
        });
    } catch {
        Message(MessageType.error, "Can't export file");
    }
};
