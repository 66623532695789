import { MAX_URL_LENGTH } from '@constants';
import { ServerModel } from '@models/Server';
import { TargetType } from 'types/commonTypes';
import { serversStore } from '@store/servers/serversStore';
import { dateSchema } from '@utils/datetime';
import { yupCommonString, yupEntityNameValidationWithSpaces } from '@utils/yupValidations';
import moment from 'moment';
import * as yup from 'yup';
import isURL from 'validator/lib/isURL';

export const serverSchema = yup.object().shape({
    id: yup.string().default(''),
    name: yupEntityNameValidationWithSpaces
        .max(50, 'Must be less than 50 characters')
        .default('')
        .label('Name')
        .required('Required field')
        .test('uniqueName', 'This name already exists', (value, { parent: { id } }) => {
            const servers: ServerModel[] = serversStore.data;
            return !servers.find((item) => (item.name ?? '').toLowerCase() === value.toLowerCase() && item.id !== id);
        }),
    type: yup.mixed().oneOf(Object.values(TargetType)).default(TargetType.mt4).label('Type').required('Required field'),
    taName: yupCommonString
        .max(50, 'Must be less than 50 characters')
        .default('')
        .label('Taker Name')
        .test('isRequired', 'Required field', (value, { parent: { type } }) => {
            if (type === 'fix' || type === 'Taker FIX') {
                return true;
            }
            return !!value;
        })
        .required('Required field'),
    address: yupCommonString
        .max(MAX_URL_LENGTH, `Must be less than ${MAX_URL_LENGTH} characters`)
        .default('')
        .label('URL')
        .test('url-is-required', 'Required field', (value) => {
            return !!value;
        })
        .test('url-contains-protocol', 'Invalid URL', (value) => {
            if (!value) return false;
            return value.startsWith('http://') || value.startsWith('https://');
        })
        .test('url-port-validation', 'Invalid URL', (value) => {
            if (!value) return false;
            try {
                const url = new URL(value);
                const port = url.port ? parseInt(url.port, 10) : url.protocol === 'http:' ? 80 : 443;
                return port > 0 && port < 65536;
            } catch {
                return false;
            }
        })
        .test('is-valid-url', 'URL must contain http/https protocol and port', (value) => {
            if (!value) return false;
            try {
                new URL(value);
                return true;
            } catch (e) {
                return false;
            }
        })
        .test('isURL', 'Invalid URL', (value) => {
            return isURL(value, {
                protocols: ['http', 'https'],
                require_valid_protocol: true,
                require_protocol: true,
                require_host: true,
                require_port: false,
                allow_underscores: true,
                allow_protocol_relative_urls: true,
            });
        })
        .required('Required field'),
    username: yupCommonString
        .max(50, 'Must be less than 50 characters')
        .default('')
        .label('Username')
        .required('Required field'),
    password: yupCommonString
        .max(50, 'Must be less than 50 characters')
        .default('')
        .label('Password')
        .required('Required field'),
    available: yup.boolean().default(false).label('Available'),
    createDate: dateSchema.default(moment.utc()).label('Create Date'),
});
