import React, { useEffect } from 'react';
import { templatesDividentStore } from '@store/templatesDividentStore';
import { DividentTemplatesTable } from './DividentTemplatesTable';
import styles from '@pages/page-styles.module.scss';

export const DividentTemplates = () => {
    useEffect(() => {
        templatesDividentStore.getAllDividendTemplates();
        return () => {
            templatesDividentStore.reset();
        };
    }, []);

    return (
        <div className={styles.pageWrapper}>
            <div className={styles.contentWrapper}>
                <DividentTemplatesTable />
            </div>
        </div>
    );
};
