/* eslint-disable @typescript-eslint/no-explicit-any */
// @ts-nocheck
import { Dispatch } from 'react';
import { CheckboxValuesT } from '@components/TableExt/RowFilters/types';
import { OperationType } from '../../types/commonTypes';
import { IFormContext } from '@utils/commonTypes';

export const getBoolFilterData: CheckboxValuesT = [
    { title: 'Enabled', compareValue: true },
    { title: 'Disabled', compareValue: false },
];

export function openEditForm<EntityT>(
    row: EntityT,
    context: IFormContext<EntityT>,
    setCurrentRow: Dispatch<number | string | null>,
    rowKey?: string,
) {
    const key = rowKey || 'key';
    if (row[key] !== undefined) {
        if (context?.formData?.currentRow && context?.formData?.currentRow[key] === row[key]) {
            context?.setFormData(undefined);
            setCurrentRow(null);
        } else {
            context!.setFormData({
                type: OperationType.update,
                currentRow: row,
            });
            setCurrentRow(row[key]);
        }
    } else {
        context!.setFormData({
            type: OperationType.update,
            currentRow: row,
        });
    }
}

type updateFormDataEffectType = {
    updateFormData: IFormContext<T>;
    dataSource: object[];
    rowKey: string;
    rowKeyValue: number | string | null | undefined;
    currentTab?: string;
};

export const updateFormDataEffect = (config: updateFormDataEffectType) => {
    const { updateFormData, dataSource, rowKey = 'id', rowKeyValue, currentTab } = config;
    if (updateFormData.formData?.type === OperationType.update && currentTab === updateFormData.currentTab) {
        const updatedRow = dataSource.find((item) => item[rowKey] === rowKeyValue);
        if (updatedRow) {
            updateFormData.setFormData({
                ...updateFormData.formData,
                currentRow: updatedRow,
            });
        } else {
            updateFormData.setFormData(undefined);
        }
    }
};

export const trimStrStartEnd = (str: string) => {
    if (!str) return str;
    return str.replace(/^\s+|\s+$/g, '');
};

export const findDuplicates = (arr: any) => {
    return arr.filter((item: string, index: number) => arr.indexOf(item) !== index);
};

export const arrValuesToLower = (arr: string[]): string[] => {
    return [...arr].map((val) => val.toLowerCase());
};

export const isArrayIncludesLowered = (arr: string[], value: string): boolean =>
    arrValuesToLower(arr).includes(value.toLowerCase());

export const isArraysHasDuplicatesLowered = (arr: string[]): boolean => {
    const arrLower = arrValuesToLower(arr);
    return findDuplicates(arrLower).length > 0;
};

export const isArraysHasCrossesLowered = (arr1: string[], arr2: string[]): boolean => {
    const arr1Lower = arrValuesToLower(arr1);
    const arr2Lower = arrValuesToLower(arr2);
    const crosses = arr1Lower.filter((val) => arr2Lower.includes(val)).filter((val) => !!val);
    return crosses.length > 0;
};

export const hasWhiteSpace = (s: string) => {
    return s.indexOf(' ') >= 0;
};

export const removeSpacesInStringArray = (val: string[]): string[] => {
    if (Array.isArray(val)) {
        return val.map((str) => str.split(' ').join(''));
    }
};

export const removeSpacesInString = (val: string): string => {
    if (typeof val === 'string') {
        return val.split(' ').join('').toLowerCase();
    }
};

export const mergedSymbols = (
    tableSymbols: TemplatesControllerSwapsType[],
    incomeRecord: TemplatesControllerSwapsType[],
) => {
    if (tableSymbols.length === 0) return incomeRecord;
    let duplicatesSymbolsInCSVArray = [];
    let newSymbolsCSVArray = [];
    let filteredSymbolsFromTable;
    tableSymbols.forEach((el) => {
        const duplicatedItem = incomeRecord.find((e) => e.symbol === el.symbol);
        if (duplicatedItem !== undefined) {
            duplicatesSymbolsInCSVArray.push(duplicatedItem);
        }
    });
    if (duplicatesSymbolsInCSVArray.length > 0) {
        let res = incomeRecord.filter((el) => {
            return duplicatesSymbolsInCSVArray
                .filter((element) => element !== undefined)
                .every((e) => el.symbol !== e.symbol);
        });
        newSymbolsCSVArray.push(res);
        filteredSymbolsFromTable = tableSymbols.filter((el) =>
            duplicatesSymbolsInCSVArray.every((symb) => {
                if (symb.symbol !== undefined) {
                    return el.symbol !== symb.symbol;
                }
            }),
        );
    } else {
        newSymbolsCSVArray.push(incomeRecord);
        filteredSymbolsFromTable = tableSymbols;
    }
    const finalArray = [
        ...filteredSymbolsFromTable,
        ...duplicatesSymbolsInCSVArray.filter((item) => item !== undefined),
        ...newSymbolsCSVArray.flat(),
    ];
    return finalArray;
};

export function hasValue<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
}

export const convertKeysToLowerCase = <T extends {}>(data: T[]): T[] => {
    return data.map((item) => {
        let newObj = {};
        Object.keys(item).forEach((key) => {
            return (newObj[key.charAt(0).toLowerCase() + key.slice(1)] = item[key]);
        });
        return newObj;
    });
};
