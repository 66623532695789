import { CustomColumnType } from '@components/TableExt/types';
import { baseSorter } from '@components/TableExt/RowFilters/utils';
import { PresetModel } from '@models/Preset';
import { renderTargetType, TargetTypesList } from 'types/targetTypes';
import { renderTargetTemplateType, TargetTemplateSwapsTypeList } from 'types/targetTemplateTypes';

export const columns: () => CustomColumnType<PresetModel>[] = () => [
    {
        title: 'Name',
        dataIndex: 'name',
        align: 'left',
        sorter: (a, b) => baseSorter(a.name, b.name),
        filterOptions: { type: 'Search' },
    },
    {
        title: 'Server Type',
        dataIndex: 'type',
        render: renderTargetType,
        sorter: (a, b) => baseSorter(renderTargetType(a.type), renderTargetType(b.type)),
        align: 'left',
        filterOptions: {
            type: 'Checkbox',
            getFilterPreviewValue: (value) => {
                if (Array.isArray(value)) {
                    return value.map((item) => renderTargetType(item)).join(', ');
                }
                return value?.toString() ?? '';
            },
            checkboxValues: () =>
                TargetTypesList.map((item) => ({
                    compareValue: item.key,
                    title: item.title,
                })),
        },
    },
    {
        title: 'Template Type',
        dataIndex: 'templateType',
        align: 'left',
        render: renderTargetTemplateType,
        sorter: (a, b) =>
            baseSorter(renderTargetTemplateType(a.templateType), renderTargetTemplateType(b.templateType)),
        filterOptions: {
            type: 'Checkbox',
            getFilterPreviewValue: (value) => {
                if (Array.isArray(value)) {
                    return value.map((item) => renderTargetTemplateType(item)).join(', ');
                }
                return value?.toString() ?? '';
            },
            checkboxValues: () =>
                TargetTemplateSwapsTypeList.map((item) => ({
                    compareValue: item.key,
                    title: item.title,
                })),
        },
    },
];
