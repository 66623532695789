/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from 'react';
import { get } from 'lodash';
import { FilterFilled } from '@ant-design/icons';
import moment, { Moment } from 'moment';
import {
    CustomColumnType,
    FilterOptionsT,
    FilterValuesContextT,
    FilterValuesT,
    FilterValueType,
    IDataPicker,
    IRangeInputD,
    IRangeInputN,
} from '../types';
import { IComparePare, RowFilterRangeNum } from './RowFilterRangeNum';
import { RowFilterCheckbox } from './RowFilterCheckbox';
import { RowFilterRangeDate } from './RowFilterRangeDate';
import { RowFilterSearch } from './RowFilterSearch';
import { FilterValuesContext } from '../TableExt';
import { Popover, Tooltip } from 'antd';
import { getDateSeconds, getDateSecondsUTC } from '@utils/datetime';
import { DataPickerFilter } from './DataPickerFilter';
import TextArea from 'antd/lib/input/TextArea';
import { textAreaAutoSize } from '@constants/inputs';
import Big from 'big.js';

export const getFlatDataIndex = (dataIndex: string | string[]): string =>
    typeof dataIndex === 'string' ? dataIndex : dataIndex.join('.');

function filterSearch<ContextDataT>(
    data: ContextDataT[],
    dataIndex: string,
    value: string,
    caseSensitive?: boolean,
    searchDataFormatter?: any,
    expandedFilterValues?: string[],
) {
    if (expandedFilterValues && expandedFilterValues.length === 1) {
        return data.map((e: any) => ({
            ...e,
            [expandedFilterValues[0]]: e[expandedFilterValues[0]].filter((el: any) => {
                const elValue = get(el, dataIndex);
                if (elValue === undefined) {
                    return false;
                }
                let colValue = (searchDataFormatter ? searchDataFormatter(elValue, el) : elValue).toString();
                colValue = caseSensitive ? colValue : colValue.toLowerCase();
                const filterValue = caseSensitive ? value : value.toLowerCase();
                return colValue.toString().includes(filterValue);
            }),
        }));
    }
    if (expandedFilterValues && expandedFilterValues.length === 2) {
        return data.map((e: any) => ({
            ...e,
            [expandedFilterValues[0]]: e[expandedFilterValues[0]]
                .map((group: any) => {
                    return {
                        ...group,
                        [expandedFilterValues[1]]: group[expandedFilterValues[1]].filter((el: any) => {
                            const elValue = get(el, dataIndex);
                            if (elValue === undefined) {
                                return false;
                            }
                            let colValue = (
                                searchDataFormatter ? searchDataFormatter(elValue, el) : elValue
                            ).toString();
                            colValue = caseSensitive ? colValue : colValue.toLowerCase();
                            const filterValue = caseSensitive ? value : value.toLowerCase();
                            return colValue.toString().includes(filterValue);
                        }),
                    };
                })
                .filter((el: any) => el.symbols.length),
        }));
    }
    return data.filter((el) => {
        const elValue = get(el, dataIndex);
        if (elValue === undefined) {
            return false;
        }
        let colValue = (searchDataFormatter ? searchDataFormatter(elValue, el) : elValue).toString();
        colValue = caseSensitive ? colValue : colValue.toLowerCase();
        const filterValue = caseSensitive ? value : value.toLowerCase();
        return colValue.toString().includes(filterValue);
    });
}

function filterRangeDate<ContextDataT>(
    data: ContextDataT[],
    dataIndex: string,
    value: IRangeInputD,
    searchDataFormatter?: any,
    customFilter?: (data: ContextDataT[], value: IRangeInputD) => ContextDataT[],
) {
    if (customFilter) return customFilter(data, value);

    return data.filter((el) => {
        const elValue = get(el, dataIndex);
        if (elValue === undefined || elValue === null) {
            return false;
        }
        const colValue = searchDataFormatter ? searchDataFormatter(elValue, el) : elValue;
        return (
            getDateSeconds(colValue) >= getDateSecondsUTC(value.from) &&
            getDateSeconds(colValue) <= getDateSecondsUTC(value.to)
        );
    });
}

function filterDataPicker<ContextDataT>(
    data: ContextDataT[],
    dataIndex: string,
    value: IDataPicker,
    searchDataFormatter?: any,
    customFilter?: (data: ContextDataT[], value: IDataPicker) => ContextDataT[],
) {
    if (customFilter) return customFilter(data, value);
    return data;
}

function filterRangeTime<ContextDataT>(
    data: ContextDataT[],
    dataIndex: string,
    value: IRangeInputD,
    searchDataFormatter?: any,
    customFilter?: (data: ContextDataT[], value: IRangeInputD) => ContextDataT[],
) {
    if (customFilter) return customFilter(data, value);

    return data.filter((el) => {
        const elValue = get(el, dataIndex);
        if (elValue === undefined || elValue === null) {
            return false;
        }
        const colValue = searchDataFormatter ? searchDataFormatter(elValue, el) : elValue;
        return colValue;
    });
}

function filterRangeNumber<ContextDataT>(
    data: ContextDataT[],
    dataIndex: string,
    value: IRangeInputN,
    searchDataFormatter?: any,
) {
    const comparePair: IComparePare = {
        from: Number.isNaN(value.from) ? Number.MIN_SAFE_INTEGER : value.from,
        to: Number.isNaN(value.to) ? Number.MAX_SAFE_INTEGER : value.to,
    };
    return data.filter((el) => {
        const colValue = searchDataFormatter ? searchDataFormatter(get(el, dataIndex), el) : get(el, dataIndex);
        return colValue >= comparePair.from && colValue <= comparePair.to;
    });
}

function filterCheckbox<ContextDataT>(
    data: ContextDataT[],
    dataIndex: string,
    value: string[],
    searchDataFormatter?: any,
) {
    return data.filter((el) => {
        const colValue = searchDataFormatter ? searchDataFormatter(get(el, dataIndex), el) : get(el, dataIndex);
        if (Array.isArray(colValue)) {
            return value.filter((v) => colValue.includes(v)).length > 0;
        }
        return value.includes(colValue);
    });
}

export function filterAll<ContextDataT>(
    data: ContextDataT[],
    filterValues: FilterValuesT,
    columns: CustomColumnType<ContextDataT>[],
    expandedFilterValues: string[],
) {
    let local = data;
    const localColumns = {} as any;
    columns.forEach((col) => {
        localColumns[getFlatDataIndex(col.dataIndex)] = col;
    });
    Object.keys(filterValues).forEach((key) => {
        if (filterValues[key]) {
            const colData = localColumns[key];
            if (colData) {
                switch (colData.filterOptions?.type) {
                    case 'Search':
                        local = filterSearch(
                            local,
                            key,
                            filterValues[key] as string,
                            colData.filterOptions.caseSensitive,
                            colData.filterOptions.searchDataFormatter,
                            expandedFilterValues,
                        );
                        break;
                    case 'Checkbox':
                        local = filterCheckbox(
                            local,
                            key,
                            filterValues[key] as string[],
                            colData.filterOptions.searchDataFormatter,
                        );
                        break;
                    case 'RangeDate':
                        local = filterRangeDate(
                            local,
                            key,
                            filterValues[key] as IRangeInputD,
                            colData.filterOptions.searchDataFormatter,
                            colData.filterOptions.customFilter,
                        );
                        break;
                    case 'RangeTime':
                        local = filterRangeTime(
                            local,
                            key,
                            filterValues[key] as IRangeInputD,
                            colData.filterOptions.searchDataFormatter,
                            colData.filterOptions.customFilter,
                        );
                        break;
                    case 'DataPicker':
                        local = filterDataPicker(
                            local,
                            key,
                            filterValues[key] as IDataPicker,
                            colData.filterOptions.searchDataFormatter,
                            colData.filterOptions.customFilter,
                        );
                        break;
                    case 'RangeNum':
                        local = filterRangeNumber(
                            local,
                            key,
                            filterValues[key] as IRangeInputN,
                            colData.filterOptions.searchDataFormatter,
                        );
                        break;
                    default:
                        break;
                }
            }
        }
    });
    return local;
}

export function Filter<ContextDataT>(props: {
    options: FilterOptionsT<ContextDataT>;
    dataIndex: string;
    firstAvailiableDateForCalendar: Moment | null;
    confirm: () => void;
}) {
    switch (props.options.type) {
        case 'Checkbox':
            return (
                <RowFilterCheckbox
                    values={props.options.checkboxValues ? props.options.checkboxValues() : []}
                    dataIndex={props.dataIndex}
                    confirmCallback={props.confirm}
                />
            );
        case 'RangeDate':
            return (
                <RowFilterRangeDate
                    dataIndex={props.dataIndex}
                    withTimePicker={props.options.withTimePicker}
                    confirmCallback={props.confirm}
                />
            );
        case 'RangeTime':
            return <RowFilterRangeDate dataIndex={props.dataIndex} timeOnly confirmCallback={props.confirm} />;
        case 'DataPicker':
            return (
                <DataPickerFilter
                    dataIndex={props.dataIndex}
                    firstAvailiableDateForCalendar={props.firstAvailiableDateForCalendar}
                    confirmCallback={props.confirm}
                />
            );
        case 'RangeNum':
            return <RowFilterRangeNum dataIndex={props.dataIndex} confirmCallback={props.confirm} />;
        case 'Search':
            return (
                <RowFilterSearch
                    dataIndex={props.dataIndex}
                    caseSensitive={props.options.caseSensitive}
                    confirmCallback={props.confirm}
                />
            );
        default:
            return null;
    }
}

export function FilterIcon({
    dataIndex,
    isOpenFilterWindow,
    getFilterPreviewValue,
}: {
    dataIndex: string;
    isOpenFilterWindow: boolean;
    getFilterPreviewValue?: (rawValue: FilterValueType) => string;
}) {
    const filterValues = useContext<FilterValuesContextT>(FilterValuesContext);
    const filterValue = filterValues.values[dataIndex];
    const value: string = getFilterPreviewValue ? getFilterPreviewValue(filterValue) : (filterValue as string);
    const content = (
        <div>
            Filtered by:
            <TextArea autoSize={textAreaAutoSize} disabled placeholder={value} />
        </div>
    );
    if (value && !isOpenFilterWindow) {
        return (
            <Popover content={content} placement="left">
                <FilterFilled style={filterValues.values[dataIndex] ? { color: '#1890ff' } : {}} />
            </Popover>
        );
    }
    return (
        <Tooltip title="Click to filter">
            <FilterFilled style={filterValues.values[dataIndex] ? { color: '#1890ff' } : {}} />
        </Tooltip>
    );
}

export const baseSorter = (
    a: Date | Big | number | string | null | boolean | undefined,
    b: Date | Big | number | string | null | boolean | undefined,
): number => {
    if (a instanceof Big && b instanceof Big) {
        return a.cmp(b);
    }
    if (typeof a === 'boolean' && typeof b === 'boolean') {
        return a > b ? 1 : -1;
    }
    if (typeof a === 'number' && typeof b === 'number') {
        return a > b ? 1 : -1;
    }
    if (a instanceof Date || b instanceof Date) {
        const aString = a instanceof Date ? a.getTime().toString() : '';
        const bString = b instanceof Date ? b.getTime().toString() : '';
        return aString > bString ? 1 : -1;
    }
    const aString = (a ?? '').toString();
    const bString = (b ?? '').toString();
    return aString.localeCompare(bString);
};

export const timeSorter = (a: moment.Moment | null | undefined, b: moment.Moment | null | undefined): number =>
    baseSorter(a ? a.format('HH:mm:ss') : '', b ? b.format('HH:mm:ss') : '');
