import React from 'react';

export const LogoutComponentIcon: React.FC = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                opacity="0.4"
                d="M12.0042 17.6333C8.90123 17.6333 6.37524 15.1073 6.37524 12.0044C6.37524 10.2608 7.16423 8.64345 8.53957 7.56691C8.86605 7.31267 9.33747 7.36976 9.59312 7.69591C9.84853 8.02286 9.79102 8.49404 9.4645 8.74993C8.4556 9.53938 7.87629 10.7262 7.87629 12.0044C7.87629 14.2801 9.72798 16.1323 12.0042 16.1323C14.2804 16.1323 16.1321 14.2801 16.1321 12.0044C16.1321 10.7254 15.553 9.53938 14.5438 8.74899C14.2173 8.49311 14.1596 8.02192 14.4151 7.69497C14.6706 7.36873 15.1422 7.31174 15.4687 7.56598C16.8451 8.64345 17.6331 10.2618 17.6331 12.0044C17.6331 15.1073 15.1071 17.6333 12.0042 17.6333Z"
                fill="#C2EDFF"
            />
            <path
                d="M11.9834 12.7547C12.4197 12.7547 12.7551 12.4193 12.7551 12.0042V6.72942C12.7551 6.31499 12.419 6 12.0257 6C11.5894 6 11.254 6.33609 11.254 6.72942V12.0042C11.254 12.4193 11.5894 12.7547 11.9834 12.7547Z"
                fill="#C2EDFF"
            />
        </svg>
    );
};
