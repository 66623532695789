/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Table, TablePaginationConfig, Tooltip } from 'antd';
import { CustomColumnType, FilterableTableProps, FilterValuesContextT, FilterValuesT } from '../types';
import { IFilterContext } from '../RowFilters/types';
import { renderColumns, stringifyDataIndex } from '../utils';
import { TableHeader } from '../TableHeader';
import { FilterValuesContext } from '../TableExt';
import { filterAll } from '../RowFilters/utils';
import styles from '../TableExt.module.scss';
import cn from 'classnames';
import Edit from '@assets/icons/edit.svg';
import Copy from '@assets/icons/copy.svg';
import Delete from '@assets/icons/del.svg';
import { Message, MessageType } from '../../Message/Message';
import { SorterResult } from 'antd/lib/table/interface';
import { AlignType } from 'rc-table/lib/interface';

function createFilterContext<ContextT>() {
    return React.createContext<IFilterContext<ContextT>>(null!);
}

export function TableExtInner<ContextDataT extends {}>(props: FilterableTableProps<ContextDataT>) {
    const Context = useMemo<React.Context<IFilterContext<ContextDataT>>>(() => createFilterContext<ContextDataT>(), []);
    const filterValues = useContext<FilterValuesContextT>(FilterValuesContext);
    const {
        columns,
        dragIcon,
        title,
        titleJSX,
        dataSource,
        draggable,
        focusedRowEntityId,
        onRefreshClick,
        dragConfirmationText,
        onRowMove,
        components,
        headerToolbar,
        rowKey = 'key',
        colsSettings,
        header,
        pagination,
        scroll,
        actionColumn,
        style,
        firstAvailiableDateForCalendar = null,
        expandable,
        expandedFilterValues = [],
        size = 'small',
        rowClassNameHandler,
        footerBtnGroup,
        tableRef,
        isStickyHeader = false,
        ...restProps
    } = props;
    const [localData, setLocalData] = useState<ContextDataT[]>(dataSource as ContextDataT[]);
    // const [internalDataSource, setInternalDataSource] = useState<ContextDataT[] | null>(null);
    const isFiltered = useMemo<boolean>(
        () => !!Object.values(filterValues.values).filter((el) => !!el).length && !!localData.length,
        [localData, filterValues.values],
    );
    const [isSorted, setIsSorted] = useState<boolean>(false);
    const isModified = useMemo(() => isSorted || isFiltered, [isFiltered, isSorted]);
    const renderedColumns = useMemo(
        () => renderColumns<ContextDataT>(columns, firstAvailiableDateForCalendar),
        [Context, columns, isModified, firstAvailiableDateForCalendar],
    );
    const [filteredValues, setFilteredValues] = useState<FilterValuesT>(null!);

    const [tableColumns, setTableColumns] = useState<CustomColumnType<ContextDataT>[]>(renderedColumns);
    const [visibleColKeys, setVisibleColKeys] = useState<string[]>(
        columns.map((col: any) => stringifyDataIndex(col.dataIndex)),
    );
    const renderRowBody = useCallback(
        ({ className, trStyle, ...restRowProps }: any) => (
            <tr
                className={`${className} ${
                    restRowProps['data-row-key'] === focusedRowEntityId ? styles.rowFocused : ''
                }`}
                style={trStyle}
                {...restRowProps}
            />
        ),
        [focusedRowEntityId],
    );
    const getVisibleCols = useCallback(
        (cols: CustomColumnType<ContextDataT>[]): CustomColumnType<ContextDataT>[] => [
            ...cols.filter((col) => col),
            ...(actionColumn
                ? [
                      {
                          align: 'center' as AlignType,
                          title: actionColumn.title,
                          width: actionColumn!.custom && 200,
                          dataIndex: actionColumn.dataIndex,
                          fixed: !actionColumn.custom?.length ? actionColumn.fixed : undefined,
                          render: (value: any, parent: any) => (
                              <div className={styles.iconRow}>
                                  {actionColumn!.onEdit && (
                                      <Tooltip title="Edit">
                                          <Button type="link" onClick={() => actionColumn!.onEdit!(value)}>
                                              <span className={cn(styles.icon, styles.blueIcon)}>
                                                  <img alt="logo" src={Edit} />
                                              </span>
                                          </Button>
                                      </Tooltip>
                                  )}
                                  {actionColumn!.copyColIndex && (
                                      <Tooltip title="Copy">
                                          <Button
                                              type="link"
                                              onClick={() => {
                                                  navigator.clipboard.writeText(
                                                      parent[actionColumn!.copyColIndex! as string] ?? '',
                                                  );
                                                  Message(MessageType.success, 'Message');
                                              }}
                                          >
                                              <span className={cn(styles.icon, styles.blueIcon)}>
                                                  <img alt="logo" src={Copy} />
                                              </span>
                                          </Button>
                                      </Tooltip>
                                  )}
                                  {actionColumn!.onDelete && (
                                      <Tooltip title="Delete">
                                          <Button type="link" onClick={() => actionColumn!.onDelete!(value)}>
                                              <span className={cn(styles.icon, styles.redIcon)}>
                                                  <img alt="logo" src={Delete} />
                                              </span>
                                          </Button>
                                      </Tooltip>
                                  )}
                                  {actionColumn!.custom &&
                                      actionColumn!.custom.map((action) => (
                                          <Tooltip title={action.text}>
                                              <Button
                                                  type="link"
                                                  onClick={() => {
                                                      action.onClick(value);
                                                  }}
                                              >
                                                  {action.icon(value)}
                                              </Button>
                                          </Tooltip>
                                      ))}
                              </div>
                          ),
                      },
                  ]
                : []),
        ],
        [actionColumn, visibleColKeys],
    );
    const tableHeader = () => {
        let toolbarElements: JSX.Element[] = [];

        if (headerToolbar) {
            toolbarElements = headerToolbar();
        }
        return (
            <TableHeader<ContextDataT>
                // toolbar={headerToolbar ? headerToolbar() : undefined}
                toolbar={toolbarElements}
                activeColumnsSetter={setVisibleColKeys}
                reorderColumns={setTableColumns}
                columns={tableColumns}
                updateData={onRefreshClick}
                title={title || ''}
                titleJSX={titleJSX}
                colsSettings={colsSettings}
            />
        );
    };

    useEffect(() => {
        setLocalData(filterAll(dataSource, filterValues.values, columns, expandedFilterValues));
    }, [filterValues.values, dataSource, columns]);

    useEffect(() => {
        setTableColumns(renderColumns<ContextDataT>(columns, firstAvailiableDateForCalendar));
    }, [Context, columns, firstAvailiableDateForCalendar]);

    useEffect(() => {
        setLocalData(filterAll(dataSource, filterValues.values, columns, expandedFilterValues));
    }, [filterValues.values, dataSource, columns]);

    useEffect(() => {
        setTableColumns(renderColumns<ContextDataT>(columns, firstAvailiableDateForCalendar));
    }, [Context, columns, firstAvailiableDateForCalendar]);

    const paginationSettings: false | TablePaginationConfig | undefined = pagination
        ? {
              ...pagination,
              size: 'default',
              defaultPageSize: pagination.defaultPageSize ?? 20,
              showSizeChanger: pagination.showSizeChanger ?? true,
          }
        : pagination ?? { size: 'default', showSizeChanger: true, defaultPageSize: 20 };
    return (
        <Context.Provider
            value={{
                data: localData,
                staticData: dataSource,
                setData: setLocalData,
                filteredPanel: filteredValues,
                setFilteredPanel: setFilteredValues,
            }}
        >
            <Table
                {...restProps}
                sticky={isStickyHeader}
                ref={tableRef}
                rowClassName={rowClassNameHandler}
                style={{ ...style, margin: style?.margin ?? '1.5rem' }}
                rowKey={rowKey}
                components={{
                    ...components,
                    body: components?.body || {
                        row: renderRowBody,
                    },
                }}
                footer={footerBtnGroup}
                dataSource={visibleColKeys.length ? localData : undefined}
                columns={getVisibleCols(tableColumns)}
                size={size}
                onChange={(_, __, sorter) => {
                    // setInternalDataSource(extra.currentDataSource);
                    return (sorter as SorterResult<ContextDataT>).column ? setIsSorted(true) : setIsSorted(false);
                }}
                scroll={scroll || { x: 800 }}
                title={header === false ? undefined : tableHeader}
                pagination={paginationSettings}
                expandable={expandable}
            />
        </Context.Provider>
    );
}
