import { makeAutoObservable } from 'mobx';
import { convertRecordToPresetModel, PresetCreateModel, PresetModel } from '@models/Preset';
import { API } from '@api/client/apiEndpoints';
import { Message, MessageType } from '@components/Message/Message';
import { convertServers, ServerModel } from '@models/Server';
import { v4 } from 'uuid';
import { TargetTypeEnum } from 'types/targetTypes';
import { baseSorter } from '@components/TableExt/RowFilters/utils';
import { PresetParsedRecord } from '@pages/presets/import/csvUtils';

export class PresetsStore {
    constructor() {
        makeAutoObservable(this);
    }

    data: PresetModel[] = [];

    preset: PresetModel | null = null;

    servers: ServerModel[] = [];

    setData(data: PresetModel[]) {
        this.data = data;
    }

    isLoading: boolean = false;

    async getAllPresets() {
        this.isLoading = true;
        return new Promise<PresetModel[]>((resolve, reject) => {
            API.presets
                .get()
                .then(({ data }) => {
                    const result = data
                        .map(convertRecordToPresetModel)
                        .sort((a, b) =>
                            baseSorter(b.updateDate ? b.updateDate.unix() : 0, a.updateDate ? a.updateDate.unix() : 0),
                        );
                    return resolve(result);
                })
                .catch((e) => {
                    Message(MessageType.error, e);
                    return reject(e);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        });
    }

    async getById(id: string): Promise<PresetModel> {
        this.isLoading = true;

        return new Promise<PresetModel>((resolve, reject) => {
            API.presets
                .getById(id)
                .then(({ data }) => {
                    const preset = convertRecordToPresetModel(data);
                    if (preset.type) {
                        this.getServersByType(preset.type)
                            .then((servers) => {
                                servers.forEach((server) => {
                                    const isServerInPreset = !!preset.presetServers.find(
                                        (e) => e.serverId === server.id,
                                    );
                                    if (!isServerInPreset) {
                                        preset.presetServers.push({
                                            id: v4(),
                                            serverId: server.id,
                                            state: false,
                                            presetServerModifiers: [],
                                        });
                                    }
                                });
                                this.preset = preset;
                                this.isLoading = false;
                                this.servers = servers;
                                return resolve(preset);
                            })
                            .catch((e) => {
                                Message(MessageType.error, e);
                                this.isLoading = false;
                                return reject();
                            });
                    } else {
                        Message(MessageType.error, 'Server type is not set');
                        this.isLoading = false;
                        this.preset = null;
                        this.servers = [];
                        return reject();
                    }
                })
                .catch((e) => {
                    Message(MessageType.error, e);
                    this.isLoading = false;
                    return reject();
                });
        });
    }

    async create(data: PresetCreateModel | PresetModel) {
        this.isLoading = true;
        await API.presets
            .create(data)
            .then(() => {
                Message(MessageType.success, 'Execution Template was created');
            })
            .catch((e) => {
                Message(MessageType.error, `Can't create Execution Template. ${e}`);
                Promise.reject(e);
            })
            .finally(() => {
                this.getAllPresets().then((result) => {
                    this.setData(result);
                });
                this.isLoading = false;
            });
    }

    async delete(id: string) {
        this.isLoading = true;
        await API.presets
            .delete(id)
            .then(() => {
                Message(MessageType.success, 'Execution Template was deleted');
            })
            .catch((e) => {
                Message(MessageType.error, `Can't delete Execution Template. ${e}`);
                Promise.reject(e);
            })
            .finally(() => {
                this.getAllPresets().then((result) => {
                    this.setData(result);
                });
                this.isLoading = false;
            });
    }

    async copy(id: string) {
        this.isLoading = true;
        await API.presets
            .copy(id)
            .then(({ data }) => {
                if (!data || !data.createDate) {
                    Message(MessageType.error, "Can't copy Execution Template");
                } else {
                    Message(MessageType.success, 'Execution Template was copied');
                }
                this.getAllPresets().then((result) => {
                    this.setData(result);
                });
            })
            .catch((e) => {
                Message(MessageType.error, `Can't copy Execution Template. ${e}`);
                Promise.reject(e);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    async update(record: PresetModel) {
        this.isLoading = true;
        return new Promise<void>((resolve, reject) => {
            API.presets
                .update(record)
                .then((data) => {
                    if (!data || data.status !== 204) {
                        Message(MessageType.error, "Can't update Execution Template");
                    } else {
                        Message(MessageType.success, 'Execution Template was updated');
                    }
                    resolve();
                })
                .catch((e) => {
                    Message(MessageType.error, `Can't update Execution Template. ${e}`);
                    reject(e);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        });
    }

    async getServersByType(type: TargetTypeEnum) {
        return new Promise<ServerModel[]>((resolve, reject) => {
            API.servers
                .getByType(type)
                .then(({ data: items }) => {
                    resolve(convertServers(items));
                })
                .catch((e) => {
                    Message(MessageType.error, e);
                    reject();
                });
        });
    }

    updatePresetFromCSV(preset: PresetModel, record: PresetParsedRecord | null) {
        this.isLoading = true;
        return new Promise<PresetModel>((resolve, reject) => {
            if (!record) return reject();
            this.getById(preset.id)
                .then((data) => {
                    const uptatePreset: PresetModel = {
                        ...data,
                        symbolMasks: record.symbolMasks,
                        groupMasks: record.groupMasks,
                    };
                    this.update(uptatePreset)
                        .then(() => {
                            resolve(uptatePreset);
                            this.isLoading = false;
                        })
                        .catch(() => {
                            this.isLoading = false;
                            reject();
                        });
                })
                .catch(() => {
                    reject();
                    this.isLoading = false;
                });
        });
    }

    resetPreset() {
        this.preset = null;
    }

    reset() {
        this.data = [];
        this.servers = [];
        this.resetPreset();
        this.isLoading = false;
    }
}

const presetsStore = new PresetsStore();
export { presetsStore };
