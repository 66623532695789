import React from 'react';
import styles from './styles.module.scss';
import cn from 'classnames';
import { ValidationMessageType } from '@models/Swaps';

export const Statistic = ({
    errors,
    warnings,
    infos,
    other,
}: {
    errors: ValidationMessageType[];
    warnings: ValidationMessageType[];
    infos: ValidationMessageType[];
    other: ValidationMessageType[];
}) => {
    const count = errors.length + warnings.length + infos.length + other.length;
    if (count === 0) {
        return null;
    }
    return (
        <div className={styles.counters}>
            {errors.length > 0 && <span className={cn(styles.count, styles.error)}>{errors.length}</span>}
            {warnings.length > 0 && <span className={cn(styles.count, styles.warning)}>{warnings.length}</span>}
            {infos.length > 0 && <span className={cn(styles.count, styles.info)}>{infos.length}</span>}
            {other.length > 0 && <span className={cn(styles.count)}>{other.length}</span>}
        </div>
    );
};
