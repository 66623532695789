import React, { useCallback } from 'react';
import { Button, Modal } from 'antd';
import Title from 'antd/es/typography/Title';
import { TableExt } from '@components/TableExt/TableExt';
import ValidAlert from '@assets/icons/validAlert.svg';
import CollapseUp from '@assets/icons/sort_up.svg';
import CollapseDown from '@assets/icons/sort_down.svg';
import styles from '../styles.module.scss';
import { useReaction } from '@utils/hooks';
import { columns, columnsExpandData, columnsExpandDataTSD, columnsExpandDataTypesTSD } from './utils';
import { TargetTypeEnum } from 'types/targetTypes';
import { TargetTemplateTypeEnum } from 'types/targetTemplateTypes';
import { updaterToolStore } from '@store/updaterToolStore';
import { ValidationResponseDataType, ValidationResponseModel } from '@models/Swaps';
import { AvaliableTypes } from '@models/Server';
import cn from 'classnames';

export const ModalValidation: React.FC<{
    type: TargetTypeEnum;
    templateType: TargetTemplateTypeEnum | null;
    modalMode: 'update' | 'validate';
    onSubmit: () => void;
}> = ({ type, templateType, modalMode = 'validate', onSubmit }) => {
    const isShowTypes = templateType === TargetTemplateTypeEnum.swapsBySymbol && type !== TargetTypeEnum.takerFix;
    const isShowTripleSwapDay = templateType === TargetTemplateTypeEnum.swapsBySymbol;

    const [validatedSwaps] = useReaction<ValidationResponseModel[]>(() => updaterToolStore.validation);
    const isTargetTypeMt = type === TargetTypeEnum.mt4 || type === TargetTypeEnum.mt5;
    const expandedFilterValues = isTargetTypeMt ? ['groups', 'symbols'] : ['symbols'];

    const avaliableServers = (servers: ValidationResponseModel[]) => {
        return servers
            .map((server) => {
                return {
                    text: server.serverName!,
                    value: server.serverName!,
                };
            })
            .filter((value, index, self) => index === self.findIndex((t) => t.value === value.value));
    };

    const renderColumnsMT = useCallback(
        (avaliableServersNames: AvaliableTypes[]) => columns(avaliableServersNames, isShowTripleSwapDay),
        [validatedSwaps],
    );

    const expandedRowRenderDataMT = (data: ValidationResponseDataType[]) => {
        return (
            <TableExt<ValidationResponseDataType>
                style={{ margin: 0 }}
                header={false}
                size="small"
                rowKey="id"
                columns={isShowTypes ? columnsExpandDataTypesTSD(type) : columnsExpandData(type)}
                dataSource={data}
                pagination={false}
                isStickyHeader={true}
            />
        );
    };
    const expandedRowRenderData = (data: ValidationResponseDataType[]) => {
        return (
            <TableExt<ValidationResponseDataType>
                style={{ margin: 0 }}
                header={false}
                size="small"
                rowKey="id"
                columns={columnsExpandData(type)}
                dataSource={data}
                pagination={false}
                isStickyHeader={true}
            />
        );
    };

    const expandedRowRenderDataTSD = (data: ValidationResponseDataType[]) => {
        return (
            <TableExt<ValidationResponseDataType>
                style={{ margin: 0 }}
                header={false}
                size="small"
                rowKey="id"
                columns={columnsExpandDataTSD(type)}
                dataSource={data}
                pagination={false}
                isStickyHeader={true}
            />
        );
    };

    const cancelHandler = () => {
        updaterToolStore.validation = [];
    };

    const updateHandler = () => {
        onSubmit();
        cancelHandler();
    };
    const rowClassNameHandler = (a: ValidationResponseModel) => {
        if (a.status === 0) return 'validationTableServerError';
        if (a.status === 1) return 'validationTableNoChanges';
        return 'validationTable';
    };

    const updateButtonsRender = (arr: ValidationResponseModel[]) => {
        if (arr.every((el) => el.status === 0 || el.status === 1)) {
            return false;
        }
        if (arr.every((el) => el.status === 2 && el.description.includes('No potential problems'))) {
            return (
                <Button style={{ marginLeft: '10px' }} onClick={updateHandler} size="large" type="primary">
                    Update
                </Button>
            );
        }
        return (
            <Button style={{ marginLeft: '10px' }} onClick={updateHandler} size="large" type="primary">
                Update anyway
            </Button>
        );
    };
    return (
        <Modal className={cn(styles.validationModal)} open onCancel={cancelHandler} footer={null} width="100%">
            <div className={styles.validationPageWrapper}>
                <div className={styles.headerBlock}>
                    <img alt="logo" className="logo_image" src={ValidAlert} />
                    <Title level={3}>Potential problems</Title>
                </div>
                <div className={styles.tableBlock}>
                    <TableExt<ValidationResponseModel>
                        rowKey="serverId"
                        className="validationTable"
                        scroll={{ x: 1200 }}
                        rowClassNameHandler={rowClassNameHandler}
                        header={false}
                        size="small"
                        style={{ margin: 0 }}
                        columns={renderColumnsMT(avaliableServers(validatedSwaps))}
                        dataSource={validatedSwaps}
                        focusedRowEntityId={null}
                        pagination={false}
                        isStickyHeader={true}
                        expandedFilterValues={[...expandedFilterValues, 'test']}
                        expandable={{
                            expandedRowRender: (record: ValidationResponseModel) => {
                                if (isShowTypes) {
                                    return expandedRowRenderDataMT(record.data);
                                }
                                if (!isShowTypes && isShowTripleSwapDay) {
                                    return expandedRowRenderDataTSD(record.data);
                                }
                                return expandedRowRenderData(record.data);
                            },
                            expandRowByClick: true,
                            // defaultExpandAllRows: true,
                            rowExpandable: (record: ValidationResponseModel) => {
                                const testIfEmptySymbolInfo = (data: ValidationResponseModel) => {
                                    // eslint-disable-next-line consistent-return
                                    const test = data.data.some((obj) => {
                                        if (obj.symbolInfo === null) return true;
                                    });
                                    return test || data.data.length === 0;
                                };
                                return !testIfEmptySymbolInfo(record);
                            },
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            expandIcon: ({ expanded, onExpand, record }: any) => {
                                const testIfEmptySymbolInfo = (data: ValidationResponseModel) => {
                                    // eslint-disable-next-line consistent-return
                                    const test = data.data.some((obj) => {
                                        if (obj.symbolInfo === null) return true;
                                    });
                                    return test || data.data.length === 0;
                                };
                                if (!testIfEmptySymbolInfo(record)) {
                                    return (
                                        <Button type="link" onClick={(e) => onExpand(record, e)}>
                                            <img
                                                alt="logo"
                                                className={styles.collapseIcon}
                                                src={expanded ? CollapseUp : CollapseDown}
                                            />
                                        </Button>
                                    );
                                }
                                return null;
                            },
                        }}
                    />
                </div>
                {modalMode === 'update' ? (
                    <div className={styles.validationBtnGroup}>
                        <Button onClick={cancelHandler} size="large" type="default">
                            Cancel
                        </Button>
                        {updateButtonsRender(validatedSwaps)}
                    </div>
                ) : (
                    <div className={styles.validationBtnClose}>
                        <Button className={styles.closeBtn} onClick={cancelHandler} size="large" type="default">
                            Close
                        </Button>
                    </div>
                )}
            </div>
        </Modal>
    );
};
