import React, { createContext, useEffect, useState } from 'react';
import styles from './Presets.module.scss';
import { IFormContext, IFormData } from 'types/commonTypes';
import { PresetModel } from '@models/Preset';
import { presetsStore } from '@store/presetsStore';
import { PresetsTable } from './presetsTable/PresetsTable';
import { PresetsForm } from './presetsForm/PresetsForm';
import { useReaction } from '@utils/hooks';

export const FormContext = createContext<IFormContext<PresetModel>>(null!);

export const Presets = () => {
    const [formData, setFormData] = useState<IFormData<PresetModel> | undefined>(undefined);
    const [preset] = useReaction<PresetModel | null>(() => presetsStore.preset);

    useEffect(() => {
        presetsStore.getAllPresets().then((presets) => {
            presetsStore.setData(presets);
        });
    }, []);

    useEffect(() => {
        if (formData && formData.currentRow?.id) {
            presetsStore.getById(formData.currentRow.id);
        }
    }, [formData]);

    return (
        <FormContext.Provider value={{ reset: () => setFormData(undefined), formData, setFormData }}>
            <div className={styles.pageWrapper}>
                <div className={styles.contentWrapper}>
                    <PresetsTable />
                </div>
                {preset && <PresetsForm />}
            </div>
        </FormContext.Provider>
    );
};
