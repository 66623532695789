import React from 'react';
import { Button, Tooltip } from 'antd';

type Props = {
    disabled?: boolean;
    onClick: () => void;
    label?: string;
    tooltip?: string;
};
export const CreateButton = ({
    disabled = false,
    onClick,
    label = 'Create',
    tooltip = 'Create a new record',
}: Props) => {
    return (
        <Tooltip placement="leftTop" title={tooltip}>
            <Button
                style={{ background: '#FCB70B', border: 'none' }}
                key="update"
                type="primary"
                disabled={disabled}
                onClick={onClick}
            >
                {label}
            </Button>
        </Tooltip>
    );
};
