import React, { createContext, FC, ReactNode } from 'react';
import { Portal } from 'react-portal';
import styles from './Overlay.module.scss';

type Props = {
    onClick: () => void;
};

export const FormContext = createContext<ReactNode | null>(null);

export const FormOverlay: FC<Props> = ({ onClick }) => (
    <Portal>
        <div className={styles.formOverlay} onClick={onClick} role="none" />
    </Portal>
);
