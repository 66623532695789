import React, { useEffect, useState } from 'react';
import { RightPanel } from '@components/RightPanelForm';
import { FieldErrors, useForm, UseFormSetError, UseFormTrigger } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { OperationType } from 'types/commonTypes';
import { Button, Modal, Select, Tooltip } from 'antd';
import { MaskType, PresetModel, PresetServer } from '@models/Preset';
import { schema } from '@pages/presets/presetsForm/schema';
import { TemplateModel, TemplateSwapModel } from '@models/Templates';
import { RightPanelCard } from '@components/RightPanelForm/Groups';
import cn from 'classnames';
import { useReaction } from '@utils/hooks';
import { updaterToolStore } from '@store/updaterToolStore';
import { General } from '@pages/presets/presetsForm/General';
import { Mask } from '@pages/presets/presetsForm/Mask';
import { Modifiers } from '@pages/presets/presetsForm/Modifiers/Modifiers';
import { ServerModel } from '@models/Server';
import { observer } from 'mobx-react';
import { presetsStore } from '@store/presetsStore';
import { FieldContainer } from '@components/Form/FieldContainer/FieldContainer';
import { FieldLabel } from '@components/Form/FieldLabel/FieldLabel';
import styles from '../styles.module.scss';
import { CreatePresetModal } from '../createPresetModal.tsx/CreatePresetModal';
import { Message, MessageType } from '@components/Message/Message';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { DEFAULT_NAME_PRESET } from '../UpdaterTool';
import { ValidationReqestModel, ValidationResponseModel } from '@models/Swaps';
import { ModalValidation } from '../modalValidation/ModalValidation';
import { baseSorter } from '@components/TableExt/RowFilters/utils';

type Props = {
    isTouchedCustom: boolean;
    localSwaps: TemplateSwapModel[];
    localPreset: PresetModel;
    defaultPreset: PresetModel;
    setLocalPreset: (value: PresetModel | null) => void;
};

const isEnabledAvailableServers = (servers: ServerModel[], presetServers: PresetServer[]): boolean =>
    presetServers.filter((server) => server.state && servers.find((s) => s.id === server.serverId && s.available))
        .length > 0;

const isEnabledNotAvailableServers = (servers: ServerModel[], presetServers: PresetServer[]): boolean =>
    presetServers.filter((server) => server.state && servers.find((s) => s.id === server.serverId && !s.available))
        .length > 0;

const getServersValidationMessage = (count: number): string => {
    if (count === 1) return 'Unavailable selected server';
    if (count > 1) return 'Unavailable selected servers';
    return '';
};

export const PresetPanelRight: React.FC<Props> = observer(
    ({ isTouchedCustom, localSwaps, localPreset, setLocalPreset, defaultPreset }) => {
        const [template] = useReaction<TemplateModel | null>(() => updaterToolStore.template);
        const [servers] = useReaction<ServerModel[]>(() => updaterToolStore.servers);
        const [validations] = useReaction<ValidationResponseModel[]>(() => updaterToolStore.validation);
        const [isLoading] = useReaction<boolean>(() => updaterToolStore.isLoading);
        const [isValidating] = useReaction<boolean>(() => updaterToolStore.isValidating);
        const [isUpdating] = useReaction<boolean>(() => updaterToolStore.isUpdating);
        const [isExporting] = useReaction<boolean>(() => updaterToolStore.isExporting);
        const [isOpenCreateModal, setIsOpenCreateModal] = useState<boolean>(false);
        const [presets] = useReaction<PresetModel[]>(() => updaterToolStore.presets);
        const [isLoadingPreset, setIsLoadingPreset] = useState<boolean>(false);
        const [modalMode, setModalMode] = useState<'update' | 'validate' | null>(null);
        const [isLoadingCreatePreset, setIsLoadingCreatePreset] = useState<boolean>(false);

        const {
            handleSubmit,
            reset,
            control,
            setError,
            clearErrors,
            trigger,
            watch,
            formState: { isDirty, isValid, dirtyFields, errors },
        } = useForm<PresetModel>({
            defaultValues: localPreset,
            mode: 'all',
            resolver: yupResolver(schema(presets)),
        });

        const formState = watch();

        const enabledUnavailableServerIds = formState.presetServers
            .filter((item) => item.state)
            .map((item) => item.serverId ?? '')
            .filter((id) => servers.find((item) => item.id === id && !item.available));

        useEffect(() => {
            if (localPreset) {
                reset(localPreset);
            }
        }, [localPreset]);

        const isReadyToExport =
            !(isLoading || isValidating || isUpdating || isLoadingPreset || localSwaps.length === 0) &&
            formState.presetServers.filter((s) => s.state).length > 0;

        const isReadyToSubmit = !(
            !isValid ||
            isLoading ||
            isValidating ||
            isUpdating ||
            isLoadingPreset ||
            localSwaps.length === 0 ||
            enabledUnavailableServerIds.length > 0 ||
            !(
                isEnabledAvailableServers(servers, formState.presetServers) &&
                !isEnabledNotAvailableServers(servers, formState.presetServers)
            )
        );

        const runValidating = (mode: 'update' | 'validate') => {
            if (template) {
                const validation: ValidationReqestModel = {
                    groupMasks: formState.groupMasks,
                    symbolMasks: formState.symbolMasks,
                    servers: formState.presetServers,
                    swaps: localSwaps,
                };
                if (mode === 'update') updaterToolStore.isUpdating = true;
                if (mode === 'validate') updaterToolStore.isValidating = true;

                updaterToolStore
                    .validateSwaps({
                        validation,
                        type: template.type,
                        templateType: template.templateType,
                    })
                    .then((res) => {
                        updaterToolStore.validation = res;
                        setModalMode(mode);
                    })
                    .finally(() => {
                        if (mode === 'update') updaterToolStore.isUpdating = false;
                        if (mode === 'validate') updaterToolStore.isValidating = false;
                    });
            }
        };

        const runExport = () => {
            if (template) {
                const validation: ValidationReqestModel = {
                    groupMasks: formState.groupMasks,
                    symbolMasks: formState.symbolMasks,
                    servers: formState.presetServers,
                    swaps: localSwaps,
                };
                updaterToolStore.export({
                    validation,
                    templateName: template.name,
                    templateId: template.id,
                });
            }
        };

        const runUpdating = () => {
            if (template) {
                const validation: ValidationReqestModel = {
                    groupMasks: formState.groupMasks,
                    symbolMasks: formState.symbolMasks,
                    servers: formState.presetServers,
                    swaps: localSwaps,
                };
                updaterToolStore.isUpdating = true;
                updaterToolStore
                    .updateSwaps({
                        swaps: {
                            templateName: template.name,
                            templateId: template.id,
                            ...validation,
                        },
                        type: template.type,
                        templateType: template.templateType,
                    })
                    .finally(() => {
                        updaterToolStore.isUpdating = false;
                        updaterToolStore.validation = [];
                    });
            }
        };

        const customButtons = (
            <div className={styles.customButtons}>
                <Button
                    size="large"
                    type="default"
                    htmlType="button"
                    style={{ marginRight: '10px' }}
                    disabled={!isReadyToSubmit}
                    loading={isValidating}
                    onClick={() => runValidating('validate')}
                >
                    Validate
                </Button>
                <Tooltip placement="top" title="Export swap rates after applied modifiers">
                    <Button
                        size="large"
                        type="default"
                        htmlType="button"
                        style={{ marginRight: '10px' }}
                        disabled={!isReadyToExport}
                        loading={isExporting}
                        onClick={runExport}
                    >
                        Export
                    </Button>
                </Tooltip>
            </div>
        );

        const handleCancelCreatePresetClick = () => {
            setIsOpenCreateModal(false);
        };

        const selectPreset = (presetId: string, availablePresets: PresetModel[]) => {
            const newPreset = availablePresets.find((e) => e.id === presetId);
            if (newPreset) {
                setIsLoadingPreset(true);
                presetsStore
                    .getById(newPreset.id)
                    .then((result) => {
                        setLocalPreset(result);
                        trigger();
                    })
                    .finally(() => {
                        setIsLoadingPreset(false);
                    });
            } else {
                setLocalPreset(defaultPreset);
            }
        };

        const onSubmitCreatePresetModal = (values: { name: string }) => {
            setIsLoadingCreatePreset(true);
            presetsStore
                .create({ ...formState, name: values.name })
                .then(() => {
                    if (template) {
                        updaterToolStore.getPresetsByType(template.type, template.templateType).then((result) => {
                            updaterToolStore.presets = result;
                            const newPreset = result.find((e) => e.name === values.name);
                            if (newPreset) {
                                selectPreset(newPreset.id, result);
                            }
                        });
                        setIsOpenCreateModal(false);
                    } else {
                        Message(MessageType.error, 'Template is not set');
                    }
                })
                .finally(() => {
                    setIsLoadingCreatePreset(false);
                });
        };

        return (
            <>
                <RightPanel<PresetModel>
                    entityTitle="SwapsUpdaterPreset"
                    name="Execution Options"
                    type={OperationType.update}
                    isOpen
                    isValid={isReadyToSubmit}
                    isTouched={isTouchedCustom || isDirty}
                    isOverlayEnabled={false}
                    onSubmit={handleSubmit(() => runValidating('update'))}
                    customButtons={customButtons}
                    submitBtnTitle="Update Swaps"
                    isSubmitting={isUpdating}
                >
                    <RightPanelCard isLoading={isLoading || isLoadingPreset}>
                        <FieldContainer>
                            <FieldLabel title="Execution Template Name" />
                            <Select
                                onChange={(id) => selectPreset(id, presets)}
                                className={cn({
                                    changed: dirtyFields.id,
                                    invalid: !!errors.id?.message,
                                })}
                                value={localPreset?.name}
                                showSearch
                                optionFilterProp="label"
                                options={[
                                    ...presets.map((item) => ({
                                        value: item.id,
                                        label: item.name,
                                    })),
                                    {
                                        value: DEFAULT_NAME_PRESET,
                                        label: '   ',
                                        hidden: true,
                                    },
                                ].sort((a, b) => baseSorter(a.label, b.label))}
                                allowClear
                                loading={isLoadingPreset}
                            />
                        </FieldContainer>
                        <br />
                        <General control={control} errors={errors} dirtyFields={dirtyFields} hideName={true} />
                        {template && (
                            <Mask
                                type={template.type}
                                templateType={template.templateType}
                                // @ts-ignore
                                trigger={trigger as UseFormTrigger<MaskType>}
                                // @ts-ignore
                                control={control as Control<MaskType>}
                                // @ts-ignore
                                clearErrors={clearErrors as UseFormClearErrors<MaskType>}
                                setError={setError as UseFormSetError<MaskType>}
                                errors={errors as FieldErrors<MaskType>}
                                symbolMasks={localPreset.symbolMasks}
                                groupMasks={localPreset.groupMasks}
                            />
                        )}
                    </RightPanelCard>
                    <Modifiers
                        control={control}
                        errors={errors}
                        dirtyFields={dirtyFields}
                        isLoading={isLoading || isLoadingPreset}
                        servers={servers}
                        preset={localPreset}
                        validationMessage={getServersValidationMessage(enabledUnavailableServerIds.length)}
                    />
                    {!isLoading && !!localPreset && !isLoadingPreset && !isLoadingCreatePreset ? (
                        formState.name === DEFAULT_NAME_PRESET ? (
                            <Button
                                size="middle"
                                type="primary"
                                htmlType="button"
                                disabled={!isValid || !(isTouchedCustom || isDirty)}
                                onClick={() => {
                                    setIsOpenCreateModal(true);
                                }}
                                className={styles.savePresetBtn}
                            >
                                Create Execution Template
                            </Button>
                        ) : (
                            <Button
                                size="large"
                                type="primary"
                                htmlType="button"
                                disabled={!isValid || !(isTouchedCustom || isDirty)}
                                onClick={() => {
                                    Modal.confirm({
                                        title: `Save changes in "${localPreset?.name}"?`,
                                        icon: <ExclamationCircleOutlined className={styles.circleIcon} />,
                                        okText: 'Save',
                                        okType: 'primary',
                                        cancelText: 'Cancel',
                                        onOk: async () => {
                                            await presetsStore.update(formState);
                                        },
                                    });
                                }}
                                className={styles.savePresetBtn}
                            >
                                Save Execution Template
                            </Button>
                        )
                    ) : undefined}
                </RightPanel>
                {isOpenCreateModal && (
                    <CreatePresetModal
                        onSubmit={onSubmitCreatePresetModal}
                        onCancel={handleCancelCreatePresetClick}
                        isLoading={isLoadingCreatePreset}
                    />
                )}
                {modalMode && validations.length > 0 && template && template.type && (
                    <ModalValidation
                        type={template.type}
                        templateType={template?.templateType}
                        modalMode={modalMode}
                        onSubmit={() => {
                            runUpdating();
                        }}
                    />
                )}
            </>
        );
    },
);
