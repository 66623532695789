import { isFilledString, isInEnum } from '@utils';

export enum TargetTemplateTypeEnum {
    swapsByGroups = 'swapsbygroups',
    swapsBySymbol = 'swapsbysymbols',
    dividends = 'dividends',
}

export const TargetTemplateSwapsTypeList = [
    {
        id: 1,
        key: TargetTemplateTypeEnum.swapsByGroups,
        updaterLabel: 'Swaps Updater by Groups',
        title: 'Swaps by Groups',
    },
    {
        id: 2,
        key: TargetTemplateTypeEnum.swapsBySymbol,
        updaterLabel: 'Swaps Updater by Symbols',
        title: 'Swaps by Symbols',
    },
];

export const TargetTemplateTypeList = [
    ...TargetTemplateSwapsTypeList,
    { id: 3, key: TargetTemplateTypeEnum.dividends, updaterLabel: 'CFD Dividends Updater', title: 'Dividends' },
];

export const ConvertStringToTargetTemplateType = (value?: string | null): TargetTemplateTypeEnum | null => {
    if (!value || !isFilledString(value)) return null;
    const valueLower = value.toLowerCase().trim();
    if (!isInEnum(valueLower, TargetTemplateTypeEnum)) {
        return null;
    }
    if (valueLower === TargetTemplateTypeEnum.swapsByGroups) return TargetTemplateTypeEnum.swapsByGroups;
    if (valueLower === TargetTemplateTypeEnum.swapsBySymbol) return TargetTemplateTypeEnum.swapsBySymbol;
    if (valueLower === TargetTemplateTypeEnum.dividends) return TargetTemplateTypeEnum.dividends;
    return null;
};

export const renderTargetTemplateType = (value: TargetTemplateTypeEnum | string | null | undefined): string => {
    const targetRecord = TargetTemplateTypeList.find((item) => item.key === value?.trim().toLowerCase());
    return targetRecord?.title ?? '';
};

// export const getBEKeyTargetTemplateType = (value: TargetTemplateTypeEnum): string => {
//     switch (value) {
//         case TargetTemplateTypeEnum.swapsByGroups:
//             return 'swapsbygroups';
//         case TargetTemplateTypeEnum.swapsBySymbol:
//             return 'swapsbysymbols';
//         case TargetTemplateTypeEnum.dividends:
//             return 'dividends';
//         default:
//             return '';
//     }
// };
