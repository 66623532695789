import { makeAutoObservable } from 'mobx';
import { SettingsModel } from '@models/Settings';
import { API } from '@api/client/apiEndpoints';
import { Message, MessageType } from '@components/Message/Message';

export class SettingsStore {
    constructor() {
        makeAutoObservable(this);
    }

    isLoading: boolean = false;

    isLoadingBtn: boolean = false;

    data: SettingsModel = {} as SettingsModel;

    getData() {
        this.isLoading = true;
        API.settings
            .getSettings()
            .then(({ data }) => {
                this.setData(data);
            })
            .catch((e) => {
                Message(MessageType.error, e);
                Promise.reject(e);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    updateData(data: SettingsModel) {
        this.isLoadingBtn = true;
        API.settings
            .updateSettings(data)
            .then(() => {
                Message(MessageType.success, 'Changes were saved');
                this.getData();
            })
            .catch((e) => {
                Message(MessageType.error, e);
                Promise.reject(e);
            })
            .finally(() => {
                this.isLoadingBtn = false;
            });
    }

    setData(value: SettingsModel) {
        this.data = value;
    }

    reset() {
        this.data = {} as SettingsModel;
        this.isLoading = false;
    }
}

const settingsStore = new SettingsStore();
export { settingsStore };
