import { InteractionRequiredAuthError } from '@azure/msal-common';
import { getAccessTokenRequest, getMsalInstance } from '../services/auth-service';
import axios, {
    AxiosError,
    AxiosRequestConfig,
    AxiosResponse,
    CancelTokenSource as AxiosCancelTokenSource,
    AxiosStatic,
    AxiosInstance,
    Method,
} from 'axios';
import { internalOnErrorInterceptor } from './interceptors';
import { lsGetItem } from '@utils/hooks';

type FetchApiType = {
    <T>(config: AxiosRequestConfig): Promise<FetchApiResponse<T>>;
    get: <T>(endpoint: string, data?: any, config?: AxiosRequestConfig) => Promise<FetchApiResponse<T>>;
    post: <T>(endpoint: string, data?: any, config?: AxiosRequestConfig) => Promise<FetchApiResponse<T>>;
    put: <T>(endpoint: string, data?: any, config?: AxiosRequestConfig) => Promise<FetchApiResponse<T>>;
    delete: <T>(endpoint: string, data?: any, config?: AxiosRequestConfig) => Promise<FetchApiResponse<T>>;
};

export const getServerUrl = (): string => {
    // TODO: Rid of lsGetItem, when Change Server functionality will be deleted
    const serverUrl = lsGetItem('serverUrl');
    return serverUrl ? `https://${serverUrl}` : '';
};

export const { CancelToken } = axios;
export type CancelTokenSource = AxiosCancelTokenSource;
export const isFetchApiCancel = axios.isCancel;

export type FetchApiResponse<T> = AxiosResponse<T>;
export type FetchApiError<T> = AxiosError<T>;

const createFetchApiInstance = () => {
    const fetchApiInstance = axios.create();

    fetchApiInstance.interceptors.request.use(
        async (config) => {
            const accessToketRequest = await getAccessTokenRequest();

            try {
                let tokenResponse = await getMsalInstance().acquireTokenSilent(accessToketRequest);
                if (tokenResponse.accessToken) {
                    config.headers!['Authorization'] = 'Bearer ' + tokenResponse.accessToken;
                }
            } catch (error) {
                if (error instanceof InteractionRequiredAuthError) {
                    // fallback to interaction when silent call fails
                    return getMsalInstance().acquireTokenPopup(accessToketRequest);
                }
            }

            return config;
        },
        (error) => {
            return Promise.reject(error);
        },
    );

    fetchApiInstance.interceptors.response.use((response) => response, internalOnErrorInterceptor);

    return fetchApiInstance;
};

const fetchApiInstance = createFetchApiInstance();

const getFetchApiBaseParams = (config: AxiosRequestConfig): AxiosRequestConfig => {
    return {
        ...config,
        url: config.url,
    };
};

const getFetchApiParams = (
    method: Method,
    endpoint: string,
    data?: any,
    config?: AxiosRequestConfig,
): AxiosRequestConfig => ({
    method: method,
    url: `${getServerUrl()}${endpoint}`,
    data,
    ...config,
});

const buildFetchApi = (axiosInstance: AxiosInstance): AxiosStatic => {
    const fetchApiBase = <T>(config: AxiosRequestConfig): Promise<FetchApiResponse<T>> => {
        var params = getFetchApiBaseParams(config);
        return axiosInstance(params) as Promise<FetchApiResponse<T>>;
    };

    const fetchApiGet = <T>(endpoint: string, config?: AxiosRequestConfig): Promise<FetchApiResponse<T>> => {
        return fetchApiBase(getFetchApiParams('get', endpoint, config));
    };

    const fetchApiPost = <T>(
        endpoint: string,
        data?: any,
        config?: AxiosRequestConfig,
    ): Promise<FetchApiResponse<T>> => {
        return fetchApiBase(getFetchApiParams('post', endpoint, data, config));
    };

    const fetchApiDelete = <T>(
        endpoint: string,
        data?: any,
        config?: AxiosRequestConfig,
    ): Promise<FetchApiResponse<T>> => {
        return fetchApiBase(getFetchApiParams('delete', endpoint, data, config));
    };

    const fetchApiPut = <T>(
        endpoint: string,
        data?: any,
        config?: AxiosRequestConfig,
    ): Promise<FetchApiResponse<T>> => {
        return fetchApiBase(getFetchApiParams('put', endpoint, data, config));
    };

    return Object.assign(fetchApiBase, {
        get: fetchApiGet,
        post: fetchApiPost,
        put: fetchApiPut,
        delete: fetchApiDelete,
    }) as AxiosStatic;
};

const fetchApi: FetchApiType = buildFetchApi(fetchApiInstance);
export default fetchApi;
