import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Layout } from 'antd';
import { Sidebar } from '@components/Sidebar/sidebar';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import styles from './main.module.scss';
import logo from '@assets/icons/logo.svg';
import cn from 'classnames';
import { useNavigate } from 'react-router';
import { useMsal, useAccount } from '@azure/msal-react';
import { selectServerStore } from '@store/selectServer';
import { useReaction } from '@utils/hooks';
import { LogoutComponentIcon } from '@assets/iconsComponents/logoutComponent';
import { AppRoutes, UI_VERSION } from '@constants';
import { resetStore } from '@store/resetStore';

const { Header, Sider, Content } = Layout;

export const Main: React.FunctionComponent<{ children?: ReactElement[] | ReactElement }> = ({ children }) => {
    const { accounts, instance } = useMsal();
    const navigate = useNavigate();
    const [version] = useReaction<string>(() => selectServerStore.version);
    const serverUrl = localStorage.getItem('serverUrl');
    const [collapsed, setCollapsed] = useState(false);
    const [shouldAnimate, setShouldAnimate] = useState(!serverUrl);
    const account = useAccount(accounts[0]);
    const handleLogout = () => {
        instance.logoutRedirect();
        resetStore();
    };

    useEffect(() => {
        if (!serverUrl) {
            setCollapsed(true);
        } else {
            setCollapsed(false);
        }
    }, [serverUrl]);

    const name = account!.name!.split(' ')[0];

    return (
        <Layout>
            <Header className={styles.header}>
                <div className={styles.headerContent}>
                    <div className={styles.logo}>
                        <img alt="logo" className={styles.logo_image} src={logo} />
                        <div className={styles.logo_titleBlock}>
                            <p className={styles.logo_title}>FINSTEK</p>
                            <p className={styles.logo_subtitle}>Archon</p>
                        </div>
                        <div className={styles.leftSection}>
                            <p className={styles.leftSection_server}>
                                Server:{' '}
                                <span className={styles.leftSection_serverName}>
                                    {serverUrl !== null ? (
                                        JSON.stringify(serverUrl).replace(/['"]+/g, '').replace(/\\/g, '')
                                    ) : (
                                        <span style={{ fontStyle: 'italic', color: 'red' }}>
                                            Server is not selected
                                        </span>
                                    )}
                                </span>
                            </p>
                            <Button
                                key="back"
                                onClick={() => {
                                    navigate(AppRoutes.selectServer);
                                }}
                                type="link"
                                className={styles.footerButton}
                                style={{ color: '#c2edff' }}
                            >
                                Change Server
                            </Button>
                        </div>
                    </div>

                    <div className={styles.rightSection}>
                        <p className={styles.rightSection_welcome}>
                            Welcome, <span className={styles.rightSection_login}>{name}</span>
                        </p>
                        <div className={styles.logoutBlock}>
                            <Button
                                type="text"
                                onClick={handleLogout}
                                className={cn('iconBtn', styles.logoutBlock_icon)}
                            >
                                <LogoutComponentIcon />
                                <span className={styles.logoutBlock_text}>Logout</span>
                            </Button>
                        </div>
                    </div>
                </div>
            </Header>
            <Layout>
                <Sider
                    collapsedWidth={45}
                    width={window.innerWidth <= 992 ? 210 : 230}
                    theme="light"
                    collapsible
                    collapsed={collapsed}
                    onCollapse={() => {
                        setCollapsed(!collapsed);
                        if (!shouldAnimate) setShouldAnimate(true);
                    }}
                    trigger={
                        collapsed ? (
                            <MenuUnfoldOutlined />
                        ) : (
                            <div className={styles.footerBlock}>
                                <MenuFoldOutlined />
                                <div
                                    className={styles.versionBlock}
                                    style={shouldAnimate ? undefined : { animation: 'unset', opacity: 1 }}
                                >
                                    <p className={styles.versionText}>Frontend: {UI_VERSION}</p>
                                    <p className={styles.versionText}>Backend: {version}</p>
                                </div>
                            </div>
                        )
                    }
                    className={styles.sider}
                >
                    <Sidebar collapsed={collapsed} />
                </Sider>
                <Layout
                    style={{
                        padding: '0',
                    }}
                >
                    <Content className={styles.siteLayoutContent}>{children}</Content>
                </Layout>
            </Layout>
        </Layout>
    );
};
