import { TemplateModel } from '@models/Templates';
import { groupMaskValidations, SymbolMasksValidation } from '@pages/utils/maskValidations';
import * as yup from 'yup';

export const schema = (template: TemplateModel | null) =>
    yup.object().shape({
        symbolMasks: SymbolMasksValidation,
        groupMasks: yup
            .array()
            .label('Group Mask')
            .test('isValid', 'Must be less than 100 characters', (value) =>
                groupMaskValidations.maxLength(value, template?.type ?? '', template?.templateType ?? ''),
            )
            .test('isRequired', 'Required field', (value) =>
                groupMaskValidations.mandatory(value, template?.type ?? '', template?.templateType ?? ''),
            )
            .test('isValid', 'Duplicates are not allowed', (value) => groupMaskValidations.isDuplicates(value))
            .default([]),
        servers: yup
            .array()
            .label('Servers')
            .default([])
            .of(
                yup.object().shape({
                    serverId: yup.string().default(''),
                    serverName: yup.string().default(''),
                    enabled: yup.boolean().default(false),
                    available: yup.boolean().default(false),
                }),
            ),
    });
