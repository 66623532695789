export enum DaysType {
    monday = 'Monday',
    tuesday = 'Tuesday',
    wednesday = 'Wednesday',
    thursday = 'Thursday',
    friday = 'Friday',
}

export enum DaysTypesAll {
    monday = 'Monday',
    tuesday = 'Tuesday',
    wednesday = 'Wednesday',
    thursday = 'Thursday',
    friday = 'Friday',
    saturday = 'Saturday',
    sunday = 'Sunday',
    allWeek = 'All Week',
}

export enum DaysTypesAllCSV {
    monday = 'monday',
    tuesday = 'tuesday',
    wednesday = 'wednesday',
    thursday = 'thursday',
    friday = 'friday',
    saturday = 'saturday',
    sunday = 'sunday',
    allWeek = 'allweek',
}

export enum Types {
    byPoints = 'by points',
    byMoney = 'by money',
}

export enum TypesForFilter {
    byPoints = 'bypoints',
    byMoney = 'bymoney',
}

export const days = [
    { id: 1, Name: DaysType.monday },
    { id: 2, Name: DaysType.tuesday },
    { id: 3, Name: DaysType.wednesday },
    { id: 4, Name: DaysType.thursday },
    { id: 5, Name: DaysType.friday },
];
export const daysMT5 = [
    { id: 1, Name: DaysTypesAll.monday },
    { id: 2, Name: DaysTypesAll.tuesday },
    { id: 3, Name: DaysTypesAll.wednesday },
    { id: 4, Name: DaysTypesAll.thursday },
    { id: 5, Name: DaysTypesAll.friday },
    { id: 6, Name: DaysTypesAll.saturday },
    { id: 7, Name: DaysTypesAll.sunday },
    { id: 8, Name: DaysTypesAll.allWeek },
];
export const types = [
    { id: 1, Name: Types.byMoney },
    { id: 2, Name: Types.byPoints },
];
