import { Dispatch, SetStateAction } from 'react';

export enum OperationType {
    create = 'create',
    update = 'update',
    read = 'read',
}

export enum OperationTypeBtnTitle {
    create = 'Create',
    update = 'Update',
}

export type FormDataType = {
    type: OperationType;
};

export type FormContextType = {
    reset: () => void;
    formData: FormDataType | undefined;
    setFormData: Dispatch<SetStateAction<FormDataType | undefined>>;
    currentTab?: string;
};

export enum TargetTemplateType {
    swapsByGroups = 'swapsbygroups',
    swapsBySymbol = 'swapsbysymbols',
    dividends = 'dividends',
}

export enum MaskKeys {
    symbolMasks = 'symbolMasks',
    groupMasks = 'groupMasks',
}

export const maskLabels = {
    [MaskKeys.symbolMasks]: 'Symbol Mask',
    [MaskKeys.groupMasks]: 'Group Mask',
};

export enum TargetType {
    mt4 = 'mt4',
    mt5 = 'mt5',
    takerFix = 'fix',
}

export const targetTypesArr = [
    { id: 1, type: TargetType.mt4, title: 'MT4' },
    { id: 2, type: TargetType.mt5, title: 'MT5' },
    { id: 3, type: TargetType.takerFix, title: 'Taker FIX' },
];

export const tamplateTypesArr = [
    { id: 1, type: TargetTemplateType.swapsByGroups, title: 'Swaps Updater by Groups' },
    { id: 2, type: TargetTemplateType.swapsBySymbol, title: 'Swaps Updater by Symbols' },
    { id: 3, type: TargetTemplateType.dividends, title: 'CFD Dividends Updater' },
];

export enum HistoryTemplateType {
    MT4swapsByGroups = '1',
    MT5swapsByGroups = '2',

    FIXSwapsUpdated = '3',

    MT4swapsBySymbol = '6',
    MT5swapsBySymbol = '7',

    FIXAjustDividends = '4',
    Mt4AdjustDividends = '5',
    Mt5AdjustDividends = '8',
}

export enum SwapsType {
    byMoney = 'by money',
    byPoints = 'by points',
    byInterest = 'by interest',
    byMarginCurrency = 'by margin currency',
    bygroupcurrency = 'by group currency',
    byinterestcurrent = 'by interest current',
    byinterestopen = 'by interest open',
    bycloseprice = 'by close curpricerency',
    bybid = 'by bid',
    byprofitcurrency = 'by profit currency',
}

export enum SwapsResponseType {
    byMoney = 'bymoney',
    byPoints = 'bypoints',
    byInterest = 'byinterest',
    byMarginCurrency = 'bymargincurrency',
    bygroupcurrency = 'bygroupcurrency',
    byinterestcurrent = 'byinterestcurrent',
    byinterestopen = 'byinterestopen',
    bycloseprice = 'bycloseprice',
    bybid = 'bybid',
    byprofitcurrency = 'byprofitcurrency',
}

export enum SwapsResponseTypeUpperCase {
    byMoney = 'Bymoney',
    byPoints = 'Bypoints',
    byInterest = 'Byinterest',
    byMarginCurrency = 'Bymargincurrency',
    bygroupcurrency = 'Bygroupcurrency',
    byinterestcurrent = 'Byinterestcurrent',
    byinterestopen = 'Byinterestopen',
    bycloseprice = 'Bycloseprice',
    bybid = 'Bybid',
    byprofitcurrency = 'Byprofitcurrency',
}

export interface IFormData<EntityT> {
    type: OperationType;
    currentRow?: EntityT;
}

export interface IFormContext<EntityT> {
    reset: () => void;
    formData: IFormData<EntityT> | undefined;
    setFormData: Dispatch<SetStateAction<IFormData<EntityT> | undefined>>;
    currentTab?: string;
}

export function openCreateForm<EntityT>(
    context: IFormContext<EntityT>,
    setCurrentRow: Dispatch<number | null>,
    newRecord: EntityT,
) {
    setCurrentRow(null);
    context.setFormData({
        type: OperationType.create,
        currentRow: newRecord,
    });
}
