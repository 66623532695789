import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { TableExt } from '@components/TableExt/TableExt';
import { Button, Modal } from 'antd';
import styles from '../styles.module.scss';
import { useReaction } from '@utils/hooks';
import { TemplateModel, TemplateSwapModel } from '@models/Templates';
import { observer } from 'mobx-react';
import { columns } from '@pages/templateControllerDetails/SwapsTable/columns';
import { TableInfo } from './TableInfo';
import { updaterToolStore } from '@store/updaterToolStore';
import { SwapModal } from '@pages/templateControllerDetails/swapModal/SwapModal';
import { OperationTypeBtnTitle } from 'types/commonTypes';
import { TargetTemplateTypeEnum } from 'types/targetTemplateTypes';
import { TargetTypeEnum } from 'types/targetTypes';
import { Message, MessageType } from '@components/Message/Message';

type Props = {
    swaps: TemplateSwapModel[];
    setLocalSwaps: (val: TemplateSwapModel[]) => void;
    setIdsOfEditedRow: Dispatch<SetStateAction<number[]>>;
    idsOfEditedRow: number[];
};

export const SwapsTable: React.FC<Props> = observer(({ swaps, setLocalSwaps, setIdsOfEditedRow, idsOfEditedRow }) => {
    const [isLoading] = useReaction<boolean>(() => updaterToolStore.isLoading);
    const [template] = useReaction<TemplateModel | null>(() => updaterToolStore.template);
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const [idSwapToEdit, setIdSwapToEdit] = useState<number | null>(null);

    const handleOpenCreateSwapModal = () => {
        setIdSwapToEdit(null);
        setIsOpenModal(true);
    };

    const isShowTripleSwapDay = template ? template.templateType == TargetTemplateTypeEnum.swapsBySymbol : false;
    const isShowTypes = template
        ? template.templateType === TargetTemplateTypeEnum.swapsBySymbol && template.type !== TargetTypeEnum.takerFix
        : false;

    const renderToolbar = useCallback(
        () => [
            <div className={styles.buttonsGroup}>
                <Button
                    style={{ background: '#FCB70B', border: 'none' }}
                    key="update"
                    type="primary"
                    onClick={handleOpenCreateSwapModal}
                >
                    Create Symbol
                </Button>
            </div>,
        ],
        [swaps],
    );
    const onSubmitModal = ({ value, isNewRecord }: { value: TemplateSwapModel; isNewRecord: boolean }) => {
        if (isNewRecord) {
            setLocalSwaps([...swaps, value]);
        } else {
            setLocalSwaps([...swaps.map((swap) => (swap.id === value.id ? value : swap))]);
        }
        setIsOpenModal(false);
        setIdSwapToEdit(null);
        setIdsOfEditedRow((prev) => prev.concat(value.id));
    };

    const handleDeleteClick = (id: number) => {
        Modal.confirm({
            title: `The symbol will be deleted. This action cannot be undone.`,
            okText: 'Delete',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk: () => {
                const updatedSwap = swaps.filter((swap: TemplateSwapModel) => {
                    return swap.id !== id;
                });
                setLocalSwaps(updatedSwap);
                setIdsOfEditedRow((prev) => prev.concat(id));
            },
        });
    };

    const handleCancelModalClick = () => {
        setIsOpenModal(false);
        setIdSwapToEdit(null);
    };

    const handleEditClick = (id: number) => {
        const targetSwap = swaps.find((item) => item.id === id);
        if (targetSwap) {
            setIsOpenModal(true);
            setIdSwapToEdit(id);
        }
    };

    const rowClassNameHandler = (a: TemplateSwapModel) => {
        if (idsOfEditedRow.includes(a.id)) return 'editedRowTable';
        return '';
    };

    const saveFooterToolbar = () => {
        const onSave = () => {
            if (template === null) {
                Message(MessageType.error, 'Template is not set');
                return;
            } else {
                updaterToolStore
                    .saveTemplate({
                        ...template,
                        swaps,
                    })
                    .then(() => {
                        updaterToolStore.getTemplateById(template.id).then((result) => {
                            updaterToolStore.template = result;
                            setLocalSwaps(result.swaps);
                        });
                    });
                setTimeout(() => {
                    setIdsOfEditedRow([]);
                }, 500);
            }
        };
        return (
            <div className={styles.footerButtonSymbols}>
                <Button
                    size="middle"
                    type="primary"
                    htmlType="submit"
                    onClick={onSave}
                    // disabled={idsOfEditedRow.length === 0}
                >
                    Save Template
                </Button>
            </div>
        );
    };

    return (
        <>
            <TableExt<TemplateSwapModel>
                rowKey="id"
                titleJSX={() => <TableInfo template={template} />}
                columns={columns({ type: template?.type ?? null, templateType: template?.templateType ?? null })}
                loading={isLoading}
                dataSource={swaps}
                headerToolbar={renderToolbar}
                focusedRowEntityId={idSwapToEdit}
                rowClassNameHandler={rowClassNameHandler}
                actionColumn={{
                    title: 'Actions',
                    dataIndex: 'id',
                    fixed: false,
                    onEdit: (id) => handleEditClick(+id),
                    onDelete: (id) => handleDeleteClick(+id),
                }}
                footerBtnGroup={saveFooterToolbar}
            />
            {isOpenModal && template && (
                <SwapModal
                    onSubmit={onSubmitModal}
                    title={idSwapToEdit === null ? 'Create Symbol:' : 'Edit Symbol:'}
                    submitBtnTitle={idSwapToEdit === null ? OperationTypeBtnTitle.create : OperationTypeBtnTitle.update}
                    onCancel={handleCancelModalClick}
                    swaps={swaps}
                    idSwapToEdit={idSwapToEdit}
                    isShowTypes={isShowTypes}
                    isShowTripleSD={isShowTripleSwapDay}
                    template={template}
                />
            )}
        </>
    );
});
