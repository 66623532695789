import React, { useEffect } from 'react';
import { RightPanel } from '@components/RightPanelForm';
import { Control, FieldErrors, useForm, UseFormClearErrors, UseFormSetError, UseFormTrigger } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { OperationType } from 'types/commonTypes';
import { MaskType, PresetModel } from '@models/Preset';
import { schema } from './schema';
import { TemplateModel, TemplateSwapModel } from '@models/Templates';
import { CollapseHeader, RightPanelCard, RightPanelCollapse } from '@components/RightPanelForm/Groups';
import { useReaction } from '@utils/hooks';
import { updaterToolStore } from '@store/updaterToolStore';
import { ServerModel } from '@models/Server';
import { observer } from 'mobx-react';
import { DividendsRequestType, DividentType } from '@models/Dividents';
import { Servers } from './Servers';
import { TargetTypesList } from 'types/targetTypes';
import { ValidationMessage } from '@components/Form/ValidationMessage/ValidationMessage';
import { Mask } from '@pages/presets/presetsForm/Mask';
import styles from './styles.module.scss';

type Props = {
    localSwaps: TemplateSwapModel[];
};

export const PanelRight: React.FC<Props> = observer(({ localSwaps }) => {
    const [template] = useReaction<TemplateModel | null>(() => updaterToolStore.template);
    const [servers] = useReaction<ServerModel[]>(() => updaterToolStore.servers);
    const [isLoading] = useReaction<boolean>(() => updaterToolStore.isLoading);
    const [isUpdating] = useReaction<boolean>(() => updaterToolStore.isUpdating);

    const {
        handleSubmit,
        control,
        setError,
        setValue,
        clearErrors,
        trigger,
        watch,
        formState: { isDirty, isValid, errors },
    } = useForm<DividentType>({
        defaultValues: schema(template).cast({}),
        mode: 'all',
        resolver: yupResolver(schema(template)),
    });

    useEffect(() => {
        setValue(
            'servers',
            servers.map((server) => ({
                serverId: server.id,
                serverName: server.name,
                enabled: false,
                available: server.available,
            })),
        );
    }, [servers]);

    const formState = watch();

    const serverIds = formState.servers
        .filter((server) => server.enabled !== false)
        .filter((server) => server.available !== false)
        .map((server) => server.serverId);

    const runUpdating = () => {
        if (template) {
            updaterToolStore.isUpdating = true;
            const dividents: DividendsRequestType = {
                serverIds,
                symbolMasks: formState.symbolMasks,
                groupMasks: formState.groupMasks,
                swaps: localSwaps,
                templateId: template.id,
                templateName: template.name,
            };
            updaterToolStore.adjustDividents({ dividents, type: template.type }).finally(() => {
                updaterToolStore.isUpdating = false;
            });
        }
    };

    return (
        <>
            <RightPanel<PresetModel>
                entityTitle="SwapUpdaterPreset"
                name="Execution Options"
                type={OperationType.update}
                isOpen
                isValid={isValid && localSwaps.length > 0 && serverIds.length > 0}
                isTouched={isDirty}
                isOverlayEnabled={false}
                onSubmit={handleSubmit(runUpdating)}
                submitBtnTitle="Charge Dividends"
                isSubmitting={isUpdating}
            >
                <RightPanelCard isLoading={isLoading}>
                    {template && (
                        <>
                            <Mask
                                type={template.type}
                                templateType={template.templateType}
                                // @ts-ignore
                                trigger={trigger as UseFormTrigger<MaskType>}
                                // @ts-ignore
                                control={control as Control<MasksEditType>}
                                // @ts-ignore
                                clearErrors={clearErrors as UseFormClearErrors<MaskType>}
                                setError={setError as UseFormSetError<MaskType>}
                                errors={errors as FieldErrors<MaskType>}
                                symbolMasks={[]}
                                groupMasks={[]}
                            />
                        </>
                    )}
                </RightPanelCard>
                <RightPanelCard isLoading={isLoading} className={styles.serversContainer}>
                    <RightPanelCollapse
                        defaultOpened
                        className={styles.serverCollapse}
                        header={
                            <CollapseHeader
                                title={`${
                                    TargetTypesList.find((item) => item.key === template?.type)?.title ?? ''
                                } Servers`}
                                errors={errors}
                                required
                            />
                        }
                    >
                        <Servers control={control} trigger={trigger} />
                        <ValidationMessage message={errors.servers?.message} />
                    </RightPanelCollapse>
                </RightPanelCard>
            </RightPanel>
        </>
    );
});
