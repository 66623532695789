/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { CustomColumnType } from './types';
import { Filter, FilterIcon, getFlatDataIndex } from './RowFilters/utils';
import { CheckboxValuesT } from './RowFilters/types';
import styles from './TableExt.module.scss';
import { Moment } from 'moment';

export function renderColumns<ContextDataT>(
    columns: CustomColumnType<ContextDataT>[],
    firstAvailiableDateForCalendar: Moment | null,
): CustomColumnType<ContextDataT>[] {
    const renderedColumns: CustomColumnType<ContextDataT>[] = columns.map((column) => {
        let visible = false;
        return {
            ...column,
            filterDropdown: column.filterOptions
                ? (props: any) => {
                      visible = props.visible;
                      return (
                          <Filter
                              {...props}
                              options={column.filterOptions}
                              dataIndex={getFlatDataIndex(column.dataIndex)}
                              firstAvailiableDateForCalendar={firstAvailiableDateForCalendar}
                          />
                      );
                  }
                : undefined,

            filterIcon: column.filterOptions
                ? (props: any) => (
                      <FilterIcon
                          {...props}
                          dataIndex={getFlatDataIndex(column.dataIndex)}
                          isOpenFilterWindow={visible}
                          getFilterPreviewValue={column?.filterOptions?.getFilterPreviewValue}
                      />
                  )
                : undefined,
            render: (value, record, index) => {
                const renderOption = column.render ? column.render(value, record, index) : value;
                if (column.alignContent) {
                    return <div className={styles[`colContentAlign_${column.alignContent}`]}>{renderOption}</div>;
                }
                return renderOption;
            },
        };
    });
    return renderedColumns;
}

export const getFilterValuesFromConstants = (enumObj: any): CheckboxValuesT =>
    Object.keys(enumObj).map((key) => ({ title: enumObj[key], compareValue: +key }));

export const stringifyDataIndex = (dataIndex: string | string[]): string =>
    Array.isArray(dataIndex) ? dataIndex.join('.') : dataIndex;

export const longTextPrerender = (val: string | null | undefined) =>
    val && val.length > 55 ? `${val.slice(0, 55)}...` : val;
