import React, { FC } from 'react';
import styles from './FieldContainer.module.scss';
import cn from 'classnames';

type Props = {
    className?: string;
    readOnly?: boolean;
    children: React.ReactNode;
};

export const FieldContainer: FC<Props> = ({ children, className, readOnly }) => (
    <div className={cn(styles.container, readOnly ? styles.readOnly : undefined, className)}>{children}</div>
);
