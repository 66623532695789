/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Table } from 'antd';
import { CustomColumnType, FilterableTableProps, FilterValuesContextT } from '../types';
import { IFilterContext } from '../RowFilters/types';
import { renderColumns, stringifyDataIndex } from '../utils';
import { TableHeader } from '../TableHeader';
import { FilterValuesContext } from '../TableServerSide';
import styles from '../TableExt.module.scss';

function createFilterContext<ContextT>() {
    return React.createContext<IFilterContext<ContextT>>(null!);
}

export function TableExtInner<ContextDataT extends {}>(props: FilterableTableProps<ContextDataT>) {
    const Context = useMemo<React.Context<IFilterContext<ContextDataT>>>(() => createFilterContext<ContextDataT>(), []);
    const filterValues = useContext<FilterValuesContextT>(FilterValuesContext);
    const {
        columns,
        dragIcon,
        title,
        dataSource,
        focusedRowEntityId,
        onRefreshClick,
        dragConfirmationText,
        onRowMove,
        components,
        headerToolbar,
        rowKey = 'key',
        colsSettings,
        header,
        pagination,
        scroll,
        style,
        size = 'small',
        firstAvailiableDateForCalendar = null,
        onTableChange,
        onTableFiltersChange,
        expandable,
        rowClassNameHandler,
        ...restProps
    } = props;
    const renderedColumns = useMemo(
        () => renderColumns<ContextDataT>(columns, firstAvailiableDateForCalendar),
        [columns, firstAvailiableDateForCalendar],
    );

    const [tableColumns, setTableColumns] = useState<CustomColumnType<ContextDataT>[]>(renderedColumns);
    const [visibleColKeys, setVisibleColKeys] = useState<string[]>(
        columns.map((col) => stringifyDataIndex(col.dataIndex)),
    );

    const renderRowBody = useCallback(
        ({ className, trStyle, ...restRowProps }: any) => (
            <tr
                className={`${className} ${
                    restRowProps['data-row-key'] === focusedRowEntityId ? styles.rowFocused : ''
                }`}
                style={trStyle}
                {...restRowProps}
            />
        ),
        [focusedRowEntityId],
    );

    useEffect(() => {
        if (onTableFiltersChange) onTableFiltersChange(filterValues.values);
    }, [filterValues.values]);

    useEffect(() => {
        setTableColumns(renderColumns<ContextDataT>(columns, firstAvailiableDateForCalendar));
    }, [Context, columns, dragIcon, firstAvailiableDateForCalendar]);

    const getVisibleCols = useCallback(
        (cols: CustomColumnType<ContextDataT>[]): CustomColumnType<ContextDataT>[] => [
            ...cols.filter((col) => visibleColKeys.includes(stringifyDataIndex(col.dataIndex))),
        ],
        [visibleColKeys],
    );

    const tableHeader = () => {
        let toolbarElements: JSX.Element[] = [];

        if (headerToolbar) {
            toolbarElements = headerToolbar();
        }

        return (
            <TableHeader<ContextDataT>
                toolbar={toolbarElements}
                activeColumnsSetter={setVisibleColKeys}
                reorderColumns={setTableColumns}
                columns={tableColumns}
                updateData={onRefreshClick}
                title={title || ''}
                colsSettings={colsSettings}
            />
        );
    };

    return (
        <Context.Provider
            value={{
                data: dataSource,
            }}
        >
            <Table
                {...restProps}
                style={{ ...style, margin: style?.margin ?? '1.5rem' }}
                rowClassName={rowClassNameHandler}
                rowKey={rowKey}
                components={{
                    ...components,
                    body: components?.body || {
                        row: renderRowBody,
                    },
                }}
                dataSource={visibleColKeys.length ? dataSource : undefined}
                columns={getVisibleCols(tableColumns)}
                size="middle"
                onChange={(tablePagination, _, sorterResult, __) => {
                    onTableChange(tablePagination, sorterResult);
                }}
                scroll={scroll || { x: 800 }}
                title={header === false ? undefined : tableHeader}
                pagination={pagination}
                expandable={expandable}
            />
        </Context.Provider>
    );
}
