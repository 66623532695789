import { PresetModel } from '@models/Preset';
import { presetsStore } from '@store/presetsStore';
import { yupEntityNameValidationWithSpaces } from '@utils/yupValidations';
import { TargetTemplateTypeEnum } from 'types/targetTemplateTypes';
import { TargetTypeEnum } from 'types/targetTypes';
import * as yup from 'yup';

export const presetCreateSchema = yup.object().shape({
    name: yupEntityNameValidationWithSpaces
        .max(50, 'Must be less than 50 characters')
        .default('')
        .test('uniqueName', 'This name already exists', (value, { parent: { id } }) => {
            const templates: PresetModel[] = presetsStore.data;
            return !templates.find((m) => (m.name ?? '').toLowerCase() === value.toLowerCase() && m.id !== id);
        })
        .label('Name')
        .required('Required field'),
    type: yup.mixed().oneOf(Object.values(TargetTypeEnum)).nullable().default(null).required('Required field'),
    templateType: yup
        .mixed()
        .oneOf(Object.values(TargetTemplateTypeEnum))
        .nullable()
        .default(null)
        .required('Required field'),
    symbolMask: yup.array(yup.string()).default([]),
    groupMask: yup.array(yup.string()).default([]),
});
