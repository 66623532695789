import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import styles from './styles.module.scss';
import { ImportFinaltoForm } from './ImportFinaltoForm';
import { ImportFinaltoType } from '@models/Templates';
import { templatesControllerStore } from '@store/templatesControllerStore';
import { ValidationResultType } from '@models/ImportTemplates';
import { ValidatingModal } from './validatingModal';
import { useReaction } from '@utils/hooks';

export const ImportFinalto = () => {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const [isImporting] = useReaction<boolean>(() => templatesControllerStore.isImporting);
    const [validationResult, setValidationResult] = useState<ValidationResultType | null>(null);
    const [formValues, setFormValues] = useState<ImportFinaltoType | null>(null);

    const openHandler = () => {
        setIsOpenModal(true);
    };

    const closeHandler = () => {
        setIsOpenModal(false);
        setValidationResult(null);
        setFormValues(null);
    };

    const submitHandler = (values: ImportFinaltoType) => {
        setFormValues(values);
        templatesControllerStore
            .validateImportFinaltoCSV(values)
            .then((result) => {
                setValidationResult(result);
            })
            .catch(() => {
                setValidationResult(null);
            });
    };

    const createTemplateHandler = () => {
        if (formValues && validationResult) {
            setIsCreating(true);
            templatesControllerStore
                .createTemplate(formValues, validationResult?.swapsRaw)
                .then(() => {
                    closeHandler();
                    templatesControllerStore.getTemplatesAll();
                })
                .catch(() => {
                    setValidationResult(null);
                })
                .finally(() => {
                    setIsCreating(false);
                });
        }
    };

    return (
        <>
            <Button key="upload_csv_form" type="default" onClick={openHandler} className={styles.importFinaltoButton}>
                Import from Finalto to point
            </Button>
            {isOpenModal && (
                <Modal className={styles.modal} open onCancel={closeHandler} footer={null}>
                    <ImportFinaltoForm onSubmit={submitHandler} onCancel={closeHandler} isUploading={isImporting} />
                </Modal>
            )}

            {isOpenModal && validationResult && (
                <ValidatingModal
                    data={validationResult}
                    onClose={() => {
                        setValidationResult(null);
                    }}
                    onOk={createTemplateHandler}
                    isLoading={isCreating}
                    type={formValues?.type ?? null}
                    templateType={formValues?.templateType ?? null}
                />
            )}
        </>
    );
};
