/* eslint-disable @typescript-eslint/no-explicit-any */
import { isFilledString } from '@utils';
import { v4 } from 'uuid';
import { TemplateSwapModel } from './Templates';

export type ValidateErrorType = {
    id: string;
    value: string;
    description: string;
};

export type ValidationResultType = {
    swapsRaw: any;
    swapsParsed: TemplateSwapModel[];
    errorsParsed: ValidateErrorType[];
};

export const convertErrorToValidationSymbolInfoType = (error: any): ValidateErrorType => {
    const id = isFilledString(error.id) ? error.id : v4();
    const value = isFilledString(error.value) ? error.value : '';
    const description = isFilledString(error.description) ? error.description : '';
    return {
        id,
        value,
        description,
    };
};
