import React, { FC } from 'react';
import styles from './styles.module.scss';
import { FieldContainer } from '@components/Form/FieldContainer/FieldContainer';
import { PresetServerModifier, PresetServerModifierOpposite } from '@models/Preset';
import { Switch } from 'antd';
import { RowContainer } from '@components/Form/RowContainer/RowContainer';
import { v4 } from 'uuid';
import { ServerModifierEnum } from 'types/modifiers';

type Props = {
    value: PresetServerModifierOpposite;
    onChange: (val: PresetServerModifierOpposite) => void;
};

export const getDefaultOppositeModifier = (): PresetServerModifier => ({
    id: v4(),
    orderId: 1,
    type: ServerModifierEnum.opposite,
    name: ServerModifierEnum.opposite,
    shortName: '',
    data: {
        longEnabled: false,
        shortEnabled: false,
    } as PresetServerModifierOpposite,
});

export const Opposite: FC<Props> = ({ value, onChange }) => (
    <RowContainer className={styles.mb10}>
        <FieldContainer>
            <div className={styles.switchBlock}>
                <div className={styles.switchElement}>
                    <Switch
                        checked={value.shortEnabled}
                        onChange={(e) => {
                            onChange({ ...value, shortEnabled: e });
                        }}
                        size="small"
                        className={styles.switch}
                    />
                    <span className={styles.switchLabel} title="Short Enabled">
                        Short Enabled
                    </span>
                </div>
            </div>
        </FieldContainer>
        <FieldContainer>
            <div className={styles.switchBlock}>
                <div className={styles.switchElement}>
                    <Switch
                        checked={value.longEnabled}
                        onChange={(e) => {
                            onChange({ ...value, longEnabled: e });
                        }}
                        size="small"
                        className={styles.switch}
                    />
                    <span className={styles.switchLabel} title="Long Enabled">
                        Long Enabled
                    </span>
                </div>
            </div>
        </FieldContainer>
    </RowContainer>
);
