import React, { FC, useState } from 'react';
import { Control, Controller, FieldErrors, FieldNamesMarkedBoolean, FieldValues } from 'react-hook-form';
import { FieldContainer } from '@components/Form/FieldContainer/FieldContainer';
import { ValidationMessage } from '@components/Form/ValidationMessage/ValidationMessage';
import { PresetModel, PresetServer } from '@models/Preset';
import { CollapseHeader, RightPanelCard, RightPanelCollapse } from '@components/RightPanelForm/Groups';
import { OperationType } from 'types/commonTypes';
import { ModifierItemPreview } from './ModifierItemPreview';
import styles from './Modifiers.module.scss';
import { ModifiersModal } from './ModifiersModal/ModifiersModal';
import { TargetTypesList } from 'types/targetTypes';
import { ServerModel } from '@models/Server';

type Props = {
    control: Control<PresetModel>;
    errors: FieldErrors<FieldValues>;
    dirtyFields: FieldNamesMarkedBoolean<FieldValues>;
    isLoading: boolean;
    preset: PresetModel | null;
    servers: ServerModel[];
    validationMessage?: string;
};

export const Modifiers: FC<Props> = ({ control, errors, isLoading, preset, servers, validationMessage }) => {
    const [serverToEdit, setServerToEdit] = useState<{ id: string; name: string } | null>(null);

    if (isLoading) return <RightPanelCard isLoading={isLoading}>Loading...</RightPanelCard>;

    return (
        <RightPanelCollapse
            defaultOpened
            header={
                <CollapseHeader
                    title={`${TargetTypesList.find((item) => item.key === preset?.type)?.title ?? ''} Servers`}
                    errors={errors}
                    required
                />
            }
        >
            {validationMessage && <div className={styles.invalidServers}>{validationMessage}</div>}
            <FieldContainer>
                <Controller
                    control={control}
                    name="presetServers"
                    render={({ field: { value, onChange } }) => {
                        const targetServer = value.find((e) => e.id === serverToEdit?.id);

                        const changeStateHandler = (presetId: string, newState: boolean) => {
                            const newVal = [...value];
                            const targetPresetServerIndex = newVal.findIndex((e) => e.id === presetId);
                            if (targetPresetServerIndex !== -1) {
                                newVal[targetPresetServerIndex].state = newState;
                            }
                            onChange(newVal);
                        };

                        const deleteModifierHandler = (presetId: string, modId: string) => {
                            const newVal = [...value];
                            const targetPresetServerIndex = newVal.findIndex((e) => e.id === presetId);
                            if (targetPresetServerIndex !== -1) {
                                newVal[targetPresetServerIndex].presetServerModifiers = newVal[
                                    targetPresetServerIndex
                                ].presetServerModifiers
                                    .filter((e) => e.id !== modId)
                                    .sort((a, b) => (a.orderId ?? 0) - (b.orderId ?? 0))
                                    .map((item, index) => {
                                        item.orderId = index + 1;
                                        return item;
                                    });
                            }
                            onChange(newVal);
                        };

                        const changePresetServerHandler = (newPresetServer: PresetServer) => {
                            const newVal = [...value];
                            const targetPresetServerIndex = newVal.findIndex((e) => e.id === newPresetServer.id);
                            if (targetPresetServerIndex !== -1) {
                                newVal[targetPresetServerIndex] = newPresetServer;
                            }
                            onChange(newVal);
                            setServerToEdit(null);
                        };

                        const openEditHandler = ({ id, name }: { id: string; name: string }) => {
                            setServerToEdit({ id, name });
                        };
                        return (
                            <div className={styles.modifiers}>
                                {value.map((item) => (
                                    <ModifierItemPreview
                                        presetServer={item}
                                        key={item.id}
                                        onChangeState={(presetId, newState) => {
                                            changeStateHandler(presetId, newState);
                                        }}
                                        onDeleteModifier={(modId) => {
                                            deleteModifierHandler(item.id ?? '', modId);
                                        }}
                                        onOpenEdit={openEditHandler}
                                        servers={servers}
                                    />
                                ))}
                                {targetServer && (
                                    <ModifiersModal
                                        onApply={changePresetServerHandler}
                                        onCancel={() => setServerToEdit(null)}
                                        server={targetServer}
                                        mode={OperationType.create}
                                        serverName={serverToEdit?.name ?? 'Loading...'}
                                    />
                                )}
                            </div>
                        );
                    }}
                />
                <ValidationMessage message={errors.presetServers?.message} />
            </FieldContainer>
        </RightPanelCollapse>
    );
};
