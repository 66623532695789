/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { TableExt } from '@components/TableExt/TableExt';
import { Button, Modal } from 'antd';
import styles from '../editTemplate.module.scss';
import { useReaction } from '@utils/hooks';
import { TemplateModel, TemplateSwapModel } from '@models/Templates';
import { TargetTemplateTypeEnum } from 'types/targetTemplateTypes';
import { TargetTypeEnum } from 'types/targetTypes';
import { colsToExport, columns } from './columns';
import { SwapModal } from '../swapModal/SwapModal';
import { OperationTypeBtnTitle } from 'types/commonTypes';
import { observer } from 'mobx-react';
import { SaveCSV } from '@components/TableExt/ExportCSV/SaveCSV';
import { TemplatesControllerSwapsCSVRecord } from '@pages/templatesController/import/importCSV/csvUtils';
import { ImportCSV } from '@pages/templatesController/import/importCSV/ImportCSV';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { templatesControllerStore } from '@store/templatesControllerStore';
import { mergeTemplateSwaps } from '@utils/store';
import { EditForm } from '../editFrom/EditForm';
import { useNavigate } from 'react-router';
import { AppRoutes } from '@constants';
import { Message, MessageType } from '@components/Message/Message';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import cn from 'classnames';

type Props = {
    swaps: TemplateSwapModel[];
    setLocalData: (val: TemplateSwapModel[]) => void;
    setIdsOfEditedRow: Dispatch<SetStateAction<number[]>>;
    idsOfEditedRow: number[];
};

export const SwapsTable: React.FC<Props> = observer(({ swaps, setLocalData, setIdsOfEditedRow, idsOfEditedRow }) => {
    const [isLoading] = useReaction<boolean>(() => templatesControllerStore.isLoading);
    const [isSaving] = useReaction<boolean>(() => templatesControllerStore.isSaving);
    const [template] = useReaction<TemplateModel | null>(() => templatesControllerStore.template);
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const [idSwapToEdit, setIdSwapToEdit] = useState<number | null>(null);
    const navigate = useNavigate();

    const handleOpenCreateSwapModal = () => {
        setIdSwapToEdit(null);
        setIsOpenModal(true);
    };

    const isShowTripleSwapDay = template ? template.templateType == TargetTemplateTypeEnum.swapsBySymbol : false;
    const isShowTypes = template
        ? template.templateType === TargetTemplateTypeEnum.swapsBySymbol && template.type !== TargetTypeEnum.takerFix
        : false;

    const onSubmitImportCSV = (records: TemplatesControllerSwapsCSVRecord[]) => {
        setLocalData(
            mergeTemplateSwaps({
                swaps,
                csvRecords: records,
                templateType: template?.templateType,
            }),
        );
        // templatesControllerStore.updateSwapsFromCSV({ templateId: template.id, records });
    };

    const deleteHandler = () => {
        Modal.confirm({
            title: 'Template will be deleted. This action cannot be undone.',
            icon: <ExclamationCircleOutlined className={styles.circleIcon} />,
            okText: 'Delete',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk: () => {
                if (template && template.id) {
                    templatesControllerStore.deleteTemplate(template.id).then(() => {
                        navigate(AppRoutes.templatesController);
                    });
                } else {
                    Message(MessageType.error, 'Template id is not set');
                }
            },
        });
    };

    const renderToolbar = useCallback(
        () => [
            <div className={cn(styles.buttonsGroup, styles.buttonsGroupTableDetails)}>
                <Button
                    type="default"
                    danger
                    onClick={deleteHandler}
                    className={styles.deleteTemplateBtn}
                    disabled={!template}
                >
                    Delete Template
                </Button>
                {template && (
                    <div className={styles.importCSVBtnWrapper}>
                        <ImportCSV onSubmitImportCSV={onSubmitImportCSV} template={template} okText="Import Symbols" />
                        <ReactTooltip
                            id="tooltip-csvImport"
                            render={() => <span className={styles.csvToolTip}>Import</span>}
                            className="ant-tooltip-inner"
                            place="left"
                        />
                    </div>
                )}
                <div className={styles.exportCSVIcon}>
                    <SaveCSV<TemplateSwapModel>
                        getDataToExport={() =>
                            new Promise((resolve) =>
                                resolve({
                                    data: swaps,
                                    cols: colsToExport({
                                        type: template?.type ?? null,
                                        templateType: template?.templateType ?? null,
                                    }),
                                }),
                            )
                        }
                        tooltipText="Export"
                        fileName={template?.name ?? 'Symbols Template'}
                        successText="Symbols Template was exported"
                    />
                </div>

                <Button
                    style={{ background: '#FCB70B', border: 'none' }}
                    key="update"
                    type="primary"
                    onClick={handleOpenCreateSwapModal}
                >
                    Create Symbol
                </Button>
            </div>,
        ],
        [isShowTripleSwapDay, isShowTypes, swaps],
    );
    const onSubmitModal = ({ value, isNewRecord }: { value: TemplateSwapModel; isNewRecord: boolean }) => {
        if (isNewRecord) {
            setLocalData([...swaps, value]);
        } else {
            setIdsOfEditedRow((prev) => prev.concat(value.id));
            setLocalData([...swaps.map((swap) => (swap.id === value.id ? value : swap))]);
        }
        setIsOpenModal(false);
        setIdSwapToEdit(null);
    };

    const handleDeleteClick = (id: number) => {
        Modal.confirm({
            title: `The symbol will be deleted. This action cannot be undone.`,
            okText: 'Delete',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk: () => {
                const updatedSwap = swaps.filter((swap: TemplateSwapModel) => {
                    return swap.id !== id;
                });
                setLocalData(updatedSwap);
            },
        });
    };

    const handleCancelModalClick = () => {
        setIsOpenModal(false);
        setIdSwapToEdit(null);
    };

    const handleEditClick = (id: number) => {
        const targetSwap = swaps.find((item) => item.id === id);
        if (targetSwap) {
            setIsOpenModal(true);
            setIdSwapToEdit(id);
        }
    };

    const rowClassNameHandler = (a: TemplateSwapModel) => {
        if (idsOfEditedRow.includes(a.id)) return 'editedRowTable';
        return '';
    };

    return (
        <>
            <TableExt<TemplateSwapModel>
                rowKey="id"
                titleJSX={() => (
                    <EditForm
                        localSwaps={swaps}
                        setIdsOfEditedRow={setIdsOfEditedRow}
                        actions={renderToolbar}
                        setLocalData={setLocalData}
                    />
                )}
                columns={columns({ type: template?.type ?? null, templateType: template?.templateType ?? null })}
                loading={isLoading || isSaving}
                dataSource={swaps}
                focusedRowEntityId={idSwapToEdit}
                rowClassNameHandler={rowClassNameHandler}
                actionColumn={{
                    title: 'Actions',
                    dataIndex: 'id',
                    fixed: false,
                    onEdit: (id) => handleEditClick(+id),
                    onDelete: (id) => handleDeleteClick(+id),
                }}
            />
            {isOpenModal && template && (
                <SwapModal
                    onSubmit={onSubmitModal}
                    title={idSwapToEdit === null ? 'Create Symbol:' : 'Edit Symbol:'}
                    submitBtnTitle={idSwapToEdit === null ? OperationTypeBtnTitle.create : OperationTypeBtnTitle.update}
                    onCancel={handleCancelModalClick}
                    swaps={swaps}
                    idSwapToEdit={idSwapToEdit}
                    isShowTypes={isShowTypes}
                    isShowTripleSD={isShowTripleSwapDay}
                    template={template}
                />
            )}
        </>
    );
});
