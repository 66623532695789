import { baseSorter } from '@components/TableExt/RowFilters/utils';
import { CustomColumnType } from '@components/TableExt/types';
import { BaseTemplateModel } from '@models/Templates';
import { renderTargetTemplateType, TargetTemplateTypeList } from 'types/targetTemplateTypes';
import { renderTargetType, TargetTypesList } from 'types/targetTypes';

const tableColKeys = {
    name: 'name',
    type: 'type',
    templateType: 'templateType',
};

export const columns: CustomColumnType<BaseTemplateModel>[] = [
    {
        title: 'Name',
        dataIndex: tableColKeys.name,
        align: 'left',
        filterOptions: { type: 'Search' },
        sorter: (a, b) => {
            return baseSorter(a.name, b.name);
        },
    },
    {
        title: 'Server Type',
        dataIndex: tableColKeys.type,
        render: renderTargetType,
        sorter: (a, b) => baseSorter(renderTargetType(a.type), renderTargetType(b.type)),
        align: 'left',
        filterOptions: {
            type: 'Checkbox',
            getFilterPreviewValue: (value) => {
                if (Array.isArray(value)) {
                    return value.map((item) => renderTargetType(item)).join(', ');
                }
                return value?.toString() ?? '';
            },
            checkboxValues: () =>
                TargetTypesList.map((item) => ({
                    compareValue: item.key,
                    title: item.title,
                })),
        },
    },
    {
        title: 'Template Type',
        dataIndex: tableColKeys.templateType,
        render: renderTargetTemplateType,
        sorter: (a, b) =>
            baseSorter(renderTargetTemplateType(a.templateType), renderTargetTemplateType(b.templateType)),
        align: 'left',
        filterOptions: {
            type: 'Checkbox',
            getFilterPreviewValue: (value) => {
                if (Array.isArray(value)) {
                    return value.map((item) => renderTargetTemplateType(item)).join(', ');
                }
                return value?.toString() ?? '';
            },
            checkboxValues: () =>
                TargetTemplateTypeList.map((item) => ({
                    compareValue: item.key,
                    title: item.title,
                })),
        },
    },
];
