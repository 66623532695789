/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useContext, useState } from 'react';
import { Button, DatePicker } from 'antd';
import { FilterPropsRangeDate } from './types';
import moment from 'moment';
import { typedMemo } from '../../../utils';
import { IDataPicker } from '../types';
import { FilterValuesContext } from '../TableExt';
import { FORMAT_DT } from '@utils/datetime';
import styles from './RowFilters.module.scss';
import ButtonGroup from 'antd/lib/button/button-group';

export const DataPickerFilter = typedMemo((props: FilterPropsRangeDate) => {
    const filterValues = useContext(FilterValuesContext);
    const [value, setValue] = useState<IDataPicker>(
        filterValues.values[props.dataIndex] ? (filterValues.values[props.dataIndex] as IDataPicker) : { from: null },
    );
    const onTimeChange = (rangeValue: any) => {
        setValue({ from: rangeValue });
    };
    const onFilter = () => {
        filterValues.setValues({
            ...filterValues.values,
            [props.dataIndex]: !value.from ? null : value,
        });
        props.confirmCallback();
    };
    // const datesAreOnSameDay = (selectedTime: any, userCurrentTime: any) =>
    //     moment(selectedTime).format('YYYY.MM.DD') === moment(userCurrentTime).format('YYYY.MM.DD');
    const onReset = () => {
        filterValues.setValues({ ...filterValues.values, [props.dataIndex]: null });
        setValue({ from: null });
        props.confirmCallback();
    };
    // const range = (start: any, end: any) => {
    //     const result = [];
    //     for (let i = start; i < end; i++) {
    //         result.push(i);
    //     }
    //     return result;
    // };
    // const currentTimeUTC = moment.utc();
    // const disabledTime = (val: any) => ({
    //     disabledHours: () =>
    //         datesAreOnSameDay(val, currentTimeUTC) ? range(currentTimeUTC.toDate().getHours() + 1, 24) : null,
    //     disabledMinutes: () =>
    //         datesAreOnSameDay(val, currentTimeUTC) ? range(currentTimeUTC.toDate().getMinutes() + 1, 60) : null,
    // });
    const today = moment().utc();
    // const isDisabledFutureTime = true;

    const disabledDate = useCallback(
        (d: any) => {
            if (d == null) {
                return null;
            }
            return (
                (props.firstAvailiableDateForCalendar != null &&
                    d.isBefore(props.firstAvailiableDateForCalendar, 'day') &&
                    !d.isSame(props.firstAvailiableDateForCalendar, 'day')) ||
                (today != null && d.isAfter(today, 'day') && !d.isSame(today, 'day'))
            );
        },
        [props.firstAvailiableDateForCalendar, today],
    );
    const getDateRangeFooter = () => (
        <div>
            <ButtonGroup className={styles.timePicker}>
                <Button
                    type="link"
                    onClick={() => {
                        setValue({ from: moment().startOf('day') });
                    }}
                >
                    Today
                </Button>
                <Button
                    type="link"
                    onClick={() => {
                        setValue({ from: moment().add(-1, 'days').startOf('day') });
                    }}
                >
                    Yesterday
                </Button>
            </ButtonGroup>
        </div>
    );
    return (
        <div className={styles.wrapper}>
            <DatePicker
                showTime
                format={FORMAT_DT}
                onChange={onTimeChange}
                value={value.from ? moment(value.from) : null}
                onOk={onFilter}
                renderExtraFooter={getDateRangeFooter}
                showMinute
                showNow={false}
                onSelect={onTimeChange}
                disabledDate={disabledDate}
                // disabledTime={isDisabledFutureTime && disabledTime}
            />
            <div className={styles.btnBlock}>
                <Button type="primary" onClick={onReset} size="small" className={styles.button}>
                    Reset
                </Button>
            </div>
        </div>
    );
});
