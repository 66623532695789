/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from 'react';
import { get } from 'lodash';
import { FilterFilled } from '@ant-design/icons';
import {
    CustomColumnType,
    FilterOptionsT,
    FilterValuesContextT,
    FilterValuesT,
    FilterValueType,
    IDataPicker,
    IRangeInputD,
    IRangeInputN,
} from '../types';
import { IComparePare, RowFilterRangeNum } from './RowFilterRangeNum';
import { RowFilterCheckbox } from './RowFilterCheckbox';
import { RowFilterSearch } from './RowFilterSearch';
import { FilterValuesContext } from '../TableServerSide';
import { Popover, Tooltip } from 'antd';
import { getDateSeconds, timeToNanoseconds } from '@utils/datetime';
import { Moment } from 'moment';
import { DataPickerFilter } from './DataPickerFilter';
import styles from './RowFilters.module.scss';
import TextArea from 'antd/lib/input/TextArea';
import { textAreaAutoSize } from '@constants/inputs';
import Big from 'big.js';
import { isValidNumber } from '@utils';

export const getFlatDataIndex = (dataIndex: string | string[]): string =>
    typeof dataIndex === 'string' ? dataIndex : dataIndex.join('.');

function filterSearch<ContextDataT>(
    data: ContextDataT[],
    dataIndex: string,
    value: string,
    caseSensitive?: boolean,
    searchDataFormatter?: any,
) {
    return data.filter((el) => {
        const elValue = get(el, dataIndex);
        if (elValue === undefined) {
            return false;
        }
        let colValue = (searchDataFormatter ? searchDataFormatter(elValue, el) : elValue).toString();
        colValue = caseSensitive ? colValue : colValue.toLowerCase();
        const filterValue = caseSensitive ? value : value.toLowerCase();
        return colValue.toString().includes(filterValue);
    });
}

function filterRangeDate<ContextDataT>(
    data: ContextDataT[],
    dataIndex: string,
    value: IRangeInputD,
    searchDataFormatter?: any,
    customFilter?: (data: ContextDataT[], value: IRangeInputD) => ContextDataT[],
) {
    if (customFilter) return customFilter(data, value);

    return data.filter((el) => {
        const elValue = get(el, dataIndex);
        if (elValue === undefined || elValue === null) {
            return false;
        }
        const colValue = searchDataFormatter ? searchDataFormatter(elValue, el) : elValue;
        return (
            getDateSeconds(colValue) >= getDateSeconds(value.from) &&
            getDateSeconds(colValue) <= getDateSeconds(value.to)
        );
    });
}

function filterRangeTime<ContextDataT>(
    data: ContextDataT[],
    dataIndex: string,
    value: IRangeInputD,
    searchDataFormatter?: any,
    customFilter?: (data: ContextDataT[], value: IRangeInputD) => ContextDataT[],
) {
    if (customFilter) return customFilter(data, value);

    return data.filter((el) => {
        const elValue = get(el, dataIndex);
        if (elValue === undefined || elValue === null) {
            return false;
        }
        const colValue = searchDataFormatter ? searchDataFormatter(elValue, el) : elValue;
        const fromTimeNS = value.from ? timeToNanoseconds(value.from) ?? 0 : 0;
        const toTimeNS = value.to ? timeToNanoseconds(value.to) ?? 0 : 0;
        return colValue >= fromTimeNS && colValue <= toTimeNS;
    });
}

function filterRangeNumber<ContextDataT>(
    data: ContextDataT[],
    dataIndex: string,
    value: IRangeInputN,
    searchDataFormatter?: any,
) {
    const comparePair: IComparePare = {
        from: value.from === null ? Big(Number.MIN_SAFE_INTEGER) : value.from,
        to: value.to === null ? Big(Number.MAX_SAFE_INTEGER) : value.to,
    };
    return data.filter((el) => {
        const colValue = searchDataFormatter ? searchDataFormatter(get(el, dataIndex), el) : get(el, dataIndex);
        let colValString = colValue ? colValue.toString() : '';
        if (!isValidNumber(+colValString)) colValString = '';
        return (
            (comparePair.from !== null && !!colValString ? comparePair.from.lte(Big(colValString)) : true) &&
            (comparePair.to !== null && !!colValString ? comparePair.to.lte(Big(colValString)) : true)
        );
    });
}
function filterDataPicker<ContextDataT>(
    data: ContextDataT[],
    dataIndex: string,
    value: IDataPicker,
    searchDataFormatter?: any,
    customFilter?: (data: ContextDataT[], value: IDataPicker) => ContextDataT[],
) {
    if (customFilter) return customFilter(data, value);
    return data;
}
function filterCheckbox<ContextDataT>(
    data: ContextDataT[],
    dataIndex: string,
    value: string[],
    searchDataFormatter?: any,
) {
    return data.filter((el) => {
        const colValue = searchDataFormatter ? searchDataFormatter(get(el, dataIndex), el) : get(el, dataIndex);
        if (Array.isArray(colValue)) {
            return value.filter((v) => colValue.includes(v)).length > 0;
        }
        return value.includes(colValue);
    });
}

export function filterAll<ContextDataT>(
    data: ContextDataT[],
    filterValues: FilterValuesT,
    columns: CustomColumnType<ContextDataT>[],
) {
    let local = data;
    const localColumns: any = {};
    columns.forEach((col) => {
        localColumns[getFlatDataIndex(col.dataIndex)] = col;
    });
    Object.keys(filterValues).forEach((key) => {
        if (filterValues[key]) {
            const colData = localColumns[key];
            if (colData) {
                switch (colData.filterOptions?.type) {
                    case 'Search':
                        local = filterSearch(
                            local,
                            key,
                            filterValues[key] as string,
                            colData.filterOptions.caseSensitive,
                            colData.filterOptions.searchDataFormatter,
                        );
                        break;
                    case 'Checkbox':
                        local = filterCheckbox(
                            local,
                            key,
                            filterValues[key] as string[],
                            colData.filterOptions.searchDataFormatter,
                        );
                        break;
                    case 'RangeDate':
                        local = filterRangeDate(
                            local,
                            key,
                            filterValues[key] as IRangeInputD,
                            colData.filterOptions.searchDataFormatter,
                            colData.filterOptions.customFilter,
                        );
                        break;
                    case 'RangeTime':
                        local = filterRangeTime(
                            local,
                            key,
                            filterValues[key] as IRangeInputD,
                            colData.filterOptions.searchDataFormatter,
                            colData.filterOptions.customFilter,
                        );
                        break;
                    case 'RangeNum':
                        local = filterRangeNumber(
                            local,
                            key,
                            filterValues[key] as IRangeInputN,
                            colData.filterOptions.searchDataFormatter,
                        );
                        break;
                    case 'DataPicker':
                        local = filterDataPicker(
                            local,
                            key,
                            filterValues[key] as IDataPicker,
                            colData.filterOptions.searchDataFormatter,
                            colData.filterOptions.customFilter,
                        );
                        break;
                    default:
                        break;
                }
            }
        }
    });
    return local;
}

export function Filter(props: {
    options: FilterOptionsT;
    dataIndex: string;
    confirm: () => void;
    firstAvailiableDateForCalendar: Moment | null;
}) {
    switch (props.options.type) {
        case 'Checkbox':
            return (
                <RowFilterCheckbox
                    values={props.options.checkboxValues ? props.options.checkboxValues() : []}
                    dataIndex={props.dataIndex}
                    allOption={props.options.allOption}
                    confirmCallback={props.confirm}
                />
            );
        case 'DataPicker':
            return (
                <DataPickerFilter
                    dataIndex={props.dataIndex}
                    firstAvailiableDateForCalendar={props.firstAvailiableDateForCalendar}
                    confirmCallback={props.confirm}
                />
            );
        case 'RangeNum':
            return <RowFilterRangeNum dataIndex={props.dataIndex} confirmCallback={props.confirm} />;
        case 'Search':
            return (
                <RowFilterSearch
                    dataIndex={props.dataIndex}
                    caseSensitive={props.options.caseSensitive}
                    confirmCallback={props.confirm}
                />
            );
        default:
            return null;
    }
}

export function FilterIcon({
    dataIndex,
    isOpenFilterWindow,
    getFilterPreviewValue,
}: {
    dataIndex: string;
    isOpenFilterWindow: boolean;
    getFilterPreviewValue?: (rawValue: FilterValueType) => string;
}) {
    const filterValues = useContext<FilterValuesContextT>(FilterValuesContext);
    const filterValue = filterValues.values[dataIndex];
    const value: string = getFilterPreviewValue ? getFilterPreviewValue(filterValue) : (filterValue as string);
    const timeValue: { from: Moment } = filterValues.values[dataIndex] as unknown as { from: Moment };
    const content = (
        <div className={styles.filterModal}>
            Filtered by:
            <TextArea
                disabled
                autoSize={textAreaAutoSize}
                placeholder={
                    dataIndex !== 'timestamp'
                        ? value
                        : timeValue
                          ? timeValue.from
                              ? timeValue.from.format('YYYY-MM-DD')
                              : undefined
                          : undefined
                }
            />
        </div>
    );

    const isFilledFilter = value ? (dataIndex === 'timestamp' ? !!timeValue?.from : !!value) : false;

    if (isFilledFilter && !isOpenFilterWindow) {
        return (
            <Popover content={content} placement="left">
                <FilterFilled style={isFilledFilter ? { color: '#1890ff' } : {}} />
            </Popover>
        );
    }
    return (
        <Tooltip title="Click to filter" placement="left">
            <FilterFilled style={isFilledFilter ? { color: '#1890ff' } : {}} />
        </Tooltip>
    );
}

export const baseSorter = (
    a: Date | Big | number | string | null | boolean | undefined,
    b: Date | Big | number | string | null | boolean | undefined,
): number => {
    if (typeof a === 'boolean' && typeof b === 'boolean') {
        return a > b ? 1 : -1;
    }
    if (typeof a === 'number' && typeof b === 'number') {
        return a > b ? 1 : -1;
    }
    if (a instanceof Date || b instanceof Date) {
        const aString = a instanceof Date ? a.getTime().toString() : '';
        const bString = b instanceof Date ? b.getTime().toString() : '';
        return aString > bString ? 1 : -1;
    }
    const aString = (a ?? '').toString();
    const bString = (b ?? '').toString();
    return aString.localeCompare(bString);
};

export const longTextPrerender = (val: string | null | undefined) =>
    val && val.length > 30 ? `${val.slice(0, 30)}...` : val;
