/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Collapse, Space, Modal } from 'antd';
// import { Reject } from '@api/fbe/core';

const { Panel } = Collapse;

export async function userCatchError<T>(
    work: (() => Promise<T>) | (() => T) | Promise<T>,
    finallyDo?: () => void,
    params: {
        about?: string;
    } = {},
): Promise<T> {
    const { about } = params;

    const handleError = (err: unknown) => {
        let errorText = '';
        let developerInfo = '';

        // if (err instanceof Reject) {
        //     errorText = err.Reason;
        //     developerInfo = JSON.stringify(err, null, 2);
        // } else {
        errorText = `${err}`;
        developerInfo = (typeof err === 'object' && (err as any).stack) || /* old opera */ (err as any).stacktrace;
        // }

        const { destroy } = Modal.error({
            title: `Error`,
            // icon: <ExclamationCircleOutlined style={{ zIndex: 2 }} />,
            content: (
                <>
                    {about && <p>{about}</p>}
                    {errorText && <p>{errorText}</p>}
                    {developerInfo && (
                        <Space direction="vertical">
                            <Collapse collapsible="header">
                                <Panel header="Developer info" key="developerInfo">
                                    <pre>{developerInfo}</pre>
                                </Panel>
                            </Collapse>
                        </Space>
                    )}
                </>
            ),
            okText: 'Ok',
            onOk: async () => {
                destroy();
            },
        });
    };

    try {
        const result = await (typeof work === 'function' ? work() : work);
        if (finallyDo) finallyDo();
        return result;
    } catch (err) {
        handleError(err);
        if (finallyDo) finallyDo();
        throw err;
    }
}
