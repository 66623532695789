/* eslint-disable @typescript-eslint/no-explicit-any */
import { MAX_APP_NUMBER_INT, MIN_APP_NUMBER_INT, NUM_INPUT_PRECISION } from '@constants/inputs';
import { isValidNumber } from '@utils';
import Big from 'big.js';

export const printNumber = (num: Big | number | string | undefined | null): string => {
    if (num instanceof Big || ((typeof num === 'number' || typeof num === 'string') && isValidNumber(+num))) {
        var bigNum = new Big(num);
        var decimalPlaces = bigNum.c.length - bigNum.e - 1;
        if (decimalPlaces < 0) return bigNum.toFixed();
        return bigNum.toFixed(decimalPlaces);
    }
    return '';
};

const avoidNotation = (numIn: string): string => {
    if (!numIn) return '';
    if (numIn === 'NaN' || numIn === 'undefined') return '';
    numIn += ''; // To cater to numric entries
    let sign = ''; // To remember the number sign
    if (numIn.charAt(0) === '-') {
        numIn = numIn.substring(1);
        sign = '-';
    } // remove - sign & remember it
    let str = numIn.split(/[eE]/g); // Split numeric string at e or E
    if (str.length < 2) return sign + numIn; // Not an Exponent Number? Exit with orginal Num back
    const power = Number(str[1]); // Get Exponent (Power) (could be + or -)

    // const deciSp = (1.1).toLocaleString().substring(1, 2); // Get Deciaml Separator
    const deciSp = '.'; // Get Deciaml Separator
    str = str[0].split(deciSp); // Split the Base Number into LH and RH at the decimal point
    let baseRH = str[1] || '', // RH Base part. Make sure we have a RH fraction else ""
        baseLH = str[0]; // LH base part.

    if (power >= 0) {
        // - Positive Exponents (Process the RH Base Part)
        if (power > baseRH.length) baseRH += '0'.repeat(power - baseRH.length); // Pad with "0" at RH
        baseRH = baseRH.slice(0, power) + deciSp + baseRH.slice(power); // Insert decSep at the correct place into RH base
        if (baseRH.charAt(baseRH.length - 1) === deciSp) baseRH = baseRH.slice(0, -1); // If decSep at RH end? => remove it
    } else {
        // - Negative exponents (Process the LH Base Part)
        const num = Math.abs(power) - baseLH.length; // Delta necessary 0's
        if (num > 0) baseLH = '0'.repeat(num) + baseLH; // Pad with "0" at LH
        baseLH = baseLH.slice(0, power) + deciSp + baseLH.slice(power); // Insert "." at the correct place into LH base
        if (baseLH.charAt(0) === deciSp) baseLH = '0' + baseLH; // If decSep at LH most? => add "0"
    }
    // Rremove leading and trailing 0's and Return the long number (with sign)
    return sign + (baseLH + baseRH).replace(/^0*(\d+|\d+\.\d+?)\.?0*$/, '$1');
};

// export const getCuttedNumber = (value: number, precision: number): number => {
//     if (precision < 1 || !Number.isInteger(precision) || !Number.isFinite(value)) return value;
//     const shift = (val: any, exponent: any) => {
//         val = `${val}e`.split('e');
//         return +`${val[0]}e${+val[1] + (exponent || 0)}`;
//     };
//     const n = shift(value, +precision);
//     return shift(Math.trunc(n), -precision);
// };

export const getRoundedNumber = (value: number, precision: number): number => {
    if (precision < 1 || !Number.isInteger(precision) || !Number.isFinite(value)) return value;
    return Math.round((value + Number.EPSILON) * 10 ** precision) / 10 ** precision;
};

export const formatNumber = (
    value: Big | number | string | undefined | null,
    precision: number = NUM_INPUT_PRECISION,
    placeholder: string = '',
): string => {
    if (value instanceof Big) {
        return printNumber(value);
    }
    if (typeof value === 'number' && Number.isFinite(value)) {
        return printNumber(avoidNotation(getRoundedNumber(value, precision).toString()));
    }
    if (typeof value === 'string' && !!value) {
        return printNumber(avoidNotation(getRoundedNumber(+value, precision).toString()));
    }

    return placeholder;
};

export const numberInputFormatter = (
    value: number | string | undefined,
    info: {
        userTyping: boolean;
        input: string;
    },
): string => {
    if (value === undefined || value === '') return '';
    if ((value === '0' || value === 0) && info.input === '-0') {
        return '-0';
    }
    if (info.input === '-0') {
        return '0';
    }
    if (info.input === '-0.' || info.input === '-0,') {
        return '0';
    }
    return `${value}`.replace(',', '.');
};

export const inputNumberKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // @ts-ignore
    const inputValue = e?.currentTarget?.value ?? e?.target?.value ?? '';

    if (e.key === '.') {
        if (inputValue.includes('.')) {
            e.preventDefault();
            return;
        }
    }
    if (e.key === '-') {
        if (inputValue.startsWith('-') || inputValue.includes('-')) {
            e.preventDefault();
            return;
        }
    }

    // Allow: backspace, delete, tab, escape, enter and .
    if (
        [46, 8, 9, 27, 13, 110, 190].includes(e.keyCode) ||
        // Allow: Ctrl+A,Ctrl+C,Ctrl+V, Command+A
        ([65, 86, 67].includes(e.keyCode) && (e.ctrlKey === true || e.metaKey === true)) ||
        // Allow: home, end, left, right, down, up
        (e.keyCode >= 35 && e.keyCode <= 40)
    ) {
        // let it happen, don't do anything
        return;
    }

    if (
        !/[0-9]/.test(e.key) &&
        e.key !== '-' &&
        e.key !== '.' &&
        e.key !== 'Backspace' &&
        e.key !== 'Delete' &&
        e.key !== 'Enter' &&
        e.key !== 'ArrowLeft' &&
        e.key !== 'ArrowRight' &&
        e.key !== 'ArrowUp' &&
        e.key !== 'ArrowDown' &&
        e.key !== 'Home' &&
        e.key !== 'End' &&
        e.key !== 'PageUp' &&
        e.key !== 'PageDown' &&
        e.key !== 'Control' &&
        e.key !== 'Tab'
    ) {
        e.preventDefault();
        return;
    }
    if (inputValue) {
        try {
            if (
                (Big(inputValue).gt(0) &&
                    inputValue.length > MAX_APP_NUMBER_INT.toString().length + NUM_INPUT_PRECISION + 1) ||
                (Big(inputValue).lt(0) &&
                    inputValue.length > MIN_APP_NUMBER_INT.toString().length + NUM_INPUT_PRECISION + 1)
            ) {
                e.preventDefault();
                return;
            }
        } catch (error) {
            return;
        }
    }
};
