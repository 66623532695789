/* eslint-disable @typescript-eslint/no-explicit-any */

import { TemplateSwapModel } from '@models/Templates';
import { TemplatesControllerSwapsCSVRecord } from '@pages/templatesController/import/importCSV/csvUtils';
import Big from 'big.js';
import { TargetTemplateTypeEnum } from 'types/targetTemplateTypes';

export const getServerUtils = (api: any, template: any, type: any, setTemplate: any, updatedObj: any, swaps: any) => {
    api(type).then((res: any) => {
        const ids = res.data.map((e: any) => e.id);
        const serversNames = res.data.map((item: any, index: number) => {
            return {
                id: index,
                name: item.name,
                enabled: false,
                serverId: item.id,
            };
        });
        setTemplate(template, serversNames);
        updatedObj = {
            ...updatedObj,
            serversId: ids,
            swaps,
        };
    });
};

export const mergeTemplateSwaps = ({
    swaps,
    csvRecords,
}: {
    swaps: TemplateSwapModel[];
    csvRecords: TemplatesControllerSwapsCSVRecord[];
    templateType: TargetTemplateTypeEnum | undefined;
}): TemplateSwapModel[] => {
    const convert = (val: TemplatesControllerSwapsCSVRecord): TemplateSwapModel => ({
        id: 0,
        symbol: val.symbol ?? '',
        shortSwap: val.shortswap ?? Big(0),
        longSwap: val.longswap ?? Big(0),
        type: val.type,
        tripleSwapDay: val.tripleswapday,
        swapsEnabled: true,
    });
    const convertedCSVRecords = csvRecords.map(convert);
    swaps.map((swap) => {
        const index = convertedCSVRecords.findIndex((e) => e.symbol === swap.symbol);
        if (index >= 0) {
            const convertedRecord = { ...convertedCSVRecords[index] };
            swap.shortSwap = convertedRecord.shortSwap;
            swap.longSwap = convertedRecord.longSwap;
            swap.type = convertedRecord.type ?? swap.type ?? null;
            swap.tripleSwapDay = convertedRecord.tripleSwapDay ?? swap.tripleSwapDay;
            convertedCSVRecords.splice(index, 1);
        }
    });
    const getMaxId = (data: TemplateSwapModel[]): number => {
        return data.reduce((acc: number, swap: TemplateSwapModel) => Math.max(acc, swap.id), 0);
    };
    // const convertedCSVRecordsWithModifiedTSD = convertedCSVRecords.map((swap) => {
    //     if (templateType === TargetTemplateTypeEnum.swapsBySymbol && !swap.type) {
    //         swap.type = SwapTypeEnum.byPoints;
    //     }
    //     return swap;
    // });
    const newSwaps = [...swaps, ...convertedCSVRecords];
    newSwaps.forEach((record) => {
        if (record.id === 0) {
            record.id = getMaxId(newSwaps) + 1;
        }
    });
    return newSwaps;
};
