import React, { FC } from 'react';
import styles from './Modifiers.module.scss';
import Edit from '@assets/icons/edit.svg';
import Add from '@assets/icons/add.svg';

type Props = {
    onClick: () => void;
    icon: 'edit' | 'add';
};

export const ModifierEditButton: FC<Props> = ({ onClick, icon }) => (
    <div className={styles.editButton} onClick={onClick}>
        {icon === 'edit' ? <img alt="Edit" src={Edit} /> : <img alt="Add" src={Add} />}
    </div>
);
