import React, { FC } from 'react';
import Text from 'antd/lib/typography/Text';
import styles from './FieldLabel.module.scss';
import cn from 'classnames';

export const FieldLabel: FC<{
    title: string;
    required?: boolean;
    children?: React.ReactNode;
    className?: string;
}> = ({ title, required, children, className }) => (
    <Text className={cn(styles.container, className)}>
        {title}
        {required && <span className={styles.required}>*</span>}
        <span className={styles.child}>{children}</span>
    </Text>
);
