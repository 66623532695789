import React, { FC } from 'react';
import { Button, Divider } from 'antd';
import styles from './FormFooter.module.scss';
import { OperationType } from 'types/commonTypes';

export const FormFooter: FC<{
    onSubmit: () => void;
    type: OperationType;
    isDirty: boolean;
    isValid: boolean;
    isSubmitting?: boolean;
    onBack?: () => void;
    onReset?: () => void;
    submitBtnTitle?: string;
    isShowBackBtn?: boolean;
    backBtnType?: 'default' | 'link' | 'text' | 'ghost' | 'primary' | 'dashed';
}> = ({
    onBack,
    onReset,
    onSubmit,
    type,
    isDirty,
    isValid,
    submitBtnTitle = 'Apply',
    isSubmitting,
    isShowBackBtn = true,
    backBtnType = 'default',
}) => {
    return (
        <>
            <Divider />
            <div className={isShowBackBtn ? styles.modalFooterButtons : styles.modalFooterButton}>
                {isShowBackBtn && (
                    <Button key="back" onClick={onBack} type={backBtnType} className={styles.footerButton}>
                        Back
                    </Button>
                )}

                <div className={styles.btnBlock}>
                    {onReset && (
                        <Button
                            key="reset"
                            onClick={onReset}
                            type="ghost"
                            disabled={!isDirty}
                            className={styles.footerButton}
                        >
                            Reset
                        </Button>
                    )}
                    <Button
                        key="submit"
                        type="primary"
                        onClick={onSubmit}
                        disabled={!isValid || !isDirty}
                        className={styles.footerButton}
                        loading={isSubmitting}
                    >
                        {submitBtnTitle === '' ? (type === OperationType.update ? 'Update' : 'Create') : submitBtnTitle}
                    </Button>
                </div>
            </div>
        </>
    );
};
