/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, createContext, memo, useContext, useMemo } from 'react';
import { Button, Modal } from 'antd';
import { CloseOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import styles from './rightPanel.module.scss';
import cn from 'classnames';
import { Resizable } from 're-resizable';
import { useLocalStorage, useWindowSize } from '@utils/hooks';
import { FormOverlay } from './Overlay/Overlay';
import { LS_FORM_WIDTH_KEY } from '@utils';

type Props = {
    isOpen?: boolean;
    isTouched?: boolean;
    onClose?: () => void;
    children: React.ReactNode;
    resizable?: boolean;
    isOverlayEnabled?: boolean;
};

const EXTENDABLE_FORM_BREAKPOINT = 1024;
const FORM_MIN_WIDTH = 382;
export const AppLayoutContext = createContext<any>({ isSidebarCollapsed: false });
export const RightPanelWrapper: FC<Props> = memo(
    ({ isOpen, isTouched, onClose, children, resizable = true, isOverlayEnabled = false }) => {
        const { isSidebarCollapsed } = useContext(AppLayoutContext);
        const [displayWidth] = useWindowSize();
        const [LSFormWidth, setLSFormWidth] = useLocalStorage(LS_FORM_WIDTH_KEY, FORM_MIN_WIDTH);

        const formContent = useMemo(
            () =>
                isOpen && (
                    <>
                        <div className={styles.rightPanel__header}>
                            {onClose && (
                                <Button type="link" onClick={onClose}>
                                    <CloseOutlined />
                                </Button>
                            )}
                        </div>
                        {children}
                    </>
                ),
            [children, isOpen, onClose],
        );
        return useMemo<JSX.Element>(
            () =>
                displayWidth <= EXTENDABLE_FORM_BREAKPOINT || !resizable ? (
                    <div
                        className={cn(styles.rightPanel, isSidebarCollapsed ? styles.menu_collapsed : styles.menu_open)}
                        style={{ display: isOpen ? 'block' : 'none' }}
                    >
                        {formContent}
                    </div>
                ) : (
                    <Resizable
                        lockAspectRatio
                        maxWidth="40vw"
                        defaultSize={{ width: LSFormWidth, height: '100%' }}
                        minWidth={FORM_MIN_WIDTH}
                        onResizeStop={(_, __, ref) => {
                            setLSFormWidth(ref.clientWidth);
                        }}
                        enable={{ left: true }}
                        className={cn(styles.rightPanel, isSidebarCollapsed ? styles.menu_collapsed : styles.menu_open)}
                        style={{ display: isOpen ? 'block' : 'none' }}
                    >
                        {formContent}
                        {isOpen && isTouched && isOverlayEnabled && (
                            <FormOverlay
                                onClick={() => {
                                    Modal.confirm({
                                        title: 'Close Form?',
                                        icon: <ExclamationCircleOutlined className={styles.circleIcon} />,
                                        content:
                                            'The data inserted in this Form are not saved. If you close this Form, the data will be lost.',
                                        okText: 'Yes, close',
                                        okType: 'primary',
                                        cancelText: 'Continue, to edit',
                                        onOk: onClose,
                                        maskClosable: true,
                                    });
                                }}
                            />
                        )}
                    </Resizable>
                ),
            [displayWidth, formContent, isOpen, isSidebarCollapsed],
        );
    },
);

(RightPanelWrapper as FC<unknown>).displayName = 'RightPanelWrapper';
