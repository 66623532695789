import { PresetModel } from '@models/Preset';
import { GroupMasksValidation, SymbolMasksValidation } from '@pages/utils/maskValidations';
import { dateSchema } from '@utils/datetime';
import { yupEntityNameValidationWithSpaces } from '@utils/yupValidations';
import moment from 'moment';
import { TargetTemplateTypeEnum } from 'types/targetTemplateTypes';
import { TargetTypeEnum } from 'types/targetTypes';
import * as yup from 'yup';

export const schema = (presets: PresetModel[]) =>
    yup.object().shape({
        id: yup.string().default(''),
        name: yupEntityNameValidationWithSpaces
            .max(50, 'Must be less than 50 characters')
            .default('')
            .test('uniqueName', 'This name already exists', (value, { parent: { id } }) => {
                return !presets.find((m) => (m.name ?? '').toLowerCase() === value.toLowerCase() && m.id !== id);
            })
            .label('Name')
            .required('Required field'),
        templateType: yup
            .mixed()
            .oneOf(Object.values(TargetTemplateTypeEnum))
            .nullable()
            .default(null)
            .label('Template Type')
            .required('Required field'),
        type: yup
            .mixed()
            .oneOf(Object.values(TargetTypeEnum))
            .nullable()
            .default(null)
            .label('Type')
            .required('Required field'),
        symbolMasks: SymbolMasksValidation,
        groupMasks: GroupMasksValidation,
        presetServers: yup.array().default([]).label('Servers'),
        createDate: dateSchema.default(moment.utc()).label('Create Date'),
        updateDate: dateSchema.default(moment.utc()).label('Update Date'),
    });
