import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import Title from 'antd/es/typography/Title';
import { FieldContainer } from '@components/Form/FieldContainer/FieldContainer';
import { FieldLabel } from '@components/Form/FieldLabel/FieldLabel';
import { FormFooter } from '@components/Form/FormFooter/FormFooter';
import { Controller, useForm } from 'react-hook-form';
import { Modal, Input } from 'antd';
import { OperationType } from 'types/commonTypes';
import { newPresetCreateSchema } from './schema';
import { ValidationMessage } from '@components/Form/ValidationMessage/ValidationMessage';
import styles from './Preset.module.scss';
import { PresetCreateModel, PresetModel } from '@models/Preset';
import { presetsStore } from '@store/presetsStore';

const FORM_ID = 'CreatePreset';

const defaultTemplate = newPresetCreateSchema([]).cast({ name: '' });

export const CreatePresetModal = (props: {
    onSubmit: (values: { name: string }) => void;
    onCancel: () => void;
    isLoading: boolean;
}) => {
    const [allPresets, setAllPresets] = useState<PresetModel[]>([]);
    const { onSubmit, onCancel, isLoading } = props;

    const {
        handleSubmit,
        reset,
        formState: { isDirty, isValid, isSubmitSuccessful, errors },
        control,
    } = useForm<PresetCreateModel>({
        defaultValues: defaultTemplate,
        mode: 'all',
        resolver: yupResolver(newPresetCreateSchema(allPresets)),
    });

    useEffect(() => {
        presetsStore.getAllPresets().then((presets) => {
            setAllPresets(presets);
        });
    }, []);

    useEffect(() => {
        if (allPresets.length > 0) {
            reset(newPresetCreateSchema(allPresets).cast({ name: '' }));
        }
    }, [allPresets]);

    const handleReset = () => {
        reset(defaultTemplate);
    };

    const cancelHandler = () => {
        handleReset();
        onCancel();
    };

    useEffect(() => {
        handleReset();
    }, [isSubmitSuccessful]);

    return (
        <Modal className={styles.modal} open onCancel={cancelHandler} footer={null}>
            <Title level={3}>Create Target Template</Title>
            <form id={FORM_ID}>
                <FieldContainer>
                    <FieldLabel title="Name" required />
                    <Controller
                        control={control}
                        name="name"
                        render={({ field }) => {
                            return <Input {...field} value={field.value ?? undefined} />;
                        }}
                    />
                    <ValidationMessage message={errors.name?.message} />
                </FieldContainer>
                <FormFooter
                    isValid={isValid}
                    isDirty={isDirty}
                    type={OperationType.create}
                    onBack={cancelHandler}
                    onSubmit={handleSubmit(onSubmit)}
                    isSubmitting={isLoading}
                    submitBtnTitle="Create"
                />
            </form>
        </Modal>
    );
};
