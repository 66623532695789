/* eslint-disable @typescript-eslint/no-explicit-any */
import { EVERY_SYMBOL, S, SYMBOL, symbolAll } from '@constants';
import { MaskType } from '@models/Preset';
import { MaskKeys, TargetTemplateType } from 'types/commonTypes';
import { TargetTypeEnum } from 'types/targetTypes';
import * as yup from 'yup';
import { isArrayIncludesLowered, isArraysHasCrossesLowered, isArraysHasDuplicatesLowered } from './commonUtils';

export const getReplacesSToSymbol = (val: string): string => {
    if (val.toLowerCase().includes(symbolAll.toLowerCase())) {
        const index = val.toLowerCase().indexOf(symbolAll.toLowerCase());
        const newVal = val.substring(0, index) + '*' + val.substring(index + symbolAll.length);
        return newVal;
    }
    if (val.toLowerCase().includes(SYMBOL.toLowerCase())) {
        const index = val.toLowerCase().indexOf(SYMBOL.toLowerCase());
        const newVal = val.substring(0, index) + SYMBOL + val.substring(index + SYMBOL.length);
        return newVal;
    }
    if (val.toLowerCase().includes(S.toLowerCase())) {
        const index = val.toLowerCase().indexOf(S.toLowerCase());
        const newVal = val.substring(0, index) + SYMBOL + val.substring(index + S.length);
        return newVal;
    }
    return val;
};

export const rawTagValueToMasks = (value: string): string[] => {
    if (!value) return [];
    return value
        .split(' ')
        .join('')
        .split(',')
        .map((val) => val.trim())
        .filter((val) => !!val)
        .map(getReplacesSToSymbol);
};

export const symbolMaskValidations = {
    required: (value: any[] | undefined): boolean => {
        return value !== null && value !== undefined && value.length > 0;
    },
    maxLength: (value: any[] | undefined): boolean => {
        return !(JSON.stringify(value).length > 100);
    },
    mandatory: (value: any[] | undefined): boolean => {
        let modifiedArrayValues = [];
        if (value !== undefined && value !== null) {
            modifiedArrayValues = value!.map((e) => {
                if (e === '*') {
                    return symbolAll;
                } else {
                    return e;
                }
            });
        }
        const regexS = new RegExp(S);
        const regexSYMBOL = new RegExp(SYMBOL);
        const regexAllSymbols = new RegExp(symbolAll);
        if (modifiedArrayValues.length > 0) {
            return modifiedArrayValues.every(
                (e) => regexSYMBOL.test(e.toLowerCase()) || regexS.test(e.toLowerCase()) || regexAllSymbols.test(e),
            );
        }
        return true;
    },
    isTwice: (value: any[] | undefined): boolean => {
        if (Array.isArray(value)) {
            return value.some((val) => {
                const countS = val.toLowerCase().split(S).length - 1;
                const countSymbol = val.toLowerCase().split(SYMBOL).length - 1;
                return countS <= 1 && countSymbol <= 1 && countS + countSymbol <= 1;
            });
        }
        return true;
    },
    checkCase: (value: any[] | undefined): boolean => {
        let invalidCases = 0;
        if (Array.isArray(value)) {
            value.forEach((val) => {
                const indexS = val.toLowerCase().indexOf(S);
                if (indexS !== -1 && S !== val.substr(indexS, S.length)) invalidCases = invalidCases + 1;
                const indexSymbol = val.toLowerCase().indexOf(SYMBOL);
                if (indexSymbol !== -1 && SYMBOL !== val.substr(indexSymbol, SYMBOL.length))
                    invalidCases = invalidCases + 1;
            });
        }
        return invalidCases === 0;
    },
    isDuplicates: (value: any[] | undefined): boolean => {
        if (Array.isArray(value)) {
            const formattedValues = value
                .map((e) => e.toLowerCase().replace(S, SYMBOL))
                .map((e) => (e === '*' ? EVERY_SYMBOL : e));
            return formattedValues.length === Array.from(new Set(formattedValues)).length;
        }
        return true;
    },
};

export const groupMaskValidations = {
    maxLength: (value: any[] | undefined, type: string, templateType: string): boolean => {
        if (type === TargetTypeEnum.takerFix || type === 'Taker FIX') return true;
        if (!type && !templateType) return true;
        if (templateType === TargetTemplateType.swapsBySymbol) return true;
        return !(JSON.stringify(value).length > 100);
    },
    mandatory: (value: any[] | undefined, type: string, templateType: string): boolean => {
        if (type === TargetTypeEnum.takerFix || type === 'Taker FIX') return true;
        if (templateType === TargetTemplateType.swapsBySymbol) return true;
        if (!type && !templateType) return true;
        if (value !== null && value !== undefined) {
            return !!value.length;
        }
        return false;
    },
    isDuplicates: (value: any[] | undefined): boolean => {
        if (Array.isArray(value)) {
            const formattedValues = value.map((e) => e.toLowerCase());
            return formattedValues.length === Array.from(new Set(formattedValues)).length;
        }
        return true;
    },
};

export const SymbolMasksValidation = yup
    .array()
    .label('Symbol Mask')
    .test('isRequired', 'Required field', (value) => symbolMaskValidations.required(value))
    .test('isValid', 'Must be less than 100 characters', (value) => symbolMaskValidations.maxLength(value))
    .test('isValid', 'Symbol Mask must contain the required key {symbol} or {s} or *', (value) =>
        symbolMaskValidations.mandatory(value),
    )
    .test('isValid', "Don't use {symbol} tag twice", (value) => symbolMaskValidations.isTwice(value))
    .test('isValid', 'Use tag {symbol} in lower case', (value) => symbolMaskValidations.checkCase(value))
    .test('isValid', 'Duplicates are not allowed', (value) => symbolMaskValidations.isDuplicates(value))
    .default([]);

export const GroupMasksValidation = yup
    .array()
    .label('Group Mask')
    .test('isValid', 'Must be less than 100 characters', (value, { parent }) =>
        groupMaskValidations.maxLength(value, parent.type, parent.templateType),
    )
    .test('isRequired', 'Required field', (value, { parent }) =>
        groupMaskValidations.mandatory(value, parent.type, parent.templateType),
    )
    .test('isValid', 'Duplicates are not allowed', (value) => groupMaskValidations.isDuplicates(value))
    .default([]);

export const validateNewRawTag = ({
    value: incomingValue,
    masks,
    maskName,
}: {
    value: string;
    masks: MaskType;
    maskName: MaskKeys;
}): string[] => {
    const errorMessages: string[] = [];

    if (incomingValue) {
        const incomingValues = incomingValue
            .split(' ')
            .join('')
            .split(',')
            .map((val) => val.trim())
            .filter((val) => !!val);
        const mergedMasks = masks[maskName].concat(incomingValues);
        if (!isArraysHasCrossesLowered(masks[maskName], incomingValues)) {
            if (isArraysHasDuplicatesLowered(incomingValues)) {
                errorMessages.push('Duplicates are not allowed');
            }
            if (!isArraysHasDuplicatesLowered(mergedMasks)) {
                return [];
            }
        } else {
            errorMessages.push('Duplicates are not allowed');
        }
    }
    if (!incomingValue) {
        const sm = masks[MaskKeys.symbolMasks];
        if (
            sm.length > 0 &&
            !(isArrayIncludesLowered(sm, S) || isArrayIncludesLowered(sm, SYMBOL) || isArrayIncludesLowered(sm, '*'))
        ) {
            errorMessages.unshift('Symbol Mask must contain the required key {symbol} or {s} or *');
        }
    }
    return errorMessages;
};
