import React, { Context, useCallback, useContext, useState } from 'react';
import { TableExt } from '@components/TableExt/TableExt';
import { serversStore } from '@store/servers/serversStore';
import { ServerCreateBaseModel, ServerModel } from '@models/Server';
import { columns } from './columns';
import { useReaction } from '@utils/hooks';
import { EntityIdT } from '@components/TableExt/types';
import { openEditForm } from '@pages/utils/commonUtils';
import { FormContext } from '../ServersSettings';
import { Modal } from 'antd';
import { IFormContext, openCreateForm } from 'types/commonTypes';
import { CreateServerModal } from '../createServerModal/CreateServerModal';
import styles from '../ServerSettings.module.scss';
import { DEL_SERVER_CONFIRM_TEXT } from '@constants';
import { CreateButton } from '@components/Form/Buttons/CreateButton';
import { serverSchema } from '../serverPanelRight/schema';

const ROW_KEY = 'id';

export const ServersTable: React.FC = () => {
    const [servers] = useReaction<ServerModel[]>(() => serversStore.data);
    const [isLoading] = useReaction<boolean>(() => serversStore.isLoading);
    const [currentRow, setCurrentRow] = useState<EntityIdT | null>(null);
    const [isOpenCreateModal, setIsOpenCreateModal] = useState<boolean>(false);
    const currentFormContext = useContext<IFormContext<ServerModel>>(FormContext as Context<IFormContext<ServerModel>>);

    const handleOpenCreateServerForm = () => {
        setIsOpenCreateModal(true);
    };

    const renderToolbar = useCallback(
        () => [
            <div className={styles.buttonsGroup}>
                <CreateButton
                    onClick={handleOpenCreateServerForm}
                    disabled={!!currentFormContext.formData?.currentRow}
                    label="Create Server"
                />
            </div>,
        ],
        [currentFormContext.formData?.currentRow],
    );

    const onSubmitModal = (newRecord: ServerCreateBaseModel) => {
        const newServer: ServerModel = { ...serverSchema.cast({}), ...newRecord };
        openCreateForm(currentFormContext, setCurrentRow, newServer);
        setIsOpenCreateModal(false);
    };

    const handleEditClick = (id: string) => {
        const targetServer = servers.find((item) => item.id === id);
        if (targetServer) {
            openEditForm(targetServer, currentFormContext, setCurrentRow, ROW_KEY);
        }
    };

    const handleCancelCreateClick = () => {
        setIsOpenCreateModal(false);
    };

    const handleDeleteClick = (id: string) => {
        Modal.confirm({
            title: DEL_SERVER_CONFIRM_TEXT,
            okText: 'Delete',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk: async () => {
                serversStore.deleteServer(id);
            },
        });
    };

    return (
        <>
            <TableExt<ServerModel>
                rowKey={ROW_KEY}
                title="Servers Settings"
                columns={columns}
                dataSource={servers}
                focusedRowEntityId={currentRow}
                headerToolbar={renderToolbar}
                loading={isLoading}
                actionColumn={{
                    title: 'Actions',
                    dataIndex: 'id',
                    fixed: false,
                    onEdit: handleEditClick,
                    onDelete: handleDeleteClick,
                }}
            />
            {isOpenCreateModal && <CreateServerModal onSubmit={onSubmitModal} onCancel={handleCancelCreateClick} />}
        </>
    );
};
