/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, FC, memo, ReactElement, ReactNode, SetStateAction } from 'react';
import { FormInstance } from 'antd';
import { FieldData } from '@models';
import { OperationType } from 'types/commonTypes';
import { RealRightPanel } from './Real';

export type RightPanelProps<DataT = unknown> = {
    type?: OperationType;
    isOpen?: boolean;
    isSubmitting?: boolean;
    onClose?: () => void;
    onReset?: () => void;
    onSubmit?: (e?: React.BaseSyntheticEvent | undefined) => Promise<void>;
    onDelete?: (() => void) | (() => Promise<void>);
    deleteConfirmationMessage?: string;
    isTouched?: boolean;
    isValid?: boolean;
    name?: string;
    entityTitle?: string;
    labelText?: string;
    hideFooter?: boolean;
    resizable?: boolean;
    isOverlayEnabled?: boolean;
    submitBtnTitle?: string;
    isShowSpinner?: boolean;
    customButtons?: React.JSX.Element | null;

    children:
        | undefined
        | ReactElement
        | (ReactElement | undefined)[]
        | ((params: {
              formData: FieldData[];
              props: RightPanelProps<DataT>;
              form: FormInstance<DataT>;
              setFormData: Dispatch<SetStateAction<FieldData[]>>;
          }) => ReactNode);
};

export const RightPanel = memo((props: RightPanelProps<unknown>) => {
    return <RealRightPanel {...props} />;
}) as <T>(props: RightPanelProps<T>) => ReactElement;

(RightPanel as FC<unknown>).displayName = 'RightPanel';
