import { historyStore } from './history/historyStore';
import { serversStore } from './servers/serversStore';
import { settingsStore } from './settings/settingsStore';
import { presetsStore } from './presetsStore';
import { templatesControllerStore } from './templatesControllerStore';
import { templatesDividentStore } from './templatesDividentStore';
import { templatesSwapStore } from './templatesSwapStore';

export const resetStore = (): void => {
    historyStore.reset();
    serversStore.reset();
    settingsStore.reset();
    presetsStore.reset();
    templatesControllerStore.reset();
    templatesDividentStore.reset();
    templatesSwapStore.reset();
};
