import { TemplateSwapModel } from '@models/Templates';
import { yupBigNumber, yupEntityNameValidationWithSpaces } from '@utils/yupValidations';
import Big from 'big.js';
import { DaysEnum } from 'types/days';
import { SwapTypeEnum } from 'types/swapsTypes';
import * as yup from 'yup';

export const swapSchema = (symbols: TemplateSwapModel[]) =>
    yup.object().shape({
        id: yup.number().default(0).required(),
        symbol: yupEntityNameValidationWithSpaces
            .max(50, 'Must be less than 50 characters')
            .test('uniqueName', 'This name already exists', (value, { parent: { id } }) => {
                return !symbols.find((m) => (m.symbol ?? '') === value! && m.id !== id);
            })
            .required('Required field')
            .default(''),
        shortSwap: yupBigNumber().default(Big(0)).required('Required field'),
        longSwap: yupBigNumber().default(Big(0)).required('Required field'),
        type: yup
            .mixed()
            .nullable()
            .default(null)
            .oneOf([...Object.values(SwapTypeEnum), null]),
        tripleSwapDay: yup
            .mixed()
            .nullable()
            .default(null)
            .oneOf([...Object.values(DaysEnum), null]),
        swapsEnabled: yup.boolean().default(false),
    });
