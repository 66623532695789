import React, { createContext, useEffect } from 'react';
import { IFormContext } from 'types/commonTypes';
import styles from './settingsForm/settingsForm.module.scss';
import { SettingsForm } from './settingsForm/SettingsForm';
import { SettingsModel } from '@models/Settings';
import { settingsStore } from '@store/settings/settingsStore';

export const FormContext = createContext<IFormContext<SettingsModel>>(null!);

export const Settings = () => {
    useEffect(() => {
        settingsStore.getData();
        return () => {
            settingsStore.reset();
        };
    }, []);

    return (
        <div className={styles.pageWrapper}>
            <div className={styles.contentWrapper}>
                <SettingsForm />
            </div>
        </div>
    );
};
