import { CustomColumnType } from '@components/TableExt/types';
import ErrorValidation from '@assets/icons/errorValidation.svg';
import { Tooltip } from 'antd';
import styles from '@pages/page-styles.module.scss';
import React from 'react';
import { ValidationMessageType, ValidationResponseDataType, ValidationResponseModel } from '@models/Swaps';
import { renderSwapType, SwapTypesListShort } from 'types/swapsTypes';
import { AllDaysList, renderDay, WeekDaysList } from 'types/days';
import { Pair } from '@components/Pair/Pair';
import { AvaliableTypes } from '@models/Server';
import { formatNumber } from '@utils/number';
import { TargetTypeEnum } from 'types/targetTypes';
import { ProblemsList } from './potentialProblems/ProblemList/ProblemsList';

const tableColKeys = {
    id: 'id',
    name: 'name',
    server: 'serverName',
    group: 'groupName',
    errorsCount: 'errorsCount',
    tripleSwapDay: 'tripleSwapDay',
    short: 'short',
    long: 'long',
    status: 'status',
    description: 'description',
};

const errorsCounterRender = ({ data }: ValidationResponseModel) => {
    const validationMessages = data.map((item) => item.validationMessages).flat();
    const errorsCount = validationMessages.filter((item) => item.status === 0).length;
    const warningsCount = validationMessages.filter((item) => item.status === 1).length;
    const infosCount = validationMessages.filter((item) => item.status === 2).length;
    const othersCount = validationMessages.filter((item) => item.status > 2).length;

    const messages: string[] = [];
    if (errorsCount === 1) messages.push(`${errorsCount} Error`);
    if (errorsCount > 1) messages.push(`${errorsCount} Errors`);
    if (warningsCount === 1) messages.push(`${warningsCount} Warning`);
    if (warningsCount > 1) messages.push(`${warningsCount} Warnings`);
    if (infosCount === 1) messages.push(`${infosCount} Info`);
    if (infosCount > 1) messages.push(`${infosCount} Infos`);
    if (othersCount > 1) messages.push(`${othersCount} Other`);

    if (messages.length === 0) return null;

    return <span style={{ fontWeight: 'bold', paddingLeft: '80px' }}>{messages.join(', ')}</span>;
};

export const columns: (
    avaliableServers: AvaliableTypes[],
    isTripleSwapDay: boolean,
) => CustomColumnType<ValidationResponseModel>[] = (avaliableServers, isTripleSwapDay) => {
    return [
        {
            title: 'Servers',
            dataIndex: tableColKeys.server,
            align: 'left',
            width: 160,
            filterOptions: {
                type: 'Checkbox',
                getFilterPreviewValue: (value) => {
                    if (Array.isArray(value)) {
                        return value.join(', ');
                    }
                    return value?.toString() ?? '';
                },
                checkboxValues: () => {
                    return avaliableServers.map((item) => ({
                        compareValue: item.value,
                        title: item.text,
                    }));
                },
            },
        },
        {
            dataIndex: tableColKeys.description,
            render: (value, record) => {
                if (record.status === 1) {
                    return (
                        <span>
                            <span style={{ fontWeight: 'bold' }}>Warning</span>: no changes will be made. Check the
                            configuration.
                        </span>
                    );
                }
                if (record.status === 0 && record.data.length > 0) {
                    return (
                        <span>
                            <span style={{ fontWeight: 'bold' }}>Error</span>: Server{' '}
                            <span style={{ fontWeight: 'bold' }}>{record.serverName}</span> is not available due to some
                            reason
                            <span>
                                {' '}
                                <Tooltip title={value}>
                                    <img style={{ height: '25px', width: '25px' }} alt="logo" src={ErrorValidation} />
                                </Tooltip>
                            </span>
                        </span>
                    );
                }
                return record.data.length > 0 ? (
                    <div className={isTripleSwapDay ? styles.errorCounterFIX : styles.errorCounterMT}>
                        {errorsCounterRender(record)}
                    </div>
                ) : (
                    value
                );
            },
            align: 'left',
        },
    ];
};

const tableColExpandedKeys = {
    id: 'id',
    name: ['symbolInfo', 'name'],
    group: ['symbolInfo', 'group'],
    tripleSwapDay: ['symbolInfo', 'tripleSwapDay'],
    short: ['symbolInfo', 'short'],
    long: ['symbolInfo', 'long'],
    type: ['symbolInfo', 'type'],
    validationMessages: 'validationMessages',
};

export const columnsExpandData: (serverType: TargetTypeEnum) => CustomColumnType<ValidationResponseDataType>[] = (
    serverType,
) => {
    const shortCol = {
        title: 'Short (Old)',
        width: 160,
        dataIndex: tableColExpandedKeys.short,
        render: (value, obj) => <div>{Pair(formatNumber(value), formatNumber(obj.symbolInfo?.oldShort))}</div>,
        align: 'left',
    } as CustomColumnType<ValidationResponseDataType>;

    const longCol = {
        title: 'Long (Old)',
        width: 160,
        dataIndex: tableColExpandedKeys.long,
        render: (value, obj) => <div>{Pair(formatNumber(value), formatNumber(obj.symbolInfo?.oldLong))}</div>,
        align: 'left',
    } as CustomColumnType<ValidationResponseDataType>;

    const shortLongCols = serverType === TargetTypeEnum.takerFix ? [shortCol, longCol] : [longCol, shortCol];

    return [
        {
            title: 'Symbol',
            width: 160,
            dataIndex: tableColExpandedKeys.name,
            filterOptions: { type: 'Search' },
            align: 'left',
        },
        {
            title: 'Group',
            width: 160,
            dataIndex: tableColExpandedKeys.group,
            filterOptions: { type: 'Search' },
            align: 'left',
        },
        ...shortLongCols,
        {
            title: 'Potential problems1',
            dataIndex: tableColExpandedKeys.validationMessages,
            render: (value: ValidationMessageType[]) => {
                return <ProblemsList values={value} />;
            },
            align: 'left',
        },
    ];
};

export const columnsExpandDataTypesTSD: (
    serverType: TargetTypeEnum,
) => CustomColumnType<ValidationResponseDataType>[] = (serverType) => {
    const shortCol = {
        title: 'Short (Old)',
        width: 160,
        dataIndex: tableColExpandedKeys.short,
        render: (value, record) => {
            const old = record.symbolInfo?.oldShort;
            return <div>{Pair(formatNumber(value), formatNumber(old))}</div>;
        },
        align: 'left',
    } as CustomColumnType<ValidationResponseDataType>;

    const longCol = {
        title: 'Long (Old)',
        width: 160,
        dataIndex: tableColExpandedKeys.long,
        render: (value, record) => {
            const old = record.symbolInfo?.oldLong;
            return <div>{Pair(formatNumber(value), formatNumber(old))}</div>;
        },
        align: 'left',
    } as CustomColumnType<ValidationResponseDataType>;

    const shortLongCols = serverType === TargetTypeEnum.takerFix ? [shortCol, longCol] : [longCol, shortCol];

    return [
        {
            title: 'Symbol',
            width: 160,
            dataIndex: tableColExpandedKeys.name,
            filterOptions: { type: 'Search' },
            align: 'left',
        },
        ...shortLongCols,
        {
            title: 'Type (Old)',
            dataIndex: tableColExpandedKeys.type,
            width: 160,
            render: (value, record) => {
                return <div>{Pair(renderSwapType(value), renderSwapType(record.symbolInfo?.oldType))}</div>;
            },
            align: 'left',
            filterOptions: {
                type: 'Checkbox',
                getFilterPreviewValue: (value) => {
                    if (Array.isArray(value)) {
                        return value.map((item) => renderSwapType(item)).join(', ');
                    }
                    return value?.toString() ?? '';
                },
                checkboxValues: () =>
                    SwapTypesListShort.map((item) => {
                        return {
                            compareValue: item.key,
                            title: item.title,
                        };
                    }),
            },
        },
        {
            title: 'Triple Swap Day (Old)',
            width: 180,
            dataIndex: tableColExpandedKeys.tripleSwapDay,
            render: (value, record) => {
                return <div>{Pair(renderDay(value), renderDay(record.symbolInfo?.oldTripleSwapDay))}</div>;
            },
            filterOptions: {
                type: 'Checkbox',
                getFilterPreviewValue: (value) => {
                    if (Array.isArray(value)) {
                        return value.map((item) => renderDay(item.trim().toLowerCase())).join(', ');
                    }
                    return value?.toString() ?? '';
                },
                checkboxValues: () =>
                    AllDaysList.map((item) => ({
                        compareValue: item.key,
                        title: item.title,
                    })),
            },
            align: 'left',
        },
        {
            title: 'Potential problems',
            dataIndex: tableColExpandedKeys.validationMessages,
            render: (value: ValidationMessageType[]) => {
                return <ProblemsList values={value} />;
            },
            align: 'left',
        },
    ];
};

export const columnsExpandDataTSD: (serverType: TargetTypeEnum) => CustomColumnType<ValidationResponseDataType>[] = (
    serverType,
) => {
    const shortCol = {
        title: 'Short (Old)',
        width: 160,
        dataIndex: tableColExpandedKeys.short,
        render: (value, obj) => <div>{Pair(formatNumber(value), formatNumber(obj.symbolInfo?.oldShort))}</div>,
        align: 'left',
    } as CustomColumnType<ValidationResponseDataType>;
    const longCol = {
        title: 'Long (Old)',
        width: 160,
        dataIndex: tableColExpandedKeys.long,
        render: (value, obj) => <div>{Pair(formatNumber(value), formatNumber(obj.symbolInfo?.oldLong))}</div>,
        align: 'left',
    } as CustomColumnType<ValidationResponseDataType>;

    const shortLongCols = serverType === TargetTypeEnum.takerFix ? [shortCol, longCol] : [longCol, shortCol];

    return [
        {
            title: 'Symbol',
            width: 160,
            dataIndex: tableColExpandedKeys.name,
            filterOptions: { type: 'Search' },
            align: 'left',
        },
        ...shortLongCols,
        {
            title: 'Triple Swap Day (Old)',
            width: 180,
            dataIndex: tableColExpandedKeys.tripleSwapDay,
            render: (value, record) => {
                return <div>{Pair(renderDay(value), renderDay(record.symbolInfo?.oldTripleSwapDay))}</div>;
            },
            filterOptions: {
                type: 'Checkbox',
                getFilterPreviewValue: (value) => {
                    if (Array.isArray(value)) {
                        return value.map((item) => renderDay(item.trim().toLowerCase())).join(', ');
                    }
                    return value?.toString() ?? '';
                },
                checkboxValues: () =>
                    WeekDaysList.map((item) => ({
                        compareValue: item.key,
                        title: item.title,
                    })),
            },
            align: 'left',
        },
        {
            title: 'Potential problems',
            dataIndex: tableColExpandedKeys.validationMessages,
            render: (value: ValidationMessageType[]) => {
                return <ProblemsList values={value} />;
            },
            align: 'left',
        },
    ];
};
