import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import Title from 'antd/es/typography/Title';
import { FieldContainer } from '@components/Form/FieldContainer/FieldContainer';
import { FormFooter } from '@components/Form/FormFooter/FormFooter';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';
import { Modal, Radio } from 'antd';
import { ServerCreateBaseModel } from '@models/Server';
import { OperationType, targetTypesArr } from 'types/commonTypes';
import { createServerSchema } from './schema';
import styles from './Servers.module.scss';

const FORM_ID = 'CreateServer';

const defaultServer = createServerSchema.cast({});

export const CreateServerModal: React.FC<{
    onSubmit: (values: ServerCreateBaseModel) => void;
    onCancel: () => void;
}> = ({ onSubmit, onCancel }) => {
    const {
        handleSubmit,
        reset,
        formState: { isValid },
        control,
    } = useForm<ServerCreateBaseModel>({
        defaultValues: defaultServer,
        mode: 'all',
        resolver: yupResolver(createServerSchema),
    });

    const handleReset = () => {
        reset(defaultServer);
    };

    const cancelHandler = () => {
        handleReset();
        onCancel();
    };

    const submitHandler: SubmitHandler<ServerCreateBaseModel> = (data) => {
        onSubmit({ ...data });
    };

    return (
        <Modal className={styles.modal} open onCancel={cancelHandler} footer={null}>
            <Title level={3}>Create Server</Title>
            <form id={FORM_ID}>
                <FieldContainer>
                    <Controller
                        control={control}
                        name="type"
                        render={({ field: { value, onChange }, field }) => (
                            <Radio.Group {...field} onChange={onChange} value={value} className={styles.serverTypes}>
                                {targetTypesArr.map((type) => (
                                    <Radio key={type.id} value={type.type}>
                                        {type.title}
                                    </Radio>
                                ))}
                            </Radio.Group>
                        )}
                    />
                </FieldContainer>
                <FormFooter
                    isValid={isValid}
                    isDirty={true}
                    type={OperationType.create}
                    onBack={cancelHandler}
                    onSubmit={handleSubmit(submitHandler)}
                    submitBtnTitle="Create"
                />
            </form>
        </Modal>
    );
};
