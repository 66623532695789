import React, { useMemo, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useReaction } from '@utils/hooks';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';
import { OperationType } from 'types/commonTypes';
import { SettingsModel } from '@models/Settings';
import { settingsStore } from '@store/settings/settingsStore';
import { FieldContainer } from '@components/Form/FieldContainer/FieldContainer';
import { FieldLabel } from '@components/Form/FieldLabel/FieldLabel';
import { InputNumber, Tooltip } from 'antd';
import { settingsSchema } from './schema';
import Title from 'antd/es/typography/Title';
import { FormFooter } from '@components/Form/FormFooter/FormFooter';
import styles from './settingsForm.module.scss';
import { formatNumber, numberInputFormatter } from '@utils/number';
import { ValidationMessage } from '@components/Form/ValidationMessage/ValidationMessage';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { NUM_INPUT_PRECISION } from '@constants/inputs';

const FORM_ID = 'settings';

export const SettingsForm: React.FC = () => {
    const [settingsData] = useReaction<SettingsModel>(() => settingsStore.data);
    const [isLoading] = useReaction<boolean>(() => settingsStore.isLoading);
    const [isLoadingBtn] = useReaction<boolean>(() => settingsStore.isLoadingBtn);
    const defaultValues = useMemo(() => settingsData, [settingsData]);
    const {
        handleSubmit,
        reset,
        control,
        formState: { isDirty, isValid, errors },
    } = useForm<SettingsModel>({
        defaultValues,
        mode: 'all',
        resolver: yupResolver(settingsSchema),
    });

    useEffect(() => {
        reset(settingsData);
    }, [settingsData]);

    const submitHandler: SubmitHandler<SettingsModel> = (data) => {
        settingsStore.updateData(data);
    };

    return (
        <FieldContainer className={styles.settingsFormWrapper}>
            <Title level={1}>Settings</Title>
            <form id={FORM_ID}>
                <FieldContainer className={styles.settingsFormContainer}>
                    <FieldContainer className={styles.settingsFormContainerLabel}>
                        <FieldLabel title="Swaps Difference Validator" required>
                            <Tooltip title="Checks if a new swap value is N times more/less than the previous one.">
                                <ExclamationCircleOutlined style={{ color: '#079b51' }} />
                            </Tooltip>
                        </FieldLabel>
                    </FieldContainer>
                    <FieldContainer className={styles.settingsFormContainerForm}>
                        <Controller
                            control={control}
                            name="maxDifferenceFactor"
                            render={({ field }) => (
                                <InputNumber<string>
                                    {...field}
                                    onChange={(value) => {
                                        field.onChange(value ? +value : null);
                                    }}
                                    disabled={isLoading}
                                    decimalSeparator="."
                                    formatter={numberInputFormatter}
                                    value={formatNumber(field.value, NUM_INPUT_PRECISION)}
                                    stringMode
                                    className={styles.settingsFormInput}
                                    addonAfter="times"
                                />
                            )}
                        />
                        <ValidationMessage message={errors.maxDifferenceFactor?.message} />
                    </FieldContainer>
                </FieldContainer>
                <FieldContainer className={styles.settingsFormContainer}>
                    <FieldContainer className={styles.settingsFormContainerLabel}>
                        <FieldLabel title="Delete Logs After" required>
                            <Tooltip title='Logs are stored in "apirequestlogs" table of DB'>
                                <ExclamationCircleOutlined style={{ color: '#079b51' }} />
                            </Tooltip>
                        </FieldLabel>
                    </FieldContainer>
                    <FieldContainer className={styles.settingsFormContainerForm}>
                        <Controller
                            control={control}
                            name="deleteLogInterval"
                            render={({ field }) => (
                                <InputNumber<string>
                                    {...field}
                                    onChange={(value) => {
                                        field.onChange(value ? +value : null);
                                    }}
                                    disabled={isLoading}
                                    precision={0}
                                    decimalSeparator="."
                                    formatter={numberInputFormatter}
                                    value={formatNumber(field.value, NUM_INPUT_PRECISION)}
                                    stringMode
                                    className={styles.settingsFormInput}
                                    addonAfter="days"
                                />
                            )}
                        />
                        <ValidationMessage message={errors.deleteLogInterval?.message} />
                    </FieldContainer>
                </FieldContainer>
                <FieldContainer className={styles.settingsFormFooter}>
                    <FormFooter
                        isValid={isValid}
                        isDirty={isDirty}
                        type={OperationType.create}
                        isShowBackBtn={false}
                        submitBtnTitle="Save"
                        onSubmit={handleSubmit(submitHandler)}
                        isSubmitting={isLoadingBtn || isLoading}
                    />
                </FieldContainer>
            </form>
        </FieldContainer>
    );
};
