import { get } from 'lodash';
import { FieldError } from 'react-hook-form';

export const getErrorMessage = (key: string, error?: FieldError): string => {
    if (error && typeof error === 'object') {
        const message = get(error, `${key}.message`);
        return message ?? '';
    }
    return '';
};
