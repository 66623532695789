import { GroupsValidateType } from '@models/SwapsUpdater';
import React from 'react';
import styles from '@pages/history/styles.module.scss';
import {
    AccountsDividendsType,
    GroupsDividendsValidateType,
    HistoryDetailsFIXDividendsServersType,
    HistoryDetailsFIXServersType,
    HistoryDetailsMTDividendsServersType,
    HistoryDetailsMTServersType,
} from '@models/History';

export const tableColKeys = {
    id: 'id',
    user: 'user',
    date: 'timestamp',
    event: 'event',
};

export const expandedtableColKeys = {
    id: 'id',
    number: 'number',
    symbols: 'symbols',
    server: 'serverName',
    updatedSwaps: 'updatedSwaps',
    adjustedDividends: 'adjustedDividends',
    name: 'name',
    group: 'groupName',
    errorsCount: 'errorsCount',
    tripleSwapDay: 'tripleSwapDay',
    short: 'short',
    long: 'long',
    description: 'validationDescription',
    result: 'result',
    type: 'type',
    login: 'login',
    logins: 'logins',
    symbol: 'symbol',
    orderId: 'orderId',
    balanceOperationId: 'balanceOperationId',
    val: 'value',
    accounts: 'accounts',
};
export const renderWarningErrorsAndSymbols = (
    record:
        | HistoryDetailsMTServersType
        | HistoryDetailsFIXServersType
        | HistoryDetailsMTDividendsServersType
        | HistoryDetailsFIXDividendsServersType,
    isInsideTable = true,
    isDividends = false,
) => {
    const groupSymbolsInfoHandler = (
        records:
            | HistoryDetailsMTServersType
            | HistoryDetailsFIXServersType
            | HistoryDetailsMTDividendsServersType
            | HistoryDetailsFIXDividendsServersType,
    ) => {
        let totalSymbolsUpdated = 0;
        let totalAccountsUpdated = 0;
        if (isDividends) {
            if ('groups' in records) {
                records.groups.map((group) => {
                    if ('accounts' in group) totalAccountsUpdated += group.accounts.length;
                });
            }
            if ('accounts' in records && records.accounts) {
                totalAccountsUpdated += records.accounts.length;
            }
            if ('groups' in records || 'accounts' in records) {
                return (
                    <span style={{ fontWeight: 'bold' }}>{`${totalAccountsUpdated - record.errorsCount!} ${
                        totalAccountsUpdated - record.errorsCount! === 1 ? 'account' : 'accounts'
                    } updated`}</span>
                );
            }
        }

        if ('groups' in records) {
            records.groups.map((group) => {
                if ('symbols' in group) totalSymbolsUpdated += group.symbols.length;
            });
        }

        if ('symbols' in records && records.symbols) {
            totalSymbolsUpdated += records.symbols.length;
        }
        if ('groups' in records || 'symbols' in records) {
            return (
                <span style={{ fontWeight: 'bold' }}>{`${totalSymbolsUpdated - record.errorsCount!} ${
                    totalSymbolsUpdated - record.errorsCount! === 1 ? 'symbol' : 'symbols'
                } updated`}</span>
            );
        }
    };
    if (!record.isValid) {
        return (
            <div>
                <span>
                    <span style={{ fontWeight: 'bold' }}>Error</span>: {record.error}
                </span>
            </div>
        );
    }
    // if (record.isNoChanges) {
    //     return (
    //         <span>
    //             <span style={{ fontWeight: 'bold' }}>Warning</span>: no changes will be made. Check the configuration.
    //         </span>
    //     );
    // }
    return (
        <div className={isInsideTable ? styles.additionalInfoErrorServerTable : styles.additionalInfoErrorServer}>
            {record.errorsCount !== null && record.errorsCount > 0 && (
                <div>
                    <span style={{ fontWeight: 'bold' }}>
                        {record.errorsCount === 1 ? `${record.errorsCount} Error ` : `${record.errorsCount} Errors `}
                    </span>
                </div>
            )}
            <span>{record.isValid && groupSymbolsInfoHandler(record)}</span>
        </div>
    );
};

export const renderWarningErrorsAndSymbolsFIX = (value: string, record: HistoryDetailsFIXServersType) => {
    const groupInfoHandler = ({ symbols }: HistoryDetailsFIXServersType) => {
        return (
            <span style={{ fontWeight: 'bold' }}>{`${symbols.length - record.errorsCount!} ${
                symbols.length - record.errorsCount! === 1 ? 'symbol' : 'symbols'
            } updated`}</span>
        );
    };
    if (!record.isValid) {
        return (
            <div>
                <span>
                    <span style={{ fontWeight: 'bold' }}>Error</span>: {record.error}
                </span>
            </div>
        );
    }
    // if (record.isNoChanges) {
    //     return (
    //         <span>
    //             <span style={{ fontWeight: 'bold' }}>Warning</span>: no changes will be made. Check the configuration.
    //         </span>
    //     );
    // }
    return (
        <div className={styles.additionalInfoErrorServer}>
            {record.errorsCount !== null && record.errorsCount > 0 && (
                <div>
                    <span style={{ fontWeight: 'bold' }}>
                        {record.errorsCount === 1 ? `${record.errorsCount} Error ` : `${record.errorsCount} Errors `}
                    </span>
                </div>
            )}
            <span>{groupInfoHandler(record)}</span>
        </div>
    );
};

export const renderErrorsAndUpdates = (
    value: number,
    record:
        | GroupsValidateType
        | GroupsDividendsValidateType
        | AccountsDividendsType
        | HistoryDetailsFIXDividendsServersType,
) => {
    if (value > 0) {
        if ('symbols' in record && record.symbols !== undefined && record.symbols.length > 0)
            return (
                //TODO Currently hidden
                // <div className={styles.additionalInfoErrorSymbol}>
                <div>
                    <span style={{ fontWeight: 'bold' }}>{value === 1 ? `${value} Error ` : `${value} Errors `}</span>
                    <span style={{ fontWeight: 'bold' }}>{`${record.symbols.length - value} ${
                        record.symbols.length === 1 ? 'symbol' : 'symbols'
                    } updated`}</span>
                </div>
            );
    }
    if (value === 0 && 'symbols' in record && record.symbols !== undefined && record.symbols.length > 0) {
        return (
            <div className={styles.additionalInfoSymbol}>
                <span style={{ fontWeight: 'bold' }}>{`${record.symbols.length} ${
                    record.symbols.length === 1 ? 'symbol' : 'symbols'
                } updated`}</span>
            </div>
        );
    }
    if (value === 0 && 'accounts' in record && record.accounts !== undefined && record.accounts.length > 0) {
        return (
            <div className={styles.additionalInfoSymbol}>
                <span style={{ fontWeight: 'bold' }}>{`Dividends charged to ${record.accounts.length} trading ${
                    record.accounts.length === 1 ? 'account' : 'accounts'
                }`}</span>
            </div>
        );
    }
    if (value > 0) {
        if ('accounts' in record && record.accounts !== undefined && record.accounts.length > 0)
            return (
                //TODO Currently hidden
                // <div className={styles.additionalInfoErrorSymbol}>
                <div>
                    <span style={{ fontWeight: 'bold' }}>{value === 1 ? `${value} Error ` : `${value} Errors `}</span>
                    <span style={{ fontWeight: 'bold' }}>{`Dividends charged to ${
                        record.accounts.length - value
                    } trading ${record.accounts.length === 1 ? 'account' : 'accounts'} updated`}</span>
                </div>
            );
    }
    if (value === 0 && 'dividends' in record && record.dividends !== undefined && record.dividends.length > 0) {
        return (
            <div className={styles.additionalInfoSymbol}>
                <span style={{ fontWeight: 'bold' }}>{`Number of balance ${
                    record.dividends.length === 1 ? 'operation' : 'operations'
                }: ${record.dividends.length} `}</span>
            </div>
        );
    }
    if (value > 0) {
        if ('dividends' in record && record.dividends !== undefined && record.dividends.length > 0)
            return (
                //TODO Currently hidden
                // <div className={styles.additionalInfoErrorSymbol}>
                <div>
                    <span style={{ fontWeight: 'bold' }}>{value === 1 ? `${value} Error ` : `${value} Errors `}</span>
                    <span style={{ fontWeight: 'bold' }}>{`Number of balance ${
                        record.dividends.length === 1 ? 'operation' : 'operations'
                    }:  ${record.dividends.length - value} `}</span>
                </div>
            );
    }
    return null;
};
