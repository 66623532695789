import React, { useEffect } from 'react';
import { SwapTemplatesTable } from './SwapTemplatesTable';
import { templatesSwapStore } from '@store/templatesSwapStore';
import styles from '@pages/page-styles.module.scss';

export const SwapTemplates = () => {
    useEffect(() => {
        templatesSwapStore.getAllSwapTemplates();
        return () => {
            templatesSwapStore.reset();
        };
    }, []);

    return (
        <div className={styles.pageWrapper}>
            <div className={styles.contentWrapper}>
                <SwapTemplatesTable />
            </div>
        </div>
    );
};
