import React from 'react';
import styles from './styles.module.scss';
import { ValidateErrorType } from '@models/ImportTemplates';
import { TableExt } from '@components/TableExt/TableExt';
import { CustomColumnType } from '@components/TableExt/types';
import { baseSorter } from '@components/TableExt/RowFilters/utils';

type Props = {
    errors: ValidateErrorType[];
    skipValueCol?: boolean;
};

export const columns: () => CustomColumnType<ValidateErrorType>[] = () => [
    {
        title: 'Value',
        dataIndex: 'value',
        align: 'left',
        width: 150,
        filterOptions: { type: 'Search' },
        sorter: (a, b) => baseSorter(a.value, b.value),
    },
    {
        title: 'Description',
        dataIndex: 'description',
        align: 'left',
        filterOptions: { type: 'Search' },
        sorter: (a, b) => baseSorter(a.value, b.value),
    },
];

export const ErrorsList = ({ errors, skipValueCol }: Props) => {
    return (
        <TableExt<ValidateErrorType>
            rowKey="id"
            columns={columns().filter((col) => !skipValueCol || col.dataIndex !== 'value')}
            dataSource={errors
                .map((error, index) => ({
                    ...error,
                    id: index.toString(),
                }))
                .sort((a, b) => baseSorter(a.value, b.value))}
            className={styles.errorsList}
            pagination={false}
            scroll={{ x: 800, y: 600 }}
            style={{ minHeight: 300, backgroundColor: 'white' }}
        />
    );
};
