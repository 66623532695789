import React, { useEffect, useState } from 'react';
import { Input, Radio, Select } from 'antd';
import styles from './styles.module.scss';
import { FieldContainer } from '@components/Form/FieldContainer/FieldContainer';
import { FieldLabel } from '@components/Form/FieldLabel/FieldLabel';
import { Controller, useForm } from 'react-hook-form';
import { ValidationMessage } from '@components/Form/ValidationMessage/ValidationMessage';
import { yupResolver } from '@hookform/resolvers/yup';
import { TargetTypeEnum, TargetTypesList } from 'types/targetTypes';
import { TargetTemplateSwapsTypeList, TargetTemplateTypeEnum } from 'types/targetTemplateTypes';
import Title from 'antd/es/typography/Title';
import { RowContainer } from '@components/Form/RowContainer/RowContainer';
import { ImportFinaltoType, SymbolModeEnum } from '@models/Templates';
import { schemaImportFinalto } from './schema';
import { FormFooter } from '@components/Form/FormFooter/FormFooter';
import { OperationType } from 'types/commonTypes';
import { API } from '@api/client/apiEndpoints';
import { Message, MessageType } from '@components/Message/Message';
import { convertServers, ServerModel } from '@models/Server';
import { baseSorter } from '@components/TableExt/RowFilters/utils';
import Available from '@assets/icons/availiable.svg';
import unAvailable from '@assets/icons/unavailiable.svg';
import classNames from 'classnames';

const FORM_ID = 'ImportFinaltoCSV';

type Props = {
    onCancel: () => void;
    onSubmit: (values: ImportFinaltoType) => void;
    isUploading: boolean;
};

export const ImportFinaltoForm = ({ onCancel, onSubmit, isUploading }: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [servers, setServers] = useState<ServerModel[]>([]);
    const {
        handleSubmit,
        watch,
        resetField,
        setValue,
        reset,
        formState: { isValid, errors, isDirty },
        control,
    } = useForm<ImportFinaltoType>({
        mode: 'all',
        defaultValues: schemaImportFinalto.cast({}),
        resolver: yupResolver(schemaImportFinalto),
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const submitHandler = (values: ImportFinaltoType) => {
        onSubmit(values);
    };

    const getServers = () => {
        setIsLoading(true);
        API.servers
            .getAll()
            .then(({ data }) => {
                const allServers = convertServers(data).sort((a, b) =>
                    baseSorter(b.createDate.unix(), a.createDate.unix()),
                );
                setServers(allServers);
            })
            .catch(() => {
                Message(MessageType.error, "Can't get servers.");
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getServers();

        return () => {
            reset(schemaImportFinalto.cast({}));
        };
    }, []);

    const templateType = watch('templateType');
    const takerType = watch('type');
    const availableServers = servers.filter((server) => server.type === takerType);

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <Title level={3}>Import from Finalto to point</Title>
                <form id={FORM_ID} encType="multipart/form-data" className={styles.form}>
                    <FieldContainer>
                        <FieldLabel title="Name" required />
                        <Controller
                            control={control}
                            name="name"
                            render={({ field }) => {
                                return <Input {...field} value={field.value ?? undefined} />;
                            }}
                        />
                        <ValidationMessage message={errors.name?.message} />
                    </FieldContainer>
                    <RowContainer className={styles.rowContainerRadio}>
                        <FieldContainer className={styles.typesTemplates}>
                            <FieldLabel title="Server Type" required className={styles.radioGroupLabel} />
                            <Controller
                                control={control}
                                name="type"
                                render={({ field: { value, onChange }, field }) => (
                                    <Radio.Group
                                        {...field}
                                        onChange={(val) => {
                                            if (
                                                val.target.value === 'fix' &&
                                                templateType !== TargetTemplateTypeEnum.swapsByGroups
                                            ) {
                                                if (
                                                    templateType === TargetTemplateTypeEnum.dividends ||
                                                    templateType === TargetTemplateTypeEnum.swapsBySymbol
                                                ) {
                                                    onChange(val);
                                                    return;
                                                }
                                                resetField('templateType');
                                                onChange(val);
                                            } else {
                                                onChange(val);
                                            }
                                            setValue('serverId', '');
                                        }}
                                        value={value}
                                        className={styles.radioGroup}
                                    >
                                        {TargetTypesList.map((type) => (
                                            <Radio
                                                key={type.id}
                                                disabled={
                                                    type.key === TargetTypeEnum.takerFix &&
                                                    templateType !== null &&
                                                    templateType === TargetTemplateTypeEnum.swapsByGroups
                                                }
                                                value={type.key}
                                            >
                                                {type.title}
                                            </Radio>
                                        ))}
                                    </Radio.Group>
                                )}
                            />
                        </FieldContainer>
                        <FieldContainer className={styles.typesTemplates}>
                            <FieldLabel title="Symbol Mode" required className={styles.radioGroupLabel} />
                            <Controller
                                control={control}
                                name="symbolMode"
                                render={({ field: { value, onChange }, field }) => (
                                    <Radio.Group
                                        {...field}
                                        onChange={onChange}
                                        value={value}
                                        className={styles.radioGroup}
                                    >
                                        <Radio key={SymbolModeEnum.FOREX} value={SymbolModeEnum.FOREX}>
                                            {SymbolModeEnum.FOREX}
                                        </Radio>
                                        <Radio key={SymbolModeEnum.CFD} value={SymbolModeEnum.CFD}>
                                            {SymbolModeEnum.CFD}
                                        </Radio>
                                    </Radio.Group>
                                )}
                            />
                        </FieldContainer>
                        <FieldContainer className={styles.typesTemplates}>
                            <FieldLabel title="Template Type " required className={styles.radioGroupLabel} />
                            <Controller
                                control={control}
                                name="templateType"
                                render={({ field: { value, onChange }, field }) => (
                                    <Radio.Group
                                        {...field}
                                        onChange={(val) => {
                                            if (
                                                takerType === TargetTypeEnum.takerFix &&
                                                takerType !== null &&
                                                val.target.value !== TargetTemplateTypeEnum.swapsBySymbol &&
                                                val.target.value !== TargetTemplateTypeEnum.dividends
                                            ) {
                                                resetField('templateType');
                                            } else {
                                                onChange(val);
                                            }
                                        }}
                                        value={value}
                                        className={styles.radioGroup}
                                    >
                                        {TargetTemplateSwapsTypeList.map((type) => (
                                            <Radio
                                                key={type.id}
                                                disabled={
                                                    takerType === TargetTypeEnum.takerFix &&
                                                    takerType !== null &&
                                                    type.key !== TargetTemplateTypeEnum.swapsBySymbol &&
                                                    type.key !== TargetTemplateTypeEnum.dividends
                                                }
                                                value={type.key}
                                            >
                                                {type.updaterLabel}
                                            </Radio>
                                        ))}
                                    </Radio.Group>
                                )}
                            />
                        </FieldContainer>
                    </RowContainer>
                    <FieldContainer>
                        <FieldLabel title="Server" required />
                        <Controller
                            control={control}
                            name="serverId"
                            render={({ field: { value, onChange, ...field } }) => {
                                return (
                                    <Select
                                        {...field}
                                        value={value}
                                        onChange={(val) => {
                                            const targetServer = availableServers.find((e) => e.id === val);
                                            if (!targetServer || !targetServer.available) {
                                                Message(
                                                    MessageType.error,
                                                    'Server is not available. Please select available server.',
                                                );
                                            } else {
                                                onChange(val);
                                            }
                                        }}
                                        loading={isLoading}
                                        options={availableServers
                                            .sort((a, b) => baseSorter(b.available, a.available))
                                            .map((item) => ({
                                                value: item.id,
                                                label: (
                                                    <div
                                                        className={classNames({
                                                            [styles.disabledServer]: !item.available,
                                                        })}
                                                        title={item.name}
                                                    >
                                                        <img
                                                            alt="logo"
                                                            className={styles.serverOptionIcon}
                                                            src={!!item?.available ? Available : unAvailable}
                                                        />
                                                        {item.name}
                                                    </div>
                                                ),
                                            }))}
                                    />
                                );
                            }}
                        />
                        <ValidationMessage message={errors.serverId?.message} />
                    </FieldContainer>
                    <FieldContainer>
                        <FieldLabel title="File" required />
                        <Controller
                            control={control}
                            name="file"
                            render={({ field: { value, onChange, ...field } }) => {
                                console.log(value);
                                console.log(value?.name);
                                return (
                                    <Input
                                        {...field}
                                        onChange={(event) => {
                                            onChange(event.target.files ? event.target.files[0] : undefined);
                                        }}
                                        allowClear
                                        type="file"
                                        placeholder="Attach .csv or .xlsx file"
                                        id="importFile_input"
                                        accept=".csv,.xlsx"
                                    />
                                );
                            }}
                        />
                        <ValidationMessage message={errors.file?.message} />
                    </FieldContainer>
                    <FormFooter
                        isValid={isValid && !isLoading}
                        isDirty={isDirty}
                        type={OperationType.create}
                        onBack={onCancel}
                        onSubmit={handleSubmit(submitHandler)}
                        isSubmitting={isUploading}
                        submitBtnTitle="Import"
                    />
                </form>
            </div>
        </div>
    );
};
