import React, { createContext, useEffect, useState } from 'react';
import styles from './ServerSettings.module.scss';
import { ServersTable } from '@pages/serversSettings/serverSettingsTable/ServersTable';
import { ServerModel } from '@models/Server';
import { serversStore } from '@store/servers/serversStore';
import { ServerPanelRight } from './serverPanelRight/ServerPanelRight';
import { IFormContext, IFormData } from 'types/commonTypes';

export const FormContext = createContext<IFormContext<ServerModel>>(null!);

export const ServersSettings = () => {
    const [formData, setFormData] = useState<IFormData<ServerModel> | undefined>(undefined);

    useEffect(() => {
        serversStore.getServers();
    }, []);

    return (
        <FormContext.Provider value={{ reset: () => setFormData(undefined), formData, setFormData }}>
            <div className={styles.pageWrapper}>
                <div className={styles.contentWrapper}>
                    <ServersTable />
                </div>
                {formData && <ServerPanelRight />}
            </div>
        </FormContext.Provider>
    );
};
