import React, { FC } from 'react';
import { Input } from 'antd';
import cn from 'classnames';
import { Control, Controller, FieldErrors, FieldNamesMarkedBoolean, FieldValues } from 'react-hook-form';
import { FieldContainer } from '@components/Form/FieldContainer/FieldContainer';
import { FieldLabel } from '@components/Form/FieldLabel/FieldLabel';
import { ValidationMessage } from '@components/Form/ValidationMessage/ValidationMessage';
import { PresetModel } from '@models/Preset';

type Props = {
    isLoading?: boolean;
    control: Control<PresetModel>;
    errors: FieldErrors<FieldValues>;
    dirtyFields: FieldNamesMarkedBoolean<FieldValues>;
    hideName?: boolean;
};

export const General: FC<Props> = ({ control, errors, dirtyFields, isLoading, hideName = false }) => {
    return (
        <>
            {!hideName && (
                <FieldContainer>
                    <FieldLabel title="Name" required />
                    <Controller
                        control={control}
                        name="name"
                        render={({ field }) => (
                            <Input
                                {...field}
                                disabled={isLoading}
                                className={cn({
                                    changed: dirtyFields.name,
                                    invalid: !!errors.name?.message,
                                })}
                                value={field.value ?? undefined}
                                size="middle"
                            />
                        )}
                    />
                    <ValidationMessage message={errors.name?.message} />
                </FieldContainer>
            )}

            {/* <FieldContainer>
                <FieldLabel title="Server Type" />
                <Controller
                    control={control}
                    name="type"
                    render={({ field }) => {
                        return (
                            <Select
                                {...field}
                                disabled
                                value={field.value}
                                options={TargetTypesList.map((item) => ({ value: item.key, label: item.title }))}
                            />
                        );
                    }}
                />
            </FieldContainer>
            <FieldContainer>
                <FieldLabel title="Template Type" />
                <Controller
                    control={control}
                    name="templateType"
                    render={({ field }) => {
                        return (
                            <Select
                                {...field}
                                disabled
                                value={field.value}
                                options={TargetTemplateTypeList.map((item) => ({
                                    value: item.key,
                                    label: item.title,
                                }))}
                            />
                        );
                    }}
                />
            </FieldContainer> */}
        </>
    );
};
