import { TableExt } from '@components/TableExt/TableExt';
import React, { useCallback, useState } from 'react';
import { Modal } from 'antd';
import Copy from '@assets/icons/copy.svg';
import { useNavigate } from 'react-router';
import { useReaction } from '@utils/hooks';
import { Message, MessageType } from '@components/Message/Message';
import styles from './styles.module.scss';
import { AppRoutes } from '@constants';
import { templatesControllerStore } from '@store/templatesControllerStore';
import { BaseTemplateModel, TemplateCreateModel, TemplateSwapModel } from '@models/Templates';
import { columns } from './columns';
import { CreateTemplateControllerModal } from '../createTemplateControllerModal/CreateTemplateControllerModal';
import { TemplatesControllerSwapsCSVRecord } from '@pages/templatesController/import/importCSV/csvUtils';
import { getTemplateNameById } from '../import/importCSV/executeImport';
import { ImportCSV } from '@pages/templatesController/import/importCSV/ImportCSV';
import { observer } from 'mobx-react';
import { CreateButton } from '@components/Form/Buttons/CreateButton';
import { ImportFinalto } from '../import/importFinalto/ImportFinalto';
import { colsToExport } from '@pages/templateControllerDetails/SwapsTable/columns';
import { SaveCSV } from '@components/TableExt/ExportCSV/SaveCSV';

export const ROW_KEY = 'id';

export const TemplatesControllerTable = observer(() => {
    const [isOpenCreateModal, setIsOpenCreateModal] = useState<boolean>(false);
    const [templates] = useReaction<BaseTemplateModel[]>(() => templatesControllerStore.data);
    const [isLoading] = useReaction<boolean>(() => templatesControllerStore.isLoading);
    const [isSaving] = useReaction<boolean>(() => templatesControllerStore.isSaving);
    const [template, setTemplate] = useState<BaseTemplateModel | null>();

    const navigate = useNavigate();

    const handleOpenCreateServerForm = () => {
        setIsOpenCreateModal(true);
    };

    const renderToolbar = useCallback(
        () => [
            <div className={styles.buttonsGroup}>
                <ImportFinalto />
                <CreateButton
                    onClick={handleOpenCreateServerForm}
                    label="Create Template"
                    tooltip="Create a new template"
                />
            </div>,
        ],
        [],
    );

    const onSubmitModalCreate = (value: TemplateCreateModel) => {
        templatesControllerStore.createTemplate(value).finally(() => {
            templatesControllerStore.getTemplatesAll();
        });
        setIsOpenCreateModal(false);
    };

    const handleCancelCreateClick = () => {
        setIsOpenCreateModal(false);
    };

    const handleCopyTemplateClick = (id: string) => {
        const targetTemplate = templates.find((templ) => templ.id === id);
        if (!targetTemplate) {
            Message(MessageType.error, `Can't find template to copy.`);
            return;
        }
        if (targetTemplate.name.length < 45) {
            templatesControllerStore.copyTemplate(id).finally(() => {
                templatesControllerStore.getTemplatesAll();
            });
        } else {
            Message(
                MessageType.error,
                `The file name would be too long for the copy. You can shorten the template name and try again.`,
            );
        }
    };

    const deleteCondition = (id: string) => {
        Modal.confirm({
            title: `Template will be deleted. This action cannot be undone.`,
            okText: 'Delete',
            okType: 'danger',
            cancelText: 'Cancel',
            onOk: async () => {
                templatesControllerStore.deleteTemplate(id).finally(() => {
                    templatesControllerStore.getTemplatesAll();
                });
            },
        });
    };

    const handleEditClick = (id: string) => {
        const targetTemplate = templates.find((item) => item.id === id);
        if (targetTemplate) {
            navigate(`${AppRoutes.templatesController}/${id}`);
        }
    };

    const setTargetTemplate = (id: string) => {
        const targetTemplate = templates.find((item) => item.id === id);
        if (targetTemplate) {
            setTemplate(targetTemplate);
        } else {
            Message(MessageType.error, `Can\'t import template. ${id} is not in the list of templates`);
        }
    };

    const onSubmitImportCSV = (records: TemplatesControllerSwapsCSVRecord[]) => {
        if (template) {
            templatesControllerStore.updateSwapsFromCSV({ templateId: template.id, records });
        }
    };

    return (
        <>
            <TableExt<BaseTemplateModel>
                rowKey={ROW_KEY}
                title="Symbols Templates"
                columns={columns}
                dataSource={templates}
                headerToolbar={renderToolbar}
                loading={isLoading || isSaving}
                actionColumn={{
                    title: 'Actions',
                    dataIndex: 'id',
                    fixed: false,
                    onEdit: handleEditClick,
                    custom: [
                        {
                            icon: () => <img alt="logo" src={Copy} />,
                            onClick: handleCopyTemplateClick,
                            text: 'Copy',
                        },
                        {
                            icon: () => <ImportCSV onSubmitImportCSV={onSubmitImportCSV} template={template} />,
                            onClick: (id) => {
                                setTargetTemplate(id);
                            },
                            text: 'Import',
                        },
                        {
                            icon: (id: string) => (
                                <SaveCSV<TemplateSwapModel>
                                    getDataToExport={() =>
                                        templatesControllerStore.getTemplateById(id).then((tmpl) => ({
                                            data: tmpl.swaps,
                                            cols: colsToExport({
                                                type: tmpl.type ?? null,
                                                templateType: tmpl.templateType ?? null,
                                            }),
                                        }))
                                    }
                                    fileName={getTemplateNameById(templates, id) ?? 'Symbols Template'}
                                    className={styles.exportCSVIcon}
                                    successText="Symbols Template was exported"
                                />
                            ),
                            onClick: () => {},
                            text: 'Export',
                        },
                    ],
                    onDelete: deleteCondition,
                }}
            />
            {isOpenCreateModal && (
                <CreateTemplateControllerModal onSubmit={onSubmitModalCreate} onCancel={handleCancelCreateClick} />
            )}
        </>
    );
});
