import { makeAutoObservable } from 'mobx';
import { Message, MessageType } from '@components/Message/Message';
import { API } from '@api/client/apiEndpoints';
import { convertServers, ServerModel, ServerModelToApiCreate, ServerModelToApiUpdate } from '@models/Server';
import { baseSorter } from '@components/TableExt/RowFilters/utils';

export class ServersStore {
    constructor() {
        makeAutoObservable(this);
    }

    isLoading: boolean = false;

    data: ServerModel[] = [];

    setData(value: ServerModel[]) {
        this.data = value;
    }

    async getServers() {
        this.isLoading = true;
        await API.servers
            .getAll()
            .then(({ data }) => {
                this.setData(convertServers(data).sort((a, b) => baseSorter(b.createDate.unix(), a.createDate.unix())));
            })
            .catch((e) => {
                Message(MessageType.error, e);
            })
            .finally(() => {
                this.isLoading = false;
            });
    }

    async deleteServer(id: string) {
        this.isLoading = true;
        await API.servers
            .delete(id)
            .then(() => {
                Message(MessageType.success, 'Server was deleted');
            })
            .catch((e) => {
                Message(MessageType.error, `Can't delete Server. ${e}`);
                Promise.reject(e);
            })
            .finally(() => {
                this.getServers();
            });
    }

    async create(record: ServerModel): Promise<void> {
        this.isLoading = true;
        return new Promise<void>((resolve, reject) => {
            API.servers
                .create(ServerModelToApiCreate(record))
                .then(() => {
                    this.getServers();
                    resolve();
                })
                .catch((e) => {
                    reject(e);
                    this.isLoading = false;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        });
    }

    async update(record: ServerModel) {
        this.isLoading = true;
        return new Promise<void>((resolve, reject) => {
            API.servers
                .update(ServerModelToApiUpdate(record))
                .then(() => {
                    this.getServers().finally(() => {
                        resolve();
                    });
                })
                .catch((e) => {
                    reject(e);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        });
    }

    reset() {
        this.data = [];
        this.isLoading = false;
    }
}

const serversStore = new ServersStore();
export { serversStore };
