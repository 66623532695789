import { isFilledString, isInEnum } from '@utils';

export enum ServerModifierEnum {
    markup = 'markup',
    opposite = 'opposite',
}

export const ServerModifiersList = [
    {
        id: 1,
        key: ServerModifierEnum.markup,
        name: 'Markup',
        shortName: 'M',
    },
    {
        id: 2,
        key: ServerModifierEnum.opposite,
        name: 'Opposite',
        shortName: 'O',
    },
];

export const ConvertStringToServerModifier = (value?: string | null): ServerModifierEnum | null => {
    if (!value || !isFilledString(value)) return null;
    const valueLower = value.toLowerCase().trim();
    if (!isInEnum(valueLower, ServerModifierEnum)) {
        return null;
    }
    if (valueLower === ServerModifierEnum.markup) return ServerModifierEnum.markup;
    if (valueLower === ServerModifierEnum.opposite) return ServerModifierEnum.opposite;
    return null;
};

export const renderModifier = (value: ServerModifierEnum | null | undefined): string => {
    const targetRecord = ServerModifiersList.find((item) => item.key === value);
    return targetRecord?.name ?? '';
};
