import { yupBigNumber, yupEntityNameValidationWithSpaces } from '@utils/yupValidations';
import Big from 'big.js';
import { ServerModifierEnum } from 'types/modifiers';
import * as yup from 'yup';

export const markupSchema = yup.object().shape({
    negative: yupBigNumber().label('Coefficient for negative values').default(Big(0)).required('Required field'),
    positive: yupBigNumber().label('Coefficient for positive values').default(Big(0)).required('Required field'),
});

export const oppositeSchema = yup.object().shape({
    longEnabled: yup.boolean().default(false).label('Long Enabled').required('Required field'),
    shortEnabled: yup.boolean().default(false).label('Short Enabled').required('Required field'),
});

export const PresetServerSchema = yup.object().shape({
    id: yup.string().default(''),
    serverId: yup.string().default('').label('Server'),
    state: yup.boolean().default(false),
    presetServerModifiers: yup.array().of(
        yup.object().shape({
            id: yup.string().default(''),
            orderId: yup.number().default(null),
            type: yup.string().default(''),
            name: yup.string().default(''),
            shortName: yupEntityNameValidationWithSpaces
                .label('Short Name')
                .default('')
                .max(20, 'Must be less than 20 characters')
                // @ts-ignore
                .test('uniqueName', 'Short name must be unique', (value, { from, parent }) => {
                    if (!from || !value) return true;
                    // @ts-ignore
                    const modifiers = from.find((m) => !!m.value.presetServerModifiers)?.value?.presetServerModifiers;
                    if (!modifiers) return true;
                    return !modifiers.find(
                        // @ts-ignore
                        (m) => (m.shortName ?? '').toLowerCase() === value.toLowerCase() && m.id !== parent.id,
                    );
                })
                .label('Short Name')
                .required('Required field'),
            data: yup.lazy((value, context) => {
                if (context?.parent?.type === undefined) return yup.object().shape({});
                return context?.parent?.type === ServerModifierEnum.markup ? markupSchema : oppositeSchema;
            }),
        }),
    ),
});
