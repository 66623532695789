import { isFilledString, isInEnum } from '@utils';

export enum SwapTypeEnum {
    byMoney = 'bymoney',
    byPoints = 'bypoints',
    byInterest = 'byinterest',
    byMarginCurrency = 'bymargincurrency',
    bygroupcurrency = 'bygroupcurrency',
    byinterestcurrent = 'byinterestcurrent',
    byinterestopen = 'byinterestopen',
    bycloseprice = 'bycloseprice',
    bybid = 'bybid',
    byprofitcurrency = 'byprofitcurrency',
}

export const SwapTypesListShort: Array<{ index: number; key: SwapTypeEnum; title: string; csvKey: string }> = [
    { index: 0, key: SwapTypeEnum.byMoney, title: 'By Money', csvKey: 'ByMoney' },
    { index: 1, key: SwapTypeEnum.byPoints, title: 'By Points', csvKey: 'ByPoints' },
];

export const SwapTypesList: Array<{ index: number; key: SwapTypeEnum; title: string; csvKey: string }> = [
    ...SwapTypesListShort,
    { index: 2, key: SwapTypeEnum.byInterest, title: 'By Interest', csvKey: 'ByInterest' },
    { index: 3, key: SwapTypeEnum.byMarginCurrency, title: 'By Margin Currency', csvKey: 'ByMarginCurrency' },
    { index: 4, key: SwapTypeEnum.bygroupcurrency, title: 'By Group Currency', csvKey: 'ByGroupCurrency' },
    { index: 5, key: SwapTypeEnum.byinterestcurrent, title: 'By Interest Current', csvKey: 'ByInterestCurrent' },
    { index: 6, key: SwapTypeEnum.byinterestopen, title: 'By Interest Open', csvKey: 'ByInterestOpen' },
    { index: 7, key: SwapTypeEnum.bycloseprice, title: 'By Close Price', csvKey: 'ByClosePrice' },
    { index: 8, key: SwapTypeEnum.bybid, title: 'By Bid', csvKey: 'ByBid' },
    { index: 9, key: SwapTypeEnum.byprofitcurrency, title: 'By Profit Currency', csvKey: 'ByProfitCurrency' },
];

export const ConvertStringToSwapTypes = (value?: string | null): SwapTypeEnum | null => {
    if (!value || !isFilledString(value)) return null;
    const valueLower = value.toLowerCase().trim();
    if (!isInEnum(valueLower, SwapTypeEnum)) {
        return null;
    }
    if (valueLower === SwapTypeEnum.byMoney) return SwapTypeEnum.byMoney;
    if (valueLower === SwapTypeEnum.byPoints) return SwapTypeEnum.byPoints;
    if (valueLower === SwapTypeEnum.byInterest) return SwapTypeEnum.byInterest;
    if (valueLower === SwapTypeEnum.byMarginCurrency) return SwapTypeEnum.byMarginCurrency;
    if (valueLower === SwapTypeEnum.bygroupcurrency) return SwapTypeEnum.bygroupcurrency;
    if (valueLower === SwapTypeEnum.byinterestcurrent) return SwapTypeEnum.byinterestcurrent;
    if (valueLower === SwapTypeEnum.byinterestopen) return SwapTypeEnum.byinterestopen;
    if (valueLower === SwapTypeEnum.bycloseprice) return SwapTypeEnum.bycloseprice;
    if (valueLower === SwapTypeEnum.bybid) return SwapTypeEnum.bybid;
    if (valueLower === SwapTypeEnum.byprofitcurrency) return SwapTypeEnum.byprofitcurrency;
    return null;
};

export const renderSwapType = (value: SwapTypeEnum | string | null | undefined): string => {
    const targetRecord = SwapTypesListShort.find(
        (item) => item.key === (value ? value.trim().replace(' ', '').toLowerCase() : null),
    );
    return targetRecord?.title ?? '';
};

// export const getBEKeySwapTypeEnum = (value: SwapTypeEnum): string => {
//     switch (value) {
//         case SwapTypeEnum.byMoney:
//             return 'bymoney';
//         case SwapTypeEnum.byPoints:
//             return 'bypoints';
//         case SwapTypeEnum.byInterest:
//             return 'byinterest';
//         case SwapTypeEnum.byMarginCurrency:
//             return 'bymargincurrency';
//         case SwapTypeEnum.bygroupcurrency:
//             return 'bygroupcurrency';
//         case SwapTypeEnum.byinterestcurrent:
//             return 'byinterestcurrent';
//         case SwapTypeEnum.byinterestopen:
//             return 'byinterestopen';
//         case SwapTypeEnum.bycloseprice:
//             return 'bycloseprice';
//         case SwapTypeEnum.bybid:
//             return 'bybid';
//         case SwapTypeEnum.byprofitcurrency:
//             return 'byprofitcurrency';
//         default:
//             return '';
//     }
// };
