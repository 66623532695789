import React, { useEffect } from 'react';
import { TemplatesControllerTable } from './templatesControllerTable/TemplatesControllerTable';
import styles from './templateController.module.scss';
import { templatesControllerStore } from '@store/templatesControllerStore';

export const TemplatesController = () => {
    useEffect(() => {
        templatesControllerStore.getTemplatesAll();
        return () => {
            templatesControllerStore.reset();
        };
    }, []);

    return (
        <div className={styles.pageWrapper}>
            <div className={styles.contentWrapper}>
                <TemplatesControllerTable />
            </div>
        </div>
    );
};
