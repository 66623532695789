import React from 'react';
import { CustomColumnType } from '@components/TableExt/types';
import { ServerModel } from '@models/Server';
import Available from '@assets/icons/availiable.svg';
import unAvailable from '@assets/icons/unavailiable.svg';
import styles from '../ServerSettings.module.scss';
import { baseSorter } from '@components/TableExt/RowFilters/utils';
import { longTextPrerender } from '@components/TableExt/utils';
import { renderTargetType, TargetTypesList } from 'types/targetTypes';

export const columns: CustomColumnType<ServerModel>[] = [
    {
        title: 'Available',
        dataIndex: 'available',
        width: 120,
        sorter: (a, b) => baseSorter(a.available, b.available),
        render: (value) => (
            <div className={styles.iconBlock}>
                <img alt="logo" src={value === true ? Available : unAvailable} />
            </div>
        ),
        align: 'center',
    },
    {
        title: 'Name',
        dataIndex: 'name',
        align: 'left',
        sorter: (a, b) => baseSorter(a.name, b.name),
        filterOptions: { type: 'Search' },
    },
    {
        title: 'URL',
        dataIndex: 'address',
        sorter: (a, b) => baseSorter(a.address, b.address),
        render: longTextPrerender,
        align: 'left',
        filterOptions: { type: 'Search' },
    },
    {
        title: 'Server Type',
        dataIndex: 'type',
        render: renderTargetType,
        sorter: (a, b) => baseSorter(a.type ? renderTargetType(a.type) : '', b.type ? renderTargetType(b.type) : ''),
        align: 'left',
        filterOptions: {
            type: 'Checkbox',
            getFilterPreviewValue: (value) => {
                if (Array.isArray(value)) {
                    return value.map((item) => renderTargetType(item)).join(', ');
                }
                return value?.toString() ?? '';
            },
            checkboxValues: () =>
                TargetTypesList.map((item) => ({
                    compareValue: item.key,
                    title: item.title,
                })),
        },
    },
];
